import React, { Component } from "react";
import { Line, Circle } from "rc-progress";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import RestDataSource from "../../services/restdatasource";
import { Global_var } from "../../global/global_var";

export class adminProfileComplitiomBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileComplitionPercent: "",
      userProfileCompletedData: "",
      userProfileFieldWeight: ""
    };

    RegisterUserService.getAdminUserAddress(
      "partyId=" + localStorage.getItem("userid-Token"),
      res => {
        let peraddress;
        let corsAddress;
        (res.responseListObject || []).forEach(function (addr) {
          if (addr.addressType === "permanent") {
            peraddress = addr;
          }
        });
        (res.responseListObject || []).forEach(function (addr) {
          if (addr.addressType === "correspondence") {
            corsAddress = addr;
          }
        });
        let userProfileAddressData = {
          perStreet: (peraddress || "").street,
          perStreet2: (peraddress || "").street2,
          percityId: (peraddress || "").cityId,
          perstateId: (peraddress || "").stateId,
          percountryId: (peraddress || "").countryId,
          perPostalCode: (peraddress || "").postalCode,

          corsStreet: (corsAddress || "").street,
          corsStreet2: (corsAddress || "").street2,
          corscityId: (corsAddress || "").cityId,
          corsstateId: (corsAddress || "").stateId,
          corscountryId: (corsAddress || "").countryId,
          corsPostalCode: (corsAddress || "").postalCode
        };
        let userProfileAddressDataWeight = {
          perStreet: 5,
          perStreet2: 5,
          percityId: 5,
          perstateId: 5,
          percountryId: 5,
          perPostalCode: 5,
          corsStreet: 5,
          corsStreet2: 5,
          corscityId: 5,
          corsstateId: 5,
          corscountryId: 5,
          corsPostalCode: 5
        };

        var obj = userProfileAddressData;
        var keys = Object.keys(obj);
        var filteredAddressData = keys.filter(function (key) {
          return obj[key];
        });
        let combinedweightvalueForAddress = 0;
        let fiterweighAddress = [];
        fiterweighAddress.push(userProfileAddressDataWeight);
        filteredAddressData.forEach(filteredElement => {
          combinedweightvalueForAddress +=
            fiterweighAddress[0][filteredElement];
        });
        this.dataSource = new RestDataSource(
          Global_var.BASEURL +
          Global_var.URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID +
          "?partyID=" +
          localStorage.getItem("userid-Token") +
          "&roleID=" +
          localStorage.getItem("roleId")
        );
        LoginService.TokenUser(res => {
          if (res.status === "success") {
            localStorage.setItem("jwt-token", res.responseObject);
            this.dataSource.GetData(res => {
              let userProfileCompletedData = {
                studentID: res.data.responseObject.studentID || "",
                mobileNo:
                  res.data.responseObject.mobileNo ||
                  res.data.responseObject.contactNo ||
                  "",
                emailID:
                  res.data.responseObject.emailID ||
                  res.data.responseObject.contactEmail ||
                  "",

                firstName: res.data.responseObject.firstName || "",
                middleName: res.data.responseObject.middleName || "",
                lastName: res.data.responseObject.lastName || "",
                secondaryEmailID:
                  res.data.responseObject.contactEmail2 ||
                  res.data.responseObject.secondaryEmailID ||
                  "",
                contactNo:
                  res.data.responseObject.contactNo2 ||
                  res.data.responseObject.contactNo ||
                  ""
              };
              let userProfileFieldWeight = {
                mobileNo: 7,
                emailID: 8,
                firstName: 5,
                middleName: 5,
                lastName: 5,
                contactNo: 5,
                secondaryEmailID: 5
              };
              var obj = userProfileCompletedData;
              var keys = Object.keys(obj);
              var filtered = keys.filter(function (key) {
                return obj[key];
              });

              let combinedweightvalue = 0;
              let fiterweigh = [];
              fiterweigh.push(userProfileFieldWeight);
              filtered.forEach(filteredElement => {
                combinedweightvalue += fiterweigh[0][filteredElement];
              });

              let combinedper =
                combinedweightvalue + combinedweightvalueForAddress;

              this.setState({
                profileComplitionPercent: combinedper,
                userProfileCompletedData: userProfileCompletedData,
                userProfileFieldWeight: userProfileFieldWeight
              });
            });
          }
        });
      }
    );
  }

  render() {
    return (
      <div>
        {this.props.checkFlag === "fromEditProfile" ? (
          <div>
            <center>
              <div className="profile-graph">
                <Circle
                  percent={(this.state || "").profileComplitionPercent}
                  strokeWidth="4"
                  strokeColor="#7bc248"
                  width="120px"
                  height="auto"
                />
              </div>
              <span className="profile-completion-title">
                {(this.state || "").profileComplitionPercent}% Completed
              </span>
            </center>
          </div>
        ) : (
          <div>
            <div className="profile-graph">
              <Line
                percent={(this.state || "").profileComplitionPercent}
                strokeWidth="4"
                strokeColor="#7bc248"
              />
            </div>
            <span className="profile-completion-title">
              {(this.state || "").profileComplitionPercent}% Completed
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default adminProfileComplitiomBar;
