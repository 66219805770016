export const FETCH_CALENDER_EVENTS_PENDING = 'FETCH_CALENDER_EVENTS_PENDING';
export const FETCH_CALENDER_EVENTS_SUCCESS = 'FETCH_CALENDER_EVENTS_SUCCESS';
export const FETCH_CALENDER_EVENTS_ERROR = 'FETCH_CALENDER_EVENTS_ERROR';

export function fetchCalenderEventsPending() {
    
    return {
        type: FETCH_CALENDER_EVENTS_PENDING
    }
}

export function fetchCalenderEventsSuccess(calender_events) {

    return {
        type: FETCH_CALENDER_EVENTS_SUCCESS,
        payload: calender_events
    }
}

export function fetchCalenderEventsError(error) {
    return {
        type: FETCH_CALENDER_EVENTS_ERROR,
        error: error
    }
}