import React, { Component } from "react";
import { connect } from "react-redux";
import {
  unassignedUsersReportError,
  unassignedUsersReport,
  unassignedUsersReportPending
} from "../../reducer/unassignedUsersReducers";
import { bindActionCreators } from "redux";
import { getUnassignedUsersReport } from "../../services/fetchStudentDashboard.service";
import { LoginService } from "../../services/login.service";
import AdminHeader from "../../components/dashboardheader/adminheader";
import FinxFooter from "../finxfooter/finxfooter";
import UnassignedUsersReport from "../../components/report/unassignedUsersReport";
import { PopUpPortal } from "../popups/PopUpPortal";


const mapStateToProps = state => ({
  unassignedUsersReport: unassignedUsersReport(state.unassignedUsersReportReducer),
  unassignedUsersReportPending: unassignedUsersReportPending(
    state.unassignedUsersReportReducer
  ),
  unassignedUsersReportError: unassignedUsersReportError(
    state.unassignedUsersReportReducer
  )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUnassignedUsersReport: getUnassignedUsersReport
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const unassignedUsersReportLanding = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
      });
      const { fetchUnassignedUsersReport } = this.props;

      fetchUnassignedUsersReport("productID=" + 0);

      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
        }
      });
    }

    render() {
      const {
        unassignedUsersReport,
        unassignedUsersReportPending
      } = this.props;
      ////console.log("assessmentReport",this.props.assessmentReport)
      if (!unassignedUsersReportPending) {
        return (
          <React.Fragment>
            <AdminHeader
              {...this.props}
            />
            <UnassignedUsersReport
              unassignedUsersReport={unassignedUsersReport}
            />
            <FinxFooter />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />
            </React.Fragment>
            )
      }
    }
  }
);

export default unassignedUsersReportLanding;
