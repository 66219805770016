import React, { Component } from "react";

export default class DiscountTooltip extends Component {
  render() {
    return (
      <>
        <div>
          Percentage Based : Enter percentage of discount for product level or
          cart level.
        </div>
        <div>
          Fixed Amount : Enter amount of discount for product level or cart
          level
        </div>
        <div>
          Free Gift : Link the Offers as free gift to purchase of product level
          or cart level
        </div>
      </>
    );
  }
}
