export const FETCH_PARTNER_PENDING = 'FETCH_PARTNER_PENDING';
export const FETCH_PARTNER_SUCCESS = 'FETCH_PARTNER_SUCCESS';
export const FETCH_PARTNER_ERROR = 'FETCH_PARTNER_ERROR';

export function fetchPartnerPending() {
    return {
        type: FETCH_PARTNER_PENDING,
    }
}

export function fetchPartnerSuccess(partner) {

    return {
        type: FETCH_PARTNER_SUCCESS,
        payload: partner
    }
}

export function fetchPartnerError(error) {
    return {
        type: FETCH_PARTNER_ERROR,
        error: error
    }
}