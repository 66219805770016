export const FETCH_MODULES_LIST_PENDING = 'FETCH_MODULES_LIST_PENDING';
export const FETCH_MODULES_LIST_SUCCESS = 'FETCH_MODULES_LIST_SUCCESS';
export const FETCH_MODULES_LIST_ERROR = 'FETCH_MODULES_LIST_ERROR';

export function fetchModulesListPending() {
    return {
        type: FETCH_MODULES_LIST_PENDING,
    }
}

export function fetchModulesListSuccess(modules) {

    return {
        type: FETCH_MODULES_LIST_SUCCESS,
        payload: modules
    }
}

export function fetchModulesListError(error) {
    return {
        type: FETCH_MODULES_LIST_ERROR,
        error: error
    }
}