import React from 'react';
// import Finxheader from '../finxheader/finxheader';
// import Finxfooter from '../finxfooter/finxfooter';
import FinxFooterLinks from "../finxfooter/finxfooterlinks"

export default class Disclaimer extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <React.Fragment>
                <section className="disclaimer pt-50 mb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1 className="text-center">Disclaimer</h1>
                                <p>
                                    <strong> General:</strong> This web site, www.finxpert.org contains features of services that AV Financial Experts Network Private Limited (FinX) offers or proposes to offer. The services, which are offered, may vary from time
                                    to time at the sole discretion of FinX, and shall be subject to change without notice. FinX exclusively reserves its right to decide the criteria based on which students and clients would be chosen to participate in the services
                                    provided by it. The information on this website is intended for general information regarding FinX, its products and services and not intended to address the circumstances of any particular individual or entity.</p>
                                <p>
                                    <strong> Usage:</strong> Use of this website is at the sole risk of the user/visitor. FinX may at any time edit, alter and or remove any information in whole or in part that may be available on this website and it shall not be
                                    held responsible for all or any actions that may subsequently result into any loss, damage and or liability.</p>
                                <p>
                                    <strong> Copyright:</strong> The copyright of the contents of this website including the logos, trademarks, word marks and other intellectual property belong exclusively to FinX. These contents shall only be read by those persons
                                    to whom it has been intended. No reprinting, reproduction, copying, distribution of the contents of this website in any manner whatsoever, in whole or in part, is permitted without the prior express written consent of FinX.</p>
                                <p>
                                    <strong> Hyperlinking:</strong> At many places in this website, you may find links to other websites /portals which are not under the control of FinX. FinX has no control over the nature, content and availability of those websites/portals.
                                    The inclusion of any such links does not necessarily imply a recommendation or endorse the views expressed in them. Mere presence of the link or its listing on this website should not be assumed as endorsement of any kind. FinX
                                    does not guarantee that these links will work all the time and we have no control over the availability of linked pages.</p>
                                <p>
                                    <strong> Accessibility:</strong> While every effort is made to keep the website available and accessible at all times, FinX takes no responsibility for, and will not be liable for, the website being temporarily unavailable due
                                    to any reason(s).</p>
                                <p>
                                    <strong> Cookies Policy:</strong> A cookie is a piece of software code that an internet web site sends to your browser when you access information at that site. This website may use cookies to improve performance and enhance user
                                    experience. By continuing to browse this site, you agree to the use of cookies. To view our cookie policy, click here. (PLEASE READ THIS POLICY CAREFULLY BEFORE USING OUR WEBSITE)</p>
                                <p>
                                    <strong> Jurisdiction:</strong> Any dispute arising out of the contents of this website shall be subject to the exclusive jurisdiction of the Courts in Mumbai (India) only.</p>
                                <p>
                                    <strong> Refund & Cancellation:</strong> As a general policy for all courses, we DO NOT refund or cancel the subscription once the course has been subscribed and payment received. However in lieu of special cases; to request a
                                    refund, the learner is advised to send an email to info@finxpert.org stating the course details along with a reason for refund. AV Financial Experts Network P td (FinX) reservers the right to issue to a refund to the learners at
                                    our discretion.</p>
                                <p>
                                    <strong> Disclaimer on use of names of external bodies:</strong> All the names / trademarks / logos mentioned on various pages of this website are those of industry bodies, educational institutions and/or companies that support
                                    AV Financial Experts Private Limited (“FinX”) in various capacities in respect of the educational and training initiatives undertaken by FinX in the Banking, Financial Services and Insurance sector. It is hereby clarified and made
                                    known to all concerned that these industry bodies, educational institutions and/or companies, by no means promote, warrant, provide any assurance or assume any liability in any manner whatsoever in respect of any of the products,
                                    services, educational and/or training programs offered by FinX. The conduct, sale and/or execution of the products, services, educational and/or training programs shall at all times be the sole responsibility of FinX. None of these
                                    industry bodies, educational institutions and/or companies are in any way whatsoever responsible for the quality of the products, services, educational and/or training programs and/or placement of / providing employment to the
                                    candidates who enroll for any of the programs offered by FinX.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <FinxFooterLinks />
            </React.Fragment>
        );
    }

}


