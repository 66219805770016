import {
  FETCH_ENROLLMENT_HISTORY_PENDING,
  FETCH_ENROLLMENT_HISTORY,
  FETCH_ENROLLMENT_HISTORY_ERROR
} from "../action/fetchEnrollmentHistoryData";

const initialState = {
  pending: false,
  studentsEnrollmentHistory: [],
  error: null
};
   
const studentsEnrollmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ENROLLMENT_HISTORY_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_ENROLLMENT_HISTORY:
      return {
        ...state,
        pending: false,
        err: false,
        studentsEnrollmentHistory: action.payload
      };
    case FETCH_ENROLLMENT_HISTORY_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default studentsEnrollmentReducer;

export const getStudentsEnrollmentHistory = state => state.studentsEnrollmentHistory;
export const getStudentsEnrollmentHistoryPending = state => state.pending;
export const getStudentsEnrollmentHistoryError = state => state.error;
   