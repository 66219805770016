import { FETCH_ADMIN_USER_PENDING, FETCH_ADMIN_USER_SUCCESS, FETCH_ADMIN_USER_ERROR } from '../action/adminUserAction';

const initialState = {
    pending: false,
    adminUser: [],
    error: null
}

const adminUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_USER_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_ADMIN_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                adminUser: action.payload
            }
        case FETCH_ADMIN_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default adminUserReducer;
export const getAdminUser = state => state.adminUser;
export const getAdminUserPending = state => state.pending;
export const getAdminUserError = state => state.error;