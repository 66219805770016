import { FETCH_VOCATIONAL_COURSES_ALL_PENDING, FETCH_VOCATIONAL_COURSES_ALL_SUCCESS, FETCH_VOCATIONAL_COURSES_ALL_ERROR } from '../action/vocationCoursesAllAction';

const initialState = {
    
    pending: false,
    vocationalCoursesAll: [],
  
    error: null
}

const vocationalCoursesAllReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_VOCATIONAL_COURSES_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_VOCATIONAL_COURSES_ALL_SUCCESS:
            return {
                ...state,
                pending: false,
                vocationalCoursesAll: action.payload,
              
            }
        case FETCH_VOCATIONAL_COURSES_ALL_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default vocationalCoursesAllReducer;
export const getVocationalCoursesAll = state => state.vocationalCoursesAll;
export const getVocationalCoursesAllPending = state => state.pending;
export const getVocationalCoursesAllError = state => state.error;
