import {
  FETCH_STUDENT_PROGRESS_OVERVIEW_PENDING,
  FETCH_STUDENT_PROGRESS_OVERVIEW,
  FETCH_STUDENT_PROGRESS_OVERVIEW_ERROR
} from "../action/getStudentProgressOverviewAction";

const initialState = {
  pending: false,
  studentsProgressOverview: [],
  error: null
};

const studentsProgressOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDENT_PROGRESS_OVERVIEW_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_STUDENT_PROGRESS_OVERVIEW:
      return {
        ...state,
        pending: false,
        err: false,
        studentsProgressOverview: action.payload
      };
    case FETCH_STUDENT_PROGRESS_OVERVIEW_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default studentsProgressOverviewReducer;

export const getStudentsProgressOverview = state =>  state.studentsProgressOverview;
export const getStudentsProgressOverviewPending = state => state.pending;
export const getStudentsProgressOverviewError = state => state.error;
