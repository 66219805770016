import React, { Component } from "react";
import { connect } from "react-redux";
import UserManagement from "../../components/report/usermanagement";
import {
  getUserActivityDataError,
  getUserActivityData,
  getUserActivityDataPending
} from "../../reducer/userActivityReducer";
import { bindActionCreators } from "redux";

import { UserManagementservice } from "../../services/userManagementService";
import { LoginService } from "../../services/login.service";
import AdminHeader from "../../components/dashboardheader/adminheader";
import FinxFooter from "../finxfooter/finxfooter";
import { PopUpPortal } from "../popups/PopUpPortal";

const mapStateToProps = state => ({
  successUserActivityData: getUserActivityData(
    state.userActivityReducer
  ),
  pendingUserActivityHistory: getUserActivityDataPending(
    state.userActivityReducer
  ),
  errorUserActivityHistory: getUserActivityDataError(
    state.userActivityReducer
  ),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserManagementService: UserManagementservice,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const useractivityLanding = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {
      const { UserManagementService } = this.props
      //console.log("container")
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);

      });
      UserManagementService("limit=-1");

    }

    render() {

      const {
        successUserActivityData,
        pendingUserActivityHistory
      } = this.props;
      //console.log(" activity", this.props.successUserActivityData)
      ////console.log("studentEnrollmentHistory",this.props.studentEnrollmentHistory)
      if (!pendingUserActivityHistory) {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={!this.state.loading}
              IsVisible={!this.state.loading}
            />

            <AdminHeader
              //alertNotificationEvents={this.props.location.state !== undefined ? this.props.location.state.alerts || [] : []}
              //notificationEvents={this.props.location.state !== undefined ? this.props.location.state.notification || [] : []}
              {...this.props}
            />
            <UserManagement successUserActivityData={successUserActivityData}{...this.props} />
            <FinxFooter />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />
            </React.Fragment>
        )
      }
    }
  }
);

export default useractivityLanding;
