import React, { Component } from "react";
import AdminHeader from "../../components/dashboardheader/adminheader";
import Finxfooter from "../finxfooter/finxfooter";
import AddEventLandingPage from "../../components/event/addEvent";
import { EventService } from "../../services/event.service";

class eventAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getAllEventTypes: [],
    };
  }
  componentDidMount() {
    EventService.getAllEventTypes((res) => {
      console.log("res-getAllEventTypes", res);
      this.setState({
        getAllEventTypes: res.data.responseListObject,
      });
    });
  }
  render() {
    console.log("eventaddc",this.state);
    return (
      <>
        <AdminHeader {...this.props} />
        <AddEventLandingPage getAllEventTypes={this.state.getAllEventTypes} {...this.props}  />
        <Finxfooter />
      </>
    );
  }
}

export default eventAdd;
