import Axios from 'axios';
// import { axiosheaders } from './header';
// import { fetchStudentError } from '../action/studentAction';
// import ErrorBoundary from '../components/errorboundary/errorboundary';

export default class RestDataSource {
  constructor(base_url, errorCallback) {
    //Axios.defaults.headers.common['tokentype'] = localStorage.getItem('TokenName') === null ? 'jti' : localStorage.getItem('TokenName');
    Axios.defaults.headers.common['jtitoken'] = localStorage.getItem('jti-token');
    Axios.defaults.headers.common['token'] = localStorage.getItem('jwt-token');
    Axios.defaults.headers.common['tokentype'] = localStorage.getItem('TokenName');
    if (localStorage.getItem('userid') !== '' && localStorage.getItem('userid') !== null) {
      Axios.defaults.headers.common['userId'] = JSON.parse(localStorage.getItem('userid'));
      Axios.defaults.headers.common['userlogin'] = localStorage.getItem('eMail');
    } else {
      Axios.defaults.headers.common['userId'] = 2;
      Axios.defaults.headers.common['userlogin'] = 'pramila@gmail.com';

    }
    this.BASE_URL = base_url;
    this.handleError = errorCallback;
  }

  async GetData(callback) {
    this.SendRequest('get', this.BASE_URL, callback);
  }
  async GetOneByParam(id, callback) {
    this.SendRequest('get', `${this.BASE_URL}?${id}`, callback);
  }
  async GetOne(id, callback) {
    this.SendRequest('get', `${this.BASE_URL}/${id}`, callback);
  }
  async Store(data, callback) {
    this.SendRequest('post', this.BASE_URL, callback, data);
  }
  async Update(data, callback) {
    this.SendRequest('put', this.BASE_URL, callback, data);
  }
  async UpdateOneByParam(id,data,callback) {
    this.SendRequest('put', `${this.BASE_URL}?${id}`, callback, data);
  }
  async Delete(data, callback) {
    this.SendRequest('delete', this.BASE_URL, callback, data);
  }

  async DeleteOneByParam(id, callback) {
    this.SendRequest('delete', `${this.BASE_URL}?${id}`, callback);
  }
  async SendRequest(method, url, callback, data) {
    try {
      let response = await Axios.request({
        //headers: axiosheaders.Headers(),
        method: method,
        url: url,
        data: data
      });
      callback(response);
    } catch (err) {

      console.log(err);
      // this.handleError(err);
    }
  }
}
