export const FETCH_PRODUCT_PRODUCT_TYPE_PENDING = 'FETCH_PRODUCT_PRODUCT_TYPE_PENDING';
export const FETCH_PRODUCT_PRODUCT_TYPE_SUCCESS = 'FETCH_PRODUCT_PRODUCT_TYPE_SUCCESS';
export const FETCH_PRODUCT_PRODUCT_TYPE_ERROR = 'FETCH_PRODUCT_PRODUCT_TYPE_ERROR';

export function fetchProductProductTypePending() {
    return {
        type: FETCH_PRODUCT_PRODUCT_TYPE_PENDING,
    }
}

export function fetchProductProductTypeSuccess(Product_Details_ALL) {

    return {
        type: FETCH_PRODUCT_PRODUCT_TYPE_SUCCESS,
        payload: Product_Details_ALL
    }
}

export function fetchProductProductTypeError(error) {
    return {
        type: FETCH_PRODUCT_PRODUCT_TYPE_ERROR,
        error: error
    }
}