import React, { Component } from "react";
// import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
// import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import {
  getCalenderEvents,
  getCalenderEventsPending
} from "../../reducer/calenderEventsReducer";
import { bindActionCreators } from "redux";
import {
  fetchCalenderEvents
} from "../../services/fetchStudentDashboard.service";
import AdminProfileLandingPage from "../../components/admin/adminProfile";
// import $ from "jquery";
import Adminheader from "../../components/dashboardheader/adminheader";
import { fetchRoles } from "../../services/fetchRoles";
import { getRoles } from "../../reducer/roleReducer";
import { LoginService } from "../../services/login.service";
import { fetchAdminUser } from "../../services/admin.service";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchStudentDashboard.service";

const mapStateToProps = state => ({
  calenderEvents: getCalenderEvents(state.calenderEventsReducer),
  pendingCalenderEvents: getCalenderEventsPending(state.calenderEventsReducer),
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),
  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
  roles: getRoles(state.roleReducer)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCalenderEvents: fetchCalenderEvents,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchRoles: fetchRoles,
      fetchAdminUser: fetchAdminUser,
      adminUser: fetchAdminUser,
      fetchNotificationEvents: fetchNotificationEvents
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const adminProfile = connectFunction(
  class extends Component {

    // constructor(props) {
    //   super(props);
    // }

    componentDidUpdate() { }

    componentDidMount() {
      const {
        fetchCalenderEvents,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,
        fetchRoles,
        // adminUser
      } = this.props;


      let partyID = localStorage.getItem("userid-Token");


      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          fetchCalenderEvents("partyID=" + partyID);
          // fetchAlertNotificationEvents();
          fetchNotificationEvents("partyId=" + partyID);
          fetchRoles();

        }
      });
    }
    render() {
      const {
        calenderEvents,
        // alertNotificationEvents,
        // notificationEvents,
        roles,
        fetchAdminUser,
        // adminUser,
        pendingAlertNotificationEvents,
        pendingNotificationEvents
      } = this.props;

      //console.log("alertNotificationEvents adminueer", alertNotificationEvents)


      if (!pendingAlertNotificationEvents
        && !pendingNotificationEvents) {

        return (
          <React.Fragment>

            <Adminheader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <AdminProfileLandingPage
              calenderEvents={calenderEvents}
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              roles={roles}
              fetchAdminUser={fetchAdminUser}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
      else {

        return null;

      }
    }
  }
);

export default adminProfile;
