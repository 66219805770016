import React, { Component } from "react";
import AdminHeader from "../../components/dashboardheader/adminheader";
import Finxfooter from "../finxfooter/finxfooter";
import ProductValidityListLandingPage from "../../components/product-validity/productValidityList";

class ProductValidityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllProductValidity: [],
      fromDate: "",
      toDate: "",
    };
  }

  
  componentDidMount() {
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);

    const monthMilliSeconds = 2629800000;
    const prevMonthMilliseconds = parseFloat(new Date().getTime()) - parseFloat(monthMilliSeconds);

    let params = "fromDate=" + startDate.toLocaleDateString() + "&toDate=" + endDate.toLocaleDateString();
    this.setState({
      fromDate: startDate.toLocaleDateString(),
      toDate: endDate.toLocaleDateString(),
    });

  }
  render() {
    const { AllProductValidity, fromDate, toDate } = this.state;

    return (
      <>
        <AdminHeader {...this.props} />
        <ProductValidityListLandingPage
          AllProductValidity={AllProductValidity}
          fromDate={fromDate}
          toDate={toDate}
          {...this.props}
        />
        <Finxfooter />
      </>
    );
  }
}
export default ProductValidityList;
