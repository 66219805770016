import React from 'react';
import '../../assets/css/style.css';
import Finxlogo from "../../assets/images/finxlogo1.png";
function FinxFooterLinks() {
    return (
        <div>
            <section class="footer-1">
                <div class="container">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                            <h1 class="footer-title"> About Us </h1>
                            <a href="http://finxpert.org/" target="_blank" rel="noopener noreferrer">
                                {/* <img src="images/CFX.png" width="100px" height="auto" />  */}
                                <img src={Finxlogo} width="100px" height="auto" alt='img' />
                            </a>
                            <p>Creating a sustainable stream of skilled & employable resources for BFSI industry</p>

                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                            <h1 class="footer-title"> Quick Menu </h1>
                            <ul>
                                <li><a href="http://finxpert.org/about-us/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> About Us </a></li>
                                <li><a href="http://finxpert.org/cfx/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> CFX </a></li>
                                <li><a href="http://finxpert.org/feat/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> FEAT </a></li>
                                <li><a href="http://finxpert.org/faqs/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> FAQs </a></li>
                                <li><a href="http://finxpert.org/partner-with-us/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> Partner with Us </a></li>
                            </ul>
                        </div>
                        <div class="col-md-4 col-lg-5 col-sm-12 col-xs-12">
                            <h1 class="footer-title"> Updates </h1>
                            <ul>
                                <li><a href="https://finxpert.org/cfx-placement/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> CFX Placement </a></li>
                                <li><a href="https://finxpert.org/kj-somaiya-college-of-science-commerce-mou-signing/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> KJ Somaiya College of Science & Commerce - MoU Signing </a></li>
                                <li><a href="https://finxpert.org/finx-acquires-a-majority-stake-in-ciel-2/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> FinX Acquires A Majority Stake In CIEL</a></li>
                                <li><a href="https://finxpert.org/sies-nerul-college-of-arts-science-commerce-mou-signing/" target="_blank" rel="noopener noreferrer"><i class="fa fa-angle-right" aria-hidden="true"></i> SIES (Nerul) College Of Arts, Science & Commerce - MoU Signing </a></li>
                                <a href="https://finxpert.org/events/" target="_blank" rel="noopener noreferrer"><button type="button" class="btn-footer mt-30">View All Events</button></a>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <noscript>Not seeing a <a href="https://www.scrolltotop.com/">Scroll to Top Button</a>? Go to our FAQ page for more info.</noscript>
        </div>
    );
}

export default FinxFooterLinks;
