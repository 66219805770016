import { Global_var } from '../global/global_var';
import RestDataSource from '../services/restdatasource';
import { LoginService } from './login.service';

export const OrderService = {
    InsertOrder,
    ApplyForLoan,
    getDataForLoan,
    nonPayableProductPayment
};

function InsertOrder(OrderDetails, fn, fnError) {
    LoginService.TokenUser(res => {
        if (res.status === 'success') {
            localStorage.setItem('jwt-token', res.responseObject);
            var url = Global_var.BASEURL + Global_var.URL_ORDER_INSERT;

            //var mobilevalidat = JSON.stringify(ProductByName);
            return new RestDataSource(url, fnError).Store(OrderDetails, (res) => {
                
                if (res != null) {
                    if (res.headers["jtitoken"] != null) {
                        localStorage.setItem('jti-token', res.headers.jtitoken);
                    }
                    fn(res.data);
                }
            });
        }
    }, fnError);




   
    
    // LoginService.TokenUser(res => {
    //     localStorage.setItem('jwt-token', res.responseObject);
    //     if (res.status === 'success') {
    //         var url = Global_var.BASEURL + Global_var.URL_ORDER_INSERT;
    //         return new RestDataSource(url, fnError).Store(OrderDetails, (res) => fn(res));
    //     }
    // });
}
function ApplyForLoan(data, fn, fnError) {
    LoginService.TokenUser(res => {
        if (res.status === 'success') {
            localStorage.setItem('jwt-token', res.responseObject);
            var url = Global_var.BASEURL + Global_var.URL_APPLY_LOAN;

            //var mobilevalidat = JSON.stringify(ProductByName);
            return new RestDataSource(url, fnError).Store(data, (res) => {
                
                if (res != null) {
                    if (res.headers["jtitoken"] != null) {
                        localStorage.setItem('jti-token', res.headers.jtitoken);
                    }
                    fn(res.data);
                }
            });
        }
    }, fnError);
}




function getDataForLoan(fn, fnError) {
    
  var url = Global_var.BASEURL +  Global_var.URL_GET_APPLY_LOAN;
   // var url =  "http://192.168.12.100:8065"+Global_var.URL_GET_APPLY_LOAN;
    return new RestDataSource(url, fnError).GetData(res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    });
  }
  function nonPayableProductPayment(user, fn, fnError) {
    LoginService.TokenUser((res) => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);
        var url = Global_var.BASEURL + Global_var.URL_NON_PAYABLE_PAYMENT;
        return new RestDataSource(url, fnError).Update(user, (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        });
      }
    });
  }
