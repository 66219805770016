import React from "react";
import progressoverview from "../../assets/images/progress-overview.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DemoCarousel from "../carousel/adminCarousel";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

class AdminProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  render() {
    if (this.props.studentProgressOverviewDataPending) {
      return (
        <React.Fragment>
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
            <h3 className="student-title">Progress Overview</h3>
            <div className="progress-overview">
              <DemoCarousel
                studentProgressOverview={this.props.studentProgressOverviewData}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default AdminProgress;
