import { FETCH_LEARNING_PATH_PENDING, FETCH_LEARNING_PATH_SUCCESS, FETCH_LEARNING_PATH_ERROR } from '../action/learningPathAction';

const initialState = {
    
    pending: false,
    learningPath: [],
    error: null
}

const learningPathReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_LEARNING_PATH_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_LEARNING_PATH_SUCCESS:
            return {
                ...state,
                pending: false,
                learningPath: action.payload,
              
            }
        case FETCH_LEARNING_PATH_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        default:
            return state;
    }
}
export default learningPathReducer;
export const getLearningPath = state => state.learningPath;
export const getlearningPathPending = state => state.pending;
export const getlearningPathError = state => state.error;
