import React from 'react';
import Slider from 'react-slick';
// import bannerImg1 from '../../../assets/images/Banner Image 1_without text.png';
// import bannerImg2 from '../../../assets/images/Banner Image 2 without text.png';
// import bannerImg3 from '../../../assets/images/Banner Image 3_without_text.png';
// import bannerImg4 from '../../../assets/images/Banner Image 4_without_text.png';
// import bannerImgMob1 from '../../../assets/images/Banner Image 1_without text_mob.png';
// import bannerImgMob2 from '../../../assets/images/Banner Image 2_without text_mob.png';
// import bannerImgMob3 from '../../../assets/images/Banner Image 3_without text_mob.png';
// import bannerImgMob4 from '../../../assets/images/Banner Image 4_without text_mob.png';
import bannerImg1 from '../../../assets/images/Banner1_new.png';
import bannerImg2 from '../../../assets/images/Banner2_new.png';
import bannerImg3 from '../../../assets/images/Banner3_new.png';
// import bannerImg4 from '../../../assets/images/Banner4_new.png';
import bannerImg4 from '../../../assets/images/19 April _ logo placement changes_ Banner 4.png';
import bannerImgMob1 from '../../../assets/images/Banner1_mob_new.png';
import bannerImgMob2 from '../../../assets/images/Banner2_mob_new.png';
import bannerImgMob3 from '../../../assets/images/Banner3_mob_new.png';
// import bannerImgMob4 from '../../../assets/images/Banner4_mob_new.png';
import bannerImgMob4 from '../../../assets/images/Banner Image 1_without text-5.png';
import { Carousel } from 'react-responsive-carousel';
import { useHistory,Link } from "react-router-dom";
import './BannerSlider.css';

function BannerSlider() {
    const history = useHistory();
    const settings = {
        // dots: true,
        arrows: false,
        // fade: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        SlidesToScroll: 1
    };
    const clickImage = () => {
        // window.open("https://core.finxpert.org/register","_blank")
        history.push("/register",{searchParams:history.location.search,pathname:history.location.pathname}); 
    }
    return (
        <div className='ap-banner-slider1 col-100 floatLft relative'>
     <Carousel infiniteLoop useKeyboardArrows autoPlay autoPlaySpeed={3000} onClickItem={clickImage} >
                <div className="ap-bannerItem1 col-100 floatLft relative">
                    
                    <div className="ap-bannerImg col-100 floatLft">
                        <img  src={bannerImg1} className="ap-deskBanner1" alt="Banner Image 1" />
                        <img src={bannerImgMob1} className="ap-mobBanner1" alt="Banner Mob Image 1" />
                    </div>
                    <div className="ap-banner-cnt11 floatLft textLeft absolute finx-margin1">
                        <h1 className='col-100 floatLft ap-gotham-medium ap-primary-black finx-mb1'>
                        <b className="finx-linked1">FinX Placement Linked Program</b>
                        </h1>
                        <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red fast-track-margin'>
                        <span className="fast-track"><b style={{color:"#97196b"}}>Fast-track</b> your career with</span>
                        </h2>
                        <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>
                        <b className="chartered-text">Chartered Financial Expert</b>
                        </h3>
                        </div>
                        {/* <div className="ap-banner-cnt11 floatLft textLeft absolute finx-margin2">
                        <a className="ap-joinBtn11 inlineBlk ap-gotham-bold ap-primary-black ap-deskBanner1 join-btn-margin"
                        onClick={clickImage}>
                        <b className="join-now-btn">Join Now</b></a>
                    </div> */}
                   
                </div>
                <div className="ap-bannerItem1 col-100 floatLft relative">
                    <div className="ap-bannerImg col-100 floatLft">
                        <img src={bannerImg2} className="ap-deskBanner1" alt="Banner Image 2" />
                        <img  src={bannerImgMob2} className="ap-mobBanner1" alt="Banner Mob Image 2" />
                    </div>
                    <div className="ap-banner-cnt11 floatLft textLeft absolute finx-margin1">
                        <h1 className='col-100 floatLft ap-gotham-medium ap-primary-black finx-mb1'>
                        <b className="finx-linked1">FinX Placement Linked Program</b>
                        </h1>
                        <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red fast-track-margin'>
                        <span className="fast-track"><b style={{color:"#97196b"}}>Fast-track</b> your career with</span>
                        </h2>
                        <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>
                        <b className="chartered-text">Chartered Financial Expert</b>
                        </h3>
                        </div>
                        {/* <div className="ap-banner-cnt11 floatLft textLeft absolute finx-margin2">
                        <a className="ap-joinBtn11 inlineBlk ap-gotham-bold ap-primary-black ap-deskBanner1 join-btn-margin" 
                        onClick={clickImage}>
                        <b className="join-now-btn">Join Now</b></a>
                    </div> */}
                </div>
                <div className="ap-bannerItem1 col-100 floatLft relative">
                    <div className="ap-bannerImg col-100 floatLft">
                        <img src={bannerImg3} className="ap-deskBanner1" alt="Banner Image 3" />
                        <img  src={bannerImgMob3} className="ap-mobBanner1" alt="Banner Mob Image 3" />
                    </div>
                    <div className="ap-banner-cnt11 floatLft textLeft absolute finx-margin3">
                    <h1 className='col-100 floatLft ap-gotham-medium ap-primary-black'>
                        <b className="finx-linked3">FinX Placement Linked Program</b>
                        </h1>
                    </div>
                </div>
                <div className="ap-bannerItem1 col-100 floatLft relative">
                    <div className="ap-bannerImg col-100 floatLft">
                        <img src={bannerImg4} className="ap-deskBanner1" alt="Banner Image 4" />
                        <img  src={bannerImgMob4} className="ap-mobBanner1" alt="Banner Mob Image 4" />
                    </div>
                    <div className="ap-banner-cnt11 floatLft textLeft absolute finx-margin4">
                    <h1 className='col-100 floatLft ap-gotham-medium ap-primary-black finx-line-height4'>
                        <b className="finx-linked4">FinX Placement Linked Program</b>
                        </h1>
                        <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red'>
                        <b className="success-story">Student Success Stories</b></h2>
                    </div>
                </div>
            </Carousel>
            
        </div>
      )
//   return (
//     <div className='ap-banner-slider1 col-100 floatLft relative' 
//     // style={{height:'530px'}}
//     >
//  <Carousel infiniteLoop useKeyboardArrows autoPlay autoPlaySpeed={3000} onClickItem={clickImage}>
//         {/* <Slider {...settings}> */}
//             <div className="ap-bannerItem1 col-100 floatLft relative">
                
//                 <div className="ap-bannerImg col-100 floatLft">
//                     <img  src={bannerImg1} className="ap-deskBanner1" alt="Banner Image 1" />
//                     <img src={bannerImg1} className="ap-mobBanner1" alt="Banner Mob Image 1" />
//                     {/* <img src={bannerImgMob1} className="ap-mobBanner1" alt="Banner Mob Image 1" /> */}
//                 </div>
//                 <div className="ap-banner-cnt11 floatLft textLeft absolute">
//                     <p className='col-100 floatLft'>
//                         <b style={{
//                             fontSize:"40px",fontFamily:"Roboto",color:"#000000"
//                         }}>FinX Placement Linked Program</b>
//                     </p>
//                     <h2 className='col-100 floatLft' style={{
//                             fontSize:"32.8px",fontFamily:"Roboto",color:"#97196b"
//                         }}>
//                         <b style={{color:"#97196b"}}>Fast-track</b> your career with</h2>
//                     <h3 className='col-100 floatLft'
//                     ><b style={{
//                         fontSize:"21.7px",fontFamily:"Roboto",color:"#77bf49"
//                     }}>Chartered Financial Expert</b></h3>
//                     <a 
//                     // href="https://bit.ly/3yezxWu" 
//                     // href="https://core.finxpert.org/register" 
//                     // href="http://localhost:3000/register" 
//                     // target="_blank" 
//                     onClick={clickImage}
//                     className="ap-joinBtn11 inlineBlk"
//                     ><b style={{
//                         color:"#f2922a",fontSize:"38px",fontFamily:"Roboto"
//                     }}>Join Now</b></a>
//                 </div>
               
//             </div>
//             <div className="ap-bannerItem1 col-100 floatLft relative">
//                 <div className="ap-bannerImg col-100 floatLft">
//                     {/* <img src={bannerImg3} className="ap-deskBanner1" alt="Banner Image 2" />
//                     <img  src={bannerImg3} className="ap-mobBanner1" alt="Banner Mob Image 2" /> */}
//                     <img src={bannerImg2} className="ap-deskBanner1" alt="Banner Image 2" />
//                     <img  src={bannerImg2} className="ap-mobBanner1" alt="Banner Mob Image 2" />
//                     {/* <img  src={bannerImgMob2} className="ap-mobBanner1" alt="Banner Mob Image 2" /> */}
//                 </div>
//                 <div className="ap-banner-cnt11 floatLft textLeft absolute">
//                     <p className='col-100 floatLft'>
//                         <b style={{
//                             fontSize:"40px",fontFamily:"Roboto",color:"#000000"
//                         }}>FinX Placement Linked Program</b>
//                     </p>
//                     <h2 className='col-100 floatLft' style={{
//                            fontSize:"32.8px",fontFamily:"Roboto",color:"#97196b"
//                         }}>
//                         <b style={{color:"#97196b"}}>Fast-track</b> your career with</h2>
//                     <h3 className='col-100 floatLft'
//                     ><b style={{
//                         fontSize:"21.7px",fontFamily:"Roboto",color:"#77bf49"
//                     }}>Chartered Financial Expert</b></h3>
//                     <a 
//                     // href="https://bit.ly/3yezxWu" 
//                     // href="https://core.finxpert.org/register" 
//                     // href="http://localhost:3000/register" 
//                     // target="_blank" 
//                     onClick={clickImage}
//                     className="ap-joinBtn11 inlineBlk"
//                     ><b style={{
//                         color:"#f2922a",fontSize:"38px",fontFamily:"Roboto"
//                     }}>Join Now</b></a>
//                 </div>
//                 {/* <div className="ap-banner-cnt11 floatLft textLeft absolute">
//                     <p className='col-100 floatLft ap-gotham-medium ap-primary-black'>
//                     Groom your skills and <br /> kickstart your career
//                     </p>
//                     <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red'>Kotak India</h2>
//                     <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>a global leader in
//                     independent research & data.</h3>
//                     <a 
//                     // href="https://bit.ly/3yezxWu"
//                     // href="https://core.finxpert.org/register"
//                     href="http://localhost:3000/register"
//                      target="_blank" className="ap-joinBtn11 inlineBlk ap-gotham-bold ap-primary-black">JOIN NOW</a>
//                 </div> */}
//             </div>
//             <div className="ap-bannerItem1 col-100 floatLft relative">
//                 <div className="ap-bannerImg col-100 floatLft">
//                     {/* <img src={bannerImg2} className="ap-deskBanner1" alt="Banner Image 3" />
//                     <img  src={bannerImg2} className="ap-mobBanner1" alt="Banner Mob Image 3" /> */}
//                     <img src={bannerImg3} className="ap-deskBanner1" alt="Banner Image 3" />
//                     <img  src={bannerImg3} className="ap-mobBanner1" alt="Banner Mob Image 3" />
//                     {/* <img  src={bannerImgMob3} className="ap-mobBanner1" alt="Banner Mob Image 3" /> */}
//                 </div>
//                 <div className="ap-banner-cnt11 floatLft textLeft absolute">
//                 <p className='col-100 floatLft'style={{marginTop:"-130px"}}>
//                         <b style={{
//                             fontSize:"40px",fontFamily:"Roboto",color:"#000000"
//                         }}>FinX Placement Linked Program</b>
//                     </p>
//                     {/* <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red'>Kotak India</h2>
//                     <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>a global leader in
//                     independent research & data.</h3>
//                     <a 
//                     // href="https://bit.ly/3yezxWu"
//                     // href="https://core.finxpert.org/register"
//                     href="http://localhost:3000/register"
//                      target="_blank" className="ap-joinBtn11 inlineBlk ap-gotham-bold ap-primary-black">JOIN NOW</a> */}
//                 </div>
//             </div>
//             <div className="ap-bannerItem1 col-100 floatLft relative">
//                 <div className="ap-bannerImg col-100 floatLft">
//                     <img src={bannerImg4} className="ap-deskBanner1" alt="Banner Image 4" />
//                     <img  src={bannerImg4} className="ap-mobBanner1" alt="Banner Mob Image 4" />
//                     {/* <img  src={bannerImgMob4} className="ap-mobBanner1" alt="Banner Mob Image 4" /> */}
//                 </div>
//                 <div className="ap-banner-cnt11 floatLft textLeft absolute">
//                 <p className='col-100 floatLft'style={{marginTop:"-150px"}}>
//                         <b style={{
//                             fontSize:"40px",fontFamily:"Roboto",color:"#000000"
//                         }}>FinX Placement Linked Program</b>
//                     </p>
//                     <p className='col-100 floatLft'style={{marginTop:"-100px"}}>
//                         <b style={{
//                             fontSize:"32px",fontFamily:"Roboto",color:"#77bf49"
//                         }}>Student Success Stories</b>
//                     </p>
//                     {/* <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red'>Kotak India</h2>
//                     <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>a global leader in
//                     independent research & data.</h3>
//                     <a 
//                     // href="https://bit.ly/3yezxWu"
//                     // href="https://core.finxpert.org/register"
//                     href="http://localhost:3000/register"
//                      target="_blank" className="ap-joinBtn11 inlineBlk ap-gotham-bold ap-primary-black">JOIN NOW</a> */}
//                 </div>
//             </div>

//         {/* </Slider> */}
//         </Carousel>
        
//     </div>
//   )
}

export default BannerSlider