export const FETCH_GDPI_STATUS_PENDING = "FETCH_GDPI_STATUS_PENDING";
export const FETCH_GDPI_STATUS_SUCCESS = "FETCH_GDPI_STATUS_SUCCESS";
export const FETCH_GDPI_STATUS_ERROR = "FETCH_GDPI_STATUS_ERROR";

export function fetchGdpiStatusPending() {
  return {
    type: FETCH_GDPI_STATUS_PENDING
  };
}

export function fetchGdpiStatusSuccess(gdpi_status) {
  return {
    type: FETCH_GDPI_STATUS_SUCCESS,
    payload: gdpi_status
  };
}

export function fetchGdpiStatusError(error) {
  return {
    type: FETCH_GDPI_STATUS_ERROR,
    error: error
  };
}
