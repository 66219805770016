import { Global_var } from "../global/global_var";
import RestDataSource from "../services/restdatasource";
import { LoginService } from "./login.service";
// import { func } from "prop-types";
import {
  fetchUserProfileData,
  fetchUserAddressData,
  fetchUserPreferenceData,
  fetchUserEmploymentData,
  fetchUserLeadRegData,
} from "../action/userProfile";

export const RegisterUserService = {
  RegisterUser,
  RegisterSocialUser,
  getUserPersonnelDetails,
  getLeadRegData,
  getUserAddress,
  getUserPreference,
  getOtherJobPreference,
  getJobRole,
  getJobKeyskill,
  getJobInterestArea,
  getPreferenceNotification,
  getPreferredDomain,
  getUserEmployment,
  updateUserProfile,
  updateParentDetails,
  updateUserPreference,
  updateUserResume,
  updateUserEducation,
  updateUserEmployment,
  uploadUserFiles,
  AHRegisterUser,
  AHRegisterUserBulk,
  AHRegisterUserNew,
  AHRegisterUserBulkNew,
  AHRegisterUserGCUBE,
  getEmployeType,
  updateMyTour,
  getAdminUserAddress,
  bulkUpload,
  checkPAN,
  validateUserData,
  createdBy,
  updateEduLoan,
  fetchOrerDetailsByUser,
  generateOTPMail,
  validateOTPMail,
  emailAuthntication,
  validateFalsePositiveEmail,
  bulkPaymentUpload,
  morningStartMail,
  bulkJobUpload,
  bulkGCubeUpload,
  downloadProfileDocument,
  gCubePassword,
  uploadProfileScore,
  downloadDocument,
  resumeDownload,
  fetchEducationalDetailsOnPartyID,
  getWaterMarkedVideo,
  getTotalExperienceList,
  getAnnualSalaryList,
  getPriceListVideo,
  getPriceListCourse,
  getCertificateQualificationList,
  hireFromUs,
  getTypeOfCompanyList,
  getSectorList,
  getTypeOfCorporateList,
  getCourseAndCertification,
  getInternshipPlacements,
  getCollegeUniversity,
  getAnnualFamilyIncomeList,
  getIncomeProofList,
  getOtherPurposeList,
  getNumberOfEmployeesList,
  saveCPBFIDetails,
  getCPBFIDetails,
  LeadRegisterClickSave,
  AcceptAdmissionLetter,
  getRatingReport,
  getEducationQualificationByGroupName,
  saveDOBDetails,
};

function RegisterUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_FC_REGISTER_USER;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
function LeadRegisterClickSave(user, fn, fnError) {      
  var url = Global_var.BASEURL + Global_var.URL_LEAD_REGISTER_CLICK_SAVE;
  return new RestDataSource(url, fnError).Store(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

/**
 * ! author : Akshay Bharadva
 * ! Function For User Social Register
 */
function RegisterSocialUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      // ! Changes
      var url = Global_var.BASEURL + Global_var.URL_FC_REGISTER_SOCIAL_USER;
      console.log(url)
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
function downloadDocument(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);

    var url = Global_var.BASEURL + Global_var.URL_DOCUMENT_DOWNLOAD;

    return new RestDataSource(url, fnError).GetOneByParam(data, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        fn(res.data);
      }
    });
  });
}
function resumeDownload(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);

    var url = Global_var.BASEURL + Global_var.URL_DOWNLOAD_ZIP;

    return new RestDataSource(url, fnError).Store(data, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        fn(res.data);
      }
    });
  });
}

function AHRegisterUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function AHRegisterUserBulk(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER_BULK;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
function AHRegisterUserNew(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER_NEW;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function AHRegisterUserBulkNew(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER_BULK_NEW;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
function AHRegisterUserGCUBE(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER_GCUBE;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function getUserPersonnelDetails(user, fn, fnError) {
  return dispatch => {
    var url = Global_var.BASEURL + Global_var.URL_USERDATA;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchUserProfileData(res.data));
        fn(res.data);
      }
    });
  };
}
function getLeadRegData(user, fn, fnError) {
  return dispatch => {
    var url = Global_var.BASEURL + Global_var.URL_LEAD_REG_DATA;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchUserLeadRegData(res.data));
        fn(res.data);
      }
    });
  };
}

function getUserAddress(user, fn, fnError) {
  return dispatch => {
    var url = Global_var.BASEURL + Global_var.URL_USERADDRESS;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        dispatch(fetchUserAddressData(res.data));
        fn(res.data);
      }
    });
  };
}

// ! Fetch Other Preferences
function getOtherJobPreference(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_OTHERPREFERENCE;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Fetch Role Details
function getJobRole(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PREFERENCEJOBROLE;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Fetch Keyskill
function getJobKeyskill(fn, fnError, param = "") {
  var url = Global_var.BASEURL + Global_var.URL_PREFERENCEJOBKEYSKILL;
  return new RestDataSource(url, fnError).GetOneByParam(param, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Fetch Interest Area
function getJobInterestArea(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PREFERENCEJOBINTERESTAREA;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getPreferenceNotification(type, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PREFERENCENOTIFICATION;
  return new RestDataSource(url, fnError).GetOneByParam(type, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getPreferredDomain(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PREFERREDDOMAIN;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}


// ! Fetch User Preference
function getUserPreference(user, fn, fnError) {
  return dispatch => {
    var url = Global_var.BASEURL + Global_var.URL_USERPREFERENCE;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        dispatch(fetchUserPreferenceData(res.data));
        fn(res.data);
      }
    });
  };
}
// ! Fetch User Employement
function getUserEmployment(user, fn, fnError) {
  return dispatch => {
    var url = Global_var.BASEURL + Global_var.URL_USEREMPLOYMENT;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        dispatch(fetchUserEmploymentData(res.data));
        fn(res.data);
      }
    });
  };
}

function getAdminUserAddress(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_USERADDRESS;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function updateUserProfile(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATEUSERPROFILE;
    //var url = Global_var.URL_UPDATEUSERPROFILE;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}

function updateParentDetails(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATE_PARENT_DETAILS;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}
// ! Update Preference
function updateUserPreference(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATEUSERPREFERENCE;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}
// ! Update Resume
function updateUserResume(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATEUSERRESUME;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}
// ! Update Education
function updateUserEducation(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATEUSEREDUCATION;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}
// ! Update Employment
function updateUserEmployment(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATEUSEREMPLOYMENT;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}

function uploadUserFiles(file, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPLOADFILES;
    return new RestDataSource(url).Store(file, res => fn(res));
  });
}
function getEmployeType(fn, fnError) {
  let emptype = "codeTypeName=employment";
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_CD_TYPE_NAME;
  return new RestDataSource(url, fnError).GetOneByParam(emptype, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function updateMyTour(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_USERDATA;
  return new RestDataSource(url, fnError).Update(data, res => {
    fn(res.data);
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
    }
  });
}
function saveCPBFIDetails(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_SAVE_CPBFI_DETAILS;
  return new RestDataSource(url, fnError).Store(data, res => {
    fn(res.data);
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
    }
  });
}
function getCPBFIDetails(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_CPBFI_DETAILS;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}
function bulkUpload(file, fn) {

  var url = Global_var.BASEURL + Global_var.URL_BULK_UPLOAD;
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}

function checkPAN(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_Check_PAN;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function validateUserData(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_Validate_New_User;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function createdBy(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CREATED_BY;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function updateEduLoan(loan, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_EDUCATION_LOAN;

  return new RestDataSource(url, fnError).Store(loan, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem('jti-token', res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function fetchOrerDetailsByUser(Id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_ORDER_DETAILS;

  return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Date : 24 Feb, 2022
// ! Coded by Akshay Bharadva
function generateOTPMail(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_OTP_EMAIL;
  console.log(url);
  return new RestDataSource(url, fnError).Store(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function validateOTPMail(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_VALIDATE_OTP_EMAIL;
  return new RestDataSource(url, fnError).Store(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function emailAuthntication(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_AUTHENTICATE_EMAIL;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function validateFalsePositiveEmail(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CREATED_BY;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function bulkPaymentUpload(file, fn) {
  var url = Global_var.BASEURL + Global_var.URL_BULK_PAYMENT_UPLOAD
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}
function morningStartMail(user, fn, fnError) { 
  var url = Global_var.BASEURL + Global_var.URL_MORNING_STAR_EMAIL;
  console.log(url);
  return new RestDataSource(url, fnError).Store(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }  
      fn(res.data);
    }
  });
}

function bulkJobUpload(file, fn) {
  var url = Global_var.BASEURL + Global_var.URL_BULK_JOB_UPLOAD
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}
function bulkGCubeUpload(file, fn) {
  var url = Global_var.BASEURL + Global_var.URL_BULK_GCUBE_UPLOAD
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}
function downloadProfileDocument(file, fn) {
  // var url = "http://192.168.71.118:8022/finx-thor-lookupmanagement/api/v1/Document/getStudentDocuments"
  var url = Global_var.BASEURL + Global_var.URL_STUDENT_DOCUMENT
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}
function gCubePassword(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_ENTER_GCUBE_PASSWORD;
  return new RestDataSource(url, fnError).GetOneByParam(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function uploadProfileScore(user, fn, fnError) { 
  var url = Global_var.BASEURL + Global_var.URL_UPLOAD_PROFILE_SCORE;
  return new RestDataSource(url, fnError).Store(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }  
      fn(res.data);
    }
  });
}
function fetchEducationalDetailsOnPartyID(fetchEducationalDetailsOnPartyID, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url =
        Global_var.BASEURL + Global_var.URL_USERDATA;
      return new RestDataSource(url, fnError).GetOneByParam(
        fetchEducationalDetailsOnPartyID,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}
function getWaterMarkedVideo(getWaterMarkedVideo, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_WATERMARKED_VIDEO;
      return new RestDataSource(url, fnError).GetOneByParam(
        getWaterMarkedVideo,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}
// ! Fetch Experience List
function getTotalExperienceList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_TOTAL_EXPERIENCE_LIST;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Fetch Annual Salary Range List
function getAnnualSalaryList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_ANNUAL_SALARY_LIST;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Fetch Price List
function getPriceListVideo(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_PRICE_LIST_VIDEO;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getPriceListCourse(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_PRICE_LIST_COURSE;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// !get certificate qualification list


function getCertificateQualificationList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_CERTIFICATE_QUALIFICATION_LIST;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
//! Corporate Landing Page API's Starts
//! Hire From Us Api Starts
function hireFromUs(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_HIRE_FROM_US;
  return new RestDataSource(url, fnError).Store(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
//! Hire From Us Api Ends
// ! Fetch Type Of Company List Starts
function getTypeOfCompanyList(params,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_TYPE_OF_COMPANY_LIST;
  return new RestDataSource(url, fnError).GetOneByParam(
    params,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
}
// ! Fetch Type Of Company List Ends
// ! Fetch Sector List Starts
function getSectorList(params,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_SECTOR_LIST;
  return new RestDataSource(url, fnError).GetOneByParam(
    params,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
}
// ! Fetch Sector List Ends
// ! Fetch Type Of Corporate List Starts
function getTypeOfCorporateList(params , fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_TYPE_OF_CORPORATE_LIST;
  return new RestDataSource(url, fnError).GetOneByParam(
    params,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
}
// ! Fetch Type Of Corporate List Ends
function getCourseAndCertification(params, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_COURSE_CERTIFICATE;
      return new RestDataSource(url, fnError).GetOneByParam(
        params,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}
function getInternshipPlacements(params, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_INTERNSHIP_PLACEMENTS;
      return new RestDataSource(url, fnError).GetOneByParam(
        params,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}
function getCollegeUniversity(params, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_COLLEGE_UNIVERSITY;
      return new RestDataSource(url, fnError).GetOneByParam(
        params,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

// ! Fetch Annual Family Income Starts
function getAnnualFamilyIncomeList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_TYPE_OF_ANNUAL_FAMILY_LIST;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Fetch Type Of Annual Family Income Ends
// ! Fetch Type Of Income Proof List Starts
function getIncomeProofList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_TYPE_OF_INCOME_PROOF_LIST;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Fetch Type Of Income Proof List Ends
// ! Fetch Purpose List Starts
function getOtherPurposeList(params,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_OTHER_PURPOSE_LIST;
  return new RestDataSource(url, fnError).GetOneByParam(
    params,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
}
// ! Fetch Purpose List Ends
// ! Fetch Number of Employees List Starts
function getNumberOfEmployeesList(params,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_NUMBER_OF_EMPLOYEES_LIST;
  return new RestDataSource(url, fnError).GetOneByParam(
    params,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
}
// ! Fetch Number of Employees List Ends
//!Accept Admission Letter Start
function AcceptAdmissionLetter(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_ACCEPT_ADMISSION_LETTER;
      return new RestDataSource(url, fnError).Store(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
//!Accept Admission Letter End
function getRatingReport(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_RATING_REPORT_DASHBOARD;
  return new RestDataSource(url, fnError).Store(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getEducationQualificationByGroupName(groupName, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_EDUCATION_QUALIFICATION_BY_GROUP_NAME;
      return new RestDataSource(url, fnError).Store(
        groupName,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}
function saveDOBDetails(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_SAVE_DOB_DETAILS;
      return new RestDataSource(url, fnError).Store(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
