export const FETCH_CFX_ASSESSMENT_REPORT = "FETCH_CFX_ASSESSMENT_REPORT";
export const FETCH_CFX_ASSESSMENT_REPORT_PENDING = "FETCH_CFX_ASSESSMENT_REPORT_PENDING";
export const FETCH_CFX_ASSESSMENT_REPORT_ERROR = "FETCH_CFX_ASSESSMENT_REPORT_ERROR";

export function fetchCfxAssessmentReportPending() {
  return {
    type: FETCH_CFX_ASSESSMENT_REPORT_PENDING
  };
}

export function fetchCfxAssessmentReportSuccess(assessmentReport) {
  return {
    type: FETCH_CFX_ASSESSMENT_REPORT,
    payload: assessmentReport
  };
}

export function fetchCfxAssessmentReportError(error) {
  return {
    type: FETCH_CFX_ASSESSMENT_REPORT_ERROR,
    error: error
  };
}
