export const FETCH_PRODUCT_PRE_REQUISITE_PENDING = 'FETCH_PRODUCT_PRE_REQUISITE_PENDING';
export const FETCH_PRODUCT_PRE_REQUISITE_SUCCESS = 'FETCH_PRODUCT_PRE_REQUISITE_SUCCESS';
export const FETCH_PRODUCT_PRE_REQUISITE_ERROR = 'FETCH_PRODUCT_PRE_REQUISITE_ERROR';

export function fetchProductPreRequisitePending() {
    return {
        type: FETCH_PRODUCT_PRE_REQUISITE_PENDING
    }
}

export function fetchProductPreRequisiteSuccess(product_pre_requisite) {

    return {
        type: FETCH_PRODUCT_PRE_REQUISITE_SUCCESS,
        payload: product_pre_requisite
    }
}

export function fetchProductPreRequisiteError(error) {
    return {
        type: FETCH_PRODUCT_PRE_REQUISITE_ERROR,
        error: error
    }
}