import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import {
  fetchStudentEnquiryPending,
  fetchStudentEnquiryError,
  fetchStudentEnquirySuccess
} from "../action/studentEnquiryAction";

export const GeneralEnquiryService = {
  PostEnquiry,
  getEnquiry
};     

function PostEnquiry(user, fn, fnError) {
  
  //console.log("user" + user);
  var url = Global_var.BASEURL + Global_var.URL_GENERALENQUIRY;

  return new RestDataSource(url, fnError).Store(user, res => {
    
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getEnquiry(datParam) {
  var url = Global_var.BASEURL + Global_var.URL_GENERALENQUIRY;
  return dispatch => {
    dispatch(fetchStudentEnquiryPending());
    return new RestDataSource(url, err =>
      dispatch(fetchStudentEnquiryError(err.message))
    ).GetOneByParam(datParam,res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchStudentEnquirySuccess(res.data.responseListObject));
      }
    });
  };
}
