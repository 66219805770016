import {
  FETCH_USER_ACTIVITY_PENDING,
  USER_ACTIVITY_HISTORY,
  FETCH_USER_ACTIVITY_ERROR
} from "../action/fetchUserActivity";

const initialState = {
  pending: false,
  UserActivityData: [],
  error: null
};

const userActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_ACTIVITY_PENDING:
      
      return {
        ...state,
        pending: true
      };
    case USER_ACTIVITY_HISTORY:
      return {
        ...state,
        pending: false,
        err: false,
        UserActivityData: action.payload
      };
    case FETCH_USER_ACTIVITY_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default userActivityReducer;

export const getUserActivityData = state => state.UserActivityData;
export const getUserActivityDataPending = state => state.pending;
export const getUserActivityDataError = state => state.error;
