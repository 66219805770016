import React from "react";
// import Finxfooter from "../../containers/finxfooter/finxfooter";
// import DashboardHeader from "../dashboardheader/dashboardheader";
// import Table from "react-bootstrap/Table";
import ReactTable from "react-table";
import "react-table/react-table.css";
import GdpiScoreUpdate from "./gdpiScoreUpdate";
// import { filterstudents } from "../../services/fetchStudents"
import { ProductService } from "../../services/product.service"
import Toggle from "react-toggle";
// import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";
import $ from "jquery";
import {
  success,
  // warning,
  successNotification,
  error,
  errorNotification,
  // warningNotification
} from "../notification/notifications";
import { ExcelService } from "../Excel/excel";

class GdpiStudentList extends React.Component {
  constructor(props) {
    super(props);
    let gridDataValue = [];
    this.props.students.forEach(element => {
      if (element[1] !== null) {
        gridDataValue.push(element);
      }
    });



    this.state = {
      partyId: "",
      studentName: "",
      gDVenue: "",
      gDDate: "",
      pIVenue: "",
      pIDate: "",
      gDCompleted: "",
      pICompleted: "",
      gDScore: "",
      pIScore: "",
      videoResumeScore: "",
      gDPIScore: "",
      gDPIStatus: "",
      gDAssessedBy: "",
      pIConductedBy: "",
      videoResumeAssessedBy: "",
      requestRaisedOn: "",
      createdBy: "",
      createdDate: "",
      lastModifiedBy: "",
      lastModifiedDate: "",
      isActive: "",
      appliedfilter: "",
      griddata: gridDataValue,
      selection: [],
      selectAll: false,
      disableapprovegdpi: true,
      disabledownloadxls: true,
    };

  }
  componentDidMount() {
  }

  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(value.toLowerCase()) : true
  }

  handleClick = value => {
    ////console.log("VALUE: ", JSON.stringify(value[0]));
    this.setState({
      partyId: value[1].partyId,
      studentName: value[1].firstName,
      studentPartyId: value[0].studentPartyId,
      gdpiId: value[0].gDPIID,
      gDVenue: value[0].gDVenue,
      gDDate: value[0].gDDate,
      gdStatus: value[0].gDStatus,
      piStatus: value[0].pIStatus,
      pIVenue: value[0].pIVenue,
      pIDate: value[0].pIDate,
      gDCompleted: value[0].gDCompleted,
      pICompleted: value[0].pICompleted,
      gDScore: value[0].gDScore,
      pIScore: value[0].pIScore,
      videoResumeScore: value[0].videoResumeScore,
      gDPIScore: value[0].gDPIScore,
      gDPIStatus: value[0].gDPIStatus,
      gDAssessedBy: value[0].gDAssessedBy,
      pIConductedBy: value[0].pIConductedBy,
      videoResumeAssessedBy: value[0].videoResumeAssessedBy,
      requestRaisedOn: value[0].requestRaisedOn,
      gdpiinitiatedBy: value[0].gdpiinitiatedBy,
      GDPIInitiatedDate: value[0].GDPIInitiatedDate,
      isGDPICompleted: value[0].isGDPICompleted,
      createdBy: value[0].createdBy,
      createdDate: value[0].createdDate,
      lastModifiedBy: value[0].lastModifiedBy,
      lastModifiedDate: value[0].lastModifiedDate,
      isActive: value[1].isActive,

    });
  };

  exportToCSV = () => {
    let modifiedData = [];
    let data = this.state.griddata;
    data.forEach(element => {
      var data1 = {
        GDPI_ID: element[0].gDPIID,
        //studentPartyID: element[0].studentPartyID,
        Student_ID: ((element[1] || '').partyId || "").toString(),
        Student_Name: element[1] === null ? '' : element[1].firstName !== undefined ? element[1].firstName : '',
        Request_Raised_Date: (element[0].requestRaisedOn.split(' ')[0] || "").toString(),
        GD_Score: (element[0].gDScore || "").toString(),
        GD_Status: element[0].gDStatus === 10306 ? "Completed" : element[0].gDStatus === 10303 ? "Approved" : "Requested",
        PI_Score: (element[0].pIScore || "").toString(),
        PI_Status: element[0].pIStatus === 10306 ? "Completed" : element[0].pIStatus === 10303 ? "Approved" : "Requested",
        VR_Score: (element[0].videoResumeScore || "").toString(),
        GDPI_Score: (element[0].gDPIScore || "").toString(),
        GDPI_Status: element[0].gDPIStatus === 10306 ? "Completed" : element[0].gDPIStatus === 10303 ? "Approved" : "Requested",
        GDPI_Approved: element[0].isGDPICompleted === 1 ? "YES" : "NO",
        Approved_date: element[0].lastModifiedDate,
        // Email_ID: element[1] === null ? '' : element[1].contactEmail !== undefined ? element[1].contactEmail : '',
      };
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'FC-GDPI-Student-List-');

  };

  admingdpibulk = () => {

    // this.props.history.push("/userbulkupload")
    this.props.history.push("/adminbulkuploadgdpi",

      {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents,
      })
  }

  handlestatusfilterChange = (e) => {
    this.setState({
      appliedfilter: e.target.value,
    });
  }

  showfiltereddata = (e) => {
    let statusparameter = "";
    if (e.target.name !== 'all') {
      if (this.state.appliedfilter !== 'selected') {
        statusparameter = "gdpiStatus=" + this.state.appliedfilter;
      }
    }
    else {
      $("#paymentStatus").val('selected');
    }
    ProductService.filterstudents(statusparameter, cb => {

      if (cb.status === "success") {
        let sortedData = [];

        if (cb.responseListObject.length > 0) {
          cb.responseListObject.forEach(element => {
            if (element[1] !== null) {
              sortedData.push(element);
            }
          });
        }

        //console.log("CB Post Filter", JSON.stringify(cb.responseListObject));
        this.setState({
          griddata: sortedData.length > 0 ? sortedData : '',
          disableapprovegdpi: this.state.appliedfilter === '10302' ? false : true,
          disabledownloadxls: this.state.appliedfilter === '10306' ? false : true,
        })
      } else {
        error(cb.reasonText, {
          ...errorNotification,
          container: "top-center"
        });
      }
    });
    ////console.log("Response12345890 :" + JSON.stringify(filterreddata))

  }

  stateonpagechange = (props) => {
    this.setState({

      selectAll: this.state.selectAll === true ? false : false,
    })
  }

  // Check All Handler
  checkAllHandler = () => {

    var self = this
    //alert('clicked'+ self.state.selectAll)

    this.setState({
      selectAll: self.state.selectAll === true ? false : true,
    })

    //$("input:checkbox").prop("checked", self.state.selectAll);




  }


  // Check Single Handler
  handleCheck = (props) => {
    //alert('select single'+ JSON.stringify(props));
  }

  updateGDPIuserstatus = () => {

    //alert("clicked")
    var checkedgdpidata = [];
    $.each($("input[type='checkbox']:checked"), function () {
      if ($(this).val() !== 'on' || $(this).val() !== 'NaN') {
        checkedgdpidata.push(parseInt($(this).val()))
      }
    });

    //console.log("Checked boxes", checkedgdpidata);
    //console.log("Checked boxes", checkedgdpidata.length);


    if (checkedgdpidata.length > 0) {
      this.state.griddata.map(person => {

        if (checkedgdpidata.includes(person[0].gDPIID)) {
          var gdpidata = {};
          Object.assign(gdpidata, { gDPIID: person[0].gDPIID });
          Object.assign(gdpidata, { studentPartyID: person[0].studentPartyID });
          Object.assign(gdpidata, { email: person[1].contactEmail });
          Object.assign(gdpidata, { isActive: person[1].isActive });
          Object.assign(gdpidata, { gDPIStatus: 10303 });
          Object.assign(gdpidata, { gDStatus: 10303 });
          Object.assign(gdpidata, { pIStatus: 10303 });

          /*gdpidata.gDPIID = person[0].gDPIID,
          gdpidata.studentPartyID = person[0].studentPartyID,
          gdpidata.email = person[1].contactEmail,
          gdpidata.isActive = person[1].isActive,
          gdpidata.gDPIStatus = 10303,*/
          //console.log("GDPI Data" + JSON.stringify(gdpidata))

          this.setState({
            selection: this.state.selection.push(gdpidata),
            // selection: [...this.state.selection, gdpidata]
          })

        }


      })

      ProductService.updategdpiStatus(this.state.selection, res => {

        //console.log("res:" + JSON.stringify(res))
        if (res.status === "success") {
          success("GDPI Status Updated successfully", successNotification);
          // window.location.reload();
          this.props.fetchStudents();
        }
        else {
          error(res.reasonText, errorNotification);
        }
      });
      //console.log("Selectionnnn :" + JSON.stringify(this.state.selection))

    }
  }


  handleStatus = (e, props) => {

    var gdpidata = {};
    Object.assign(gdpidata, { gDPIID: props[0].gDPIID });
    Object.assign(gdpidata, { studentPartyID: props[0].studentPartyID });
    Object.assign(gdpidata, { email: props[1].contactEmail });
    Object.assign(gdpidata, { isActive: props[1].isActive });
    Object.assign(gdpidata, { gDPIStatus: 10303 });
    Object.assign(gdpidata, { gDStatus: 10303 });
    Object.assign(gdpidata, { pIStatus: 10303 });

    this.setState({
      selection: this.state.selection.push(gdpidata),
      //selection: [...this.state.selection, gdpidata]
    });

    ProductService.updategdpiStatus(this.state.selection, res => {
      if (res.status === "success") {
        success("GDPI Status Updated successfully", successNotification);
        // window.location.reload();
        this.props.fetchStudents();
      }
      else {
        error(res.reasonText, errorNotification);
      }
    });
    //console.log("Selectionnnn :" + JSON.stringify(this.state.selection))
  }

  render() {
    //console.log("PROPS Students 123: ", JSON.stringify(this.props.students));
    console.log("this.state.griddata", this.state.griddata)
    // var apprvstatus = '';
    return (
      <React.Fragment>
        <section class="dashboard-tab user-tab pt-50 mb-50">
          <div class="padding-rl-50">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="table-header">
                    <h3>
                      <div class="row">
                        <div class="col-md-8 col-sm-6 col-xs-4">
                          <span class="left-icon">
                            <i class="fa fa-list" aria-hidden="true"></i>
                          </span>{" "}
                          GDPI
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-8">
                          <span class="right-icon">
                            <a title="Download Score update template" href="#">
                              <i
                                // onClick={() => this.exportToCSV()}
                                onClick={this.exportToCSV}
                                disabled={this.state.disabledownloadxls}
                                class="fa fa-file-excel-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span>
                          <span class="right-icon">
                            {/* <a href="/userbulkupload"> */}
                            <a title="Bulk Upload" onClick={this.admingdpibulk} href="#">
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </a>
                          </span>
                          <span class="right-icon">
                            {/* <a href="/userbulkupload"> */}
                            <a title="Approve GDPI" onClick={this.updateGDPIuserstatus} disabled={this.state.disableapprovegdpi} href="#">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </a>
                          </span>


                        </div>
                      </div>
                    </h3>

                  </div>


                  <div class="filter">
                    <div className="form-group">
                      <h5 className="filter-heading">Search Criteria</h5>
                      <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12">
                          <label>GDPI Status</label>
                          <select
                            name="paymentStatus"
                            id="paymentStatus"
                            class="form-control"
                            onChange={this.handlestatusfilterChange}
                          >
                            <option value="selected">Select Status</option>
                            <option value="10302">Requested</option>
                            <option value="10303">Approved</option>
                            <option value="10306">Completed</option>
                          </select>
                        </div>
                        <div className="col-md-8 col-sm-6 col-xs-12 mt-30">
                          <button className="btn-5 mr-20 ml-20 mt-10" type="button" name="filter" onClick={this.showfiltereddata}>
                            Search
                          </button>
                          <button
                            className="btn-5 mt-10"
                            type="button"
                            name="all"
                            onClick={this.showfiltereddata}
                          // disabled={this.state.disableapprovegdpi}
                          >
                            Reset
                          </button>

                        </div>

                        {/* <div className="col-md-12 col-sm-12 col-xs-12 mt-30">
							<button className="btn-5 mr-20 ml-20 mt-10" type="button"  onClick={this.updateGDPIuserstatus}  disabled={ this.state.disableapprovegdpi }>
								Approve GDPI
							</button>
							<button
							   className="btn-5 mt-10"
							   type="reset"
							   onClick={this.exportToCSV}
							   disabled={ this.state.disabledownloadxls }
							   >
							Download Score update template
							</button>

							</div> */}

                      </div>
                    </div>
                  </div>



                  <div class="bg-box">
                    <div class="table-responsive">
                      <ReactTable
                        minRows={2}
                        data={this.state.griddata || []}
                        className="-striped -highlight"
                        filterable
                        onPageChange={this.stateonpagechange}
                        ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                        defaultFilterMethod={this.filterCaseInsensitive}
                        columns={[
                          {

                            columns: [
                              {
                                Header: (
                                  <input
                                    label='check '
                                    type='checkbox'
                                    name="selectall"
                                    value="selectall"
                                    onChange={this.checkAllHandler}
                                  // checked={this.state.select === true ? 'checked' : ""}
                                  />
                                ),
                                id: 'checkbox5',
                                width: 30,
                                sortable: false,
                                filterable: false,
                                accessor: d => d[0].gDPIID,
                                Cell: props => {

                                  let status = this.props.gdpiStatus.map(status =>
                                    status.codeValueId === props.original[0].gDPIStatus
                                      ? status.codeValue === 'Requested' ?

                                        <input type="checkbox" name={props.original[0].gDPIID} value={props.original[0].gDPIID} defaultChecked={this.state.selectAll} onChange={e => this.handleCheck(props.original)} />

                                        /*  (this.state.selectAll == true ?
                                            //var gdpidata = [];
                                        (gdpidata["gDPIID"]= props.original[0].gDPIID,
                                        gdpidata["studentPartyID"]= props.original[0].studentPartyID,
                                        gdpidata["email"]=props.original[1].contactEmail,
                                        gdpidata["isActive"]= props.original[1].isActive,
                                        gdpidata["gDPIStatus"]= 10303,
                                            this.setState({
                                              selection : this.state.selection.concat(gdpidata)
                                            })
                                          )
                                          : "")*/


                                        : ""


                                      : ""
                                  )
                                  return (
                                    <div>

                                      {status}
                                    </div>
                                  );

                                }
                              },
                              {
                                Header: "Student ID",
                                id: "partyId",
                                accessor: d => ((d[1] || '').partyId || "").toString()
                              },
                              {
                                Header: "Student Name",
                                id: "firstName",
                                accessor: d => ((d[1] || '').firstName || "")
                              },
                              {
                                Header: "Request Raised Date",
                                id: "requestRaisedOn",
                                accessor: d => (d[0].requestRaisedOn.split(' ')[0] || "").toString()
                              },
                              {
                                Header: "GD Score",
                                id: "gDScore",
                                accessor: d => (d[0].gDScore || "").toString()


                              },

                              {
                                Header: "GD Status",
                                id: "gDCompleted",
                                filterable: false,
                                sortable: false,
                                accessor: d =>
                                  this.props.gdpiStatus.map(status =>
                                    status.codeValueId === d[0].gDStatus
                                      ? status.codeValue
                                      : ""
                                  )
                              },
                              {
                                Header: "PI Score",
                                id: "pIScore",
                                accessor: d => (d[0].pIScore || "").toString()
                              },
                              {
                                Header: "PI Status",
                                id: "pICompleted",
                                filterable: false,
                                sortable: false,
                                accessor: d =>
                                  this.props.gdpiStatus.map(status =>
                                    status.codeValueId === d[0].pIStatus
                                      ? status.codeValue
                                      : ""
                                  )
                              },
                              {
                                Header: "VR Score",
                                id: "videoResumeScore",
                                accessor: d => (d[0].videoResumeScore || "").toString()
                              },
                              {
                                Header: "GDPI Score",
                                id: "gDPIScore",
                                accessor: d => (d[0].gDPIScore || "").toString()
                              },
                              {
                                Header: "GDPI Status",
                                id: "gDPIStatus",
                                filterable: false,
                                sortable: false,
                                accessor: d =>
                                  this.props.gdpiStatus.map(status =>
                                    status.codeValueId === d[0].gDPIStatus
                                      ? status.codeValue
                                      : ""
                                  )
                              },
                              {
                                Header: "GDPI Approved",
                                id: "actiongdpi",
                                filterable: false,
                                sortable: false,
                                accessor: d => (d[0].pIStatus || "").toString(),
                                Cell: props => {
                                  //console.log("Action column new" + JSON.stringify(props.original[0]))

                                  let status = this.props.gdpiStatus.map(status =>
                                    status.codeValueId === props.original[0].gDPIStatus
                                      ? status.codeValue === 'Requested' ? <Toggle
                                        icons={false}
                                        defaultChecked={false}
                                        onChange={e =>
                                          this.handleStatus(e, props.original)
                                        }
                                      /> : <Toggle
                                        icons={false}
                                        defaultChecked={true}
                                        disabled={true}
                                      />
                                      : ""
                                  )
                                  return (
                                    <div>

                                      {status}
                                    </div>
                                  );

                                }
                              },
                              {
                                Header: "Approved date",
                                id: "lastModifiedDate",
                                accessor: d => d[0].lastModifiedDate
                              },



                              // {
                              //   Header: "GD Venue",
                              //   id: "gDVenue",
                              //   accessor: d => d[0].gDVenue
                              // },
                              // {
                              //   Header: "GD Date",
                              //   id: "gDDate",
                              //   accessor: d => d[0].gDDate
                              // },
                              // {
                              //   Header: "PI Venue",
                              //   id: "pIVenue",
                              //   accessor: d => d[0].pIVenue
                              // },

                              // {
                              //   Header: "PI Date",
                              //   id: "pIDate",
                              //   accessor: d => d[0].pIDate
                              // },

                              // {
                              //   Header: "GD Assessed By",
                              //   id: "gDAssessedBy",
                              //   accessor: d => d[0].gDAssessedBy
                              // },

                              // {
                              //   Header: "PI Conducted By",
                              //   id: "pIConductedBy",
                              //   accessor: d => d[0].pIConductedBy
                              // },

                              // {
                              //   Header: "VR Assessed By",
                              //   id: "videoResumeAssessedBy",
                              //   accessor: d => d[0].videoResumeAssessedBy
                              // },


                              // {
                              //   Header: "Initiated By",
                              //   id: "createdBy",
                              //   accessor: d => d[0].gdpiinitiatedBy
                              // },

                              // {
                              //   Header: "Initiate Date",
                              //   id: "createdDate",
                              //   accessor: d => (d[0].GDPIInitiatedDate || "").toString()
                              // },

                              // {
                              //   Header: "Last Modified By",
                              //   id: "lastModifiedBy",
                              //   accessor: d => d[0].lastModifiedBy
                              // },

                              // {
                              //   Header: "Last Modified Date",
                              //   id: "lastModifiedDate",
                              //   accessor: d => d[0].lastModifiedDate
                              // },

                              // {
                              //   Header: "Status",
                              //   id: "isActive",
                              //   accessor: d => d[1].isActive
                              // },
                              // {
                              //   Header: "Initiate",
                              //   id: "initiate",
                              //   accessor: d =>
                              //     this.props.gdpiStatus.map(status =>
                              //       status.codeValueId == d[0].gDPIStatus
                              //         ? status.codeValue
                              //         : ""
                              //     )
                              // },



                              {
                                Header: "Actions",
                                id: "action",
                                filterable: false,
                                sortable: false,
                                accessor: d => d[0].pIStatus,
                                Cell: props => {

                                  let status = this.props.gdpiStatus.map(status =>
                                    status.codeValueId === props.original[0].gDPIStatus
                                      ? status.codeValueId !== 10302 ?

                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#updateGDPI"
                                          class="mar-left"
                                        >
                                          <i
                                            className="fa fa-edit fa-2x"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#gdpi-score"
                                            data-backdrop="static"
                                            data-keyboard="true"
                                            onClick={() =>
                                              this.handleClick(props.original)
                                            }
                                          ></i>
                                        </a>

                                        : "" : ""
                                  )
                                  return (
                                    <div>
                                      {status}
                                    </div>
                                  );
                                }
                              }

                            ]
                          }
                        ]}
                        defaultPageSize={10}
                        style={{
                          width: "100%",
                          maxHeight: "500px"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <GdpiScoreUpdate
          student={this.state}
          gdpiStatus={this.props.gdpiStatus}
          fetchStudents={this.props.fetchStudents}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}

export default GdpiStudentList;
