import { Global_var } from "../global/global_var";
import { LoginService } from "./login.service";
import RestDataSource from "./restdatasource";

export const EventService = {
  updateEventDetails,
  addEvent,
  getEventDetailsById,
  getAllEventTypes,
  getFilteredCity,
  getSubUrbListOnCityID,
  getFilteredStudent,
  excelFileValidate,
  eventReportList,
  sendEventRemainderMail
};
function getAllEventTypes(data, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_GET_ALL_EVENT_TYPES}`;
  return new RestDataSource(url, fnError).GetData(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getFilteredCity(getFilteredCity, fn, fnError) {
  // LoginService.TokenUser((res) => {
    // if (res.status === "success") {
      // localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_FILTERED_CITY;
      return new RestDataSource(url, fnError).GetOneByParam(
        getFilteredCity,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
  //   }
  // }, fnError);
}
function getSubUrbListOnCityID(getSubUrbListOnCityID, fn, fnError) {
  // LoginService.TokenUser((res) => {
    // if (res.status === "success") {
      // localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_SUBURB_LIST_ON_CITY_ID;
      return new RestDataSource(url, fnError).GetOneByParam(
        getSubUrbListOnCityID,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    // }
  // }, fnError);
}

function getFilteredStudent(getFilteredStudent, fn, fnError) {
  // LoginService.TokenUser((res) => {
    // if (res.status === "success") {
      // localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_FILTERED_STUDENT;
      return new RestDataSource(url, fnError).GetOneByParam(
        getFilteredStudent,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
  //   }
  // }, fnError);
}

function excelFileValidate(file, fn) {
  var url = Global_var.BASEURL + Global_var.URL_EXCEL_FILE_VALIDATE;
  return new RestDataSource(url).Store(file, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}

function addEvent(data, fn, fnError) {
  // LoginService.TokenUser((res) => {
    // if (res.status === "success") {
      // localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_EVENT;
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    // }
  // }, fnError);
}
function eventReportList(videoReportList, fn, fnError) {
  // LoginService.TokenUser((res) => {
    // if (res.status === "success") {
      // localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_EVENT_REPORT;
      return new RestDataSource(url, fnError).GetOneByParam(
        videoReportList,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
  //   }
  // }, fnError);
}
function getEventDetailsById(getEventDetailsById, fn, fnError) {
  // LoginService.TokenUser((res) => {
    // if (res.status === "success") {
      // localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_EVENT_DETAILS_BY_ID;
      return new RestDataSource(url, fnError).GetOneByParam(
        getEventDetailsById,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    // }
  // }, fnError);
}

function updateEventDetails(data, fn, fnError) {
  // LoginService.TokenUser((res) => {
    // if (res.status === "success") {
      // localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_UPDATE_EVENT_DETAILS;
      return new RestDataSource(url, fnError).Update(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    // }
  // }, fnError);
}
function sendEventRemainderMail(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_SEND_EVENT_REMAINDER_EMAIL;
  return new RestDataSource(url).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}