// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import "react-table/react-table.css";
import AdminProfile from "./newAdminProfile";
import AdminProgress from "./adminProgress";
// import AdminCalender from "./adminCalender";
import AdminSocialStreaming from "./adminSocialStreaming";
import RecentActivity from "./recentActivity";
import MyCalander from "../student-dashboard/mycalander";
// import $ from "jquery";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

class Admin extends Component {
  constructor(props) {
    super(props);

    //console.log("admin", this.props.alertNotificationEvents);
    this.state = {
      f_auth: {
        uname: "",
        passwd: ""
      },
      loading: true
    };
  }
  componentDidMount() {
    document.querySelector("#loader").style.display = "none";
  }

  render() {
    return (
      <React.Fragment>
        <section
          class="dashboard-tab admin-dashboard mb-50"
          id="admin-dashboard"
        >
          <div className="container">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-50 mb-10 pl pr">
              <div className="row">
                <AdminProfile
                  profileName={this.props.adminUser}
                  getAdminAddress={this.props.getAdminAddress}
                  {...this.props}
                />
                <AdminProgress
                  studentProgressOverviewData={
                    this.props.studentProgressOverviewData
                  }
                  studentProgressOverviewDataPending={
                    this.props.studentProgressOverviewDataPending
                  }
                />
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  {this.props.calenderEventsPending ? (
                    <PopUpPortal
                      HidePopup={this.state.loading}
                      IsVisible={this.state.loading}
                    />
                  ) : (
                    <MyCalander props={this.props.calenderEvents} />
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10 mb-50 pl pr">
              <div className="row">
                <RecentActivity
                  studentProgressOverviewData={
                    this.props.studentProgressOverviewData
                  }
                  studentProgressOverviewDataPending={
                    this.props.studentProgressOverviewDataPending
                  }
                />
                <AdminSocialStreaming
                  studentEnquiry={this.props.studentEnquiry}
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Admin;
