import React, { Component } from "react";
import { connect } from "react-redux";
import { LoginService } from "../../services/login.service";
import Finxheader from "../finxheader/finxheader";
import AdminHeader from "../../components/dashboardheader/adminheader";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "./finxfooter";
import Privacydetails from "./privacydetails";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchStudentDashboard.service";
import { bindActionCreators } from "redux";

const mapStateToProps = state => ({
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

// export default class finxprivacypolicy extends React.Component {
const finxprivacypolicy = connectFunction(
  class extends Component {

    // constructor(props) {

    //   super(props);
    //   //console.log("in my product", this.props);
    //   //alert(this.props.history.location.state.product.productID);
    //   //console.log("Props :", this.props)

    // }

    componentDidMount() {
      const {
        fetchAlertNotificationEvents,
        fetchNotificationEvents
      } = this.props;

      let partyID = localStorage.getItem("userid-Token");
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
      });
      // fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID); //"partyId=" + partyID
    }
    render() {
      // var alertdata = JSON.parse(localStorage.getItem("alertdata"));
      // var notealertdata = JSON.parse(localStorage.getItem("notealertdata"));
      //console.log("alertdata",alertdata)
      // const {
        // alertNotificationEvents,
        // pendingAlertNotificationEvents,
        // errorAlertNotigicationEvents,
        // notificationEvents,
        // pendingNotificationEvents,
        // errorNotificationEvents,
      // } = this.props;

      localStorage.setItem("alertdata", JSON.stringify(this.props.alertNotificationEvents))
      localStorage.setItem("notealertdata", JSON.stringify(this.props.notificationEvents))
      let header = "";
      if (localStorage.getItem('userid-Token')) {

        if ([5,'5'].includes(localStorage.getItem('roleId'))) {

          header = <DashboardHeader
            //alertNotificationEvents={localStorage.getItem('alertdata')}
            //notificationEvents={localStorage.getItem('notealertdata')}
            {...this.props}
          />
        }
        else {
          header = <AdminHeader
            //alertNotificationEvents={localStorage.getItem('alertdata')}
            //notificationEvents={localStorage.getItem('alertnote')}
            {...this.props}
          />
        }
      }
      else {
        header = <Finxheader />
      }
      return (
        <React.Fragment>
          {header}
          <Privacydetails />
          <Finxfooter />
        </React.Fragment>
      );
    }
  }
);
export default finxprivacypolicy;
