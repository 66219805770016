//Library Imports 
import React, { Component } from 'react';
import { Formik, Field, ErrorMessage,Form} from "formik";
import loadable from '@loadable/component';
import CssBaseline from '@material-ui/core/CssBaseline';
import $ from "jquery";
import * as Yup from "yup";
// Service Imports
import { RegisterUserService } from "../../../services/registration.service";
import { LoginService } from "../../../services/login.service";
import { PopUpPortal } from "../../../containers/popups/PopUpPortal";
import LoadingFallBack from "../../../components/loadingFallBack";
// Asset Imports
const ThankU = require('../../../assets/images/ThankYouBanner.png');
// Component Imports
const Finxfooter = loadable(
  () => import("../../../containers/finxfooter/finxfooter"),
  {
    fallback: <LoadingFallBack />,
  }
);
// import Finxfooter from '../../../containers/finxfooter/finxfooter';

// Unused Imports 
// import {
  //   error,
  //   success,
  //   warning,
  //   warningNotification,
  //   errorNotification,
  //   successNotification
  // } from "../../notification/notifications";
  // import ThankU from '../../../assets/images/Thank_u.png';
  
  class RegisterSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoginName: "",
      userPassword: "",
      userLoginName1: "",
      userPassword1: "",
      isMaintainenceActivated: false,
      isMobile: false,
      ThankUImageShow:false,
    };
  }

  handleBackClick = () => {
    let loginFrom = localStorage.getItem('loginFrom')

    let email = JSON.parse(localStorage.getItem('loginData'))["emailID"]
    let googleId = JSON.parse(localStorage.getItem('loginData'))["googleId"]
    let facebookId = JSON.parse(localStorage.getItem('loginData'))["facebookId"]
    let password = JSON.parse(localStorage.getItem('loginData'))["password"]

    this.setState({ userPassword: password })
    this.setState({ userLoginName: email })
    this.setState({ googleId })
    this.setState({ facebookId })

    // ! Login Handle Logic
    this.setState({ loading: true });
    var logincon = [];
    this.setState({ loading: true });

    if (loginFrom === 'Social') {


      const LoginDetails = {
        userLoginName: email,
        emailId: email,
        googleId: googleId,
        facebookId: facebookId
      }

      this.setState({ loading: true });
      LoginService.sendUserSocial(LoginDetails, (res) => {

        if (res.status === 'success') {
          logincon = res;
          this.setState({ credentials: logincon });
          localStorage.setItem('eMail', logincon.responseObject.emailId);
          localStorage.setItem('userid-Token', logincon.responseObject.partyId);
          localStorage.setItem('userid', logincon.responseObject.userId);
          localStorage.setItem('roleId', logincon.responseObject.role[0].roleId);
          localStorage.setItem('roleName', logincon.responseObject.role[0].roleName);
          localStorage.setItem('checkedtab', false);
          localStorage.setItem('TokenName', 'jti');
          LoginService.TokenUser(res => {
            this.setState(state => {
              let oldstate = Object.assign({}, state);
              oldstate.credentials.responseObject.token = res.responseObject;
              this.state = oldstate; // setState({...})
              localStorage.setItem('jti-token', res.responseObject);
              localStorage.setItem('login', JSON.stringify(oldstate.credentials.responseObject));
            });

          }).then(()=>{
          localStorage.setItem('TokenName', 'jwt');
          LoginService.TokenUser(res => {
            localStorage.setItem('jwt-token', res.responseObject);
            this.setState({ loading: false });

            if (this.state.credentials.responseObject["forcePasswordChangeOnNextLoginFlag"] === '1') {
              localStorage.setItem('reload', 'true');
              this.props.history.push('/changepassword');
            } else {
              if (this.state.credentials.responseObject.role[0].roleId === 5) {
                $('.modal-backdrop').hide();
                this.setState({ loading: true });
                setTimeout(() => {  
                this.setState({ loading: false });
                this.props.history.push('/studentDashboard');
                localStorage.setItem('reload', 'true');
                }, 3000);
              } else if (this.state.credentials.responseObject.role[0].roleId === 6) {
                debugger
                $('.modal-backdrop').hide();
                this.setState({ loading: true });
                setTimeout(() => {  
                this.setState({ loading: false });
                this.props.history.push('/ops-dashboard');
                localStorage.setItem('reload', 'true');
                }, 3000);
              } else {
                $('.modal-backdrop').hide();
                this.setState({ loading: true });
                setTimeout(() => {  
                this.setState({ loading: false });
                this.props.history.push('/admin-dashboard');
                localStorage.setItem('reload', 'true');
                }, 3000);
              }
            }
          });
          });
        }
      })
      // ! End of Login Handle Logic
    } else {

      const LoginDetails = {
        userLoginName: email,
        emailId: email,
        userPassword: password,
        throughRegister: 1
      }
      this.setState({ loading: true });
      LoginService.SendUser(LoginDetails, this.state.isMobile, (res) => {
        if (res.status === 'success') {
          logincon = res;
          this.setState({ credentials: logincon });
          localStorage.setItem('eMail', logincon.responseObject.emailId);
          localStorage.setItem('userid-Token', logincon.responseObject.partyId);
          localStorage.setItem('userid', logincon.responseObject.userId);
          localStorage.setItem('roleId', logincon.responseObject.role[0].roleId);
          localStorage.setItem('roleName', logincon.responseObject.role[0].roleName);
          localStorage.setItem('checkedtab', false);
          localStorage.setItem('TokenName', 'jti');
          LoginService.TokenUser(res => {
            this.setState(state => {
              let oldstate = Object.assign({}, state);
              oldstate.credentials.responseObject.token = res.responseObject;
              this.state = oldstate; //setState({...})
              localStorage.setItem('jti-token', res.responseObject);
              localStorage.setItem('login', JSON.stringify(oldstate.credentials.responseObject));
            });

          }).then(()=>{
          localStorage.setItem('TokenName', 'jwt');
          LoginService.TokenUser(res => {
            localStorage.setItem('jwt-token', res.responseObject);
            this.setState({ loading: false });

            if (this.state.credentials.responseObject["forcePasswordChangeOnNextLoginFlag"] === '1') {
              localStorage.setItem('reload', 'true');
              this.props.history.push('/changepassword');
            } else {
              if (this.state.credentials.responseObject.role[0].roleId === 5) {
                $('.modal-backdrop').hide();
                this.setState({ loading: true });
                setTimeout(() => {  
                this.setState({ loading: false });
                this.props.history.push('/studentDashboard');
                localStorage.setItem('reload', 'true');
                }, 3000);
              } else {
                $('.modal-backdrop').hide();
                this.setState({ loading: true });
                setTimeout(() => {  
                this.setState({ loading: false });
                this.props.history.push('/admin-dashboard');
                localStorage.setItem('reload', 'true');
                }, 3000);
              }
            }
          });
          });
        }
      })

      // ! End of Login Handle Logic
    }


  }
  whatsappClick = () => {
    window.open("https://chat.whatsapp.com/FkdV4OejvNHKIClsJqsBnw","_blank")
  }
  componentDidMount() {    
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-HBDWR4PNHC";
    script.async = true;

    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-HBDWR4PNHC');
    
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=GTM-WX26Q65";
    script1.async = true;

    document.head.appendChild(script1);

    window.dataLayer = window.dataLayer || [];
    function gtag1(){
      window.dataLayer.push(arguments);
    }
    gtag1('js', new Date());
    gtag1('config', 'GTM-WX26Q65');

     // Add Linkedin Code
     const script2 = document.createElement('script');
     script2.type = 'text/javascript';
     script2.innerHTML = `
      var _linkedin_partner_id = "5529801";
       window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
       window._linkedin_data_partner_ids.push(_linkedin_partner_id);
     `;
 
     const script3 = document.createElement('script');
     script3.type = 'text/javascript';
     script3.innerHTML = `
       (function(l) {
         if (!l) {
           window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
           window.lintrk.q=[];
         }
         var s = document.getElementsByTagName("script")[0];
         var b = document.createElement("script");
         b.type = "text/javascript";
         b.async = true;
         b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
         s.parentNode.insertBefore(b, s);
       })(window.lintrk);
     `;
 
     const noscriptImg = document.createElement('img');
     noscriptImg.height = '1';
     noscriptImg.width = '1';
     noscriptImg.style.display = 'none';
     noscriptImg.alt = '';
     noscriptImg.src = 'https://px.ads.linkedin.com/collect/?pid=5529801&fmt=gif';
 
     document.head.appendChild(script2);
     document.head.appendChild(script3);
     document.querySelector('noscript').appendChild(noscriptImg);
    this.handleCPBFIPopUp();
   }
   handleCPBFIPopUp=()=>{
    this.setState({
      loading:true
    })
    let LoginData=JSON.parse(localStorage.getItem("loginData"))
    let PARAM;
    PARAM = `partyId=${LoginData?.studentPartyID}`;
    
  RegisterUserService.getCPBFIDetails(PARAM,
      (res1) => {
        console.log("CPBFIDetails getFlag",res1);
     if(res1.status==="success"){

     if(res1.responseObject==="Y"){   
      if(["/registerSuccess","/registerSuccess","/RegisterSuccess"].includes(window?.location?.pathname)){ 
        window
        .$("#CPBFIModal")
        .modal({ backdrop: "static", keyboard: false }, "show");
      }
     }
     else{
      window.$("#CPBFIModal").modal("hide");
      this.setState({ThankUImageShow:true})
     }
      this.setState({
        loading:false
      })
     }
     else{
      this.setState({
        loading:false
      })
     }
    });
   
  }
   handleCPBFISubmit=(values)=>{
    let LoginData=JSON.parse(localStorage.getItem("loginData"))
    let studentID1=LoginData?.studentID;
  let data = {
        qualificationCertificateId:4,
        studentID: studentID1?studentID1:"",
        certiNumber:values.BFSID?values.BFSID:"",
        batchNumber: values.batchNumber?values.batchNumber:"",
      }  
    console.log('CPBFIPayload data',data);

    this.setState({ loading: true });
    RegisterUserService.saveCPBFIDetails(data, (res) => {
    console.log('CPBFI res',res);
    if (res.status === 'success') {
    this.setState({ loading: false });
    window.$("#CPBFIModal").modal("hide");
    this.setState({
      ThankUImageShow:true
    });
    }
    else {
    this.setState({ loading: false });
    // this.setState({
    //   ThankUImageShow:true
    // });
    // window.$("#CPBFIModal").modal("hide");
    }
    })
  }
   render() {
    return (
      <React.Fragment>
          <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
        <CssBaseline />
        <div className="container mb-50p">
          <div className="">
            <div className="col-md-12 col-sm-12 col-xs-12 text-center" style={{ display: 'flex', flexDirection: 'column', columnGap: '20px' }}>
              <div className="col-md-12 col-sm-12 col-xs-12">
            {this.state.ThankUImageShow&&(
                <div style={{position:"relative"}}>
              <img src={ThankU} width="300px" alt='img' />
              <div className="thanku-click-btn"  onClick={this.handleBackClick}></div>
              </div>
                )}
                {/* <p><b>Thank you for Successfully Registering on Finx Portal</b></p> */}
              </div><br />
              {/* <div className="col-md-12 col-sm-12 col-xs-12">
                <button type="submit" onClick={this.handleBackClick} className="btn-4 margin-auto" name="Submit">Ok</button>
              </div> */}
            </div>
          </div>
        </div>
            {/* ! Start of CPBFI Modal */}
            <Formik
          enableReinitialize={true}
          initialValues={{
            BFSID:"",
            batchNumber:"",
          }}
          validationSchema={Yup.object().shape({
            BFSID: Yup.string().required("You must Specify BFS ID").matches(
                /^[a-zA-Z0-9]*$/,
                "Please Enter Valid BFS ID(Only AlphaNumeric Characters are allowed)"
              ),
            batchNumber: Yup.string().required("You must Specify Batch Number").matches(
                /^[a-zA-Z0-9]*$/,
                "Please Enter Valid Batch Number(Only AlphaNumeric Characters are allowed)"
              ),
          })}
          render={({
            errors,
            touched,
            handleSubmit,
            handleChange,
            isValid,
            values,
            setFieldValue,
            setFieldTouched,
            ...formikProps
          }) => (
            <React.Fragment>
              <div
                className="modal fade"
                id="CPBFIModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                style={{ textAlign: "center",background:"none" }}
                              >
                                <h4 style={{ color: "black" }}>
                                  {" "}
                            If you are a CPBFI student or alumni, kindly complete the following information.
                                </h4>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <Form>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              BFSID
                                            <span className="validation">
                                                {" "}
                                                *
                                              </span> 
                                            </label>

                                            <Field
                                              type="text"
                                              name="BFSID"
                                              id="BFSID"
                                              value={values.BFSID}
                                              // className={"form-control"}
                                              className={
                                                "form-control " +
                                                (errors.BFSID && touched.BFSID
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="BFS ID"
                                            />
                                             <ErrorMessage
                                              name="BFSID"
                                              className="validation"
                                              component="div"
                                            />
                                          </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              Batch Number
                                             <span className="validation">
                                                {" "}
                                                *
                                              </span> 
                                            </label>
                                            <Field
                                              type="text"
                                              name="batchNumber"
                                              id="batchNumber"
                                              value={values.batchNumber}
                                              // className={"form-control"}
                                              className={
                                                "form-control " +
                                                (errors.batchNumber && touched.batchNumber
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Batch Number"
                                            />
                                              <ErrorMessage
                                                name="batchNumber"
                                                className="validation"
                                                component="div"
                                              />
                                          </div>
                                        </div>
                                          <div className="row">
                                              <div className="col-md-3 col-sm-3 col-xs-12">
                                                {" "}
                                              </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12">
                                              <button
                                                type="button"
                                                className="btn-1 mt-40"
                                                onClick={async ()=>{
                                                  const curErrors = await formikProps.validateForm();
                                                  const curErrorsKeys = Object.keys(curErrors);
                                                  console.log("CPBFI popup formik errors",curErrorsKeys)
                                                  if (curErrorsKeys.length) {
                                                    const el = document.getElementById(curErrorsKeys[0]);
                                                    if (el){
                                                      console.log("el",el)
                                                      setFieldTouched(curErrorsKeys[0],true);
                                                        el.focus();
                                                    }  
                                                  }
                                                  if (!curErrorsKeys.length) {
                                                    this.handleCPBFISubmit(values)}
                                                  }
                                                  
                                                }
                                              >
                                                Submit
                                              </button>
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-xs-12">
                                                {" "}
                                              </div>
                                          </div>
                                      </div>
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />
        {/* ! End of CPBFI Modal */}
        <Finxfooter />
      </React.Fragment>
    );
  }
}

export default RegisterSuccess;