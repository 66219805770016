import RestDataSource from './restdatasource';
import { Global_var } from '../global/global_var';

export const EnrollmentService = {
  fetchEnrollment,
  Usermanagement
};

function fetchEnrollment(data, fn, fnError) {

  var url = Global_var.BASEURL + Global_var.URL_STUDENT_ENROLLMENT_HISTORY;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    //console.log("enroll",res)
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);


    }
  });
}

function Usermanagement(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_USER_MANAGEMENT;
  return new RestDataSource(url, fnError).GetOneByParam(data, res => {

    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}




