import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as moment from "moment";

export const ExcelService = {
    ExcelData,
    ExcelData1,
    ExcelData2,
    ExcelData3,
    ExcelData4,
};

function ExcelData(data, fileName) {
    let todayDate = moment(new Date()).format('DD-MM-YYYY');
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(data);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const datas = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(datas, fileName + todayDate + fileExtension);
    //return;


}
function ExcelData1(eventName,data, fileName) {
    let todayDate = moment(new Date()).format('DD-MM-YYYY');
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    
    const headers = Object.keys(data[0]);
    const wsData = [
    ['Participant Details'],
    ['EVENT NAME: ' + eventName],
    [],//for add new blank line 
    headers,
    ...data.map(item => headers.map(header => item[header]))
  ];

  const ws = XLSX.utils.aoa_to_sheet(wsData);

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const datas = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(datas, fileName + todayDate + fileExtension);
}

function ExcelData2(data, fileName) {
    let todayDate = moment(new Date()).format('DD-MM-YYYY');
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    
    const headers = Object.keys(data[0]);
    const wsData = [
    [`Candidate Job Details`],
    headers,
    ...data.map(item => headers.map(header => item[header]))
  ];

  const ws = XLSX.utils.aoa_to_sheet(wsData);

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const datas = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(datas, fileName + todayDate + fileExtension);
}
function ExcelData3(data, fileName) {
  let todayDate = moment(new Date()).format('DD-MM-YYYY');
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  
  const headers = Object.keys(data[0]);
  const wsData = [
  [`Candidate Course Details`],
  headers,
  ...data.map(item => headers.map(header => item[header]))
];

const ws = XLSX.utils.aoa_to_sheet(wsData);

const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
const datas = new Blob([excelBuffer], { type: fileType });
FileSaver.saveAs(datas, fileName + todayDate + fileExtension);
}
function ExcelData4(data, fileName) {
  let todayDate = moment(new Date()).format('DD-MM-YYYY');
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  
  const headers = Object.keys(data[0]);
  const wsData = [
  [`Candidate Rating Details`],
  headers,
  ...data.map(item => headers.map(header => item[header]))
];

const ws = XLSX.utils.aoa_to_sheet(wsData);

const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
const datas = new Blob([excelBuffer], { type: fileType });
FileSaver.saveAs(datas, fileName + todayDate + fileExtension);
}
