import React from "react";

const FirstMessage = () => {
  return (
    <div className="notify">
      <br />
      <span style={{float:"right",fontSize:"30px",marginTop:"-27px",marginRight:"6px"}}>&times;</span>
      <b className="notify">Info!</b>
      <p className="notify">
        1. This is a great way to build your knowledge of various aspects of
        BFSI and strengthen your CV. To start with, you can access free videos
        and courses.
        <br />
        2. The recommendations displayed are derived from the information that
        you have provided.
      </p>
    </div>
  );
};
const SecondMessage = () => {
  return (
    <div className="notify">
      <br />
      <span style={{float:"right",fontSize:"30px",marginTop:"-27px",marginRight:"6px"}}>&times;</span>
      <b className="notify">Info!</b>
      <p className="notify">
        1. Make today your lucky day! A completed profile is 5X MORE likely to
        be selected by the Corporates.
        <br /> 2. The recommendations displayed are derived from the information
        that you have provided.
      </p>
    </div>
  );
};

export { FirstMessage, SecondMessage };
