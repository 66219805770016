export const FETCH_ROLES_PENDING = 'FETCH_ROLES_PENDING';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';

export function fetchRolesPending() {
    return {
        type: FETCH_ROLES_PENDING
    }
}

export function fetchRolesSuccess(roles) {
    return {
        type: FETCH_ROLES_SUCCESS,
        payload: roles
    }
}

export function fetchRolesError(error) {
    return {
        type: FETCH_ROLES_ERROR,
        error: error
    }
}