import {
  fetchCalenderEventsPending,
  fetchCalenderEventsSuccess,
  fetchCalenderEventsError
} from "../action/calenderEventsAction";
import {
  fetchAlertNotificationEventsPending,
  fetchAlertNotificationEventsSuccess,
  fetchAlertNotificationEventsError
} from "../action/alertNotificationAction";
import {
  fetchNotificationEventsPending,
  fetchNotificationEventsSuccess,
  fetchNotificationEventsError
} from "../action/notificationAction";

import {
  fetchProductPreRequisitePending,
  fetchProductPreRequisiteSuccess,
  fetchProductPreRequisiteError
} from "../action/productPreRequisiteAction";
import {
  fetchProductDetailsByPartyIdPending,
  fetchProductDetailsByPartyIdSuccess,
  fetchProductDetailsByPartyIdError
} from "../action/productDetailsByPartyIDAction";

import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import {
  fetchProductDetailsAllError,
  fetchProductDetailsAllSuccess,
  fetchProductDetailsAllPending
} from "../action/productDetailsAllAction";
import {
  fetchModulesListPending,
  fetchModulesListSuccess,
  fetchModulesListError
} from "../action/modulesListAction"
import {
  fetchLearningPathPending,
  fetchLearningPathSuccess,
  fetchLearningPathError
} from "../action/learningPathAction"
import {
  fetchLearningPathProductPending,
  fetchLearningPathProductSuccess,
  fetchLearningPathProductError
} from "../action/learningPathProductDataAction"
import {
  fetchPartnerError,
  fetchPartnerSuccess,
  fetchPartnerPending
} from "../action/productPartnerAction";
import {
  fetchStatusError,
  fetchStatusSuccess,
  fetchStatusPending
} from "../action/productStatusAction";
import {
  fetchHasModulePending,
  fetchHasModuleSuccess,
  fetchHasModuleError
} from "../action/productHasModuleAction";
import {
  fetchStudentProgressOverviewPending,
  fetchStudentProgressOverviewSuccess,
  fetchStudentProgressOverviewError
} from "../action/getStudentProgressOverviewAction";
import {
  fetchProductProductTypePending,
  fetchProductProductTypeSuccess,
  fetchProductProductTypeError
} from "../action/productProductTypeAction";
import {
  fetchStudentPaymentsHistoryPending,
  fetchStudentPaymentsHistorySuccess,
  fetchStudentPaymentsHistoryError
} from "../action/fetchStudentPaymentHistory";

import {
  fetchEnrollmentHistoryPending,
  fetchEnrollmentHistorySuccess,
  fetchEnrollmentHistoryError
} from "../action/fetchEnrollmentHistoryData";
import {
  fetchAssessmentReportPending,
  fetchAssessmentReportSuccess,
  fetchAssessmentReportError
} from "../action/assessmentAction";

import {
  fetchUnassignedUsersReportPending,
  fetchUnassignedUsersReportSuccess,
  fetchUnassignedUsersReportError
} from "../action/unassignedUsersAction";
import {
  fetchCfxAssessmentReportPending,
  fetchCfxAssessmentReportError,
  fetchCfxAssessmentReportSuccess
} from "../action/cfxAssessmentAction";
import {
  fetchVocationalCoursesAllError,
  fetchVocationalCoursesAllPending,
  fetchVocationalCoursesAllSuccess
} from "../action/vocationCoursesAllAction"

export function fetchCalenderEvents(param) {
  var url = Global_var.BASEURL + Global_var.URL_CALENDER_EVENTS;
  return dispatch => {
    dispatch(fetchCalenderEventsPending());

    return new RestDataSource(url, err => {
      fetchCalenderEventsError(err);
    }).GetOneByParam(param, res => {
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(fetchCalenderEventsSuccess(res.data.responseListObject));
        }
      } else {
        dispatch(fetchCalenderEventsError(res.data.reasonText));
      }
    });
  };
}

export function fetchAlertNotificationEvents() {
  var url = Global_var.BASEURL + Global_var.URL_ALERTS_NOTIFICATION_EVENTS;

  return dispatch => {
    dispatch(fetchAlertNotificationEventsPending());
    return new RestDataSource(url, err => {
      fetchAlertNotificationEventsError(err);
    }).GetData(res => {
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchAlertNotificationEventsSuccess(res.data.responseListObject)
          );
        }
      } else {
        dispatch(fetchAlertNotificationEventsError(res.data.reasonText));
      }
    });
  };
}

export function fetchAlertNotificationEvents1(param) {
  var url = Global_var.BASEURL + Global_var.URL_LATEST_THREE_ALERTS;
  return dispatch => {
    dispatch(fetchAlertNotificationEventsPending());
    return new RestDataSource(url, err => {
      fetchAlertNotificationEventsError(err);
    }).GetOneByParam(param,res => {
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchAlertNotificationEventsSuccess(res.data.responseListObject)
          );
        }
      } else {
        dispatch(fetchAlertNotificationEventsError(res.data.reasonText));
      }
    });
  };
}

export function fetchNotificationEvents(Id) {
  var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS;
  return dispatch => {
    dispatch(fetchNotificationEventsPending());
    return new RestDataSource(url, err => {
      fetchNotificationEventsError(err);
    }).GetOneByParam(Id, res => {
      //console.log("service", res);
      if (res.data.status === null) {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(fetchNotificationEventsSuccess(res.data.responseListObject));
        }
      } else {
        dispatch(fetchNotificationEventsError(res.data.reasonText));
      }
    });
  };
}

export function fetchNotificationEvents1(Id) {
  var url = Global_var.BASEURL + Global_var.URL_LATEST_TREE_MSG;
  return dispatch => {
    dispatch(fetchNotificationEventsPending());
    return new RestDataSource(url, err => {
      fetchNotificationEventsError(err);
    }).GetOneByParam(Id, res => {
      //console.log("service", res);
      if (res.data.status === 'success') {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          console.log("mydata",res.data.responseListObject);
          dispatch(fetchNotificationEventsSuccess(res.data.responseListObject));
        }
      } else {
        dispatch(fetchNotificationEventsError(res.data.reasonText));
      }
    });
  };
}

export function fetchProductPreRequisiteByParentProductId(id) {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_PRE_REQUISITE;
  return dispatch => {
    dispatch(fetchProductPreRequisitePending());
    return new RestDataSource(url, err => {
      fetchProductPreRequisiteError(err);
    }).GetOneByParam(id, res => {
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchProductPreRequisiteSuccess(res.data.responseListObject)
          );
        }
      } else {
        dispatch(fetchProductPreRequisiteError(res.data.reasonText));
      }
    });
  };
}

export function fetchProductDetailsByPartyId(id) {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_BY_PARTYID;
  return dispatch => {
    dispatch(fetchProductDetailsByPartyIdPending());
    return new RestDataSource(url, err =>
      fetchProductDetailsByPartyIdError(err)
    ).GetOneByParam(id, res => {
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchProductDetailsByPartyIdSuccess(res.data.responseListObject)
          );
        }
      } else {
        fetchProductDetailsByPartyIdError(res.data.reasonText);
      }
    });
  };
}

export function fetchProductListData(id) {
  //  to show list Data of /product-list

  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_ALL;
  return dispatch => {
    dispatch(fetchProductDetailsAllPending());
    return new RestDataSource(url, err =>
      fetchProductDetailsAllError(err)
    ).GetOneByParam(id, res => {

      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(fetchProductDetailsAllSuccess(res.data.responseListObject));
        }
      } else {
        fetchProductDetailsAllError(res.data.reasonText);
      }
    });
  };
}



export function fetchProductTypeData(productType) {
  //This is for product type dropdown

  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_CD_TYPE_NAME;
  return dispatch => {
    dispatch(fetchProductProductTypePending());
    return new RestDataSource(url, err =>
      fetchProductProductTypeError(err)
    ).GetOneByParam(productType, res => {

      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(fetchProductProductTypeSuccess(res.data.responseListObject));
        }
      } else {
        fetchProductProductTypeError(res.data.reasonText);
      }
    });
  };
}

export function fetchPartnerData() {
  var url = Global_var.BASEURL + Global_var.URL_GETPARTNER;
  return dispatch => {
    dispatch(fetchPartnerPending());
    return new RestDataSource(url, err => fetchPartnerError(err)).GetData(
      res => {

        if (res.data.status === "success") {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            //console.log("RESPONSE: ", res.data);
            dispatch(fetchPartnerSuccess(res.data.responseListObject));
          }
        } else {
          fetchPartnerError(res.data.reasonText);
        }
      }
    );
  };
}

export function fetchStatusData() {

  let parameter = "codeTypeName=status";
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_CD_TYPE_NAME;
  return dispatch => {
    dispatch(fetchStatusPending());
    return new RestDataSource(url, err => fetchStatusError(err)).GetOneByParam(parameter,
      res => {

        if (res.data.status === "success") {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            //console.log("RESPONSE: ", res.data);
            dispatch(fetchStatusSuccess(res.data.responseListObject));
          }
        } else {
          fetchStatusError(res.data.reasonText);
        }
      }
    );
  };
}

export function fetchHasModuleData() {
  let parameter = "codeTypeName=active_status";
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_CD_TYPE_NAME;
  return dispatch => {
    dispatch(fetchHasModulePending());
    return new RestDataSource(url, err => fetchHasModuleError(err)).GetOneByParam(parameter,
      res => {

        if (res.data.status === "success") {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            //console.log("RESPONSE: ", res.data);
            dispatch(fetchHasModuleSuccess(res.data.responseListObject));
          }
        } else {
          fetchHasModuleError(res.data.reasonText);
        }
      }
    );
  };
}

export function fetchProductDetailsAllIsActive() {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_ALL;
  return dispatch => {
    dispatch(fetchProductDetailsAllPending());
    return new RestDataSource(url, err =>
      fetchProductDetailsAllError(err)
    ).GetOneByParam(null, res => {
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(fetchProductDetailsAllSuccess(res.data.responseListObject));
        }
      } else {
        fetchProductDetailsAllError(res.data.reasonText);
      }
    });
  };
}
export function fetchVocationalCoursesAllIsActive(data) {
  var url = Global_var.BASEURL + Global_var.URL_GET_VOCATIONAL_COURSES;
  return dispatch => {
    dispatch(fetchVocationalCoursesAllPending());
    return new RestDataSource(url, err =>
      fetchVocationalCoursesAllError(err)
    ).Store(data, res => {
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(fetchVocationalCoursesAllSuccess(res.data.responseListObject));
        }
      } else {
        fetchVocationalCoursesAllError(res.data.reasonText);
      }
    });
  };
}


export function getProgressOverview(studentPartyId) {
  return dispatch => {
    dispatch(fetchStudentProgressOverviewPending());

    var url = Global_var.BASEURL + Global_var.URL_GET_PROGRESS_OVERVIEW;
    return new RestDataSource(url, err =>
      fetchStudentProgressOverviewError(err)
    ).GetOneByParam(studentPartyId, res => {

      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchStudentProgressOverviewSuccess(res.data.responseObject)
          );
        }
      } else {
        fetchStudentProgressOverviewError(res.data.reasonText);
      }
    });
  };
}

export function getPaymentHistory(studentPartyId) {
  return dispatch => {
    dispatch(fetchStudentPaymentsHistoryPending());
    var url = Global_var.BASEURL + Global_var.URL_STUDENT_PAYMENT_HISTORY;
    return new RestDataSource(url, err =>
      fetchStudentPaymentsHistoryError(err)
    ).GetOneByParam(studentPartyId, res => {
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchStudentPaymentsHistorySuccess(res.data.responseListObject)
          );
        }
      } else {
        fetchStudentPaymentsHistoryError(res.data.reasonText);
      }
    });
  };
}

export function fetchModulesData() {
  var url = Global_var.BASEURL + Global_var.URL_GETMODULESBYID;
  var param = "productID=" + localStorage.getItem('selectedgridproduct');
  return dispatch => {
    dispatch(fetchModulesListPending());
    return new RestDataSource(url, err =>
      fetchModulesListError(err)
    ).GetOneByParam(param, res => {

      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          //console.log("SERVICE DATA", JSON.stringify(res));
          dispatch(fetchModulesListSuccess(res.data.responseListObject));
        }
      } else {
        fetchModulesListError(res.data.reasonText);
      }
    });
  };
}

export function fetchLearningPath() {
  // get - learning path list

  var url = Global_var.BASEURL + Global_var.URL_GETLEARNINGPATH;
  //var param = "parentProductID=1" ;
  // + localStorage.getItem('selectedgridproduct');
  return dispatch => {
    dispatch(fetchLearningPathPending());
    return new RestDataSource(url, err =>
      fetchLearningPathError(err)
    ).GetData(res => {

      //console.log("SERVICE DATA 1", JSON.stringify(res));
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          //console.log("SERVICE DATA", JSON.stringify(res));
          dispatch(fetchLearningPathSuccess(res.data.responseListObject));
        }
      } else {
        fetchLearningPathError(res.data.reasonText);
      }
    });
  };
}

export function fetchLearningPathAllProductData() {
  // This api is for PRODUCT NAME dropdown in add learning path

  var url = Global_var.BASEURL + Global_var.URL_GETLEARNINGAllPRODUCT;
  //var param = "parentProductID=1" ;
  // + localStorage.getItem('selectedgridproduct');
  return dispatch => {
    dispatch(fetchLearningPathProductPending());
    return new RestDataSource(url, err =>
      fetchLearningPathProductError(err)
    ).GetData(res => {

      //console.log("SERVICE DATA AllProductData 1", JSON.stringify(res));
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          //console.log("SERVICE DATA AllProductData", JSON.stringify(res));
          dispatch(fetchLearningPathProductSuccess(res.data.responseListObject));
        }
      } else {
        fetchLearningPathProductError(res.data.reasonText);
      }
    });
  };
}


export function getEnrollmentHistory(params) {
  return dispatch => {
    dispatch(fetchEnrollmentHistoryPending());
    var url = Global_var.BASEURL + Global_var.URL_STUDENT_ENROLLMENT_HISTORY;
    return new RestDataSource(url, err =>
      fetchEnrollmentHistoryError(err)
    ).GetOneByParam(params, res => {

      //console.log("res", res)
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchEnrollmentHistorySuccess(res.data.responseListObject)
          );
        }
      } else {
        fetchEnrollmentHistoryError(res.data.reasonText);
      }
    });
  };
}

export function getAssessmentReport(params) {
  return dispatch => {
    dispatch(fetchAssessmentReportPending());
    var url = Global_var.BASEURL + Global_var.URL_ASSESSMENT_REPORT;
    return new RestDataSource(url, err =>
      fetchAssessmentReportError(err)
    ).GetOneByParam(params, res => {

      //console.log("res", res)
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchAssessmentReportSuccess(res.data.responseListObject)
          );
        }
      } else {
        fetchAssessmentReportError(res.data.reasonText);
      }
    });
  };
}

export function getCfxAssessmentReport(params) {
  return dispatch => {
    dispatch(fetchCfxAssessmentReportPending());
    var url = Global_var.BASEURL + Global_var.URL_CFX_ASSESSMENT_REPORT;
    return new RestDataSource(url, err =>
      fetchCfxAssessmentReportError(err)
    ).GetOneByParam(params, res => {

      //console.log("res", res)
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchCfxAssessmentReportSuccess(res.data.responseListObject)
          );
        }
      } else {
        fetchAssessmentReportError(res.data.reasonText);
      }
    });
  };
}

export function getUnassignedUsersReport(params) {
  return dispatch => {
    dispatch(fetchUnassignedUsersReportPending());
    var url = Global_var.BASEURL + Global_var.URL_UNASSIGNED_USERS_REPORT;
    return new RestDataSource(url, err =>
      fetchUnassignedUsersReportError(err)
    ).GetOneByParam(params, res => {

      //console.log("res", res)
      if (res.data.status === "success") {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          dispatch(
            fetchUnassignedUsersReportSuccess(res.data.responseObject)
          );
        }
      } else {
        fetchAssessmentReportError(res.data.reasonText);
      }
    });
  };
}
