import { FETCH_ALERT_NOTIFICATION_EVENTS_PENDING, FETCH_ALERT_NOTIFICATION_EVENTS_SUCCESS, FETCH_ALERT_NOTIFICATION_EVENTS_ERROR } from '../action/alertNotificationAction';

const initialState = {
    pending: false,
    alertNotificationEvents: [],
    error: null
}

const alertNotificationEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALERT_NOTIFICATION_EVENTS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_ALERT_NOTIFICATION_EVENTS_SUCCESS:
            return {
                ...state,
                pending: false,
                alertNotificationEvents: action.payload
            }
        case FETCH_ALERT_NOTIFICATION_EVENTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default alertNotificationEventsReducer;
export const getAlertNotificationEvents = state => state.alertNotificationEvents;
export const getAlertNotificationEventsPending = state => state.pending;
export const getAlertNotificationEventsError = state => state.error;