import RestDataSource from './restdatasource';
import { Global_var } from '../global/global_var';
import { LoginService } from "./login.service";

 export const FetchNotificationService = {
  fetchNotification,
  fetchNotificationCount,
  fetchNotificationByDates,
  updateReadUnreadAlerts,
  fetchLatestThreeNotification,
  documentDownload
};     

  function fetchNotification(Id,fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS;

    return new RestDataSource(url, fnError).GetOneByParam(Id,(res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    });
  }

  function fetchNotificationCount(param, fn, fnError) {
     var url = Global_var.BASEURL + Global_var.URL_MSG_COUNT;
     return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    });
   }

   function fetchNotificationByDates(param, fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_MSG_LIST_BY_DATE;
    return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
     if (res != null) {
       if (res.headers["jtitoken"] != null) {
         localStorage.setItem("jti-token", res.headers.jtitoken);
       }
       fn(res.data);
     }
   });
  }

  function fetchLatestThreeNotification(param, fn, fnError) {
    var url = `${Global_var.BASEURL}${Global_var.URL_LATEST_TREE_MSG}`;
    return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    });
  }

  function updateReadUnreadAlerts(data, fn) {
    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATE_MSG_COUNT;
      return new RestDataSource(url).UpdateOneByParam(data, '',res => fn(res.data));
    });
  }


   function documentDownload(Id, fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_DOCUMENT_DOWNLOAD;
    return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
     if (res != null) {
       if (res.headers["jtitoken"] != null) {
         localStorage.setItem("jti-token", res.headers.jtitoken);
       }
       fn(res.data);
     }
   });
  }



