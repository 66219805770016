import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { LoginService } from "./login.service";

export const chartService = {
  fetchEnrollmentChartData,
  fetchReportChartData,
  fetchCourseChartData,
};

function fetchEnrollmentChartData(data, fn, fnError) {
  
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_REPORT_ENROLLMENT

      //var mobilevalidat = JSON.stringify(ProductByName);
      //console.log("Data and URL :" + url + data)
      return new RestDataSource(url, fnError).GetOneByParam(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function fetchReportChartData(data, fn, fnError) {
  
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_REPORT_SESSION

      //var mobilevalidat = JSON.stringify(ProductByName);
      //console.log("Data and URL :" + url + data)
      return new RestDataSource(url, fnError).GetOneByParam(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function fetchCourseChartData(data, fn, fnError) {
  
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_REPORT_COURSE

      //var mobilevalidat = JSON.stringify(ProductByName);
      //console.log("Data and URL :" + url + data)
      return new RestDataSource(url, fnError).GetOneByParam(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
} 