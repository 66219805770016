import {
  fetchUserActivityPending,
  fetchUserActivitySuccess,
  fetchUserActivityError
} from "../action/fetchUserActivity";
import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";

export function UserManagementservice(parameters) {
  let url = Global_var.BASEURL + Global_var.URL_USER_MANAGEMENT;

  return dispatch => {
    dispatch(fetchUserActivityPending());
    return new RestDataSource(url, err =>
      dispatch(fetchUserActivityError(err))
    ).GetOneByParam(parameters, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchUserActivitySuccess(res.data.responseListObject));
      }
    });
  };
}


