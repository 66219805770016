// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as moment from "moment";
import * as Yup from "yup";
// import RestDataSource from "../../services/restdatasource";
// import { editRoles } from "../../services/fetchRoles";
import { updateGdpiScore } from "../../services/fetchStudents";
import {
  success,
  successNotification,
  // error,
  // errorNotification,
  warningNotification,
  warning
} from "../notification/notifications";
// import $ from "jquery";
// import { min } from "date-fns";

class GdpiScoreUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: "",
      roleName: "",
      description: "",
      isActive: "",
      activateDate: "01/06/2019",
      expiryDate: "31/12/2019",
      suspendStartDate: "31/12/2019",
      suspendEndDate: "31/12/2019",

    };
  }

  componentDidMount() {
    /* $("#gdpistatus").on("change", function(e){
     //alert(e.target.value);
       //$("#gdstatus").val(e.target.value)
      // $("#pistatus").val(e.target.value)
       if(e.target.value == 10306)
       {
       //alert(e.target.value);
         $("#gdstatus").val(10306) // option[value="+e.target.value+"]").prop('selected', 'selected');

         $("#pistatus").val(10306) // option[value="+e.target.value+"]").prop('selected', 'selected');

       }
     }) */


  }

  handleCancel = () => {
    this.props.history.goBack('-1');
    //window.$("#gdpi-score").modal('hide');
    // window.location.reload();

  };
  render() {
    if (this.props.student.gDDate) {
      let gdDate = this.props.student.gDDate.split("/");
      let gdDate1 = gdDate[2] + "-" + gdDate[1] + "-" + gdDate[0];
      console.log("gdDate1", gdDate1)
      // this.setState({ gdDate:  gdDate1  });
    }


    if (this.props.student.pIDate) {
      let pIDate = this.props.student.pIDate.split("/");
      this.pIDate = pIDate[2] + "-" + pIDate[1] + "-" + pIDate[0];
      console.log("pIdate", pIDate);
    }

    if (this.props.student.requestRaisedOn) {
      let requestRaisedOn = this.props.student.requestRaisedOn.split("/");
      this.requestRaisedOn =
        requestRaisedOn[2] +
        "-" +
        requestRaisedOn[1] +
        "-" +
        requestRaisedOn[0];
    }
    console.log("GD DATE : ", this.props.student.gDDate);
    console.log("PI DATE : ", this.props.student.pIDate);
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            partyId: this.props.student.partyId || ' ',
            studentName: this.props.student.studentName || ' ',
            gDVenue: this.props.student.gDVenue || ' ',
            gDDate: this.props.student.gDDate || '', //(this.props.student.gDDate !== null || this.props.student.gDDate !== 'Invalid date') ? this.props.student.gDDate.split(' ')[0] || ' ' : moment(new Date()).format('DD/MM/YYYY'),
            pIVenue: this.props.student.pIVenue || ' ',
            pIDate: this.props.student.pIDate || '',// (this.props.student.pIDate !== null || this.props.student.pIDate !== 'Invalid date') ? this.props.student.pIDate.split(' ')[0] || ' ' : moment(new Date()).format('DD/MM/YYYY'),
            gDScore: this.props.student.gDScore || 0,
            pIScore: this.props.student.pIScore || 0,
            pIStatus: this.props.student.piStatus || ' ',
            gDStatus: this.props.student.gdStatus || ' ',
            videoResumeScore: this.props.student.videoResumeScore || 0,
            gDPIScore: this.props.student.gDPIScore || 0,
            gDPIStatus: this.props.student.gDPIStatus || ' ',
            gDAssessedBy: this.props.student.gDAssessedBy || ' ',
            pIConductedBy: this.props.student.pIConductedBy || ' ',
            videoResumeAssessedBy: this.props.student.videoResumeAssessedBy || ' ',
            requestRaisedOn: this.props.student.requestRaisedOn || ' ',
            gDPIInitiatedBy: this.props.student.gdpiinitiatedBy || ' ',
            gDPIInitiatedDate: this.props.student.GDPIInitiatedDate || ' ',
            createdBy: this.props.student.createdBy || ' ',
            createdDate: this.props.student.createdDate || ' ',
            lastModifiedBy: this.props.student.lastModifiedBy || ' ',
            lastModifiedDate: this.props.student.lastModifiedDate || ' ',
            isActive: this.props.student.isActive
          }}
          validationSchema={Yup.object().shape({

            gDScore: Yup.number().when('gDPIStatus', {
              is: "10306",
              then: Yup.number().typeError('GD Score should be a number').min(0, "You must specify a GD Score")
                .required('You must specify a GD Score'),
              otherwise: Yup.number().notRequired(),
            }),

            //  .when('gDPIStatus', {
            //     is: "10303",
            //     then: Yup.number().typeError('GD SCORE should be a number').notRequired(),
            //      otherwise: Yup.number().notRequired(),
            //   }),
            pIScore: Yup.number().when('gDPIStatus', {
              is: "10306",
              then: Yup.number().typeError('PI SCORE should be a number').min(0, "You must specify a PI Score")
                .required('You must specify a PI SCORE'),
              otherwise: Yup.number().notRequired(),
            }),
            videoResumeScore: Yup.number().when('gDPIStatus', {
              is: "10306",
              then: Yup.number().typeError('VR Score should be a number').min(0, "You must specify a VR Score")
                .required('You must specify a VR SCORE'),
              otherwise: Yup.number().notRequired(),
            }),
            gDPIScore: Yup.number().when('gDPIStatus', {
              is: "10306",
              then: Yup.number().typeError('GDPI Score should be a number').min(0, "You must specify a GDPI Score")
                .required('You must specify a GDPI SCORE'),
              otherwise: Yup.number().notRequired(),
            }),

            // gDPIStatus: Yup.number().when('gDScore', {
            //   is: value => value =" " || value < 0,
            //   then: Yup.number().required('gDPIStatus is required.')
            // })

          })}

          onReset={(values, { resetForm }) => {
            resetForm();
          }}
          onSubmit={values => {
            let Today = new Date()
            let data = {
              gDPIID: this.props.student.gdpiId,
              gDVenue: values.gDVenue,
              gDDate: (values.gDDate !== 'Invalid') ? moment(values.gDDate).format('DD/MM/YYYY HH:mm:ss') : moment(Today).format('DD/MM/YYYY HH:mm:ss'),
              // gDDate: values.gDDate
              // ? moment(values.gDDate).format("DD/MM/YYYY HH:mm:ss")
              // : " ",
              pIVenue: values.pIVenue,
              pIDate: (values.pIDate !== 'Invalid') ? moment(values.pIDate).format('DD/MM/YYYY HH:mm:ss') : moment(Today).format('DD/MM/YYYY HH:mm:ss'),
              gDStatus: values.gDScore !== ' ' ? 10306 : values.gDStatus,
              pIStatus: values.pIScore !== ' ' ? 10306 : values.pIStatus,
              gDScore: values.gDScore,
              pIScore: values.pIScore,
              videoResumeScore: values.videoResumeScore,
              gDPIScore: values.gDPIScore,
              gDPIStatus: values.gDPIStatus,
              isGDPICompleted: (values.gDPIStatus === "10306" || values.gDPIStatus === 10306) ? 1 : 0,
              gDAssessedBy: values.gDAssessedBy,
              pIConductedBy: values.pIConductedBy,
              videoResumeAssessedBy: values.videoResumeAssessedBy,
              lastModifiedDate: moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
            };
            updateGdpiScore(data, cb => {
              if (cb.status === "success") {
                success("GDPI score has been updated on FinX successfully", successNotification);
                window.$("#gdpi-score").modal('hide');
                this.props.fetchStudents();
              } else {
                warning(cb.reasonText, { ...warningNotification, container: "top-center" });
              }
            });
          }}
          render={({ handleSubmit, errors, touched, handleChange, ...formikProps }) => (
            <React.Fragment>
              <Form
                onSubmit={handleSubmit}
                className="modal fade"
                id="gdpi-score"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <section class="updateGDPI">
                      <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" onClick={this.handleCancel}>&times;</span>
                        </button>
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                  <div class="address-border">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                          <label>USER ID</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="partyId"
                                            id="partyId"
                                            placeholder="User Id"
                                            disabled
                                          />
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                          <label>Student Name</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="studentName"
                                            id="studentName"
                                            placeholder="Student Name"
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                  <div class="address-border">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <h5 class="address-heading">Group Discussion</h5>
                                        </div>
                                        {/* sectionstart of GD */}
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>GD Venue</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="gDVenue"
                                            placeholder="GD Venue"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>GD Date</label>
                                          <Field
                                            type="date"
                                            class="form-control"
                                            name="gDDate"
                                            id="gDDate"
                                            placeholder="GD Date"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>GD Score</label>
                                          <Field
                                            type="text"
                                            name="gDScore"
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.gDScore &&
                                                touched.gDScore
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="GD Score"
                                          />
                                          <ErrorMessage
                                            name="gDScore"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>GD Status</label>
                                          <Field
                                            as="select"
                                            name="gDStatus"
                                            id="gdstatus"
                                            className="form-control"
                                          >
                                            {this.props.gdpiStatus.map(status => (
                                              status.codeValueId !== 10302 ?
                                                <option
                                                  key={status.codeValueId}
                                                  value={status.codeValueId}
                                                  style={status.codeValueID === 10302 ? { 'display': "none" } : { 'display': "block" }}
                                                >
                                                  {status.codeValue}
                                                </option>
                                                : ''
                                            ))}
                                          </Field>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>GD Assessed By</label>
                                          <Field
                                            as="select"
                                            name="gDAssessedBy"
                                            className={"form-control"}
                                            placeholder="GD Assessed By"
                                          >
                                            <option value="1">Admin</option>
                                          </Field>
                                        </div>
                                        {/* GD section end */}

                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                  <div class="address-border">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <h5 class="address-heading">Personal Interview</h5>
                                        </div>
                                        {/* PI section start */}
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>PI Venue </label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="pIVenue"
                                            placeholder=""
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>PI Date </label>
                                          <Field
                                            type="date"
                                            class="form-control"
                                            name="pIDate"
                                            placeholder="PI Date"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>PI Score</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="pIScore"
                                            placeholder="PI Score"
                                          />
                                          <ErrorMessage
                                            name="pIScore"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>PI Status</label>
                                          <Field
                                            as="select"
                                            name="pIStatus"
                                            id="pistatus"
                                            className="form-control"
                                          >

                                            {this.props.gdpiStatus.map(status => (
                                              status.codeValueId !== 10302 ?
                                                <option
                                                  key={status.codeValueId}
                                                  value={status.codeValueId}
                                                >
                                                  {status.codeValue}
                                                </option>
                                                : ''
                                            ))}
                                          </Field>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>PI Conducted By</label>
                                          <Field
                                            as="select"
                                            name="pIConductedBy"
                                            className={"form-control"}
                                          >
                                            <option value="1">Admin</option>
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* PI section end */}
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                  <div class="address-border">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <h5 class="address-heading">Video Resume</h5>
                                        </div>
                                        {/* VR section start */}
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>VR Score</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="videoResumeScore"
                                            placeholder="Video Resume Score"
                                          />
                                          <ErrorMessage
                                            name="videoResumeScore"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>Video Resume Assessed By</label>
                                          <Field
                                            as="select"
                                            name="videoResumeAssessedBy"
                                            className={"form-control"}
                                          >
                                            <option value="1">Admin</option>
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* VR section end */}
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                  <div class="address-border">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <h5 class="address-heading">Total Score</h5>
                                        </div>
                                        {/* GDPI section start */}
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>GDPI Score</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="gDPIScore"
                                            placeholder="GDPI Score"
                                          />
                                          <ErrorMessage
                                            name="gDPIScore"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>GDPI Status</label>
                                          <Field
                                            as="select"
                                            name="gDPIStatus"
                                            id="gdpistatus"
                                            className="form-control"
                                          >
                                            {this.props.gdpiStatus.map(status => (
                                              status.codeValueId !== 10302 ?
                                                <option
                                                  key={status.codeValueId}
                                                  value={status.codeValueId}
                                                >
                                                  {status.codeValue}
                                                </option>
                                                : ''
                                            ))}
                                          </Field>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* GDPI section end */}
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                  <div class="address-border">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>Request Raised Date</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="requestRaisedOn"
                                            placeholder="Request Raised On"
                                            disabled
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>Initiated By</label>
                                          <Field
                                            type="text"
                                            name="gDPIInitiatedBy"
                                            class="form-control"
                                            placeholder="GDPI Initiated By"
                                            disabled
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>Initiated Date</label>
                                          <Field
                                            type="text"
                                            name="gDPIInitiatedDate"
                                            class="form-control"
                                            placeholder="GDPI Initiated Date"
                                            disabled
                                          />
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>Last Modified By</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="lastModifiedBy"
                                            placeholder="Last Modified By"
                                            disabled
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>Last Modified Date</label>
                                          <Field
                                            type="text"
                                            class="form-control"
                                            name="lastModifiedDate"
                                            placeholder="Last Modified Date"
                                            disabled
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                          <label>Active</label>
                                          <Field
                                            as="select"
                                            name="isActive"
                                            className={"form-control"}
                                          >
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                  <button
                                    type="submit"
                                    class="btn-5 mt-30 pull-right"
                                    onClick={async () => {
                                      const curErrors = await formikProps.validateForm();
                                      const curErrorsKeys = Object.keys(
                                        curErrors
                                      );

                                      if (curErrorsKeys.length) {

                                        const el = document.getElementById(
                                          curErrorsKeys[0]
                                        );

                                        if (el) el.focus();
                                      }
                                    }}
                                  >
                                    Submit
                                  </button>
                                  <button
                                    id="close"
                                    type="button"
                                    class="btn-5 mr-20 mt-30 pull-right"
                                    data-dismiss="modal"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </section>
                  </div>
                </div>
              </Form>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default GdpiScoreUpdate;
