import React, { Component } from "react";
// import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
// import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
// import {
//   getproductDetailsAll,
//   getproductDetailsAllPending,

// } from "../../reducer/productDetailsAllReducer";
// import { getProductType } from "../../reducer/productProductTypeReducer";
// import { getPartner } from "../../reducer/productPartnerReducer";
import { getLearningPath } from "../../reducer/learningPathReducer";
import { getlearningPathProductData } from "../../reducer/learningPathProductDataReducer";
// import { getStatus } from "../../reducer/productStatusReducer";
// import { getHasModule } from "../../reducer/productHasModuleReducer";
import LearningPathLandingPage from "../../components/learningPath/learningPath";
import { bindActionCreators } from "redux";
// import ProductListLandingPage from "../../components/product/productList";
//import {fetchProductDetailsAll} from "../../services/fetchStudentDashboard.service";
// import { fetchProductTypeData, fetchPartnerData, fetchStatusData, fetchHasModuleData } from "../../services/fetchStudentDashboard.service";

import { fetchLearningPath, fetchLearningPathAllProductData } from "../../services/fetchStudentDashboard.service";

import { LoginService } from "../../services/login.service";
// import { getPersonnelAddressData } from "../../reducer/userProfileReducer";
// import { RegisterUserService } from "../../services/registration.service";
// import { fetchRoles } from "../../services/fetchRoles";
// import { getRoles } from "../../reducer/roleReducer";

// import { ProductService } from "../../services/product.service";

import AdminHeader from "../../components/dashboardheader/adminheader";
// import $ from "jquery";
// import { from } from "linq";

import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchStudentDashboard.service";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";


const mapStateToProps = state => ({
  //     productDetailsAll: getproductDetailsAll(state.productDetailsAllReducer),
  //     productDetailsAllPending: getproductDetailsAllPending(state.productDetailsAllReducer),
  //     addressData: getPersonnelAddressData(state.userProfileReducers),

  //     productType: getProductType(state.productProductTypeReducer),
  //     partner: getPartner(state.productPartnerReducer),
  //    // status: getStatus(state.productStatusReducer),
  //     //hasModule: getHasModule(state.productHasModuleReducer),
  //      roles: getRoles(state.roleReducer),

  learningPath: getLearningPath(state.learningPathReducer),
  learningPathProductData: getlearningPathProductData(state.learningPathProductDataReducer),
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //   //fetchProductList: fetchProductList,

      //   fetchPartnerData: fetchPartnerData,
      //   fetchProductTypeData:fetchProductTypeData,
      //   // fetchStatusData: fetchStatusData,
      //   // fetchHasModuleData: fetchHasModuleData,
      //   fetchRoles: fetchRoles

      fetchLearningPath: fetchLearningPath,
      fetchLearningPathAllProductData: fetchLearningPathAllProductData,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const LearningPath = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        // productType: [],
        //  partner: [],
        //  status: [],
        //  hasModule: [],
        learningPath: [],
        learningPathProductData: [],
      };
    }

    componentDidUpdate() {

    }

    componentDidMount() {
      let partyID = localStorage.getItem("userid-Token");
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
        if (localStorage.getItem('reload') === 'true') {
          localStorage.setItem('reload', 'false');
          window.location.reload();
        }
      });

      const {
        // fetchProductDetailsAll,
        // fetchRoles,
        // fetchProductTypeData,
        // fetchPartnerData
        fetchLearningPath,
        fetchLearningPathAllProductData,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,

      } = this.props;

      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          // fetchAlertNotificationEvents();
          fetchNotificationEvents("partyId=" + partyID);
          //   fetchProductTypeData( res=>{
          //       //console.log("PRODUCT TYPE DATA" , res);
          //   });

        }
      });
      LoginService.TokenUser(res => {


        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);


          // fetchRoles();
        }
      });

      //     LoginService.TokenUser(res => {
      //
      //     if (res.status === "success") {
      //       localStorage.setItem("jwt-token", res.responseObject);

      //       fetchPartnerData(res => {
      //           //console.log("PARTNER DATA", res);
      //       });

      //     }
      //   });

      LoginService.TokenUser(res => {
        //
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);

          fetchLearningPath(res => {
            //console.log("Learning Path ", res);
          });

        }
      });
      LoginService.TokenUser(res => {
        //
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);

          fetchLearningPathAllProductData(res => {
            //console.log("Learning Path ProductData ", res);
          });

        }
      });


      //   LoginService.TokenUser(res => {
      //
      //   if (res.status === "success") {
      //     localStorage.setItem("jwt-token", res.responseObject);

      //     fetchLearningPathAllProductData(res => {
      //         //console.log("AllProductData ", res);
      //     });

      //   }
      // });


    }

    render() {
      //
      const {
        // productDetailsAll,
        // productDetailsAllPending,
        // fetchUserAddressData,
        // productType,
        // partner,
        // status,
        // hasModule,
        fetchLearningPath,
        learningPath,
        learningPathProductData,
        // alertNotificationEvents,
        pendingAlertNotificationEvents,
        // errorAlertNotigicationEvents,
        // notificationEvents,
        pendingNotificationEvents,
        // errorNotificationEvents,
        // roles

      } = this.props;

      //   //console.log("productType",productType);
      //   //console.log("partner" , partner);
      // //console.log("PRODUCT DETAILS ALL : ", productDetailsAll);
      //console.log("learningPath : " , learningPath);
      //console.log("learningPathProductData : " , learningPathProductData);
      //console.log("alertNotificationEventsinn", this.props.pendingAlertNotificationEvents)
      if (!pendingAlertNotificationEvents
        && !pendingNotificationEvents) {
        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <LearningPathLandingPage
              learningPath={learningPath}
              learningPathProductData={learningPathProductData}
              fetchLearningPath={fetchLearningPath}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <LearningPathLandingPage
              //   productDetailsAll={productDetailsAll}
              //   productType={productType}
              //   partner={partner}
              // roles={roles}
              learningPath={learningPath}
              learningPathProductData={learningPathProductData}

              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
      // return (
      //   <React.Fragment>
      //     <AdminHeader
      //      //alertNotificationEvents={alertNotificationEvents}
      //      //notificationEvents={notificationEvents}
      //       {...this.props}
      //     />
      //     <LearningPathLandingPage
      //     //   productDetailsAll={productDetailsAll}
      //     //   productType={productType}
      //     //   partner={partner}
      //       // roles={roles}
      //       learningPath={learningPath}
      //       learningPathProductData={learningPathProductData}

      //       {...this.props}
      //     />
      //     <Finxfooter />
      //   </React.Fragment>
      // );
    }

  }
);

export default LearningPath;
