export const FETCH_STUDENT_PAYMENT_HISTORY = "FETCH_STUDENT_PAYMENT_HISTORY";
export const FETCH_STUDENT_PAYMENT_HISTORY_PENDING =
  "FETCH_STUDENT_PAYMENT_HISTORY_PENDING";
export const FETCH_STUDENT_PAYMENT_HISTORY_ERROR =
  "FETCH_STUDENT_PAYMENT_HISTORY_ERROR";

export function fetchStudentPaymentsHistoryPending() {
  return {
    type: FETCH_STUDENT_PAYMENT_HISTORY_PENDING
  };
}

export function fetchStudentPaymentsHistorySuccess(studentPaymentsHistoryData) {
  return {
    type: FETCH_STUDENT_PAYMENT_HISTORY,
    payload: studentPaymentsHistoryData
  };
}

export function fetchStudentPaymentsHistoryError(error) {
  return {
    type: FETCH_STUDENT_PAYMENT_HISTORY_ERROR,
    error: error
  };
}
