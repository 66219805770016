import { FETCH_CALENDER_EVENTS_PENDING, FETCH_CALENDER_EVENTS_SUCCESS, FETCH_CALENDER_EVENTS_ERROR } from '../action/calenderEventsAction';

const initialState = {
    pending: false,
    calenderEvents: [],
    error: null
}

const calenderEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CALENDER_EVENTS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_CALENDER_EVENTS_SUCCESS:
            return {
                ...state,
                pending: false,
                calenderEvents: action.payload
            }
        case FETCH_CALENDER_EVENTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default calenderEventsReducer;
export const getCalenderEvents = state => state.calenderEvents;
export const getCalenderEventsPending = state => state.pending;
export const getCalenderEventsError = state => state.error;