export const USER_ACTIVITY_HISTORY = "USER_ACTIVITY_HISTORY";
export const FETCH_USER_ACTIVITY_PENDING =
  "FETCH_USER_ACTIVITY_PENDING";
export const FETCH_USER_ACTIVITY_ERROR =
  "FETCH_USER_ACTIVITY_ERROR";

export function fetchUserActivityPending() {
 
  return {
    type: FETCH_USER_ACTIVITY_PENDING
  };
}

export function fetchUserActivitySuccess(UserActivityData) {
  
  return {
    type: USER_ACTIVITY_HISTORY,
    payload: UserActivityData
  };
}

export function fetchUserActivityError(error) {
  return {
    type: FETCH_USER_ACTIVITY_ERROR,
    error: error
  };
}
