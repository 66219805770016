import {
  FETCH_USER_PROFILE_PERSONNEL_DATA,
  FETCH_USER_PROFILE_ADDRESS_DATA,
  FETCH_USER_PROFILE_PREFERENCE_DATA,
  FETCH_USER_PROFILE_EMPLOYMENT_DATA,
  FETCH_USER_LEAD_REG_DATA,
} from "../action/userProfile";

const initialState = {
  pending: false,
  personnelData: [],
  addressData: [],
  preferenceData: {},
  employmentData: {},
  leadRegData:{},
  error: null
};

const userProfileReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_LEAD_REG_DATA:
      return {
        ...state,
        pending: false,
        leadRegData: action.payload
      };
    case FETCH_USER_PROFILE_PERSONNEL_DATA:
      return {
        ...state,
        pending: false,
        personnelData: action.payload
      };
    case FETCH_USER_PROFILE_ADDRESS_DATA:
      return {
        ...state,
        pending: false,
        addressData: action.payload
      };
    case FETCH_USER_PROFILE_PREFERENCE_DATA:
      return {
        ...state,
        pending: false,
        preferenceData: action.payload
      };
    case FETCH_USER_PROFILE_EMPLOYMENT_DATA:
      return {
        ...state,
        pending: false,
        employmentData: action.payload
      };
    default:
      return state;
  }
};

export default userProfileReducers;

// ! Selector
export const getPersonnelData = state => state.personnelData;
export const getPersonnelAddressData = state => state.addressData;
export const getPreferenceData = state => state.preferenceData;
export const getEmploymentData = state => state.employmentData;
export const getLeadRegData = state => state.leadRegData;
