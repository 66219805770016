export const FETCH_UNASSIGNED_USERS_REPORT = "FETCH_UNASSIGNED_USERS_REPORT";
export const FETCH_UNASSIGNED_USERS_REPORT_PENDING = "FETCH_UNASSIGNED_USERS_REPORT_PENDING";
export const FETCH_UNASSIGNED_USERS_REPORT_ERROR = "FETCH_UNASSIGNED_USERS_REPORT_ERROR";

export function fetchUnassignedUsersReportPending() {
  return {
    type: FETCH_UNASSIGNED_USERS_REPORT_PENDING
  };
}

export function fetchUnassignedUsersReportSuccess(assessmentReport) {
  return {
    type: FETCH_UNASSIGNED_USERS_REPORT,
    payload: assessmentReport
  };
}

export function fetchUnassignedUsersReportError(error) {
  return {
    type: FETCH_UNASSIGNED_USERS_REPORT_ERROR,
    error: error
  };
}
