import { FETCH_ROLES_PENDING, FETCH_ROLES_SUCCESS, FETCH_ROLES_ERROR } from '../action/roleAction';

const initialState = {
    pending: false,
    roles: [],
    error: null
}

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ROLES_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_ROLES_SUCCESS:
            return {
                ...state,
                pending: false,
                roles: action.payload
            }
        case FETCH_ROLES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default rolesReducer;
export const getRoles = state => state.roles;
export const getRolesPending = state => state.pending;
export const getRolesError = state => state.error;