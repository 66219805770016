import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Finxfooter from '../../../containers/finxfooter/finxfooter';
import { RegionManagementService } from '../../../services/regionManagement.service';
// import {
//     error,
//     success,
//     warning,
//     warningNotification,
//     errorNotification,
//     successNotification
// } from "../../notification/notifications";

class EmailVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };
    }

    handleBackClick = () => {
        window.location.replace('/');
    }

    componentDidMount() {
        const currentURL = window.location.href // returns the absolute URL of a page
        let emailidsplit = currentURL.split('=')

        let emailId = emailidsplit[1]

        RegionManagementService.validateEmail("emailID=" + emailId, (response) => {
            if (response.status === "success") {
                this.setState({
                    message: response.reasonText,
                });
                // success("Your email is successfully validated.", successNotification);
            } else {
                this.setState({
                    message: response.reasonText,
                });
                //  error('Your email validation has failed.', errorNotification);
            }
        })
    }
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <div className="container mb-50p">
                    <div className="">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center" >
                            <div className="modal-content">
                                <section className="registration resetpass-modal">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                <div className="modal-head">
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12" >
                                                <div className="col-md-7 col-md-offset-1 col-sm-6 col-xs-12" >
                                                    <div className="modal-head text-center">
                                                        <h3>Email Validation </h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 col-xs-12 mt-50">
                                                            <p className="modal-head text-center"><b> {this.state.message} </b></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-10 col-sm-10 col-xs-12 mt-50">
                                                            <button type="submit" onClick={this.handleBackClick} className="btn-4 pull-right" name="Submit">Ok</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Finxfooter /> */}
            </React.Fragment>
        );
    }

}

export default EmailVerification;