import React from "react";
import { useState, useRef } from "react";

import logoImg from "../../../assets/images/logoimg.png";
import hamImg from "../../../assets/images/ham-menu.png";
import "./Header.css";

function Header() {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <header className="col-100 floatLft flexDisplay justifyCenter morhead">
      <div className="wrapper">
        <div className="ap-headerWrp col-100 floatLft flexDisplay justifySpace alignCenter">
          <div className="ap-logo1 floatLft">
            <a href="#">
              <img src={logoImg} alt="Site Logo" />
            </a>
          </div>
          <nav
            className={
              isActive ? "ap-navMenu1 floatRgt open" : "ap-navMenu1 floatRgt"
            }
          >
            <ul className="ap-navigation1 col-100 floatLft">
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                   href="#aboutMorningStar"
                  className="ap-primary-black ap-gotham-medium"
                  // target="_blank"
                >
                  About Morningstar
                </a>
              </li>
              {/* <li className='inlineBlk'><a className='ap-primary-black ap-gotham-medium' >About Morningstar</a></li>  */}
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                  href="#ap-benefitsDiv"
                  className="ap-primary-black ap-gotham-medium"
                >
                  Benefits
                </a>
              </li>
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                  href="#ap-programs"
                  className="ap-primary-black ap-gotham-medium"
                >
                  Program
                </a>
              </li>
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                  href="#ap-getStarted"
                  className="ap-primary-black ap-gotham-medium"
                >
                  Process
                </a>
              </li>
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                  href="mailto:support@finxpert.org"
                  className="ap-primary-black ap-gotham-medium"
                >
                  Contact
                </a>
              </li>
              <li className="inlineBlk ap-loginBtnWrp1">
                <a
                //   href="https://bit.ly/3yezxWu"
                  href="https://uatcore.finxpert.org/register"
                  className="ap-loginClick1 ap-bg-blue ap-gotham-bold"
                  target="_blank"
                >
                  Register
                </a>
              </li>
            </ul>
          </nav>
          <div className="ap-mobBtnWrp1 floatRgt">
            <a 
            // href="https://bit.ly/3yezxWu"
            href="https://uatcore.finxpert.org/register"
            className="ap-mobLogin-btn1">
              Register
            </a>
            <button className="ap-hamBurgerClick1" onClick={toggleClass}>
              <img src={hamImg} alt="Hamburger Menu" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
