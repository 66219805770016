export const FETCH_HASMODULE_PENDING = 'FETCH_HASMODULE_PENDING';
export const FETCH_HASMODULE_SUCCESS = 'FETCH_HASMODULE_SUCCESS';
export const FETCH_HASMODULE_ERROR = 'FETCH_HASMODULE_ERROR';

export function fetchHasModulePending() {
    return {
        type: FETCH_HASMODULE_PENDING,
    }
}

export function fetchHasModuleSuccess(hasModule) {

    return {
        type: FETCH_HASMODULE_SUCCESS,
        payload: hasModule
    }
}

export function fetchHasModuleError(error) {
    return {
        type: FETCH_HASMODULE_ERROR,
        error: error
    }
}