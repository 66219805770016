export const FETCH_PRODUCT_DETAILS_BY_PARTY_PENDING = 'FETCH_PRODUCT_DETAILS_BY_PARTY_PENDING';
export const FETCH_PRODUCT_DETAILS_BY_PARTY_SUCCESS = 'FETCH_PRODUCT_DETAILS_BY_PARTY_SUCCESS';
export const FETCH_PRODUCT_DETAILS_BY_PARTY_ERROR = 'FETCH_PRODUCT_DETAILS_BY_PARTY_ERROR';

export function fetchProductDetailsByPartyIdPending() {
    return {
        type: FETCH_PRODUCT_DETAILS_BY_PARTY_PENDING,
    }
}

export function fetchProductDetailsByPartyIdSuccess(Product_Details_BY_Party) {

    return {
        type: FETCH_PRODUCT_DETAILS_BY_PARTY_SUCCESS,
        payload: Product_Details_BY_Party
    }
}

export function fetchProductDetailsByPartyIdError(error) {
    return {
        type: FETCH_PRODUCT_DETAILS_BY_PARTY_ERROR,
        error: error
    }
}