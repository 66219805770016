import {
  FETCH_ASSESSMENT_REPORT_PENDING,
  FETCH_ASSESSMENT_REPORT,
  FETCH_ASSESSMENT_REPORT_ERROR
} from "../action/assessmentAction";

const initialState = {
  pending: false,
  assessmentReport: [],
  error: null
};

const assessmentReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASSESSMENT_REPORT_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_ASSESSMENT_REPORT:
      return {
        ...state,
        pending: false,
        err: false,
        assessmentReport: action.payload
      };
    case FETCH_ASSESSMENT_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default assessmentReportReducer;

export const assessmentReport = state => state.assessmentReport;
export const assessmentReportPending = state => state.pending;
export const assessmentReportError = state => state.error;
