export const FETCH_USER_PROFILE_PERSONNEL_DATA = "FETCH_USER_PROFILE_PERSONNEL_DATA";
export const FETCH_USER_PROFILE_ADDRESS_DATA = "FETCH_USER_PROFILE_ADDRESS_DATA";
export const FETCH_USER_PROFILE_PREFERENCE_DATA = "FETCH_USER_PROFILE_PREFERENCE_DATA";
export const FETCH_USER_PROFILE_EMPLOYMENT_DATA = "FETCH_USER_PROFILE_EMPLOYMENT_DATA";
export const FETCH_USER_LEAD_REG_DATA = "FETCH_USER_LEAD_REG_DATA";

export function fetchUserLeadRegData(leadRegData) {
  return {
    type: FETCH_USER_LEAD_REG_DATA,
    payload: leadRegData
  };
}

export function fetchUserProfileData(personnelData) {
  return {
    type: FETCH_USER_PROFILE_PERSONNEL_DATA,
    payload: personnelData
  };
}

export function fetchUserAddressData(addressData) {
  return {
    type: FETCH_USER_PROFILE_ADDRESS_DATA,
    payload: addressData
  };
}

export function fetchUserPreferenceData(preferenceData) {
  return {
    type: FETCH_USER_PROFILE_PREFERENCE_DATA,
    payload: preferenceData
  };
}

export function fetchUserEmploymentData(employmentData) {
  return {
    type: FETCH_USER_PROFILE_EMPLOYMENT_DATA,
    payload: employmentData
  };
}
