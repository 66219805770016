import React, { Component } from "react";
import OpsHeader from "../../components/dashboardheader/opsheader";
import Finxfooter from "../finxfooter/finxfooter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAdminUserByPartyAndRole } from "../../services/admin.service";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import Ops from "../../components/ops-dashboard/ops";
import { getAdminUser, getAdminUserPending, getAdminUserError } from "../../reducer/adminUserReducer";
import {
  getStudentsProgressOverview,
  getStudentsProgressOverviewPending
} from "../../reducer/studentProgressOverviewReducer";
import { GeneralEnquiryService } from "../../services/generalenquiryService";
import { getStudentEnquiry } from "../../reducer/studentEnquiryReducer";
import {
  getProgressOverview, fetchCalenderEvents, fetchAlertNotificationEvents, fetchNotificationEvents
} from "../../services/fetchStudentDashboard.service";
import { getCalenderEvents, getCalenderEventsPending } from "../../reducer/calenderEventsReducer";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAdminUser } from "../../services/admin.service";


const mapStateToProps = state => ({
  adminUser: getAdminUser(state.adminUserReducer),
  adminUserPending: getAdminUserPending(state.adminUserReducer),
  adminUserError: getAdminUserError(state.adminUserReducer),

  studentProgressOverviewData: getStudentsProgressOverview(
    state.studentsProgressOverviewReducer
  ),
  studentProgressOverviewDataPending: getStudentsProgressOverviewPending(
    state.studentsProgressOverviewReducer
  ),
  studentEnquiry: getStudentEnquiry(state.studentEnquiryReducer),
  calenderEvents: getCalenderEvents(state.calenderEventsReducer),
  calenderEventsPending: getCalenderEventsPending(state.calenderEventsReducer),


  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchProducts: fetchProductsAction,
      fetchAdminUser: fetchAdminUser,
      fetchAdminUserByPartyAndRole: fetchAdminUserByPartyAndRole,
      fetchUserAddressData: RegisterUserService.getUserAddress,
      fetchStudentProfileProgressOverview: getProgressOverview,
      fetchStudentEnquiry: GeneralEnquiryService.getEnquiry,
      fetchCalenderEvents: fetchCalenderEvents,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);
const opsdashboardlanding = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {

      let partyID = localStorage.getItem("userid-Token");
      let roleId = localStorage.getItem("roleId");

      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
        if (localStorage.getItem('reload') === 'true') {
          localStorage.setItem('reload', 'false');
          window.location.reload();
        }
      });

      const {
        fetchAdminUser,
        adminUserPending,
        fetchAdminUserByPartyAndRole,
        fetchStudentProfileProgressOverview,
        fetchStudentEnquiry,
        fetchCalenderEvents,
        fetchAlertNotificationEvents,
        fetchNotificationEvents
      } = this.props;

      fetchAdminUserByPartyAndRole(partyID, roleId, err => {
        //console.log(err);
      });
      fetchStudentProfileProgressOverview("studentPartyId=0");
      fetchStudentEnquiry("enquiryType=1");
      fetchCalenderEvents("partyID=" + partyID);
      // fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID);
      document.querySelector("#loader").style.display = "none";
    }
    render() {
      const {
        adminUser,
        adminUserPending,
        fetchUserAddressData,
        studentProgressOverviewData,
        studentEnquiry,
        calenderEvents,
        studentProgressOverviewDataPending,
        calenderEventsPending,
        notificationEvents,
        pendingNotificationEvents,
        errorNotificationEvents,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        errorAlertNotigicationEvents,
      } = this.props;



      if (!adminUserPending && !studentProgressOverviewDataPending && !calenderEventsPending && !pendingNotificationEvents && !pendingAlertNotificationEvents) {
        //console.log("adminUser")
        //console.log(this.props.adminUser)
        //localStorage.setItem('adminUserprops', this.props.adminUser)
        localStorage.setItem('AdminAlertNotification', JSON.stringify([]))
        localStorage.setItem('AdminNotification', JSON.stringify(notificationEvents))

        return (
          <React.Fragment>
            <OpsHeader
              ////alertNotificationEvents={alertNotificationEvents}
              ////notificationEvents={notificationEvents}
              {...this.props}
            />
            <Ops
            //   adminUser={adminUser}
            //   fetchAdminUserByPartyAndRole={fetchAdminUserByPartyAndRole}
            //   getAdminAddress={fetchUserAddressData}
            //   studentProgressOverviewData={studentProgressOverviewData}
            //   studentEnquiry={studentEnquiry}
            //   calenderEvents={calenderEvents}
            //   studentProgressOverviewDataPending={studentProgressOverviewDataPending}
            //   calenderEventsPending={calenderEventsPending}
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      } else {

        return (
          <React.Fragment>
            {/* <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            /> */}
            <OpsHeader
              adminUser={adminUser}
              {...this.props}
            />
            <Ops
              adminUser={adminUser}
              fetchAdminUserByPartyAndRole={fetchAdminUserByPartyAndRole}
              getAdminAddress={fetchUserAddressData}
              studentProgressOverviewData={studentProgressOverviewData}
              studentEnquiry={studentEnquiry}
              calenderEvents={calenderEvents}
              calenderEventsPending={calenderEventsPending}
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
      // if (studentProgressOverviewDataPending && calenderEventsPending && pendingNotificationEvents && pendingAlertNotificationEvents) {
      //  
      //   return (
      //     <React.Fragment>
      //       <PopUpPortal
      //         HidePopup={this.state.loading}
      //         IsVisible={this.state.loading}
      //       />
      //       <OpsHeader 
      //            {...this.props}
      //       />
      //       <Admin
      //         adminUser={adminUser}
      //         fetchAdminUserByPartyAndRole={fetchAdminUserByPartyAndRole}
      //         getAdminAddress={fetchUserAddressData}
      //         studentProgressOverviewData={studentProgressOverviewData}
      //         studentEnquiry={studentEnquiry}
      //         calenderEvents={calenderEvents}
      //         calenderEventsPending={calenderEventsPending}
      //         {...this.props}
      //       />
      //       <Finxfooter />
      //     </React.Fragment>
      //   );
      // } else {
      //   return (
      //     <React.Fragment>
      //       <OpsHeader 
      //        //alertNotificationEvents={alertNotificationEvents}
      //        //notificationEvents={notificationEvents}
      //        {...this.props}
      //       />
      //       <Admin
      //         adminUser={adminUser}
      //         fetchAdminUserByPartyAndRole={fetchAdminUserByPartyAndRole}
      //         getAdminAddress={fetchUserAddressData}
      //         studentProgressOverviewData={studentProgressOverviewData}
      //         studentEnquiry={studentEnquiry}
      //         calenderEvents={calenderEvents}
      //         studentProgressOverviewDataPending={studentProgressOverviewDataPending}
      //         calenderEventsPending={calenderEventsPending}
      //         {...this.props}
      //       />
      //       <Finxfooter />
      //     </React.Fragment>
      //   );
      // }
    }
  }
);
export default opsdashboardlanding;