/*jshint esversion: 6 */

import React, { Component } from 'react';
import Routes from '../components/routes';
import ErrorBoundary from '../components/errorboundary/errorboundary';
import axios from 'axios';
// import IdleTimer from 'react-idle-timer';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Redirect,
//   Link
// } from "react-router-dom";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: '',
      reasonCode: '',
      reasonText: '',
      loading: 'false',
      token: '',
      jtitoken: '',
      timeout: 1000 * 1200 * 1,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    }


    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);


    axios.defaults.headers.common['applicationid'] = '11101';
    axios.defaults.headers.common['buid'] = '2';
    axios.defaults.headers.common['subbuid'] = '1';
    axios.defaults.headers.common['environment'] = 'dev';
    axios.defaults.headers.common['tokentype'] = 'jti';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['issuer'] = 'finx';

    // if (localStorage.getItem('login') !== '' && localStorage.getItem('login') !== null) {
    //   axios.defaults.headers.common['userId'] = JSON.parse(localStorage.getItem('login'))["userId"];
    // } else {
    //   axios.defaults.headers.common['userId'] = '9211420';
    // }

    // if (localStorage.getItem('login') !== '' && localStorage.getItem('login') !== null) {

    //   axios.defaults.headers.common['userlogin'] = JSON.parse(localStorage.getItem('login'))["userName"];
    // } else {
    axios.defaults.headers.common['userlogin'] = 'pramila@gmail.com';
    // }

    if (localStorage.getItem('jti-token') !== '' && localStorage.getItem('jti-token') !== null) {
      axios.defaults.headers.common['jtitoken'] = localStorage.getItem('jti-token');
    }
    if (localStorage.getItem('jwt-token') !== '' && localStorage.getItem('jwt-token') !== null) {
      axios.defaults.headers.common['token'] = localStorage.getItem('jwt-token');
    }
  }


  _onAction(e) {
    //console.log('user did something', e)
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    //console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    //console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      //alert("redirecting to login");
      window.location.href = "/?sessiontimeout=true"

    } else {
      this.setState({ showModal: true })
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
    }

  }


  render() {
    // const { match } = this.props
    return (
      /* <>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce ={250}
          caoture = { false }
                timeout={this.state.timeout} /> */

      <ErrorBoundary>
        <React.Fragment>
          <Routes {...this.state} />

        </React.Fragment>
      </ErrorBoundary>

      /* </>  */
    );
  }
}

export default App;
