import React, { Component } from "react";
import { connect } from "react-redux";
import { LoginService } from "../../services/login.service";
import Finxfooter from "./finxfooter";
import Cookies from "./cookies";
import Finxheader from "../finxheader/finxheader";
import AdminHeader from "../../components/dashboardheader/adminheader";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";

import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchStudentDashboard.service";
import { bindActionCreators } from "redux";

const mapStateToProps = state => ({
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);
//export default class finxcookiespolicy extends React.Component {
const finxcookiespolicy = connectFunction(
  class extends Component {
    // constructor(props) {
    //   super(props);
    // }
    componentDidMount() {
      const {
        fetchAlertNotificationEvents,
        fetchNotificationEvents
      } = this.props;

      let partyID = localStorage.getItem("userid-Token");
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
      });
      // fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID); //"partyId=" + partyID
    }

    render() {
      // const {
        // alertNotificationEvents,
        // pendingAlertNotificationEvents,
        // errorAlertNotigicationEvents,
        // notificationEvents,
        // pendingNotificationEvents,
        // errorNotificationEvents,
      // } = this.props;
      //console.log("alertNotificationEvents",this.props.alertNotificationEvents)
      //console.log("notificationEvents",this.props.notificationEvents)
      localStorage.setItem("alertdata", JSON.stringify(this.props.alertNotificationEvents))
      localStorage.setItem("notealertdata", JSON.stringify(this.props.notificationEvents))

      let header = "";
      if (localStorage.getItem('userid-Token')) {
        if ([5,'5'].includes(localStorage.getItem('roleId'))) {
          header = <DashboardHeader
            //alertNotificationEvents={localStorage.getItem('alertdata')}
            //notificationEvents={localStorage.getItem('notealertdata')}
            {...this.props}
          />
        }
        else {
          header = <AdminHeader
            //alertNotificationEvents={localStorage.getItem('alertdata')}
            //notificationEvents={localStorage.getItem('notealertdata')}
            {...this.props}
          />
        }
      }
      else {
        header = <Finxheader />
      }
      return (
        <React.Fragment>
          {header}
          <Cookies />
          <Finxfooter />
        </React.Fragment>
      );
    }
  }
)
export default finxcookiespolicy;
