import React from 'react';
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';
const Finxheader = loadable(() => import('../finxheader/finxheader'))
const Finxproduct = loadable(() => import('../finxproduct/finxproduct'))
const Finxfooter = loadable(() => import('../finxfooter/finxfooter'))



function landingPage(props) {

    return (
        <React.Fragment>
            <Finxheader {...props} />
            <Finxproduct {...props} />
            {/* <Cfxroadmap /> */}
            {/* <Aboutus /> */}
            {/* <Corporatesimage /> */}
            {/* <Generalenquiry /> */}
            <Finxfooter />
        </React.Fragment>
    );
}

export default withRouter(landingPage);

