import React from "react";
// import { RegisterUserService } from "../../services/registration.service";
// import { LoginService } from "../../services/login.service";
import { Pie,/* Doughnut, Bar, Line */ } from "react-chartjs-2";
import { Carousel } from "react-responsive-carousel";
// import progressoverview from "../../assets/images/progress-overview.jpg";

class MyAdminCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testData: {},
      displayTitle: "true",
      displayLegend: true,
      legendPosition: "right",
      courseData: {}
    };
  }
  componentDidMount() {
    this.setState({
      testData: {
        labels: ["Initiated", "InProgress", "Completed"],
        datasets: [
          {
            data: [
              //(this.props.studentProgressOverview || "").testTotal,
              (this.props.studentProgressOverview || "").testInitiated,
              (this.props.studentProgressOverview || "").testInProgress || "",
              (this.props.studentProgressOverview || "").testCompleted || ""
            ],
            backgroundColor: [
              "rgb(0, 82, 143)",
              "rgb(235, 140, 46)",
              "rgb(105, 161, 63)"
            ]
          }
        ]
      },
      courseData: {
        labels: ["Initiated", "InProgress", "Completed"],
        datasets: [
          {
            data: [
              //(this.props.studentProgressOverview || "").courseTotal,
              (this.props.studentProgressOverview || "").courseInitiated,
              (this.props.studentProgressOverview || "").courseInProgress || "",
              (this.props.studentProgressOverview || "").courseCompleted || ""
            ],
            backgroundColor: [
              "rgb(0, 82, 143)",
              "rgb(235, 140, 46)",
              "rgb(105, 161, 63)"
            ]
          }
        ]
      }
    });
  }
  render() {
    return (
      <Carousel showThumbs={false}>
        <div>
          <h3 className="student-title">Test Overview</h3>
          <h5 className="progress-title">
            Total Test = {(this.props.studentProgressOverview || "").testTotal}
          </h5>
          <Pie
            data={this.state.testData}
            width={75}
            height={60}
            options={{
              // title: {
              //   display: this.state.displayTitle
              // },
              legend: {
                display: this.state.displayLegend,
                position: this.state.legendPosition
              }
            }}
          />
        </div>
        <div>
          <h3 className="student-title">Course Overview</h3>
          <h5 className="progress-title">
            Total Course ={" "}
            {(this.props.studentProgressOverview || "").courseTotal}
          </h5>
          <Pie
            data={this.state.courseData}
            width={75}
            height={60}
            options={{
              // title: {
              //   display: this.state.displayTitle
              // },
              legend: {
                display: this.state.displayLegend,
                position: this.state.legendPosition
              }
            }}
          />
        </div>
      </Carousel>
    );
  }
}

export default MyAdminCarousel;
