import React from 'react';
import Finxheader from '../containers/finxheader/finxheader';
import Finxfooter from '../containers/finxfooter/finxfooter';
import roadmaps from '../assets/images/road_map.png';
//import roadmaps from '../../../assets/images/road_map.png';
import CFX from '../assets/images/CFX.png';
// import Modal from 'react-responsive-modal';
import Finxlogo from "../assets/images/finxlogo1.png";
import FinxRegistration from "../components/mainpage/finxregistration/finxregistration";

export default class roadmap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isMaintainenceActivated: false,
        }
    }

    okclick = () => {
        this.setState({ open: true });
    }
    onCloseModal = () => {
        this.setState({ open: false });
    };
    okclicks = () => {
        this.props.history.push("/");
    }

    render() {
        // const { open } = this.state;
        return (
            <React.Fragment>
                <Finxheader />
                <section class="road_map mb-50">
                    <div class="container">
                        <div class="section-title-black">
                            {/* <h2>Course Structure</h2> */}
                            <h2>Roadmap to CFX</h2>
                        </div>

                        <div class="road_map_image">
                            <div class="road_map_img">
                                <img alt='img' src={roadmaps} class="img-responsive" />
                            </div>
                            {!this.state.isMaintainenceActivated ?
                                <button type="submit" class="btn-1 pull-right"
                                    data-dismiss="modal" name="Submit" data-toggle="modal" data-backdrop="static" data-keyboard="true" data-target="#roadmap">Enroll Now</button>
                                : ''}
                            {/* <button type="button" class="btn-1 pull-right"><a href="#">Enroll Now</a></button> */}
                        </div>

                        <div class="roadmap_cfx">
                            <div class="col-md-3 col-md-3 col-sm-3 col-xs-3">
                                <div class="road_cfx_img">
                                    <img alt='img' src={CFX} class="img-responsive" />
                                    <div class="border_bottom-1"></div>
                                    <h3 class="cfx_name">Chartered Financial Expert </h3>
                                </div>
                            </div>
                            <div class="col-md-9 col-md-9 col-sm-9 col-xs-9">
                                <div class="cfx_details">
                                    <p>A First in India, unique collaboration to create skilled Ntrepreneurs – Entrepreneurs
                                        and Sales professionals, for the BFSI industry
                                        Chartered Financial Expert (CFX) is a six-month short-term professional certification
                                        targeted at candidates who are looking for a successful career in Banking, Financial
                                        Services
                                        and Insurance Industry. The program is designed to make students job/entrepreneurship ready.
                                        This certification is co-certified by leading industry educational institutions like
                                        National
                                        Institute of Securities Markets (NISM), NSE Academy and L.N.Welingkar Institute of
                                        Management
                                        Development and Research (WeSchool)
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/* <Modal  center> */}
                <div className="modal fade" id="roadmap" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                            <button
                                type="reset"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <section class="">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-12 col-md-offset-4 col-sm-offset-4">
                                            <div class="modal-head block-center">
                                                <img alt='img' src={Finxlogo} className="img-responsive block-center" width="120px" height="auto" />
                                                {/* <h3>Finx Core</h3> */}
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <div class="reset">
                                                <h3 className="text-center">Thank you for showing your interest in CFx. </h3>
                                                <p className="roadmap-text text-center">Meantime, please “Attempt FEAT” (if not attempted) which is a prerequisite to register for CFx program.</p>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <a href="#"
                                                        data-keyboard="true" data-toggle="modal" data-backdrop="static" data-target="#feat-Reg" >
                                                        <button type="submit" class="btn-5 mt-30 pull-right"
                                                            name="Submit">
                                                            ok {/* onClick={() => this.okclicks()} */}
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                {/* </Modal> */}
                <FinxRegistration {...this.props} />

                <Finxfooter />
            </React.Fragment>
        );
    }

}


