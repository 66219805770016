import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik, Form, Field } from "formik";
// // import { UserManagementService } from "../../services/UserManagementser";
// import {UserManagementser} from "../../services/userManagementService"
import { LoginService } from "../../services/login.service";
import * as moment from "moment";
import * as Yup from "yup";
import { EnrollmentService } from "../../services/enrollmentservice";
import { ExcelService } from "../Excel/excel";
import { warning, warningNotification } from "../notification/notifications";

export class userManagement extends Component {
  constructor(props) {
    super(props)
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("YYYY-MM-DD"),
      data: this.props.successUserActivityData,
      roleId: 0,
      loading: false,
      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],

    };
    this.showAll = this.showAll.bind(this);
  }

  componentDidMount() {
    this.initailizedDateDetails();
  }

  initailizedDateDetails() {
    var listOfYear = [];
    var listOfMonth = [];
    var listOfDay = [];
    var year = new Date().getFullYear();

    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);
    }

    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    for (var ld = 1; ld <= 31; ld++) {
      listOfDay.push(ld);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear: listOfYear });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });
  }


  exportToCSV = () => {

    let modifiedData = [];
    let data = this.state.data;

    data.forEach(element => {
      var data1 = {
        Name: element.name,
        Role: element.role,
        Module: element.module,
        Event: element.event,
        Parent_Action: element.parentAction,
        Sub_Action: element.subAction,
        Action_Date_Time: element.actionDateTime.split(' ')[0],

      }
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'UserActivity_Report-');

  };

  showAll() {
    this.setState({ data: this.props.successUserActivityData });
  }
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(value.toLowerCase()) : true
  }


  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          paymentStatus: "",
          startDate: "",
          endDate: "",
          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          listOfMonth1: this.state.listOfMonth,
          listOfDay1: this.state.listOfDay,
          fromday: "",
          frommonth: "",
          fromyear: "",
          today: "",
          tomonth: "",
          toyear: "",
        }}
        validationSchema={Yup.object().shape({
          // startDate: Yup.string(),//.required("You must specify the start date"),
          // endDate: Yup.lazy(key => {
          //   return Yup.string()
          //     .default(() => "")
          //   // .required("You must specify the end date")
          //   // .when("startDate", (startDate, schema) => {
          //   //   return schema.test({
          //   //     test: EndIntensity => !!startDate && EndIntensity >= startDate,
          //   //     message: "End date should be greater than or equal tostart date"
          //   //   });
          //   // });
          // })
        })}
        onSubmit={(fields, values) => {
          let formattedFromDate = "";
          let formattedEndDate = "";

          if ((fields["fromyear"] !== undefined && fields["fromyear"] !== "") || (fields["frommonth"] !== undefined && fields["frommonth"] !== "") || (fields["fromday"] !== undefined && fields["fromday"] !== "")) {
            let fromdy = fields["fromday"].length === 1 ? "0" + fields["fromday"] : fields["fromday"]
            let frommont = fields["frommonth"].length === 1 ? "0" + fields["frommonth"] : fields["frommonth"];
            formattedFromDate =
              fromdy + "/" + frommont + "/" + fields["fromyear"];
          } else {
            //warning("Please select valid from date", warningNotification);
            formattedFromDate = "";
          }

          if ((fields["toyear"] !== undefined && fields["toyear"] !== "") || (fields["tomonth"] !== undefined && fields["tomonth"] !== "") || (fields["today"] !== undefined && fields["today"] !== "")) {

            let tody = fields["today"].length === 1 ? "0" + fields["today"] : fields["today"]
            let tomont = fields["tomonth"].length === 1 ? "0" + fields["tomonth"] : fields["tomonth"];

            formattedEndDate =
              tody + "/" + tomont + "/" + fields["toyear"];
            //fields["today"] + "/" + fields["tomonth"] + "/" + fields["toyear"];
          } else {
            formattedEndDate = "";
          }

          let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
          let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
          let todayConverted = moment(new Date(), "DD.MM.YYYY");

          if (toDtConverted.diff(fromDtConverted, 'days') < 0) {
            this.setState({ loading: false });
            warning('“End Date” should be after the “Start Date”', warningNotification);
          } else if (fromDtConverted.diff(todayConverted, 'days') > 0) {
            this.setState({ loading: false });
            warning('“Start Date” should be before “Today’s Date”', warningNotification);
          } else {

            this.setState({ loading: true });
            LoginService.TokenUser(res => {
              localStorage.setItem("jwt-token", res.responseObject);

              let data = {
                startDate: formattedFromDate,
                endDate: formattedEndDate,
              };
              let params = "limit=" + -1 + "&fromDate=" + data.startDate + "&toDate=" + data.endDate + "&orderDir=asc"

              EnrollmentService.Usermanagement(params,
                res => {

                  if (res.status === "success") {
                    this.setState({
                      loading: false,
                      data: res.responseListObject,
                    })

                  }
                  else {
                    this.setState({
                      loading: false,
                      data: []
                    });
                  }
                }
              );

            });
          }
        }}
        render={({ handleSubmit, handleChange, errors, touched, values, setFieldValue }) => (
          <React.Fragment>
            <section class="dashboard-tab user-tab pt-50 mb-50">
              <div class="padding-rl-50">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-8 col-sm-6 col-xs-6">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              User Activity
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12">
                              <span class="right-icon">
                                <a href="#">
                                  <i onClick={() => this.exportToCSV()}

                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <Form onSubmit={handleSubmit} className="">
                          <div class="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-4 pl pr">
                                    <h5 className="filter-heading">
                                      Search Criteria
                                    </h5>
                                  </div>
                                  <div className="row">
                                    {/*<div className="col-md-3 col-sm-4 col-xs-12">
                                       <label>Status</label>
                                        <Field
                                          as="select"
                                          name="paymentStatus"
                                          class="form-control"
                                          onChange={handleChange}
                                        >
                                          <option value="selected">
                                            Select Status
                                          </option>
                                          <option value="Txn Success">
                                            Success
                                          </option>
                                          <option value="TXN_FAILURE">
                                            Fail
                                          </option>
                                          <option value="Initiated">
                                            Initiate
                                          </option>
                                        </Field>
                                      </div>  */}
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Start Date</label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="fromday"
                                            id="fromday"
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              setFieldValue((values.fromday = e.target.value));
                                              if ([31, '31'].includes(e.target.value)) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if ([30, '30'].includes(e.target.value)) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, '28', 29, '29'].includes(e.target.value)
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue((values.listOfMonth = listOfMonth));
                                            }}
                                            className={"form-control" + (errors.fromday && touched.fromday ? "is-invalid" : "")}
                                          >
                                            <option value="">DD</option>
                                            {
                                              (values.listOfDay || []).map(
                                                (d) => (<option value={d}> {d} </option>
                                                )
                                              )}
                                          </Field>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="frommonth"
                                            id="frommonth"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.frommonth = e.target.value)
                                              );
                                              if ([2, '2'].includes(e.target.value)) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                // e.target.value == 1 ||
                                                // e.target.value == 3 ||
                                                // e.target.value == 5 ||
                                                // e.target.value == 7 ||
                                                // e.target.value == 8 ||
                                                // e.target.value == 10 ||
                                                // e.target.value == 12
                                                [1, '1', 3, '3', 5, '5', 7, '7', 8, '8', 10, '10', 12, '12',].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (var ldd = 1; ldd <= 31; ldd++) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (var lod = 1; lod <= 30; lod++) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue((values.listOfDay = listOfDay));
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.month && touched.month
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth || []).map(
                                              (m) => (
                                                <option value={m.key}>
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="fromyear"
                                            id="fromyear"
                                            onChange={(e) => {
                                              setFieldValue((values.fromyear = e.target.value));
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.fromyear && touched.fromyear ? "is-invalid" : "")
                                            }
                                          >
                                            <option value="">YYYY</option>
                                            {(
                                              this.state.listOfYear || []
                                            ).map((year) => (
                                              <option value={year}>
                                                {year}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>End Date</label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="today"
                                            id="today"
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              setFieldValue((values.today = e.target.value));

                                              if ([31, '31'].includes(e.target.value)) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [30, '30'].includes(e.target.value)
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, '28', 29, '29'].includes(e.target.value)
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue((values.listOfMonth1 = listOfMonth));
                                            }}
                                            className={"form-control" + (errors.today && touched.today ? "is-invalid" : "")}
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay1 || []).map(
                                              (d) => (<option value={d}> {d} </option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="tomonth"
                                            id="tomonth"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.tomonth = e.target.value)
                                              );
                                              if ([2, '2'].includes(e.target.value)) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                // e.target.value == 1 ||
                                                // e.target.value == 3 ||
                                                // e.target.value == 5 ||
                                                // e.target.value == 7 ||
                                                // e.target.value == 8 ||
                                                // e.target.value == 10 ||
                                                // e.target.value == 12
                                                [1, '1', 3, '3', 5, '5', 7, '7', 8, '8', 10, '10', 12, '12',].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (var ldd = 1; ldd <= 31; ldd++) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (var lod = 1; lod <= 30; lod++) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue((values.listOfDay1 = listOfDay));
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.tomonth && touched.tomonth
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth1 || []).map(
                                              (m) => (
                                                <option value={m.key}>
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="toyear"
                                            id="toyear"
                                            onChange={(e) => {
                                              setFieldValue((values.toyear = e.target.value));
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.toyear && touched.toyear ? "is-invalid" : "")
                                            }
                                          >
                                            <option value="">YYYY</option>
                                            {(
                                              this.state.listOfYear1 || []
                                            ).map((year) => (
                                              <option value={year}>
                                                {year}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12 mt-40">
                                      <div className="row">
                                        <button
                                          className="btn-5 mr-20 ml-20"
                                          type="submit"
                                        >
                                          Search
                                        </button>

                                        <button
                                          className="btn-5"
                                          type="reset"
                                          onClick={this.showAll}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <div class="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filterable
                            ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            // defaultSorted={[
                            //   {
                            //     id: "actionDateTime",
                            //     desc: true
                            //   }
                            // ]}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Name",
                                    id: "name",
                                    accessor: d => d.name
                                  },

                                  {
                                    Header: "Role",
                                    id: "role",
                                    accessor: d => d.role
                                  },
                                  {
                                    Header: "Module",
                                    id: "module",
                                    accessor: d => d.module
                                  },
                                  {
                                    Header: "Event",
                                    id: "event",
                                    accessor: d => d.event
                                  },
                                  {
                                    Header: "ParentAction",
                                    id: "parentAction",
                                    accessor: d => d.parentAction
                                  },
                                  {
                                    Header: "SubAction",
                                    id: "subAction",
                                    accessor: d => d.subAction
                                  },
                                  {
                                    Header: "ActionDateTime",
                                    id: "actionDateTime",
                                    accessor: d => d.actionDateTime
                                  },
                                ]
                              }
                            ]}
                            defaultPageSize={10}

                            style={{
                              width: "100%",
                              maxHeight: "500px"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default userManagement;
