import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik } from "formik";
import { LoginService } from "../../services/login.service";
import { AssessmentReport } from "../../services/fetchAssessmentReport";
// import * as Yup from "yup";
import * as moment from "moment";
// import { ExcelService } from "../Excel/excel";
import { OrderService } from "../../services/order.service";
// import { Button } from "react-bootstrap";
import AdminHeader from "../dashboardheader/adminheader";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import { PaymentService } from "../../services/payment.service";
import { ProductService } from "../../services/product.service";
import { RegisterUserService } from '../../services/registration.service';

export class cfxEducationalLoan extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("YYYY-MM-DD"),
      data: [],
      selected: {},
      selectAll: 0,
      data1: [],
      productdetails: [],
      orderdetails: [],
      //  adminProfileUser: this.props.adminProfileUser,
    };
    // this.showAll = this.showAll.bind(this);
  }

  componentDidMount() {
    // let data = {
    //   productID: 1
    // }
    OrderService.getDataForLoan(
      res => {
        console.log(res.responseListObject)
        this.setState({
          data: res.responseListObject
        })
      }
    )

    let productName = "productName=CFX";
    ProductService.ProductByName(productName, (res) => {
      if (res.status === "success") {
        this.setState({ productdetails: res.responseObject });
      }
    })
  }
  ApproveUser = () => {
    alert('')
  }

  toggleSelectAll() {

    let newSelected = {};
    let data = []
    // let AllData = []
    if (this.state.selectAll === 0) {
      this.state.data.forEach(x => {
        let obj = {}
        newSelected[x.studentID] = true;
        obj['studentPartyID'] = x.studentPartyID
        obj['productID'] = x.productID
        obj['loanStatus'] = x.loanStatus
        obj['paymentID'] = x.paymentID
        data.push(obj)

      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
      data1: data,

    });
  }
  toggleRow(original) {
    // var ceildata2 = localStorage.getItem("PersonnelData")
    let data = this.state.data1
    console.log(data)
    let AllData = []
    let temp_data = []
    let obj = {}
    let flag = true
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[original.studentID] = !this.state.selected[original.studentID];

    data.map((e, i) => {
      if (e.studentPartyID === original.studentPartyID) {
        data.splice(i, 1);


        flag = false;
      }
    })
    if (data.length > 0) {


      if (flag === true) {
        console.log("original.comment", original.comment)
        obj['studentPartyID'] = original.studentPartyID
        obj['productID'] = original.productID
        obj['loanStatus'] = original.loanStatus
        obj['paymentID'] = original.paymentID

        obj['firstName'] = original.firstName
        obj['middleName'] = original.middleName
        obj['lastName'] = original.lastName
        obj['gender'] = original.gender

        obj['emailID'] = original.emailID
        obj['mobileNo'] = original.mobileNo
        obj['contactNo'] = original.contactNo
        obj['dob'] = original.dob


        temp_data.push(obj)
        AllData.push(original)




      }

    }
    else {
      console.log("original.comment", original.comment)
      obj['studentPartyID'] = original.studentPartyID
      obj['productID'] = original.productID
      obj['loanStatus'] = original.loanStatus
      obj['paymentID'] = original.paymentID

      obj['firstName'] = original.firstName
      obj['middleName'] = original.middleName
      obj['lastName'] = original.lastName
      obj['gender'] = original.gender

      obj['emailID'] = original.emailID
      obj['mobileNo'] = original.mobileNo
      obj['contactNo'] = original.contactNo
      obj['dob'] = original.dob


      temp_data.push(obj)
      AllData.push(original)

    }
    let d1 = data.concat(temp_data)


    this.setState({
      selected: newSelected,
      data1: d1,
      selectAll: 2,

    });
  }

  renderEditable = cellInfo => {

    // let currentValue = ""
    if (cellInfo) {


      // }

      let currentValue = cellInfo.original ? cellInfo.original.loanStatus : ""
      const cellValue = this.state.data === null ? '' : this.state.data[cellInfo.index] === undefined ? "" : this.state.data[cellInfo.index][cellInfo.column.id] === undefined ? currentValue : this.state.data[cellInfo.index][cellInfo.column.id];
      console.log(cellValue)
      return (
        <div>


          <select name="action" id="action" value={cellValue}
            onChange={this.handleInputChange.bind(null, cellInfo)}
            disabled={(currentValue === 1 || currentValue === 2 || currentValue === 3) ? true : false}


          >

            <option value="">Select</option>
            <option value="0">Pending</option>
            <option value="1">Approve</option>
            <option value="2">Reject</option>
            <option value="3">One time payment</option>

          </select>
          {/* <i class="fa fa-ellipsis-h actionIcon" aria-hidden="true"></i> */}
        </div>
      );
    }
    else {
      return ('')
    }
  };
  handleInputChange = (cellInfo, event) => {


    let data1 = [...this.state.data];
    data1[cellInfo.index][cellInfo.column.id] = event.target.value;
    //   console.log(data1)
    this.setState({
      data: data1
    })


  }

  assignCourse = (original) => {
    if (original.loanStatus === 1 || original.loanStatus === 3) {

      let dataOrder = [];
      let order = ""
      RegisterUserService.fetchOrerDetailsByUser("studentPartyID=" + original.studentPartyID, (res) => {
        dataOrder = res.responseListObject
        for (var i = 0; i < dataOrder.length; i++) {

          if (dataOrder[i].paymentStatus === "Txn Success") {
            for (var j = 0; j < dataOrder[i].orderItemList.length; j++) {
              console.log("dataOrder[i].orderItemList[j].productID::", dataOrder[i].orderItemList[j])
              if (dataOrder[i].orderItemList[j].productID === 1) {
                //alert("hii")
                order = dataOrder[i].orderItemList[j].orderid
                // alert("dataOrder[i].paymentStatus"+ order)
                console.log("dataOrder[i].paymentStatus", dataOrder[i].orderItemList[j])
                if (order > 0 || order !== 0) {
                  var CheckoutObj = {
                    studentPartyID: original.studentPartyID,
                    productID: this.state.productdetails.productID,
                    productName: this.state.productdetails.productName,
                    paymentID: order,
                    firstName: original.firstName,
                    middleName: original.middleName,
                    lastName: original.lastName,
                    gender: 1,
                    emailID: original.emailID,
                    mobileNo: original.mobileNo,
                    contactNo: original.mobileNo,
                    dob: original.dob
                  };

                  console.log("CheckoutObj:::", CheckoutObj)
                  // var AHobj = JSON.parse(localStorage.getItem('PaymentDet'));
                  RegisterUserService.AHRegisterUser(CheckoutObj, res => {
                    if (res.status === 'success') {
                      this.setState(
                        state =>
                          (state.loading = false)
                      );
                      success("You have successfully been assigned the course", successNotification)
                      setTimeout(() => {
                        window.location.reload()
                      }, 5000)
                    } else {
                      this.setState(
                        state =>
                          (state.loading = false)
                      ); error(res.reasonText, errorNotification);
                    }
                  }, error => {
                    console.log(error);
                  })

                }
              }
            }
          }


        }

      })

    } else {
      warning("Your course has not been approved yet", warningNotification)
      setTimeout(() => {
        window.location.reload()
      }, 5000)

    }



  }


  render() {
    return (
      <React.Fragment>
        <AdminHeader
          {...this.props}
        />
        <Formik
          enableReinitialize={true}
          initialValues={{
            startDate: "",
            endDate: "",
          }}
          onSubmit={(values) => {
            this.setState({ loading: true });
            LoginService.TokenUser((res) => {
              localStorage.setItem("jwt-token", res.responseObject);
              let data = {
                startDate: values.startDate
                  ? moment(values.startDate).format("DD/MM/YYYY")
                  : values.endDate
                    ? moment(new Date()).format("DD/MM/YYYY")
                    : "",
                endDate: values.endDate
                  ? moment(values.endDate).format("DD/MM/YYYY")
                  : values.startDate
                    ? moment(new Date()).format("DD/MM/YYYY")
                    : "",
              };
              let params =
                "limit=-1&fromDate=" +
                data.startDate +
                "&toDate=" +
                data.endDate +
                "&orderDir=asc";
              AssessmentReport.fethAssessmentReport(params, (res) => {
                if (res.status === "success") {
                  this.setState({ loading: true });
                  this.componentDetails(res.responseListObject, false);
                  this.setState({ loading: false });
                } else {
                  this.setState({
                    loading: false,
                    data: [],
                  });
                }
              });
            });
          }}
          render={({ handleSubmit, handleChange }) => (
            <React.Fragment>
              <section class="dashboard-tab user-tab pt-50 mb-50">
                <div class="padding-rl-50">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="table-header">
                          <h3>
                            <div class="row">
                              <div class="col-md-8 col-sm-6 col-xs-8">
                                <span class="left-icon">
                                  <i class="fa fa-list" aria-hidden="true"></i>
                                </span>{" "}
                                CFX Educational Loan List
                              </div>
                              {/* <div class="col-md-4 col-sm-6 col-xs-4">
                              <span class="right-icon">
                                <a>
                                  <i
                                    onClick={() => this.exportToCSV()}
                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div> */}
                            </div>
                          </h3>
                        </div>
                        <div class="bg-box">
                          {/* <Form onSubmit={handleSubmit} className="">
                          <div class="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                    <h5 className="filter-heading">
                                      Search Criteria
                                    </h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Start Date</label>
                                      <Field
                                        type="date"
                                        name="startDate"
                                        max={this.state.today}
                                        id="startDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="startDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>End Date</label>
                                      <Field
                                        type="date"
                                        name="endDate"
                                        max={this.state.today}
                                        id="endDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 mt-40">
                                      <div className="row">
                                        <button
                                          className="btn-5 mr-20 ml-20"
                                          type="submit"
                                        >
                                          Search
                                        </button>
                                        <button
                                          className="btn-5"
                                          type="reset"
                                          onClick={this.showAll}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form> */}
                          <div class="table-responsive">
                            <ReactTable
                              minRows={2}
                              data={this.state.data !== null ? this.state.data : []}
                              filterable
                              ref={(refReactTable) => {
                                this.refReactTable = refReactTable;
                              }}
                              defaultFilterMethod={this.filterCaseInsensitive}
                              columns={[
                                {
                                  columns: [
                                    {

                                      id: "checkbox",
                                      accessor: "",
                                      Cell: ({ original }) => {

                                        return (
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={this.state.selected[original.studentID] === true}
                                            onChange={() => this.toggleRow(original)}

                                            disabled={
                                              original.loanStatus === 1 || original.loanStatus === 2 || original.loanStatus === 3 ? true : false
                                            }
                                          />
                                        );
                                      },
                                      Header: x => {
                                        return (
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={this.state.selectAll === 1}
                                            ref={input => {
                                              if (input) {
                                                input.indeterminate = this.state.selectAll === 2;
                                              }
                                            }}
                                            onChange={() => this.toggleSelectAll()}

                                          />
                                        );
                                      },
                                      sortable: false,

                                      filterable: false,
                                      width: 45,

                                    },
                                    {
                                      Header: "User Name",
                                      id: "UserName",
                                      accessor: (d) =>
                                        (d.firstName || "").toString() + " " + (d.lastName || "").toString(),
                                    },
                                    {
                                      Header: "Email Id",
                                      id: "Email",
                                      accessor: (d) =>
                                        (d.emailID || "").toString(),
                                    },
                                    {
                                      Header: "Course Name",
                                      id: "CourseName",
                                      accessor: (d) => "CFX",
                                    },

                                    {
                                      Header: "Loan Status",
                                      id: "loanStatus",
                                      accessor: (d) => "",
                                      Cell: this.renderEditable,
                                      filterable: false,
                                    },
                                    // {

                                    //   Header: "Comments",
                                    //   id: "comment",
                                    //   accessor: "",
                                    //   Cell: ({ original }) => {
                                    //     debugger
                                    //       return (
                                    //           <input
                                    //               type="textbox"
                                    //               className="textbox"
                                    //               value={original.comment}
                                    //           />

                                    //       );
                                    //   },

                                    //   sortable: true,
                                    //   filterable: false,

                                    //   },
                                    {
                                      Header: "Assigned Course",
                                      id: "AssignedCourse",
                                      accessor: (d) => ""
                                      ,
                                      Cell: props => {
                                        return (
                                          <div>
                                            <Toggle
                                              icons={false}
                                              // defaultChecked={false
                                              // }
                                              defaultChecked={
                                                parseInt(props.original.paymentID) > 0 || props.original.paymentID > "0" || props.original.paymentID > 0
                                                  ? true
                                                  : false
                                              }
                                              disabled={
                                                props.original.paymentID > 0 ? true : false
                                              }
                                              onChange={() => this.assignCourse(props.original)}

                                            />
                                          </div>
                                        );
                                      },
                                      filterable: false
                                    },
                                  ],
                                },
                              ]}
                              defaultPageSize={10}
                              style={{
                                width: "100%",
                                maxHeight: "500px",
                              }}
                            />
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-20 text-right">


                              <button type="submit"
                                onClick={e => {

                                  console.log("Sneha", this.state.data1)
                                  var txt = "Are you sure you want to continue?"
                                  var r = window.confirm(txt);
                                  if (r === true) {
                                    console.log("Sneha" + this.state.data1)

                                    //   var person = prompt("Please enter your comment", "");
                                    // if (person != null) {
                                    //   alert("person::"+person)
                                    // }

                                    debugger
                                    if (this.state.data1.length > 0) {
                                      if (this.state.data1[0].loanStatus === "1") {
                                        let productName = "productName=CFX";
                                        ProductService.ProductByName(productName, (res) => {
                                          if (res.status === "success") {
                                            this.setState({ productdetails: res.responseObject });
                                          }
                                        });
                                        console.log("this.state.productdetails::", this.state.productdetails)
                                        let orderItemListArray = [];
                                        let orderItemList = {
                                          itemID: 1,
                                          productID: this.state.productdetails.productID,
                                          couponApplied: "ZERO",
                                          prodctPrice: this.state.productdetails.productPrice,
                                        };
                                        orderItemListArray.push(orderItemList);
                                        let checkout = {
                                          studentPartyID: this.state.data1[0].studentPartyID,
                                          orderPlacedOn: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"), //'15/11/2018 15:18:33',
                                          totalItemPrice: this.state.productdetails.productPrice,
                                          totalOrderPrice: this.state.productdetails.productPrice,
                                          paymentStatus: "Initiated",
                                          orderItemList: orderItemListArray,
                                        };
                                        console.log("checkoutcheckoutcheckout:::", checkout)
                                        LoginService.TokenUser((res) => {
                                          if (res.status === "success") {
                                            localStorage.setItem("jwt-token", res.responseObject);
                                            OrderService.InsertOrder(
                                              checkout,
                                              res1 => {
                                                if (res1.status === "success") {
                                                  this.setState({ orderdetails: res.responseObject });
                                                  let payobject = {
                                                    mid: "offline",
                                                    orderId: res1.responseObject.orderID,
                                                    txnId: "1",
                                                    bankTxnId: "1",
                                                    txnAmount: this.state.productdetails.productPrice,
                                                    currency: "INR",
                                                    status: "TXN_SUCCESS",
                                                    respCode: "01",
                                                    respMSG: "Txn Success",
                                                    txnDate: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                                                    gatewayName: "offline",
                                                    paymentMode: "offline",
                                                    bankName: "offline",
                                                    mercUnqRef: "2"
                                                  }
                                                  PaymentService.OfflinePayment(payobject, res2 => {
                                                    if (res2.status === "success") {
                                                      OrderService.ApplyForLoan(this.state.data1,
                                                        res3 => {
                                                          success("You have successfully updated loan status", successNotification)
                                                          setTimeout(() => {
                                                            window.location.reload()
                                                          }, 5000)
                                                        })
                                                    }
                                                  });
                                                }
                                              },
                                              (error) => {
                                                this.props.history.push("/");
                                              }
                                            );
                                          }
                                        });

                                      } else {
                                        OrderService.ApplyForLoan(this.state.data1,
                                          res => {
                                            success("You have successfully updated loan status", successNotification)
                                            setTimeout(() => {
                                              window.location.reload()
                                            }, 5000)
                                          })
                                      }
                                    } else {
                                      warning("Please select atleast one user", warningNotification)
                                    }
                                  }
                                }
                                }

                                className="btn-5 mt-20">Submit</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default cfxEducationalLoan;
