import React, { Component } from "react";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  warning,
  warningNotification,
  success,
  successNotification,
  error,
  errorNotification,
} from "../notification/notifications";
import ReactTable from "react-table";
import {
  camelCase,
  // capitalCase,
  // constantCase,
  // dotCase,
  // headerCase,/
  // noCase,
  // paramCase,
  // pascalCase,
  // pathCase,
  // sentenceCase,
  // snakeCase,
} from "change-case";
import ReactExport from "react-data-export";
import XLSX from "xlsx";
// import { withRouter } from "react-router-dom";
import { EventService } from "../../services/event.service";
import { LoginService } from "../../services/login.service";
import * as moment from "moment";
import RestDataSource from "../../services/restdatasource";
import { Global_var } from "../../global/global_var";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const dataSet1 = [
    {
      name: "Xyzpqr",
      emailID: "Xyzpqr@test.com",
      nature: "candidate",
    },
    {
      name: "Abcdefg",
      emailID: "abcdefg@gmail.com",
      nature: "guest",
    },
  ];
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;

  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

export default class editEvent extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      loading: false,
      listOfHours: [],
      listOfMins: [],
      today: moment(todaydate).format("YYYY-MM-DD"),
      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
      searchCandidateResults: [],
      disabledCancel: false,
      cancelEvent: "",
      searchCityTexts: "",
      subUrbList: [],
      startHour: "",
      endHour: "",
      startMinutes: "",
      endMinutes: "",
      searchCityResults: [],
      fetchEventDetails: {},
      participantsList: [],
      selectedCandidate: {},
      excelDataSet: [],
      file: {},
      data: [],
      cols: [],
      isFileUploaded: false,
      checkFileSize: false,
      totalRecords: "",
      successfullyUploaded: "",
      faildRecords: "",
      invalidData: [],
      checkDocumentType: false,
      checkDocumentSize: false,
      hideUI: false,
      showUploadButton: false,
      isEdit: false,
      isSaveBtnDisabled: false,
    };
  }
  handleCancel = () => {
    this.props.history.goBack("-1");
  };
  handleChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const checkType = files[0].name.split(".");
      if (["xls", "xlsx"].indexOf(checkType[1]) < 0) {
        this.setState({
          checkDocumentType: true,
          checkDocumentSize: false,
          file: files[0],
          isFileUploaded: false,
          showUploadButton: false,
          totalRecords: 0,
          successfullyUploaded: 0,
          faildRecords: 0,
          hideUI: false,
        });
      } else if (files && files[0] && files[0].size <= 2000000) {
        this.setState({
          checkDocumentType: false,
          checkDocumentSize: false,
          file: files[0],
          isFileUploaded: true,
          showUploadButton: true,
          totalRecords: 0,
          successfullyUploaded: 0,
          faildRecords: 0,
          hideUI: false,
        });
      } else {
        this.setState({
          checkDocumentType: false,
          checkDocumentSize: true,
          file: files[0],
          isFileUploaded: false,
          showUploadButton: false,
          totalRecords: 0,
          successfullyUploaded: 0,
          faildRecords: 0,
          hideUI: false,
        });
      }
    }
    e.target.value = null;
  };
  handleFile = () => {
    const reader = new FileReader(this.state.file);
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      //
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      e = "";

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      this.state.participantsList.map((e) => {
        data.push({
          name: e.name,
          emailID: e.emailID,
          nature: e.nature,
        });
      });

      /* Update state */
      // this.setState({ loading: true });
      this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
        LoginService.TokenUser((res) => {
          if (res.status === "success") {
            localStorage.setItem("jwt-token", res.responseObject);
            EventService.excelFileValidate(data, (res1) => {
              if (res1.data.status === "success") {
                // success(
                //   "Event Participant data Uploaded successfully",
                //   successNotification
                // );
                success("All Selected users till date have been added",successNotification)
                this.setState({ loading: false });
                this.setState({
                  // isUploaded:true
                  excelDataSet: res1.data.responseObject.validParticipantData,
                });
                this.setState({
                  hideUI: false,
                  file: {},
                  data: [],
                  cols: [],
                  faildRecords:
                    data.length - res1.data.responseObject.successfullRecords,
                  // res1.data.responseObject.invalidRecords ||

                  successfullyUploaded: res1.data.responseObject
                    .successfullRecords
                    ? res1.data.responseObject.successfullRecords
                    : " ",
                  invalidData:
                    res1.data.responseObject.invalidParticipantData || [],
                  isFileUploaded: false,
                  participantsList: [
                    ...res1.data.responseObject.validParticipantData,
                  ],
                  checkFileSize: false,
                  checkDocumentType: false,
                  showUploadButton: false,
                });
              } else {
                if (res1.data.reasonText === "Invalid Token") {
                  console.log("res1", res1);
                  error("Session Expired", errorNotification);
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 5000);
                } else {
                  console.log("res1", res1);
                  this.setState({
                    successfullyUploaded: res1.data.responseObject
                      .successfullRecords
                      ? res1.data.responseObject.successfullRecords
                      : " ",
                  });

                  warning(
                    "Due to errors in records, the uploading of files has failed. Kindly check the grid for further process.",
                    warningNotification
                  );
                  this.setState({ loading: false });
                  this.setState({
                    hideUI: true,
                    file: {},
                    data: [],
                    cols: [],
                    // faildRecords: res1.data.responseObject.invalidRecords || "",
                    faildRecords:data.length - res1.data.responseObject.successfullRecords,
                    successfullyUploaded:
                      res1.data.responseObject.successfullRecords,
                    invalidData:
                      res1.data.responseObject.invalidParticipantData || [],
                    participantsList:
                    res1.data.responseObject.validParticipantData,
                    isFileUploaded: false,
                    checkFileSize: false,
                    checkDocumentType: false,
                    showUploadButton: false,
                  });
                }
              }
            });
          }
        });
      });
      this.setState({ totalRecords: this.state.data.length });
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  };
  initailizedDateAndTimeDetails = () => {
    var listOfYear = [];
    var listOfMonth = [];
    var listOfDay = [];
    var listOfHours = [];
    var listOfMins = [];

    var year = 2018;

    for (var i = 1; year + i < 2051; i++) {
      var current = year + i;
      listOfYear.push(current);
    }
    listOfYear.reverse();

    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    for (var ld = 1; ld <= 31; ld++) {
      listOfDay.push(ld);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear: listOfYear });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });

    for (var i = 0; i < 24; i++) {
      listOfHours.push(i);
    }
    for (var j = 0; j < 60; j++) {
      listOfMins.push(j);
    }

    this.setState({ listOfHours: listOfHours });
    this.setState({ listOfMins: listOfMins });
  };
  addEventToCalander = (data1) => {
    console.log("ddddddddd", this.state.tokenClient);
    console.log("windowgapi",window.gapi);

    this.state.tokenClient.callback = async (resp) => {
      console.log(
        "this.state.tokenClient && this.state.tokenClient.callback " + resp
      );
      if (resp.error !== undefined) {
        // throw (resp);
        console.error("resssssssp", resp);
      }
      try {
        const event = data1;
        const request = window.gapi.client.calendar.events.insert({
          calendarId: "primary",
          resource: event,
        });
        console.log("rrrrr", request);

        request.execute(function (event) {
          console.log("Event created: " + JSON.stringify(event, null, 2));
        });
      } catch (err) {
        console.error(err);
        console.log("eeeerrrrr", err);
        return;
      }
    };

    if (window.gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account
      // and ask for consent to share their data
      // when establishing a new session.
      this.state.tokenClient &&
        this.state.tokenClient.requestAccessToken({ prompt: "consent" });

      console.log("aaaaddddddddd", data1);
    } else {
      // Skip display of account chooser
      // and consent dialog for an existing session.
      this.state.tokenClient &&
        this.state.tokenClient.requestAccessToken({ prompt: "" });
      console.log("aabbbbbbbbaaddddddddd", data1);
    }
  };

  fetchEventDetailsById() {
    // const eventID = this.props.location.state.eventID;
    const eventID=this.props.location.state?this.props.location.state.eventID:JSON.parse(localStorage.getItem("HistoryState")).eventID;
    let params = "eventID=" + eventID;
    this.setState({loading:true})
    EventService.getEventDetailsById(params, (res) => {
      console.log("res", res);

      if (res.status === "success") {
        let formattedEndDate1 = res.responseObject.eventStartDate.slice(0, 10);
        let formattedStartDate1 = res.responseObject.eventStartDate.slice(
          0,
          10
        );
        let formattedStartDate =
          formattedStartDate1.split("/")[2] +
          "-" +
          formattedStartDate1.split("/")[1] +
          "-" +
          formattedStartDate1.split("/")[0];
        let formattedEndDate =
          formattedEndDate1.split("/")[2] +
          "-" +
          formattedEndDate1.split("/")[1] +
          "-" +
          formattedEndDate1.split("/")[0];
        let eventStartTime1 = res.responseObject.eventStartTime;
        let eventEndTime1 = res.responseObject.eventEndTime;
        let eventStartTimeString = `${parseInt(
          eventStartTime1.split(":")[0]
        )}:${parseInt(eventStartTime1.split(":")[1])}`;
        let eventEndTimeString = `${parseInt(
          eventEndTime1.split(":")[0]
        )}:${parseInt(eventEndTime1.split(":")[1])}`;

        let eventStartTime = eventStartTimeString.split(":");
        let eventEndTime = eventEndTimeString.split(":");
        this.handleSubUrbOnCity(res.responseObject.cityID);
        let participantsListData = res.responseObject.participantsList.map(
          (data) => {
            let participantsListObject = {
              name: data.inviteeName,
              nature: data.inviteeNature,
              partyID: data.inviteePartyID,
              emailID: data.inviteeEmailID,
            };
            return participantsListObject;
          }
        );

        this.setState({
          loading: false,
          fetchEventDetails: {
            ...res.responseObject,
            eventStartDate: formattedStartDate,
            eventEndDate: formattedEndDate,
          },
          participantsList: [...participantsListData],
          startHour: eventStartTime[0],
          startMinutes: eventStartTime[1],
          endHour: eventEndTime[0],
          endMinutes: eventEndTime[1],
        });

        let today = new Date()
          // ;
          .getTime();

        let eventDate = new Date(formattedStartDate)
          // ;
          .getTime();
        console.log(
          "asdsa",
          today,
          eventDate,
          today > eventDate,
          today === eventDate
        );
        if (today > eventDate) {
          this.setState({
            disabledCancel: true,
          });
        } else if (today === eventDate) {
          this.setState({
            disabledCancel: false,
          });
        } else {
          this.setState({
            disabledCancel: false,
          });
        }
      } else {
        warning("Your session is timed out. Please login again",warningNotification);
        this.setState({
          loading: false,
          fetchEventDetails: {
            ...res.responseObject,
          },
        });
      }
    });
  }
  handleWarning = (value) => {
    warning(value, warningNotification);
  };

  componentDidMount() {
    this.setState({ isEdit: true });

    setTimeout(()=>  { 
      window.gapi.load("client", async () => {
     await window.gapi.client.init({
       apiKey: Global_var.URL_API_KEY,
       discoveryDocs: [Global_var.URL_DISCOVERY_DOC],
     });
     this.setState({ gapiInited: true }, () =>
       console.log("Client Activated")
     );
   });
   // ! Init Google API
   var tokenClient = window.google.accounts.oauth2.initTokenClient({
     client_id: Global_var.URL_CLIENT_ID,
     scope: Global_var.URL_SCOPES,
     callback: "",
   });
   this.setState({ gisInited: true, tokenClient }, () =>
     console.log("API Activated")
   );},1000)
    this.fetchEventDetailsById();
    
    this.initailizedDateAndTimeDetails();
  }

  SearchCityForm = (e) => {
    let params = `text=${e.target.value}`;
    this.setState({loading:true})
    EventService.getFilteredCity(params, (res) => {
      if (res.status === "success") {
        this.setState({
          searchCityResults: res.responseListObject,
          loading:false
        });
      }else{
        this.setState({loading:false})
      }
    });
  };

  SearchCandidateForm = (e, setFieldValue) => {
    let params = `searchText=${e.target.value}`;
    this.setState({loading:true})
    EventService.getFilteredStudent(params, (res) => {
      if (res.status === "success") {
        this.setState({
          searchCandidateResults: res.responseListObject,
          loading:false
        });
      }else{
        this.setState({ loading:false})
      }
    });
  };
  SearchCandidateForm1 = () => {
    let params = `searchText=`;
    this.setState({loading:true})
    EventService.getFilteredStudent(params, (res) => {
      if (res.status === "success") {
        let newParticipantList=res.responseListObject;
        // if (!this.state.participantsList.find((e) => e.emailID === value.emailID)) {
          let newParticipantListFiltered=[];
          newParticipantList.map((item)=>{
            const isPresent = this.state.participantsList.find(data => data.emailID === item.emailID);
            
            const Filtered = this.state.participantsList.filter(data => data.emailID === item.emailID);
            if(Filtered&&Filtered.length>0){
            console.log("Filered",Filtered)
            }
            if(!isPresent){

              let newParticipant 
              newParticipant = { ...item, nature: "Candidate" };
      
              newParticipantListFiltered.push(newParticipant)
            }
          })
        
        



        this.setState({

         
        participantsList: [...this.state.participantsList, ...newParticipantListFiltered],
        loading:false
          // allCandidateResults: res.responseListObject,
        });
        success("All registered users till date have been added",successNotification)
      } else {
        this.setState({
          loading:false
          // allCandidateResults: [],
        });
      }
    });
  };
  handleParticipantAdd = (value) => {
    this.setState({selectedCandidate: {}})
    let newParticipant;
    if (!this.state.participantsList.find((e) => e.emailID === value.emailID)) {
      newParticipant = { ...value, nature: "Candidate" };

      this.setState({
        participantsList: [...this.state.participantsList, newParticipant],
      });
    }
  };

  handleGuestAdd = (guestName, guestEmail) => {
    let newParticipient;
    if (guestName !== undefined && guestEmail !== undefined) {
      if (!this.state.participantsList.find((e) => e.emailID === guestEmail)) {
        newParticipient = {
          name: guestName,
          emailID: guestEmail,
          partyID: null,
          nature: "Guest",
        };

        this.setState({
          participantsList: [...this.state.participantsList, newParticipient],
        });
      }
    }
  };
  handleSubUrbOnCity = (value) => {
    let params = "cityID=" + value;
    this.setState({loading:true})
    EventService.getSubUrbListOnCityID(params, (res) => {
      if (res.status === "success") {
        this.setState({
          subUrbList: res.responseListObject,
          loading:false
        });
      }else{
        this.setState({
          loading:false
        })
      }
    });
  };
  handleDelete = (index) => {
    const newParticipantList = [
      ...this.state.participantsList.slice(0, index),
      ...this.state.participantsList.slice(
        index + 1,
        this.state.participantsList.length
      ),
    ];

    this.setState({
      participantsList: newParticipantList,
    });
    success("Participant Deleted Successfully",successNotification)
  };
  scrollToSection = (sectionId,flag) => {
    const section =sectionId;    
    if (section ){
      if(flag === "tabSwitch"){
        section.scrollIntoView({ behavior: 'auto'});
      }else{
        section.scrollIntoView({ behavior: 'auto',block: "end",inline:"center" });
      }
    }
  }
  render() {
    console.log("state", this.state);
    console.log("props", this.props);

    const UPLOAD_FORMAT = ["application/xls", "application/xlsx"];
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          eventID: this.state.fetchEventDetails.eventID,
          isActive: this.state.fetchEventDetails.isActive,
          eventName: this.state.fetchEventDetails.eventTitle,
          eventDescription: this.state.fetchEventDetails.eventDescription?this.state.fetchEventDetails.eventDescription:"",
          eventType: this.state.fetchEventDetails.eventTypeID,
          allEventTypes: this.props.getAllEventTypes,
          repeatEvent: this.state.fetchEventDetails.isRepeat,
          // cancelEvent: "",
          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          listOfMonth1: this.state.listOfMonth,
          listOfDay1: this.state.listOfDay,
          listOfHours: this.state.listOfHours,
          listOfMins: this.state.listOfMins,
          eventStartDate: this.state.fetchEventDetails.eventStartDate,
          eventEndDate: this.state.fetchEventDetails.eventEndDate,
          eventEndTime: this.state.fetchEventDetails.eventEndTime,
          eventStartTime: this.state.fetchEventDetails.eventStartTime,
          startHour: this.state.startHour,
          endHour: this.state.endHour,
          startMinutes: this.state.startMinutes,
          endMinutes: this.state.endMinutes,
          access: this.state.fetchEventDetails.eventAccess,
          mailResponseRequired: this.state.fetchEventDetails.isMailSend ? this.state.fetchEventDetails.isMailSend : "N",
          // sendNotification: this.state.fetchEventDetails.sendNotification ? this.state.fetchEventDetails.sendNotification : "",
          sendNotification: "",
          address:
            this.state.fetchEventDetails.eventAccess === "online"
              ? ""
              : this.state.fetchEventDetails.eventLocation,
          selectedCity:
            this.state.fetchEventDetails.eventAccess === "online"
              ? ""
              : this.state.fetchEventDetails.cityID,
          searchCityText: this.state.fetchEventDetails.cityName,
          subUrb:
            this.state.fetchEventDetails.eventAccess === "online"
              ? ""
              : parseInt(this.state.fetchEventDetails.suburbID),
          addressOnMap:
            this.state.fetchEventDetails.eventAccess === "online"
              ? null
              : this.state.fetchEventDetails.eventMap,
          accessLink:
            this.state.fetchEventDetails.eventAccess === "online"
              ? this.state.fetchEventDetails.eventLocation
              : "",
          cancelEvent:this.state.fetchEventDetails.cancelEvent?this.state.fetchEventDetails.cancelEvent:"" ,
          addParticipants: this.state.fetchEventDetails.addParticipantsType ? this.state.fetchEventDetails.addParticipantsType : "Participant",

          searchCandidateText: "",
          participants: [],
          uploadParticipants: [],
          isGuestAdded: false,
          documentTypeCheckForEvent: false,
          documentSizeCheckForEventCheck: false,
        }}
        validationSchema={Yup.object().shape({
          eventName: Yup.string()
            .required("You must specify a Event Name")
            .max(50, " Event Name should not  be greater than 50 characters"),
          eventDescription: Yup.string().max(
            100,
            "Description should not be greater then 100 characters"
          ),
          isActive: Yup.string().required("You must specify a Event Status"),
          cancelEvent: Yup.string().when("isActive", {
            is: (isActive) => (["0",0].includes(isActive)? true : false),
            then: Yup.string().required("You must specify a Cancel Event"),
            otherwise: Yup.string(),
          }),
          eventType: Yup.string().required("You must specify a eventType"),
          repeatEvent: Yup.string().required("You must specify a repeatEvent"),
          eventStartDate: Yup.string().required(
            "You must specify a Event Start Date"
          ),
          eventEndDate: Yup.string().when("repeatEvent", {
            is: (repeatEvent) => (repeatEvent === "Y" ? true : false),
            then: Yup.string().required("You must specify a Event End Date"),
            otherwise: Yup.string(),
          }),

          startHour: Yup.string().required("You must specify a startHour"),
          startMinutes: Yup.string().required(
            "You must specify a startMinutes"
          ),
          endHour: Yup.string().required("You must specify a endHour"),
          endMinutes: Yup.string().required("You must specify a endMinutes"),
          access: Yup.string().required("You must specify a access"),
          mailResponseRequired: Yup.string().required("You must specify a Mail Response Required"),
          sendNotification: Yup.string().required("You must specify a Send Notification to existing user"),
          accessLink: Yup.string().when("access", {
            is: (access) => (access === "online" ? true : false),
            then: Yup.string()
              .required("You must specify Event Meeting Link")
              .max(
                255,
                "Event Meeting link should not be greater than 255 characters"
              ),
            otherwise: Yup.string(),
          }),
          address: Yup.string().when("access", {
            is: (access) => (access === "offline" ? true : false),
            then: Yup.string()
              .required("You must specify a address")
              .max(100, " Address should not  be greater than 100 characters"),
            otherwise: Yup.string(),
          }),
          selectedCity: Yup.string().when("access", {
            is: (access) => (access === "offline" ? true : false),
            then: Yup.string().required("You must specify a city"),

            otherwise: Yup.string(),
          }),
          // subUrb: Yup.string().when("access", {
          //   is: (access) => (access === "offline" ? true : false),
          //   then: Yup.string().required("You must specify a subUrb"),
          //   otherwise: Yup.string(),
          // }),
          // addressOnMap: Yup.string().required("You must specify a "),
          addParticipants: Yup.string().required(
            "You must specify a addParticipants"
          ),
          guestName: Yup.string().when("participants", {
            is: (participants) => (participants === "Guest" ? true : false),
            then: Yup.string().when("isGuestAdded", {
              is: (isGuestAdded) => !isGuestAdded,
              then: Yup.string()
                .required("You must specify a Guest Name")
                .max(
                  255,
                  " Guest Name should not  be greater than 255 characters"
                )
                .matches(
                  // /^[a-zA-Z0-9_]*$/,
                  /^[a-zA-Z0-9]+[a-zA-Z0-9\s]*$/,
                  "Guest Name should be AlphaNumeric Only"
                ),
              otherwise: Yup.string(),
            }),
            otherwise: Yup.string(),
          }),
          guestEmail: Yup.string().when("participants", {
            is: (participants) => (participants === "Guest" ? true : false),
            then: Yup.string().when("isGuestAdded", {
              is: (isGuestAdded) => !isGuestAdded,
              then: Yup.string()
                // .email("Please Specify a valid Guest Email")
                .required("You must specify a Guest Email")
                .max(
                  255,
                  " Guest Email should not  be greater than 255 characters"
                )
                .email("Please Specify a valid Guest Email"),
                // .matches(
                //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                //   "Please Specify a valid Guest Email"
                // ),
              otherwise: Yup.string(),
            }),
            otherwise: Yup.string(),
          }),
          // uploadParticipants: Yup.string().when("addParticipants", {
          //   is: (addParticipants) =>
          //     addParticipants === "Import" ? true : false,
          //   then: Yup.object().required("You must Import/Upload Participants"),
          // .test(
          //   "fileSize",
          //   "Uploaded File is Too Big",
          //   (value) => !value || (value && value.size <= 2000000)
          // )
          // .test(
          //   "fileFormat",
          //   "Unsupported Format (valid format : XLS,XLSX)",
          //   (value) =>
          //     !value || (value && UPLOAD_FORMAT.includes(value?.type))
          // ),
          //   otherwise: Yup.string(),
          // }),
          // participants: [],
          // selectedCandidate: Yup.string().required("You must specify a Candidate "),
        })}
        onSubmit={(fields) => {
          let formattedStartDate =
            fields["eventStartDate"].split("-")[2] +
            "/" +
            fields["eventStartDate"].split("-")[1] +
            "/" +
            fields["eventStartDate"].split("-")[0] +
            " " +
            "00:00:00";
          let formattedEndDate;
          if (fields["repeatEvent"] === "Y") {
            formattedEndDate =
              fields["eventEndDate"].split("-")[2] +
              "/" +
              fields["eventEndDate"].split("-")[1] +
              "/" +
              fields["eventEndDate"].split("-")[0] +
              " " +
              "00:00:00";
          } else {
            formattedEndDate = formattedStartDate;
          }
          // console.log("formattedStartDate", formattedStartDate);
          // console.log("formattedEndDate", formattedEndDate);

          let fromDtConverted = moment(formattedStartDate, "DD.MM.YYYY");
          let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
          let eventCount = toDtConverted.diff(fromDtConverted, "days") + 1;

          if (toDtConverted.diff(fromDtConverted, "days") < 0) {
            this.setState({ loading: false });
            warning(
              '“Expiry Date” should be after the “Apply From Date”',
              warningNotification
            );
            return;
          }
          let formattedStartTime = "";

          if (
            (fields["startMinutes"] !== undefined &&
              fields["startMinutes"] !== "") ||
            (fields["startHour"] !== undefined && fields["startHour"] !== "")
          ) {
            let startHour =
              fields["startHour"].length === 1
                ? "0" + fields["startHour"]
                : fields["startHour"];

            let startMinutes =
              fields["startMinutes"].length === 1
                ? "0" + fields["startMinutes"]
                : fields["startMinutes"];
            formattedStartTime = startHour + ":" + startMinutes + ":" + "00";
          } else {
            formattedStartTime = "";
          }

          let formattedEndTime = "";

          if (
            (fields["endMinutes"] !== undefined &&
              fields["endMinutes"] !== "") ||
            (fields["endHour"] !== undefined && fields["endHour"] !== "")
          ) {
            let endHour =
              fields["endHour"].length === 1
                ? "0" + fields["endHour"]
                : fields["endHour"];
            let endMinutes =
              fields["endMinutes"].length === 1
                ? "0" + fields["endMinutes"]
                : fields["endMinutes"];
            formattedEndTime = endHour + ":" + endMinutes + ":" + "00";
          } else {
            formattedEndTime = "";
          }

          var startDate = new Date();
          startDate.setHours(fields["startHour"], fields["startMinutes"], 0, 0);
          var endDate = new Date();
          endDate.setHours(fields["endHour"], fields["endMinutes"], 0, 0);

          if (startDate > endDate) {
            warning(
              '“End Time” should be after the “Start Time”',
              warningNotification
            );
            return;
          }
          // let participantsListData = this.state.participantsList
          //   .map((data) => {
          //     let participantsListObject = {
          //       inviteeName: data.name,
          //       inviteeNature: data.nature,
          //       inviteePartyID: data.partyID,
          //       inviteeEmailID: data.emailID,
          //     };
          //     return participantsListObject;
          //   })
          //   .filter((participant) => {
          //     const email = participant.inviteeEmailID;
          //     if (emailSet.has(email)) {
          //       return false;
          //     } else {
          //       emailSet.add(email);
          //       return true;
          //     }
          //   });
            // .slice(1, this.state.participantsList.length);
           
            let participantsListData1 = this.state.participantsList
            .map((data) => ({
              inviteeName: data.name,
              inviteeNature: data.nature,
              inviteePartyID: data.partyID,
              inviteeEmailID: data.emailID,
            }));
         
          let participantsListData  = Array.from(new Set(participantsListData1.map(data => data.inviteeEmailID)))
          .map(email => participantsListData1.find(data => data.inviteeEmailID === email));
          
          let email1=localStorage.getItem("eMail");
          participantsListData=participantsListData.filter((item)=>item.inviteeEmailID!==email1)

          participantsListData = [...participantsListData];
          let attendeesEmailList = this.state.participantsList.map((data) => {
            let participantsListObject = {
              email: data.emailID,
            };
            return participantsListObject;
          });
          let email = localStorage.getItem("eMail");
          let partyID = parseInt(localStorage.getItem("userid-Token"));
          let data = {
            registrantEmailID:
              this.state.fetchEventDetails.registrantEmailID ||
              // Global_var.ADMIN_EMAIL,
              email,
            registrantName: "Self",
            registrantPartyID: partyID,
            // registrantName:this.state.fetchEventName.registrantName,
            // registrantPartyID:this.state.fetchEventDetails.registrantPartyID,
            eventID: fields.eventID ? fields.eventID : null,
            eventTitle: fields.eventName ? fields.eventName : "",
            eventDescription: fields.eventDescription
              ? fields.eventDescription
              : "",
            eventTypeID: fields.eventType ? parseInt(fields.eventType) : null,
            isRepeat: fields.repeatEvent ? fields.repeatEvent : null,
            isActive: fields.isActive,
            cancelEvent: fields.cancelEvent ? fields.cancelEvent : "",
            eventStartDate: formattedStartDate,
            eventEndDate: formattedEndDate,
            eventStartTime: formattedStartTime,
            eventEndTime: formattedEndTime,
            eventAccess: fields.access ? fields.access : "",
            eventLocation:
              fields.access === "online" ? fields.accessLink : fields.address,
            cityID: fields.access === "offline" ? fields.selectedCity : null,
            // null,
            suburbID: fields.access === "offline" ? fields.subUrb : null,
            //  null,
            eventMap:
              fields.access === "offline"
                ? fields.addressOnMap
                  ? fields.addressOnMap
                  : ""
                : // null
                  "",
            // null,
            parEventID: this.state.fetchEventDetails.parEventID,
            participantsList: fields.addParticipants === "All" ? [] : participantsListData,
            addParticipantsType:fields.addParticipants,
            isMailSend: fields.mailResponseRequired ? fields.mailResponseRequired : "",
            isMailToExistUser: fields.sendNotification ? fields.sendNotification : "",
          };
          console.log("data", JSON.stringify(data, null, 2));
          // alert(JSON.stringify(data, null, 2));
      // Google Sent Mail

      let data1 = {
        end: {
          dateTime:
            fields.repeatEvent === "Y"
              ? `${fields.eventEndDate}T${formattedEndTime}`
              : `${fields.eventStartDate}T${formattedEndTime}`,
          timeZone: "Asia/Kolkata",
        },
        start: {
          // dateTime: "2022-11-28T09:30:00",
          dateTime: `${fields.eventStartDate}T${formattedStartTime}`,
          timeZone: "Asia/Kolkata",
        },
        summary: fields.eventName ? fields.eventName : "",
        location:
          fields.access === "online" ? fields.accessLink : fields.address,
        description: fields.eventDescription ? fields.eventDescription : "",
        recurrence: [`RRULE:FREQ=DAILY;COUNT=${eventCount}`],
        attendees: attendeesEmailList,

        reminders: {
          useDefault: true,
          // overrides: [
          //   { method: "email", minutes: 24 * 60 },
          //   { method: "popup", minutes: 10 },
          // ],
        },
      };
        
      this.setState({loading:true,isSaveBtnDisabled: true})
            EventService.updateEventDetails(
              data,
              (cb) => {
                console.log("cb", cb);
                // console.log("cb.status", cb.status);
                if (cb.status === "success") {
                  this.setState({ loading: false });
                  success("Event Updated successfully!", successNotification);
                  this.props.history.push("/event-list");

                  this.addEventToCalander(data1);
                } else {
                  // console.log("cb.status", cb.status);
                  warning(cb.reasonText, {
                    ...warningNotification,
                    container: "top-center",
                  });
                  this.setState({ loading: false,isSaveBtnDisabled: false });
                }
              },
              (err) => console.log("Error", err)
            );
          }
        }
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
          ...formikProps
        }) => (
          <>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section className="dashboard-tab user-profile pt-50 mb-50">
              <Form onSubmit={handleSubmit} className="form-group">
                <div className="">
                  <div className="container">
                     {/* <div>
                      <pre>{JSON.stringify(errors, null, 2)}</pre>
                    </div>
                    <div>
                      <pre>{JSON.stringify(values, null, 2)}</pre>
                    </div>  */}

                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-130">
                        <div className="bg-box">
                          <section className="create-user" id="create-user">
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">Edit Event</h2>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Event ID{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="eventID"
                                        id="eventID"
                                        onChange={()=>{
                                          if(!this.state.isEdit)
                                          handleChange();
                                        }}
                                        onBlur={handleBlur}
                                        value={values.eventID}
                                        title={values.eventID}
                                        disabled={true}
                                        placeholder="Event ID"
                                        className={
                                          "form-control" +
                                          (errors.eventID && touched.eventID
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventID"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Event Status{" "}
                                        <span className="validation"> *</span>{" "}
                                      </label>
                                      <Field
                                        as="select"
                                        name="isActive"
                                        id="isActive"
                                        onChange={(e) =>
                                          setFieldValue(
                                            "isActive",
                                            parseInt(e.target.value)
                                          )
                                        }
                                        onBlur={handleBlur}
                                        value={values.isActive}
                                        title={
                                          values.isActive === 0
                                            ? "InActive"
                                            : "Active"
                                        }
                                        className={
                                          "form-control" +
                                          (errors.isActive && touched.isActive
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">-- Select --</option>
                                        <option
                                          disabled={this.state.disabledCancel}
                                          value="0"
                                        >
                                          Cancel
                                        </option>
                                        <option value="1">Active</option>
                                        <option disabled={true} value="2">
                                          Draft
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="isActive"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>

                                    {values.isActive === 0 && (
                                      <div class="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                        Cancel Recurring event
                                          <span className="validation"> *</span>
                                        </label>
                                        <div>
                                          <span
                                            className="mr-20"
                                            style={{
                                              display: "inline-flex",
                                              alignItems: "flex-end",
                                            }}
                                          >
                                            <Field
                                              type="radio"
                                              className={
                                                "form-control " +
                                                (errors.cancelEvent &&
                                                touched.cancelEvent
                                                  ? " is-invalid "
                                                  : " ") +
                                                "employement-radio-input mr-10"
                                              }
                                              name="cancelEvent"
                                              value="this"
                                              id="cancelEvent"
                                              onChange={handleChange}
                                              defaultChecked={
                                                values.cancelEvent === "this"
                                              }
                                            />{" "}
                                            This Event
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-flex",
                                              alignItems: "flex-end",
                                            }}
                                          >
                                            <Field
                                              type="radio"
                                              className={
                                                "form-control " +
                                                (errors.cancelEvent &&
                                                touched.cancelEvent
                                                  ? " is-invalid "
                                                  : " ") +
                                                "employement-radio-input mr-10"
                                              }
                                              name="cancelEvent"
                                              value="following"
                                              onChange={handleChange}
                                              id="cancelEvent"
                                              defaultChecked={
                                                values.cancelEvent ===
                                                "following"
                                              }
                                            />{" "}
                                            This And Following Events
                                          </span>
                                        </div>
                                        <ErrorMessage
                                          name="cancelEvent"
                                          className="validation"
                                          component="div"
                                        />
                                        {/* <div className="validation">
                                        {getErrorMessage(
                                          errors.employmentDetails,
                                          index,
                                          "isCurrentCompany"
                                        )}
                                      </div> */}
                                      </div>
                                    )}
                                  </div>

                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Event Name{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="eventName"
                                        id="eventName"
                                        maxLength={50}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.eventName}
                                        title={values.eventName}
                                        placeholder="Event Name"
                                        className={
                                          "form-control " +
                                          (errors.eventName && touched.eventName
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventName"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>Event Description </label>
                                      <Field
                                        type="text"
                                        name="eventDescription"
                                        id="eventDescription"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        maxLength={100}
                                        title={values.eventDescription}
                                        value={values.eventDescription}
                                        placeholder="Event Description"
                                        className={
                                          "form-control " +
                                          (errors.eventDescription &&
                                          touched.eventDescription
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventDescription"
                                        className="validation "
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Event Type{" "}
                                        <span className="validation"> *</span>
                                      </label>

                                      <Field
                                        as="select"
                                        name="eventType"
                                        id="eventType"
                                        onChange={handleChange}
                                        value={values.eventType}
                                        title={values.eventType}
                                        className={
                                          "form-control " +
                                          (errors.eventType && touched.eventType
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value={""}>-- Select --</option>

                                        {values.allEventTypes.map((data) => (
                                          <option value={data.eventTypeID}>
                                            {data.eventTypeName}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="eventType"
                                        className="validation "
                                        component="div"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Repeat Event
                                        <span className="validation"> *</span>
                                      </label>
                                      <div>
                                        <span
                                          className="mr-20"
                                          style={{
                                            display: "inline-flex",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          <Field
                                            type="radio"
                                            // onChange={() => {
                                            //   setFieldValue("repeatEvent", "N");
                                            //   setFieldValue("eventEndDate", "");
                                            // }}
                                            className={
                                              "form-control " +
                                              (errors.repeatEvent &&
                                              touched.repeatEvent
                                                ? " is-invalid "
                                                : " ") +
                                              "employement-radio-input mr-10"
                                            }
                                            name="repeatEvent"
                                            value="N"
                                            id="repeatEvent"
                                            defaultChecked={
                                              values.repeatEvent === "N"
                                            }
                                          />{" "}
                                          Once
                                        </span>
                                        <span
                                          style={{
                                            display: "inline-flex",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          <Field
                                            type="radio"
                                            className={
                                              "form-control " +
                                              (errors.repeatEvent &&
                                              touched.repeatEvent
                                                ? " is-invalid "
                                                : " ") +
                                              "employement-radio-input mr-10"
                                            }
                                            // onChange={() => {
                                            //   setFieldValue("repeatEvent", "Y");
                                            //   setFieldValue("eventEndDate", "");
                                            // }}
                                            name="repeatEvent"
                                            value="Y"
                                            id="repeatEvent"
                                            defaultChecked={
                                              values.repeatEvent === "Y"
                                            }
                                          />{" "}
                                          Repeatitive
                                        </span>
                                      </div>
                                      <ErrorMessage
                                        name="repeatEvent"
                                        className="validation "
                                        component="div"
                                      />
                                      {/* <div className="validation">
                                        {getErrorMessage(
                                          errors.employmentDetails,
                                          index,
                                          "isCurrentCompany"
                                        )}
                                      </div> */}
                                    </div>

                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Event Start Date{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="date"
                                        name="eventStartDate"
                                        id="eventStartDate"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.eventStartDate}
                                        title={values.eventStartDate}
                                        placeholder={values.eventStartDate}
                                        className={
                                          "form-control " +
                                          (errors.eventStartDate &&
                                          touched.eventStartDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventStartDate"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    {values.repeatEvent === "Y" && (
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                          Event End Date{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="date"
                                          name="eventEndDate"
                                          id="eventEndDate"
                                          // onChange={handleChange}
                                          onChange={(e)=>{
                                            if(values.repeatEvent==="Y"){

                                            }else{

                                              handleChange(e)
                                            }
                                          }}
                                          disabled={values.repeatEvent==="Y"}
                                          onBlur={handleBlur}
                                          value={values.eventEndDate}
                                          title={values.eventEndDate}
                                          placeholder="Event End Date"
                                          className={
                                            "form-control " +
                                            (errors.eventEndDate &&
                                            touched.eventEndDate
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="eventEndDate"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    )}

                               </div>
                                  <div className="row">
                                  <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Start Time{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 pl pr">
                                          <Field
                                            as="select"
                                            name="startHour"
                                            value={values.startHour}
                                            title={values.startHour}
                                            id="startHour"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.startHour =
                                                  e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.startHour &&
                                              touched.startHour
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">HH</option>
                                            {(values.listOfHours || []).map(
                                              (h, index) => (
                                                <option key={index} value={h}>
                                                  {" "}
                                                  {h}{" "}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="startHour"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 pl pr">
                                          <Field
                                            as="select"
                                            name="startMinutes"
                                            id="startMinutes"
                                            value={values.startMinutes}
                                            title={values.startMinutes}
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.startMinutes =
                                                  e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.startMinutes &&
                                              touched.startMinutes
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMins || []).map(
                                              (m, index) => (
                                                <option key={index} value={m}>
                                                  {" "}
                                                  {m}{" "}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="startMinutes"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        End Time{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 pl pr">
                                          <Field
                                            as="select"
                                            name="endHour"
                                            id="endHour"
                                            value={values.endHour}
                                            title={values.endHour}
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.endHour =
                                                  e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.endHour && touched.endHour
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">HH</option>
                                            {(values.listOfHours || []).map(
                                              (h, index) => (
                                                <option key={index} value={h}>
                                                  {" "}
                                                  {h}{" "}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="endHour"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 pl pr">
                                          <Field
                                            as="select"
                                            name="endMinutes"
                                            id="endMinutes"
                                            value={values.endMinutes}
                                            title={values.endMinutes}
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.endMinutes =
                                                  e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.endMinutes &&
                                              touched.endMinutes
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMins || []).map(
                                              (m, index) => (
                                                <option key={index} value={m}>
                                                  {" "}
                                                  {m}{" "}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="endMinutes"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>Mail response required ? <span className="validation"> *</span></label>
                                      <div>
                                        <span className="mr-20" style={{display: "inline-flex",alignItems: "flex-end"}}>
                                          <Field
                                            type="radio"
                                            className="employement-radio-input mr-10"
                                            name="mailResponseRequired"
                                            onChange={() =>
                                              setFieldValue("mailResponseRequired","Y")
                                            }
                                            value="Y"
                                            defaultChecked={
                                              values.mailResponseRequired === "Y"
                                            }
                                          />{" "}
                                          Yes
                                        </span>
                                        <span style={{display: "inline-flex",alignItems: "flex-end"}}>
                                          <Field
                                            type="radio"
                                            className="employement-radio-input mr-10"
                                            name="mailResponseRequired"
                                            value="N"
                                            onChange={() => 
                                              setFieldValue("mailResponseRequired", "N")
                                            }
                                            id="mailResponseRequired"
                                            defaultChecked={
                                              values.mailResponseRequired === "N"
                                            }
                                          />{" "}
                                          No
                                        </span>

                                      </div>
                                      <ErrorMessage
                                        name="mailResponseRequired"
                                        className="validation "
                                        component="div"
                                      />
                                    </div>
                          
                                </div>
                                  <div className="row">
                                  <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>Send notification to existing user ? <span className="validation"> *</span></label>
                                      <div>
                                        <span className="mr-20" style={{display: "inline-flex",alignItems: "flex-end"}}>
                                          <Field
                                            type="radio"
                                            className={
                                              "form-control " +
                                              (errors.sendNotification && touched.sendNotification
                                                ? " is-invalid "
                                                : " ") +
                                              "employement-radio-input mr-10"
                                            }
                                            name="sendNotification"
                                            id="sendNotification"
                                            onChange={() =>
                                              setFieldValue("sendNotification","Y")
                                            }
                                            value="Y"
                                          />{" "}
                                          Yes
                                        </span>
                                        <span style={{display: "inline-flex",alignItems: "flex-end"}}>
                                          <Field
                                            type="radio"
                                            className={
                                              "form-control " +
                                              (errors.sendNotification && touched.sendNotification
                                                ? " is-invalid "
                                                : " ") +
                                              "employement-radio-input mr-10"
                                            }
                                            name="sendNotification"
                                            id="sendNotification"
                                            value="N"
                                            onChange={() => 
                                              setFieldValue("sendNotification", "N")
                                            }
                                            id="sendNotification"
                                          />{" "}
                                          No
                                        </span>
                                      </div>
                                      <div>
                                      <span class="file-size-limit">No means send notification to newly added participants, if any.</span>
                                      </div>
                                      <ErrorMessage
                                        name="sendNotification"
                                        className="validation "
                                        component="div"
                                      />
                                    </div>
                           
                                 <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Access
                                        <span className="validation"> *</span>
                                      </label>
                                      <div>
                                        <span
                                          className="mr-20"
                                          style={{
                                            display: "inline-flex",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          <Field
                                            type="radio"
                                            className="employement-radio-input mr-10"
                                            name="access"
                                            // onChange={() => (
                                            //   setFieldValue(
                                            //     "access",
                                            //     "offline"
                                            //   ),
                                            //   setFieldValue("accessLink", "")
                                            // )}
                                            value="offline"
                                            // value={values.access}
                                            defaultChecked={
                                              values.access === "offline"
                                            }
                                          />{" "}
                                          Offline
                                        </span>
                                        <span
                                          style={{
                                            display: "inline-flex",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          <Field
                                            type="radio"
                                            className="employement-radio-input mr-10"
                                            name="access"
                                            value="online"
                                            //   onChange={(e)=>{setFieldValue("access","online");
                                            //   setFieldValue("addressOnMap","");
                                            //   setFieldValue("selectedCity","");
                                            //   setFieldValue("address","");
                                            //   setFieldValue("searchCityText","");
                                            //   setFieldValue("subUrb","");
                                            // }
                                            // }
                                            // value={values.access}
                                            id="access"
                                            defaultChecked={
                                              values.access === "online"
                                            }
                                          />{" "}
                                          Online
                                        </span>
                                      </div>
                                      <ErrorMessage
                                        name="access"
                                        className="validation "
                                        component="div"
                                      />
                                      {/* <div className="validation">
                                        {getErrorMessage(
                                          errors.employmentDetails,
                                          index,
                                          "isCurrentCompany"
                                        )}
                                      </div> */}
                                    </div>
                                    {values.access === "online" && (
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                          Link To Connect
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="accessLink"
                                          id="accessLink"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          maxLength={255}
                                          value={values.accessLink}
                                          title={values.accessLink}
                                          placeholder="Link To Connect"
                                          className={
                                            "form-control " +
                                            (errors.accessLink &&
                                            touched.accessLink
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="accessLink"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    )}
                                    {values.access === "offline" && (
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                          Address
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="address"
                                          id="address"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          maxLength={100}
                                          value={values.address}
                                          title={values.address}
                                          placeholder="Address"
                                          className={
                                            "form-control " +
                                            (errors.address && touched.address
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="address"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    )}
                                  </div>
                                    {values.access === "offline" && (
                                      <div className="row">
                                        <div className="col-md-4 col-sm-6 col-xs-12">
                                        <div className="mt-4 d-flex col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <label>
                                            City{" "}
                                            <span className="validation">
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div className="mt-4 col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <Field
                                            className="form-control"
                                            name="searchCityText"
                                            id="searchCityText"
                                            title={values.searchCityText}
                                            value={values.searchCityText}
                                            placeholder={
                                              values.searchCityText ||
                                              "Search..."
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                "searchCityText",
                                                e.target.value
                                              );
                                              if (e.target.value.length >= 3) {
                                                this.SearchCityForm(e);
                                              } else {
                                                this.setState({
                                                  searchCityResults: [],
                                                });
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            value={values.searchCityText}
                                          />
                                          <ErrorMessage
                                            name="selectedCity"
                                            className="validation"
                                            component="div"
                                          />
                                          {this.state.searchCityResults
                                            // .length
                                             ? (
                                            <div
                                              style={{
                                                borderLeft:
                                                  "0.3px solid #fbc583",
                                                borderRight:
                                                  "0.3px solid #fbc583",
                                                borderBottom:
                                                  "0.3px solid #fbc583",
                                              }}
                                              className="mt-4 d-flex flex-column col-md-12 col-lg-12 col-sm-12 col-xs-12 "
                                            >
                                              {(
                                                this.state.searchCityResults ||
                                                []
                                              ).map((data) => (
                                                <>
                                                  <div
                                                    value={data.cityID}
                                                    title={data.cityID}
                                                    id="selectedCity"
                                                    name="selectedCity"
                                                    style={{
                                                      marginTop: "3px",
                                                      borderBottom:
                                                        "0.1px solid  #fbc583",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => (
                                                      setFieldValue(
                                                        "searchCityText",
                                                        data.cityName
                                                      ),
                                                      setFieldValue(
                                                        "selectedCity",
                                                        data.cityID
                                                      ),
                                                      this.setState({
                                                        searchCityResults: [],
                                                      }),
                                                      this.handleSubUrbOnCity(
                                                        data.cityID
                                                      )
                                                    )}
                                                  >
                                                    {data.cityName}
                                                  </div>
                                                </>
                                              ))}
                                            </div>
                                          ) : (
                                            ""
                                          )}{" "}
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>Suburb </label>
                                        {/* <span className="validation"> *</span> */}
                                        <Field
                                          as="select"
                                          name="subUrb"
                                          id="subUrb"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          title={values.subUrb}
                                          value={values.subUrb}
                                          placeholder="subUrb"
                                          className={
                                            "form-control " +
                                            (errors.subUrb && touched.subUrb
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--SELECT--</option>
                                          {/* <option value="Udhna">Udhna</option>
                                          <option value="Bhatena">
                                            Bhatena
                                          </option>
                                          <option value="Kukatpally">
                                            Kukatpally
                                          </option>
                                          <option value="hitechcity">
                                            hitechcity
                                          </option> */}
                                          {(this.state.subUrbList || []).map(
                                            (data) => (
                                              <option value={data.suburbID}>
                                                {data.suburbName}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          name="subUrb"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>Address On Map </label>
                                        <textarea
                                          type="text"
                                          name="addressOnMap"
                                          id="addressOnMap"
                                          onChange={handleChange}
                                          maxLength={100}
                                          onBlur={handleBlur}
                                          value={values.addressOnMap}
                                          title={values.addressOnMap}
                                          placeholder="Address On Map"
                                          className={
                                            "form-control" +
                                            (errors.addressOnMap &&
                                            touched.addressOnMap
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="addressOnMap"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      </div>
                                    )}
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Add Participants
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="addParticipants"
                                        id="addParticipants"
                                        onChange={(e) => {
                                          handleChange(e)
                                          setFieldValue("participants", "")
                                          if(e.target.value==="All"){

                                            this.SearchCandidateForm1(
                                              
                                            );
                                          }
                                          }
                                     }
                                        onBlur={handleBlur}
                                        value={values.addParticipants}
                                        title={values.addParticipants}
                                        placeholder="Add Participants"
                                        className={
                                          "form-control " +
                                          (errors.addParticipants &&
                                          touched.addParticipants
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--SELECT--</option>
                                        <option value="Participant">Participant</option>
                                        <option value="Import">Import</option>
                                        <option value="All">All</option>
                                      </Field>
                                      <ErrorMessage
                                        name="addParticipants"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    {values.addParticipants === "Participant" && (
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>Participants </label>
                                        <span className="validation"> *</span>
                                        <Field
                                          as="select"
                                          name="participants"
                                          id="participants"
                                          onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue("guestName", "");
                                            setFieldValue("guestEmail", "");
                                            setFieldValue(
                                              "selectedCandidate",
                                              ""
                                            );
                                            setFieldValue(
                                              "searchCandidateText",
                                              ""
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          title={values.participants}
                                          value={values.participants}
                                          placeholder="participants"
                                          className={
                                            "form-control " +
                                            (errors.participants &&
                                            touched.participants
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--SELECT--</option>
                                          <option value="Candidate">
                                            Student/Candidate
                                          </option>
                                          <option value="Guest">Guest</option>
                                        </Field>
                                        <ErrorMessage
                                          name="participants"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                    )}
                                    {values.addParticipants === "Participant" &&
                                      values.participants === "Guest" && (
                                        <div className="col-md-4 col-sm-6 col-xs-12">
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                              <label>
                                                Add Guest{" "}
                                                <span className="validation">
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                className={
                                                  "form-control " +
                                                  (errors.guestName &&
                                                  touched.guestName
                                                    ? " is-invalid"
                                                    : "") +
                                                  "col-md-4 col-sm-4 col-xs-4"
                                                }
                                                type="text"
                                                name="guestName"
                                                id="guestName"
                                                maxLength={255}
                                                placeholder="Guest Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.guestName}
                                                title={values.guestName}
                                              />
                                              <ErrorMessage
                                                name="guestName"
                                                className="validation"
                                                component="div"
                                              />
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                className={
                                                  "form-control " +
                                                  (errors.guestEmail &&
                                                  touched.guestEmail
                                                    ? " is-invalid"
                                                    : "") +
                                                  "col-md-4 col-sm-4 col-xs-4"
                                                }
                                                type="text"
                                                name="guestEmail"
                                                id="guestEmail"
                                                placeholder="Guest Email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                maxLength={255}
                                                value={values.guestEmail}
                                                title={values.guestEmail}
                                              />

                                              <ErrorMessage
                                                name="guestEmail"
                                                className="validation"
                                                component="div"
                                              />
                                            </div>
                                            <div
                                              className=" col-md-2 col-sm-2 col-xs-2 col-lg-2 pl pr"
                                              style={{ paddingTop: "6px" }}
                                            >
                                              <a
                                                className="col-md-4 col-sm-4 col-xs-4 pl-2 pr"
                                                // href="#"
                                                onClick={() => {
                                                  let nameRegexCheck = /^[a-zA-Z0-9]+[a-zA-Z0-9\s]*$/;
                                                  let emailRegexCheck =  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                                  if(nameRegexCheck.test(values.guestName) === false){
                                                    return true;
                                                  }else if(emailRegexCheck.test(values.guestEmail) === false){
                                                    return true;
                                                  }else{
                                                  success("All Selected users till date have been added",successNotification)
                                                  this.handleGuestAdd(
                                                    values.guestName,
                                                    values.guestEmail
                                                  );
                                                  setFieldValue(
                                                    "guestEmail",
                                                    ""
                                                  );
                                                  setFieldValue(
                                                    "guestName",
                                                    ""
                                                  );
                                                  setFieldValue(
                                                    "isGuestAdded",
                                                    true
                                                  );
                                                  }
                                                }}
                                              >
                                                ADD
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {values.addParticipants === "Participant" &&
                                      values.participants === "Candidate" && (
                                        <div className="col-md-4 col-sm-6 col-xs-12">
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            <label>
                                              Search for Participants{" "}
                                              <span className="validation">
                                                {" "}
                                                *
                                              </span>
                                            </label>
                                          </div>

                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-10 col-sm-10 col-xs-10 pl pr">
                                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                                <Field
                                                  className="form-control"
                                                  name="searchCandidateText"
                                                  id="searchCandidateText"
                                                  placeholder="Search . . . . ."
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      "searchCandidateText",
                                                      e.target.value
                                                    );
                                                    if (
                                                      e.target.value.length >= 3
                                                    ) {
                                                      this.SearchCandidateForm(
                                                        e,
                                                        setFieldValue
                                                      );
                                                    } else {
                                                      this.setState({
                                                        searchCandidateResults: [],
                                                      });
                                                    }
                                                  }}
                                                  onBlur={handleBlur}
                                                  value={
                                                    values.searchCandidateText
                                                  }
                                                />

                                                <ErrorMessage
                                                  name="searchCandidateText"
                                                  className="validation"
                                                  component="div"
                                                />
                                              </div>
                                              {this.state
                                                .searchCandidateResults && (
                                                <div
                                                  style={{
                                                    borderLeft:
                                                      "0.3px solid #fbc583",
                                                    borderRight:
                                                      "0.3px solid #fbc583",
                                                    borderBottom:
                                                      "0.3px solid #fbc583",
                                                  }}
                                                  className="mt-4 d-flex flex-column col-md-12 col-lg-12 col-sm-12 col-xs-12"
                                                >
                                                  {(
                                                    this.state
                                                      .searchCandidateResults ||
                                                    []
                                                  ).map((data) => (
                                                    <>
                                                      <div
                                                        style={{
                                                          marginTop: "3px",
                                                          borderBottom:
                                                            "0.1px solid  #fbc583",
                                                          cursor: "pointer",
                                                        }}
                                                        title={data}
                                                        onClick={() => {
                                                          this.setState({
                                                            selectedCandidate: data,
                                                            searchCandidateResults: [],
                                                          });
                                                          setFieldValue(
                                                            "searchCandidateText",
                                                            data.name
                                                          );
                                                        }}
                                                      >
                                                        {data.name}
                                                      </div>
                                                    </>
                                                  ))}
                                                </div>
                                              )}
                                            </div>
                                            <div
                                              className=" col-md-2 col-sm-2 col-xs-2 pl pr"
                                              style={{ paddingTop: "6px" }}
                                            >
                                              <a
                                                className="col-md-4 col-sm-4 col-xs-4 pl-2 pr"
                                                // href="#"
                                                onClick={(data) => {
                                                  if(!["",null,undefined].includes(this.state.searchCandidateResults) 
                                                  && Object.entries(this.state.selectedCandidate).length !== 0
                                                  ){
                                                  success("All Selected users till date have been added",successNotification)
                                                  this.handleParticipantAdd(
                                                    this.state.selectedCandidate
                                                  );
                                                  setFieldValue(
                                                    "searchCandidateText",
                                                    ""
                                                  );
                                                  }else{
                                                    warning("Kindly select Participant",warningNotification)
                                                  }
                                                }}
                                              >
                                                ADD
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>

                                  {values.addParticipants === "Import" && (
                                    <div
                                      style={{ marginTop: "50px" }}
                                      className="row"
                                    >
                                      <div className="row">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <section>
                                            <div className="bg-box bulk-box">
                                              <div className="row">
                                                <div className="col-md-5 col-sm-5 col-xs-12">
                                                  <div className="scr-board scr_bulk">
                                                    <ExcelFile
                                                      element={
                                                        <h4 className="bulk_head">
                                                          Download Excel
                                                          Template{" "}
                                                          <i
                                                            className="fa fa-download"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </h4>
                                                      }
                                                      filename="Download Template"
                                                    >
                                                      <ExcelSheet
                                                        data={dataSet1}
                                                        // data={
                                                        //   this.state
                                                        //     .excelDataSet
                                                        // }
                                                        name="Employees"
                                                      >
                                                        <ExcelColumn
                                                          label="name"
                                                          value="name"
                                                        />
                                                        <ExcelColumn
                                                          label="emailID"
                                                          value="emailID"
                                                        />
                                                        <ExcelColumn
                                                          label="nature"
                                                          value="nature"
                                                        />
                                                      </ExcelSheet>
                                                    </ExcelFile>
                                                    <div className="lock-img">
                                                      <i
                                                        className="fa fa-upload"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </div>
                                                    <div className=" bottom-line"></div>
                                                    <div>
                                                      <h2 className="color_magento">
                                                        BULK UPLOAD
                                                      </h2>
                                                    </div>

                                                    <div className="scr-form">
                                                      {this.state
                                                        .isFileUploaded ? (
                                                        <div>
                                                          <p className="text-center color_gray text-wrap-bulk-upload">
                                                            File Selected.{" "}
                                                            {
                                                              this.state.file
                                                                .name
                                                            }
                                                            {/* File Name: {this.state.file.name} */}
                                                            <br />
                                                            {/* Please Upload. */}
                                                          </p>
                                                          <span className="icon btn-file btn-file3">
                                                            <i
                                                              className="fa fa-paperclip"
                                                              aria-hidden="true"
                                                            ></i>
                                                            <input
                                                              type="file"
                                                              name="uploadParticipants"
                                                              value={
                                                                values.uploadParticipants
                                                              }
                                                              accept={SheetJSFT}
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                            />
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          <br />
                                                          <span className="icon btn-file btn-file1">
                                                            <p className="color_red">
                                                              <button className="browse-text">
                                                                Select File To
                                                                Upload
                                                              </button>
                                                              <input
                                                                value={
                                                                  values.uploadParticipants
                                                                }
                                                                type="file"
                                                                name="uploadParticipants"
                                                                accept={
                                                                  SheetJSFT
                                                                }
                                                                onChange={(e) =>
                                                                  this.handleChange(
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                            </p>
                                                          </span>

                                                          <span className="icon btn-file btn-file2">
                                                            <i
                                                              className="fa fa-paperclip"
                                                              aria-hidden="true"
                                                            ></i>
                                                            <input
                                                              type="file"
                                                              name="file"
                                                              accept={SheetJSFT}
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                            />
                                                          </span>
                                                        </div>
                                                      )}
                                                      <div>
                                                        <br />
                                                        {this.state
                                                          .checkDocumentType
                                                          ? this.handleWarning(
                                                              "Invalid File Format:Supported Only .xls .xslx"
                                                            )
                                                          : ""}
                                                        {this.state
                                                          .checkDocumentSize
                                                          ? this.handleWarning(
                                                              "File Size should not be Greater Than 2 MB"
                                                            )
                                                          : ""}
                                                      </div>
                                                    </div>
                                                    <div className="">
                                                      <span class="file-size-limit-bulkupload">
                                                        Supported Formats: .xls,
                                                        .xlsx only. File Size:
                                                        Max 2MB <br />
                                                      </span>
                                                    </div>
                                                    {this.state
                                                      .showUploadButton ? (
                                                      <div
                                                        style={{
                                                          width: "120px",
                                                        }}
                                                        onClick={
                                                          this.handleFile
                                                        }
                                                        className="btn-1"
                                                      >
                                                        Upload
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-7 col-sm-7 col-xs-12">
                                                  <div className="table-header">
                                                    <h3>
                                                      <div class="row">
                                                        <div class="col-md-6 col-sm-6 col-xs-9">
                                                          <span class="left-icon">
                                                            <i
                                                              class="fa fa-list"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </span>{" "}
                                                          Bulk Upload Summary
                                                        </div>
                                                        <div class="col-md-6 col-sm-6 col-xs-3">
                                                          <span class="right-icon">
                                                            <a
                                                              title=""
                                                              // href="#"
                                                            >
                                                              <i
                                                                className="fa fa-info"
                                                                aria-hidden="true"
                                                              ></i>
                                                            </a>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </h3>
                                                  </div>
                                                  <div className="mr-20 ml-20">
                                                    <div className="table-responsive">
                                                      <table className="user-table bulkupload-tab">
                                                        <tr>
                                                          <td>
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                                marginTop:
                                                                  "15px",
                                                              }}
                                                            >
                                                              Total Records
                                                            </p>
                                                          </td>
                                                          <td>
                                                            {" "}
                                                            <b>
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "28px",
                                                                  marginTop:
                                                                    "15px",
                                                                }}
                                                                className="records"
                                                              >
                                                                {this.state
                                                                  .totalRecords ||
                                                                  0}
                                                              </span>
                                                            </b>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                                marginTop:
                                                                  "15px",
                                                              }}
                                                            >
                                                              Successfully
                                                              Uploaded{" "}
                                                            </p>
                                                          </td>
                                                          <td>
                                                            {" "}
                                                            <b>
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "28px",
                                                                  marginTop:
                                                                    "15px",
                                                                }}
                                                                className="success"
                                                              >
                                                                {this.state
                                                                  .successfullyUploaded ||
                                                                  0}
                                                              </span>{" "}
                                                            </b>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                                marginTop:
                                                                  "15px",
                                                              }}
                                                            >
                                                              Fail to Upload{" "}
                                                            </p>
                                                          </td>
                                                          <td>
                                                            <b>
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "28px",
                                                                  marginTop:
                                                                    "15px",
                                                                }}
                                                                className="fail"
                                                              >
                                                                {this.state
                                                                  .faildRecords ||
                                                                  0}
                                                              </span>
                                                            </b>
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                      {this.state.hideUI ? (
                                        <div className="row">
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            <div className="table-header">
                                              <h3>
                                                <div className="row">
                                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                    <span className="left-icon">
                                                      <i
                                                        className="fa fa-question-circle-o fa-lg"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </span>{" "}
                                                    Failed Records Details
                                                  </div>
                                                </div>
                                              </h3>
                                            </div>
                                            {this.state.invalidData.length >
                                            0 ? (
                                              <div className="bg-box">
                                                <div className="table-responsive">
                                                  <ReactTable
                                                    data={
                                                      this.state.invalidData
                                                    }
                                                    className="-striped -highlight"
                                                    filterable
                                                    defaultFilterMethod={
                                                      this.filterCaseInsensitive
                                                    }
                                                    columns={[
                                                      {
                                                        columns: [
                                                          {
                                                            Header: "Name",
                                                            id: "name",
                                                            accessor: (d) =>
                                                              d.name,
                                                          },
                                                          {
                                                            Header: "Email ID",
                                                            id: "emailID",
                                                            accessor: (d) =>
                                                              d.emailID
                                                                ? d.emailID
                                                                : "",
                                                          },
                                                          {
                                                            Header: "Nature",
                                                            id: "nature",
                                                            accessor: (d) =>
                                                              d.nature,
                                                          },

                                                          {
                                                            Header:
                                                              "Failure Reason",
                                                            id: "reason",
                                                            accessor: (d) =>
                                                              d.errorCode,
                                                            style: {
                                                              "white-space":
                                                                "unset",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    ]}
                                                    defaultPageSize={5}
                                                    style={{
                                                      width: "100%",
                                                      height: "500px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.participantsList || []}
                            // filtered={this.state.filtered}
                            // onFilteredChange={(filtered) => {
                            //   this.setState({
                            //     filtered: filtered,
                            //   });
                            // }}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={this.filterCaseInsensitive}
                            ref={(refReactTable) => {
                              this.refReactTable = refReactTable;
                            }}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Sr No",
                                    id: "name",
                                    filterable: false,
                                    sortable: false,
                                    Cell: (props) => (
                                      <span style={{textAlign:"center"}}title={props.index+1}>{props.index+1}</span>
                                    ),
                                  },
                                  {
                                    Header: "Name",
                                    id: "name",
                                    filterable: false,
                                    sortable: false,
                                    accessor: (d) => d.name,
                                    // Cell: (row) => (
                                    //   <span title={row.value}>{row.value}</span>
                                    // ),
                                    Cell:(props)=>{
                                                                return(
                                                                <span>{props.original.name}-{props.original.emailID}</span>
                                                                )
                                                              }
                                  },
                                  // {
                                  //   Header: "EMail ",
                                  //   id: "emailID",
                                  //   accessor: (d) => d.emailID,
                                  //   Cell: (row) => (
                                  //     <span title={row.value}>{row.value}</span>
                                  //   ),
                                  // },

                                  {
                                    Header: "Nature",
                                    id: "nature",
                                    filterable: false,
                                    sortable: false,
                                    accessor: (d) =>
                                      d.nature ? d.nature : "----",
                                    Cell: (row) => (
                                      <span title={row.value}>{["candidate","Candidate"].includes(row.value) ? "Candidate" : ["guest","Guest"].includes(row.value) ? "Guest" : "---"}</span>
                                    ),
                                  },
                                  {
                                    Header: "Actions",
                                    id: "action",
                                    filterable: false,
                                    sortable: false,
                                    Cell: (props) => {
                                      return (
                                        <a
                                        onClick={() =>
                                          this.handleDelete(props.index)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                      );
                                    },
                                  },
                                ],
                              },
                            ]}
                            defaultPageSize={10}
                            // style={{
                            //   width: "100%",
                            //   maxHeight: "500px",
                            // }}
                          />
                        </div>
                                        
                            {/* <div className="modal-body">
                              <div className="mb-10">
                                <h2 className="sub-header">Invitees</h2>
                              </div>
                              <table className="table table-bordered table-hover table-striped">
                                <thead>
                                  <tr className="discount-table-heading">
                                    <th>Sr No.</th>
                                    <th>Name</th>
                                    <th>Nature</th>
                                    <th>Remove</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(this.state.participantsList || []).map(
                                    (data, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {data.name} - {data.emailID}{" "}
                                        </td>
                                        <td>
                                          {data.nature === "candidate" ||
                                          data.nature === "Candidate"
                                            ? "Candidate"
                                            : data.nature === "guest" ||
                                              data.nature === "Guest"
                                            ? "Guest"
                                            : "---"}
                                        </td>
                                        <td>
                                          <a
                                            onClick={() =>
                                              this.handleDelete(index)
                                            }
                                          >
                                            <i
                                              className="fa fa-trash-o"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                             */}
                          </section>

                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                            <button
                              name="cancelForm"
                              type="button"
                              className="btn-4 pull-right"
                              onClick={this.handleCancel}
                            >
                              Cancel
                            </button>

                            <button
                              name="Save"
                              type="submit"
                              className="btn-4 mr-20 pull-right"
                              onClick={async () => {
                                const curErrors = await formikProps.validateForm();
                                const curErrorsKeys = Object.keys(curErrors);
                                if (curErrorsKeys.length) {
                                  const el = document.getElementById(curErrorsKeys[0]);
                                  if (el){
                                    this.scrollToSection(el);
                                    el.focus();
                                    setFieldTouched(curErrorsKeys[0], true);
                                  }
                                }
                              }}
                              disabled={this.state.isSaveBtnDisabled}
                              style={{
                                opacity: this.state.isSaveBtnDisabled ? 0.5 : 1 ,
                                cursor: this.state.isSaveBtnDisabled ? "not-allowed" : "pointer" 
                              }}

                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </section>
          </>
        )}
      />
    );
  }
}
