// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
// import RestDataSource from "../../services/restdatasource";
import AddRole from "./add-role";
import EditRole from "./edit-role";
// import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { success, successNotification } from "../notification/notifications";
import { LoginService } from "../../services/login.service";
import { inActiveRoles } from "../../services/fetchRoles";
// import * as moment from "moment";
import { ExcelService } from "../Excel/excel";
// import Modal from 'react-responsive-modal';
// import { fetchRoles } from "../../services/fetchRoles";
// import { connect } from "react-redux";
// import { getRoles, getRolesPending } from "../../reducer/roleReducer";
// import { bindActionCreators } from "redux";


// const mapStateToProps = state => ({
//   roles: getRoles(state.roleReducer),
//   pending: getRolesPending(state.roleReducer),
// });
// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       fetchRoles: fetchRoles,
//     },
//     dispatch
//   );
// const connectFunction = connect(mapStateToProps, mapDispatchToProps);
// const ViewRoles = connectFunction(

class ViewRoles extends Component {
  constructor(props) {
    super(props);
    //console.log("role", this.props.alertNotificationEvents)
    //console.log("roles", this.props.notificationEvents)

    this.state = {
      roleId: "",
      roleName: "",
      description: "",
      isActive: "",
      roles: this.props.roles,
    };
  }
  exportToCSV = () => {
    let modifiedData = [];
    let data = this.state.roles;
    data.forEach(element => {

      var data1 = {
        roleId: element.roleId,
        roleName: element.roleName,
        activateDate: element.activateDate.split(' ')[0],
        expiryDate: element.expiryDate.split(' ')[0],
        suspendStartDate: element.suspendStartDate.split(' ')[0],
        suspendEndDate: element.suspendEndDate.split(' ')[0],
        createdDateTime: element.createdDateTime,
        lastModifiedDateTime: element.lastModifiedDateTime,
        isActive: element.isActive === 0 ? 'In-Active' : 'Active',
        description: element.description
      };
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'FC-RoleList-');

  };

  componentDidMount() {
    //const { fetchRoles } = this.props;

    // let isReload = localStorage.getItem("reload");
    // if (isReload === "true") {
    //   window.location.reload();
    //   localStorage.setItem("reload", "false");
    // } else {
    //   localStorage.setItem("reload", "false");
    // }
  }
  handleStatus = (event, values) => {
    this.setState({
      roleId: values.roleId,
      roleName: values.roleName,
      activateDate: values.activateDate,
      expiryDate: values.expiryDate,
      suspendStartDate: values.suspendStartDate,
      suspendEndDate: values.suspendEndDate,
      createdDateTime: values.createdDateTime,
      lastModifiedDateTime: values.lastModifiedDateTime,
      description: values.description,
      isActive: values.isActive === 1 ? 0 : 1
    })

  }
  handlesub = () => {

    let data = {
      roleId: this.state.roleId,
      roleName: this.state.roleName,
      activateDate: this.state.activateDate,
      expiryDate: this.state.expiryDate,
      suspendStartDate: this.state.suspendStartDate,
      suspendEndDate: this.state.suspendEndDate,
      createdDateTime: this.state.createdDateTime,
      lastModifiedDateTime: this.state.lastModifiedDateTime,
      description: this.state.description,
      isActive: this.state.isActive
    }

    LoginService.TokenUser(res => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);

        inActiveRoles(data, (res) => {
          //console.log("data", res)

          if (res.status === "success") {
            success("Status changed successfully", successNotification);
            let params = "limit=-1"
            this.props.fetchRoles(params);
            //this.setState({ roles : roles});
            // window.location.reload();
          }
        });
        // });
      }
    });
  }

  handleClick = value => {

    this.setState({
      roleId: value.roleId,
      activateDate: value.activateDate,
      expiryDate: value.expiryDate,
      suspendStartDate: value.suspendStartDate,
      suspendEndDate: value.suspendEndDate,
      roleName: value.roleName,
      description: value.description,
      isActive: value.isActive === "1" ? 1 : 0
    });
    //console.log("State : ", this.state);
  };
  filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

  render() {
    const { } = this.props;

    return (
      <React.Fragment>
        <section class="dashboard-tab user-tab pt-50 mb-50">
          <div class="padding-rl-50">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="table-header">
                    <h3>
                      <div class="row">
                        <div class="col-md-8 col-sm-6 col-xs-8">
                          <span class="left-icon">
                            <i class="fa fa-list" aria-hidden="true"></i>
                          </span>{" "}
                          Role List
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-4">
                          {/* <span class="right-icon">
                            <a title="Export" href="">
                              <i
                                onClick={() => this.exportToCSV()}
                                class="fa fa-file-excel-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span> */}

                          <span class="right-icon">
                            <a title="Add Role"
                              href="#"
                              data-toggle="modal"
                              data-target="#add-role"
                              data-backdrop="static" data-keyboard="true"
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div class="bg-box">
                    <div class="table-responsive">
                      <ReactTable
                        minRows={2}
                        data={this.props.roles}
                        className="-striped -highlight"
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        columns={[
                          {
                            columns: [
                              {
                                Header: "Role Name",
                                id: "role_name",
                                accessor: d => d.roleName
                              },
                              {
                                Header: "Description",
                                id: "description",
                                accessor: d => d.description
                              },
                              {
                                Header: "Created Date",
                                id: "createdDateTime",
                                accessor: d => d.createdDateTime ? d.createdDateTime.split(' ')[0] : ''
                              },
                              {
                                Header: "Expiry Date",
                                id: "expiryDate",
                                accessor: d => d.expiryDate ? d.expiryDate.split(' ')[0] : ''
                              },

                              {
                                Header: "Status",
                                // data={this.props.roles},
                                id: "isActive",
                                sortable: false,
                                filterable: false,
                                Cell: props => {
                                  return (
                                    <Toggle
                                      href=""
                                      class="mar-left"
                                      data-toggle="modal"
                                      data-target="#roadmap"
                                      data-backdrop="static"
                                      data-keyboard="false"

                                      icons={false}
                                      defaultChecked={
                                        parseInt(props.original.isActive) === 1
                                          ? true
                                          : false
                                      }
                                      onChange={e =>
                                        this.handleStatus(e, props.original)
                                      }
                                    />
                                  );
                                }
                              },
                              {
                                Header: "Actions",
                                id: "action",
                                sortable: false,
                                filterable: false,
                                Cell: props => {

                                  return (
                                    <div>

                                      <a
                                        href="#"
                                        class="mar-left"
                                        data-toggle="modal"
                                        data-target="#edit-role"
                                        data-backdrop="static"
                                        data-keyboard="true"
                                        title="Edit Role"
                                        // <button type="submit" class="btn-1 pull-right"
                                        // data-dismiss="modal" name="Submit" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#roadmap">Enroll Now</button>

                                        onClick={() =>
                                          this.handleClick(props.original)
                                        }
                                      >
                                        {props.original.isActive === 0 ? <i
                                          class="fa fa-edit fa-2x"
                                          aria-hidden="true"
                                        ></i> : <i
                                          class="fa fa-edit fa-2x"
                                          aria-hidden="true"
                                        ></i>}

                                      </a>
                                    </div>
                                  )
                                }
                              },

                            ]
                          }
                        ]}
                        defaultPageSize={10}
                        style={{
                          width: "100%",
                          maxHeight: "500px"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="modal fade" id="roadmap" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <button
                type="reset"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <section class="">
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="reset">
                        <p className="roadmap-text text-center">Do you want to {this.state.isActive === 0 ? "In-Active" : "Active"} the role? </p>
                        <p className="roadmap-text text-center">Please note, the users associated to this role will {this.state.isActive === 0 ? "not" : ''}  able to access system. </p>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <a href="#"
                            data-keyboard="false" data-toggle="modal" data-backdrop="static" data-target="#feat-Reg" >
                            <button type="submit" class="btn-5 mt-30 mr-20 ml-20"
                              data-dismiss="modal" name="Submit" onClick={() => this.handlesub()}>
                              YES
                            </button>
                            <button type="submit" class="btn-5 mt-30 mr-20 ml-20"
                              data-dismiss="modal">
                              NO
                            </button>
                          </a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>


        <AddRole
          addRole={this.props.addRole}
          fetchRoles={this.props.fetchRoles}
          {...this.props}
        />
        <EditRole
          role={this.state}
          editRole={this.props.editRole}
          fetchRoles={this.props.fetchRoles}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
//);
export default ViewRoles;
