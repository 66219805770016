// Library Imports
import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Formik, Field } from "formik";
// Service Imports
import { StudentAssessmentService } from "../../services/studentAssessment.service";
// Other Imports
import {
  warning,
  warningNotification,
  success,
  successNotification,
} from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

// const myEventsList = {} //empty object for now

class MyCalander extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cal_events1: [],
      cal_events: [],
      eventMonth: "",
      eventYear: "",
      listOfYear: [],
      listOfMonth: [],
      searchEventText: "",
      storeToolbar:"",
      selectedDate: new Date(),
    };
  }
  convertDate = (date) => {
    return moment.utc(date).toDate();
  };

  componentWillMount() {
    // let self = this;
    let appointments = this.props.props;
    console.log(" appointments  ", appointments);

    let events = [];

    if (appointments !== null) {
      for (let i = 0; i < appointments.length; i++) {
        let evnetStartDate = appointments[i].eventStartDate;
        // let evnetStartDate = appointments[i].eventStartTime;
        let appStartDate = evnetStartDate.split(" ");
        let evnetStartDate1 = appStartDate[0].split("/");
        // let evnetStartDate2 =
        //   appStartDate[1] === undefined
        //     ? "8:25:30"
        //     : appStartDate[1].split(":");

        let evnetEndDate = appointments[i].eventEndDate;

        // let evnetEndDate = appointments[i].eventEndTime;

        let appEndDate = evnetEndDate.split(" ");
        let evnetEndDate1 = appEndDate[0].split("/");
        // let evnetEndDate2 =
        //   appEndDate[1] === undefined ? "12:25:30" : appEndDate[1].split(":");

        let evnetStartTime = appointments[i].eventStartTime.split(":");
        let evnetEndTime = appointments[i].eventEndTime.split(":");

        console.log("evnetStartTime", evnetStartTime, evnetEndTime);

        // let eventTime = appointments[i].eventTime;
        let ss = false;
        let evtStartDate = appointments[i].eventStartDate.split(" ");
        let evtStartDate1 = evtStartDate[0];
        console.log('evtStartDate1',evtStartDate1);
        let fromDtConverted = moment(evtStartDate1, "DD.MM.YYYY");
        let todayConverted = moment(new Date().toLocaleDateString(), "DD.MM.YYYY"); 
        
        //! for btn hide
        // if (todayConverted.diff(fromDtConverted, 'days') < 0) {
        // if(stageTimeHour > todayDateHour){
        // if(stageTimeMinute > todayDateMinute){
        // comment the disabled in 3 btns
        //! for disabled btn
        if (todayConverted.diff(fromDtConverted, 'days') > 0) {
          ss = true;
        }
        if (todayConverted.diff(fromDtConverted, 'days') === 0) {
          let stageTime1 = appointments[i].eventStartTime.split(":");
          let stageTimeHour = parseInt(stageTime1[0]);
          let stageTimeMinute = parseInt(stageTime1[1]);
          let todayDateHour = new Date().getHours();
          let todayDateMinute = new Date().getMinutes();
          if(stageTimeHour < todayDateHour){
            ss = true
          }else if(todayDateHour === stageTimeHour){
            if(stageTimeMinute < todayDateMinute){
              ss = true
            }
          }
        }

        let Event = {
          id: i,
          eventTitle: appointments[i].eventTitle,

          title: (
            <div>
              <div title={`${evtStartDate1} | ${appointments[i].eventStartTime.slice(0,5)}-${appointments[i].eventEndTime.slice(0,5)} | ${appointments[i].eventTitle}`}>
              <p>
                {appointments[i].eventTitle}
                {appointments[i].eventDescription
                  ? ` - ${appointments[i].eventDescription}`
                  : "---"}
              </p>
              <p>{appointments[i].eventTypeName}</p>
              {/* <p>{appointments[i].eventTime}</p> */}
              {appointments[i].eventAccess === "offline" ? 
              <p>
                {appointments[i].eventLocation
                  ? appointments[i].eventLocation
                  : "---"}
              </p>
              : appointments[i].eventAccess === "online" ? 
              <a href={appointments[i].eventLocation ? appointments[i].eventLocation : "#"} target="_blank" rel="noopener noreferrer">
                {appointments[i].eventLocation ? appointments[i].eventLocation : "---"}
              </a>
              : "---"}
              {/* <p>Count of Participants : {appointments[i].cntParticipants}</p> */}
              </div>
              <div className="btn-group">
                <button
                  className="btn yes-btn"
                  title="Yes"
                  disabled={ss}
                  id={`btn-yes-${appointments[i].eventID}`}
                  onClick={() =>
                    this.handleSubmitRsvpResponse(
                      "yes",
                      appointments[i].eventID
                    )
                  }
                >
                  Yes
                </button>
                <button
                  className="btn no-btn"
                  title="No"
                  disabled={ss}
                  id={`btn-no-${appointments[i].eventID}`}
                  onClick={() =>
                    this.handleSubmitRsvpResponse("no", appointments[i].eventID)
                  }
                >
                  No
                </button>
                <button
                  className="btn maybe-btn"
                  title="Maybe"
                  disabled={ss}
                  id={`btn-maybe-${appointments[i].eventID}`}
                  onClick={() =>
                    this.handleSubmitRsvpResponse(
                      "maybe",
                      appointments[i].eventID
                    )
                  }
                >
                  Maybe
                </button>
              </div>
            </div>
          ),
          // eventTime: appointments[i].eventTime,
          // eventTime: eventTime,

          // start: new Date(
          //   evnetStartDate1[2], // Year
          //   evnetStartDate1[1] - 1, // month - 1
          //   evnetStartDate1[0], // Date
          //   evnetStartDate2[0], // hour
          //   evnetStartDate2[1], //min
          //   evnetStartDate2[2] //sec
          // ),
          // end: new Date(
          //   evnetEndDate1[2],
          //   evnetEndDate1[1] - 1,
          //   evnetEndDate1[0],
          //   evnetEndDate2[0],
          //   evnetEndDate2[1],
          //   evnetEndDate2[2]
          // ),

          start: new Date(
            evnetStartDate1[2], // Year
            parseInt(evnetStartDate1[1] - 1), // month - 1
            parseInt(evnetStartDate1[0]), // Date
            parseInt(evnetStartTime[0]), // hour
            parseInt(evnetStartTime[1]), //min
            parseInt(evnetStartTime[2]) //sec
          ),
          end: new Date(
            evnetEndDate1[2],
            parseInt(evnetEndDate1[1] - 1),
            parseInt(evnetEndDate1[0]),
            parseInt(evnetEndTime[0]),
            parseInt(evnetEndTime[1]),
            parseInt(evnetEndTime[2])
          ),

          // start: moment({
          //   hours: evnetStartTime[0],
          //   minutes: evnetStartTime[1],
          //   seconds: evnetStartTime[2],
          // }).toDate(),
          // end: moment({
          //   hours: evnetEndTime[0],
          //   minutes: evnetEndTime[1],
          //   seconds: evnetEndTime[2],
          // }).toDate(),

          allDay: false,

          desc: appointments[i].eventDescription
            ? appointments[i].eventDescription
            : "---",
          eventYear: evnetStartDate1[2],
          eventMonth: evnetStartDate1[1],
        };
        events.push(Event);
      }

      this.state = {
        cal_events: events,
      }; // ! setState(...)
      // this.setState({
      //   cal_events: events
      // })
    }
  }

  componentDidMount() {
    // const eventDate = new Date().toLocaleDateString();
    const currentDate = new Date();
const day = String(currentDate.getDate()).padStart(2, '0');
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const year = currentDate.getFullYear();
const eventDate = `${day}/${month}/${year}`;
    console.log("eventDate", eventDate);
    this.setState({
      eventMonth:
        parseInt(eventDate.split("/")[1]) < 10 &&
        eventDate.split("/")[1].includes("0")
          ? parseInt(`${eventDate.split("/")[1].replace("0", "")}`)
          : parseInt(`${eventDate.split("/")[1]}`),
      eventYear: eventDate.split("/")[2],
    });

    this.initailizedDateDetails();
  }

  handleSubmitRsvpResponse(value, eventID) {
    console.log("rsvpResponse", value);
    console.log("eventID", eventID);
    let partyID = parseInt(localStorage.getItem("userid-Token"));
    console.log("partyID", partyID);
    let Event=document.getElementById(`btn-${value}-${eventID}`)
    Event.disabled = true;
    Event.style.cursor = "not-allowed";
    // Event.style.color = "#337ab7";
    Event.style.opacity = 0.5;
    let data = {
      rsvpResponse: value,
      eventID: eventID,
      partyID: partyID,
    };
    console.log("data", data);
    this.setState({loading:true})
    StudentAssessmentService.rsvpResponse(
      data,
      (cb) => {
        if (cb.status === "success") {
          success("RSVP Mail Sent Successfully", successNotification);
          this.setState({loading:false},()=>{
            Event.disabled = true;
            Event.style.cursor = "not-allowed";
            Event.style.opacity = 0.5;
          })
          // Event.disabled=false;
          // Event.style.cursor = "pointer";
          // Event.style.opacity = 1;
        } else {
          // Event.disabled=false;
          // Event.style.cursor = "pointer";
          // Event.style.opacity = 1;
          this.setState({loading:false},()=>{
            Event.disabled = true;
            Event.style.cursor = "not-allowed";
            Event.style.opacity = 0.5;
          })
          warning(cb.reasonText, {
            ...warningNotification,
            container: "top-center",
          });
        }
        this.toggleBackgroundColor("agenda-active");
      },
      (err) => console.log("Error", err)
    );
  }

  initailizedDateDetails() {
    var listOfYear = [];
    var listOfMonth = [];

    // year 2019 to 2050
    // var year = 2018;

    // for (var i = 1; year + i < 2051; i++) {
    //   var current = year + i;
    //   listOfYear.push(current);
    // }
    // listOfYear.reverse();

    // year 2021 to 2030
    var year = 2020;

    for (var i = 1; year + i < 2031; i++) {
      var current = year + i;
      listOfYear.push(current);
    }
    listOfYear.reverse();

    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "March" },
      { key: 4, value: "April" },
      { key: 5, value: "May" },
      { key: 6, value: "June" },
      { key: 7, value: "July" },
      { key: 8, value: "August" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear: listOfYear });
  }

  handleSearchEvent = (e, setFieldValue) => {
    // console.log("e", e.target.value);

    let searchEvent = e.target.value.toLowerCase();

    if (e.target.value.length >= 3) {
      // const events = this.state.cal_events;
      let result = this.state.cal_events.filter((data) => {
        // console.log("data", data.title.props.children[0].props.children[0]);

        if (data.eventTitle.toLowerCase().includes(searchEvent)) {
          return data.eventTitle;
        }

        // if (
        //   data.title.props.children[0].props.children[0]
        //     .toLowerCase()
        //     .includes(searchEvent)
        // ) {
        //   // return data.title.props.children[0].props.children[0];
        //   return data.title.props.children[0].props.children[0];
        // } else {
        //   return null;
        // }
      });

      setFieldValue("searchEventResults", result);
      setFieldValue("searchEventText", e.target.value);
      // this.setState({
      //   cal_events1: result,
      //   searchEventText: e.target.value,
      // });
      console.log("result", result);
    } else {
      setFieldValue("searchEventResults", []);
      setFieldValue("searchEventText", e.target.value);
      // this.setState({
      //   cal_events1: [],
      //   // searchEventText: e.target.value,
      //   searchEventText: "",
      // });
    }
  };
  toggleBackgroundColor = (buttonId) => {
    const button = document.getElementById(buttonId);
    const monthBtn = document.getElementById("month-active");
    const weekBtn = document.getElementById("week-active");
    const dayBtn = document.getElementById("day-active");
    const agendaBtn = document.getElementById("agenda-active");
    let BtnsArray=[monthBtn,weekBtn,dayBtn,agendaBtn]
    if (button) {
      BtnsArray.filter((item)=>item.id !== buttonId).map((item1)=>{
        item1.classList.remove("green-background");
      })
      if (button.classList.contains("green-background")) {
        // button.classList.remove("green-background");
      } else {
        button.classList.add("green-background");
      }
    }
  }

  render() {
    const handleDateSelect = (event) => {	
      this.setState({ selectedDate: event.start },()=>{
        toggleBackgroundColor("agenda-active");
        document.getElementById("calendar-top").scrollTop = 0;
        this.state.storeToolbar.onView("agenda");	
      });
    };
    const handleSlotClick = (event) => {	
      this.setState({ selectedDate: event.start },()=>{
        toggleBackgroundColor("day-active");
        this.state.storeToolbar.onView("day");
      });
    };   
    const onView = (event) => {
      this.setState({view:event},()=>{
        toggleBackgroundColor(`${event}-active`);
      })
    };
    const toggleBackgroundColor = (buttonId) => {
      const button = document.getElementById(buttonId);
      const monthBtn = document.getElementById("month-active");
      const weekBtn = document.getElementById("week-active");
      const dayBtn = document.getElementById("day-active");
      const agendaBtn = document.getElementById("agenda-active");
      let BtnsArray=[monthBtn,weekBtn,dayBtn,agendaBtn]
      if (button) {
        BtnsArray.filter((item)=>item.id !== buttonId).map((item1)=>{
          item1.classList.remove("green-background");
        })
        if (button.classList.contains("green-background")) {
          // button.classList.remove("green-background");
        } else {
          button.classList.add("green-background");
        }
      }
    }
    // console.log("state", this.state);
    const ColoredDateCellWrapper = ({ children }) =>
      React.cloneElement(React.Children.only(children), {
        style: {
          backgroundColor: "lightblue",
        },
      });

    const getCustomToolbar = (toolbar) => {
      //   console.log("aksdnn",toolbar.date.getMonth());
      this.toolbarDate = toolbar.date;
      this.state.storeToolbar=toolbar;
      // this.setState({
      //   storeToolbar:toolbar
      // })
      // const toggleBackgroundColor = (buttonId) => {
      //   const button = document.getElementById(buttonId);
      //   const monthBtn = document.getElementById("month-active");
      //   const weekBtn = document.getElementById("week-active");
      //   const dayBtn = document.getElementById("day-active");
      //   const agendaBtn = document.getElementById("agenda-active");
      //   let BtnsArray=[monthBtn,weekBtn,dayBtn,agendaBtn]
      //   if (button) {
      //     BtnsArray.filter((item)=>item.id !== buttonId).map((item1)=>{
      //       item1.classList.remove("green-background");
      //     })
      //     if (button.classList.contains("green-background")) {
      //       button.classList.remove("green-background");
      //     } else {
      //       button.classList.add("green-background");
      //     }
      //   }
      // }
      const goToDayView = (buttonId) => {
        toolbar.onView("day");
        toggleBackgroundColor(buttonId);        
      };
      const goToWeekView = (buttonId) => {
        toolbar.onView("week");
        toggleBackgroundColor(buttonId);
      };
      const goToMonthView = (buttonId) => {
        toolbar.onView("month");
        toggleBackgroundColor(buttonId);
      };
      const goToAgendaView = (buttonId) => {
        toolbar.onView("agenda");
        toggleBackgroundColor(buttonId);
      };

      //! Old One Calendar

      // const goToBack = () => {
      //   toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      //   toolbar.onNavigate("prev");
      //   this.setState({
      //     eventMonth: toolbar.date.getMonth() - 1,
      //     eventYear: toolbar.date.getFullYear(),
      //   });
      // };
      // const goToNext = () => {
      //   toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      //   toolbar.onNavigate("next");
      //   this.setState({
      //     eventMonth: toolbar.date.getMonth() + 1,
      //     eventYear: toolbar.date.getFullYear(),
      //   });
      // };

      //! New One Calendar

      const goToBack = () => {
        // console.log("---gtb", toolbar.date);
        let mDate = toolbar.date;
        let newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
        toolbar.onNavigate("prev", newDate);
        toolbar.onView("month");
        // console.log("mDate", mDate);
        // console.log("newDate", newDate);
        // console.log("mDate1", mDate.getFullYear(), mDate.getMonth());

        this.setState({
          eventMonth:
            mDate.getMonth() === 0 ? mDate.getMonth() + 12 : mDate.getMonth(),
          eventYear:
            mDate.getMonth() === 0
              ? mDate.getFullYear() - 1
              : mDate.getFullYear(),
        });
      };
      const goToNext = () => {
        // console.log("---gtn", toolbar.date);
        let mDate = toolbar.date;
        let newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
        toolbar.onNavigate("next", newDate);
        toolbar.onView("month");
        // console.log("mDate", mDate);
        // console.log("newDate", newDate);
        // console.log("mDate1", mDate.getFullYear(), mDate.getMonth());
        this.setState({
          // eventMonth:mDate.getMonth()===12?mDate.getMonth()-12:mDate.getMonth(),
          eventMonth: mDate.getMonth() + 2,

          eventYear:
            mDate.getMonth() === 11
              ? mDate.getFullYear() + 1
              : mDate.getFullYear(),
        });
      };

      //! Old Month Year
      // const goToMonth = (e) => {
      //   e.preventDefault();
      //   console.log("ee", e.target.value);
      //   let eventMonth = e.target.value;
      //   console.log("eventMonth", eventMonth);
      //   console.log("eventMonth", parseInt(eventMonth));

      //   this.setState({
      //     eventMonth: e.target.value,
      //   });
      //   toolbar.date.setMonth(e.target.value - 1);
      //   toolbar.onNavigate(e.target.value);
      // };

      // const goToYear = (e) => {
      //   e.preventDefault();
      //   console.log("ee", e.target.value);
      //   // console.log("ads", toolbar.date.setMonth(e.target.value));
      //   this.setState({
      //     eventYear: e.target.value,
      //   });
      //   toolbar.date.setFullYear(e.target.value);
      //   toolbar.onNavigate(e.target.value);
      // };

      //! New Month Year
      const goToMonth = (e) => {
        e.preventDefault();
        let eventMonth = parseInt(e.target.value);
        let mDate = toolbar.date;
        let newDate = new Date(mDate.getFullYear(), e.target.value - 1, 1);
        toolbar.onNavigate("month", newDate);
        toolbar.onView("month");
        console.log(
          "month",
          parseInt(e.target.value)
          // toolbar.date.setMonth(e.target.value)
        );
        // console.log("eventMonth", eventMonth);
        // console.log("eventMonth", parseInt(eventMonth));
        // let mDate = toolbar.date;
        // let newDate = new Date(mDate.getMonth() + 1);

        this.setState({
          eventMonth: eventMonth,
        });
        // toolbar.date.setMonth(eventMonth - 1);
        // toolbar.onNavigate(eventMonth);
      };

      const goToYear = (e) => {
        e.preventDefault();
        let eventYear = parseInt(e.target.value);
        // let mDate = toolbar.date;
        // let newDate = new Date(mDate.getFullYear());
        console.log("year", parseInt(e.target.value));
        // console.log("ads", toolbar.date.setMonth(e.target.value));
        let mDate = toolbar.date;
        let newDate = new Date(e.target.value, mDate.getMonth(), 1);
        toolbar.onNavigate("year", newDate);
        toolbar.onView("month");
        this.setState({
          eventYear: eventYear,
        });
        // toolbar.date.setFullYear(eventYear);
        // toolbar.onNavigate(eventYear);
      };

      // const label = () => {
      //   const date = moment(toolbar.date);

      //   return (
      //     <span>
      //       <b>{date.format("MMMM")}</b>
      //       <span> {date.format("YYYY")}</span>
      //     </span>
      //   );
      // };

      return (
        <Formik
          enableReinitialize={true}
          initialValues={{
            searchEventText: this.state.searchEventText
              ? this.state.searchEventText
              : "",
            searchEventResults: [],
            selectedEvent: "",
          }}
          render={({ values, handleChange, setFieldValue }) => (
            <div className="toolbar-container">
              <div className="navigation-buttons row">
                <div className="col-md-2" style={{marginBottom:"30px"}}>
                  <button
                    className="btn btn-back btn-event pull-left"
                    // onClick={(e) => goToBack(e)}
                    onClick={goToBack}
                  >
                    {"<<"}
                  </button>
                </div>

                <div className="col-md-8">
                  {/* <label className="label-date">
                    <b>{label()}</b>
                  </label> */}
                  <div className="col-md-6 pl pr">
                    <select
                      className="form-control"
                      id="calendar-month"
                      value={parseInt(this.state.eventMonth, 10)}
                      style={{ border: "1px solid #f99f31" }}
                      onChange={(e) => {
                        goToMonth(e);
                        this.setState({
                          searchEventText: "",
                        });
                        // toolbar.date.setMonth(e.target.value - 1);
                        // toolbar.onNavigate(e.target.value);
                      }}
                    >
                      {(this.state.listOfMonth || []).map((m) => (
                        <option value={m.key}>{m.value}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 pl pr">
                    <select
                      className="form-control"
                      value={parseInt(this.state.eventYear)}
                      style={{ border: "1px solid #f99f31" }}
                      onChange={(e) => {
                        goToYear(e);
                        this.setState({
                          searchEventText: "",
                        });
                        // toolbar.date.setFullYear(e.target.value);
                        // toolbar.onNavigate(e.target.value);
                        // setFieldValue("searchEventText", "");
                      }}
                    >
                      {(this.state.listOfYear || []).map((year) => (
                        <option value={year}>{year}</option>
                      ))}
                    </select>
                  </div>

                  <div
                    className="col-md-12 pl pr"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    {/* <Field
                      type="text"
                      placeholder="Search Event"
                      className="form-control"
                      style={{ border: "1px solid #f99f31" }}
                      // value={this.state.searchText}
                      value={values.searchEventText}
                      onChange={(e) => this.handleSearchEvent(e, setFieldValue)}
                    /> */}

                    <Field
                      className="form-control"
                      name="searchEventText"
                      id="searchEventText"
                      style={{ border: "1px solid #f99f31" }}
                      placeholder="Search Event"
                      onChange={(e) => {
                        setFieldValue("searchEventText", e.target.value);
                        if (e.target.value.length >= 3) {
                          this.handleSearchEvent(e, setFieldValue);
                        } else {
                          setFieldValue("searchEventResults", []);
                        }
                      }}
                      value={values.searchEventText}
                    />

                    <div
                      style={{
                        border: "0.3px solid darkorange",
                        borderTop: "0px",
                      }}
                      className="mt-4 d-flex flex-column col-md-12 col-sm-12 col-xs-12"
                    >
                      {values.searchEventResults.map((data) => (
                        <p
                          key={data.id}
                          style={{
                            marginTop: "3px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // setFieldValue("searchEventText", data.eventTitle);
                            console.log(data);
                            console.log(parseInt(data.eventMonth));
                            console.log(parseInt(data.eventYear));
                            this.setState(
                              {
                                eventMonth: data.eventMonth,
                                eventYear: data.eventYear,
                                searchEventText: data.eventTitle,
                              },
                              () => {
                                toolbar.onView("month");
                                // toolbar.onNavigate(() => )
                                this.setState({
                                  eventMonth: parseInt(data.eventMonth),
                                  eventYear: parseInt(data.eventYear),
                                });
                                toolbar.date.setMonth(
                                  parseInt(data.eventMonth) - 1
                                );
                                toolbar.date.setFullYear(
                                  parseInt(data.eventYear)
                                );
                                toolbar.onNavigate(parseInt(data.eventMonth));
                                toolbar.onNavigate(parseInt(data.eventYear));
                                // toolbar.date.setMonth(
                                //   parseInt(data.eventMonth)
                                // );
                                // toolbar.date.setFullYear(
                                //   parseInt(data.eventYear)
                                // );

                                setFieldValue("searchEventResults", []);
                              }
                            );
                          }}
                        >
                          {data.eventTitle}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <button
                    className="btn btn-next btn-event  pull-right"
                    // onClick={(e) => goToNext(e)}
                    onClick={goToNext}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
              {/* <hr style={{ border: "1px solid #085dab" }} /> */}
              <div
                className="filter-container text-center"
                style={{ marginBottom: "10px" }}
              >
                <button className="btn-event1 green-background" id="month-active" onClick={()=>{goToMonthView("month-active")}}>
                  <span className="label-filter-off">Month</span>
                </button>
                <button className="btn-event1 " id="week-active" onClick={()=>{goToWeekView("week-active")}}>
                  <span className="label-filter-off">Week</span>
                </button>
                <button className="btn-event1 rbc-active" id="day-active" onClick={()=>{goToDayView("day-active")}}>
                  <span className="label-filter-off">Day</span>
                </button>
                <button className="btn-event1 " id="agenda-active" onClick={()=>{goToAgendaView("agenda-active")}}>
                  <span className="label-filter-off">Agenda</span>
                </button>
              </div>
            </div>
          )}
        />
      );
    };

    return (
      <React.Fragment>
        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
          <h3 className="student-title">My Events</h3>
          <div className="calendar-event cal-eve-res" id="calendar-top" style={{scrollBehavior:"smooth"}}>
            <Calendar
              localizer={localizer}
              showMultiDayTimes
              events={this.state.cal_events}
              startAccessor="start"
              endAccessor="end"
              components={{
                timeSlotWrapper: ColoredDateCellWrapper,
                toolbar: getCustomToolbar,
              }}
              style={{ minHeight: 350 }}
              selectable
              onSelectEvent={handleDateSelect}
              onSelectSlot={handleSlotClick}
              date={this.state.selectedDate}
              onNavigate={date => this.setState({ selectedDate: date })}
              onView={onView}
              view={this.state.view}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MyCalander;

// localizer={localizer}
// events={[
//     {
//         title: "My event",
//         allDay: true,
//         start: new Date(2018, 0, 1, 10, 0), // 10.00 AM
//         end: new Date(2018, 0, 1, 14, 0) // 2.00 PM
//     }
// ]}
// step={5}
// timeslots={3}
// view="month"
// views={["month"]}
// min={new Date(2008, 0, 1, 8, 0)} // 8.00 AM
// max={new Date(2008, 0, 1, 17, 0)} // Max will be 6.00 PM!
// date={new Date(2018, 0, 1)}

