// eslint-disable-next-line no-unused-vars
// Library Imports
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
// Asset Imports
const FinxXLogo = require("../../assets/images/watermark-textresume.jpeg");
//! Unused Imports
// import ReactTable from 'react-table';
// import 'react-table/react-table.css';
// import DashboardHeader from '../../components/dashboardheader/dashboardheader';
// import Finxfooter from '../../containers/finxfooter/finxfooter';
// import Axios from 'axios';
// import RestDataSource from '../../services/restdatasource';

class CheckoutPaytm extends Component {
    // constructor(props) {
    //     super(props);

    // }


    componentDidMount() {
        console.log('checkout props',this.props);
        if(!["",null,undefined].includes(this.props?.location?.state)){
        const response = this.props?.location?.state?.paytm;
        console.log('checkout objbb 1',response, typeof response);
        var options = {
            "key": response.mid,
            "amount": response.totalOrderPrice, 
            "currency": response.currency,
            "name": "FINX",
            "description": "FINX Transaction",
            "image": FinxXLogo,
            "order_id": response?.website === "Razorpay" ?  response?.razorpayOrderID : response?.orderID,
            "callback_url": response.callbackUrl,
            "redirect": true,
            "handler": function (response){
                alert(response);
                alert(response.razorpay_payment_id);
                alert(response.razorpay_order_id);
                alert(response.razorpay_signature)
            },
            "modal": {
                "ondismiss": function() {
                    // Redirect to error page if Razorpay modal is dismissed
                    window.location.href = "/error";
                }
            },
            "prefill": {
                "name": response.studentName,
                "email": response.email,
                "contact": response.mobileNo
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            },
        };
        if(response?.website === "Razorpay") {
            console.log('checkout 1 options',options);
            var rzp1 = new window.Razorpay(options);
            rzp1.open(); 
        } else {            
            setTimeout(() => {
                document.paytm_form.submit();
            }, 2000);
        }
        }
    }

    render() {

        return (
            <React.Fragment >
                {ReactHtmlParser(this.props.location.state.paytm)}

            </React.Fragment>

        );
    }

}

export default CheckoutPaytm;
