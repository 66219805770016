// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// import RestDataSource from "../../services/restdatasource";
import { LoginService } from "../../services/login.service";
import {
  success,
  successNotification,
  error,
  errorNotification,
  // warning,
  warningNotification
} from "../notification/notifications";
// import * as moment from "moment";
import $ from "jquery";

class EditRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleName: "",
      description: "",
      isActive: "",
      activateDate: "",
      expiryDate: "",
      statusList: this.props.role.isActive ? this.props.role.isActive : "",
      suspendStartDate: "",
      suspendEndDate: "",
      listofActivateDtYear: [],
      listofExpiryDtYear: [],
      listOfSuspendStartYear: [],
      listOfSuspendEndYear: [],
      listofActivateDtMonth: [],
      listofExpiryDtMonth: [],
      listOfSuspendStartMonth: [],
      listOfSuspendEndMonth: [],
      listofActivateDtDay: [],
      listofExpiryDtDay: [],
      listOfSuspendStartDay: [],
      listOfSuspendEndDay: [],
      currentYear: "",
    };
  }

  componentDidMount() {
    this.initailizedDateDetails();
  }

  initailizedDateDetails() {
    var listOfYear = [];
    var listOfSuspendedYear = [];
    var listOfMonth = [];
    var listOfDay = [];

    var year = new Date().getFullYear();
    var Suspendedyear = new Date('01/02/2010').getFullYear();
    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);
    }

    // -
    for (var lsip = 0; lsip < 100; lsip++) {
      var lsipcurrent = Suspendedyear + lsip;
      listOfSuspendedYear.push(lsipcurrent);
    }
    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    for (var ld = 1; ld <= 31; ld++) {
      listOfDay.push(ld);
    }
    this.setState({ currentYear: year });
    this.setState({ listofActivateDtYear: listOfYear });
    this.setState({ listofExpiryDtYear: listOfSuspendedYear });
    this.setState({ listOfSuspendStartYear: listOfSuspendedYear });
    this.setState({ listOfSuspendEndYear: listOfSuspendedYear });
    this.setState({ listofActivateDtMonth: listOfMonth });
    this.setState({ listofExpiryDtMonth: listOfMonth });
    this.setState({ listOfSuspendStartMonth: listOfMonth });
    this.setState({ listOfSuspendEndMonth: listOfMonth });
    this.setState({ listofActivateDtDay: listOfDay });
    this.setState({ listofExpiryDtDay: listOfDay });
    this.setState({ listOfSuspendStartDay: listOfDay });
    this.setState({ listOfSuspendEndDay: listOfDay });
  }


  render() {
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            roleName: this.props.role.roleName,
            description: this.props.role.description,
            isActive: this.props.role.isActive,
            statusList: this.props.role.isActive,
            activateDate: "",
            expiryDate: "",
            suspendStartDate: "",
            suspendEndDate: "",
            listOfMonth: this.state.listOfMonth,
            listOfDay: this.state.listOfDay,
            listOfMonth1: this.state.listOfMonth,
            listOfDay1: this.state.listOfDay,
            listofActivateDtYear: this.state.listofActivateDtYear,
            listofExpiryDtYear: this.state.listofExpiryDtYear,
            listOfSuspendStartYear: this.state.listOfSuspendStartYear,
            listOfSuspendEndYear: this.state.listOfSuspendEndYear,
            listofActivateDtMonth: this.state.listofActivateDtMonth,
            listofExpiryDtMonth: this.state.listofExpiryDtMonth,
            listOfSuspendStartMonth: this.state.listOfSuspendStartMonth,
            listOfSuspendEndMonth: this.state.listOfSuspendEndMonth,
            listofActivateDtDay: this.state.listofActivateDtDay,
            listofExpiryDtDay: this.state.listofExpiryDtDay,
            listOfSuspendStartDay: this.state.listOfSuspendStartDay,
            listOfSuspendEndDay: this.state.listOfSuspendEndDay,

            ActivateDtDay: this.props.role.activateDate ? this.props.role.activateDate.split(" ")[0].split("/")[0] < "10" ? this.props.role.activateDate.split(" ")[0].split("/")[0][1] : this.props.role.activateDate.split(" ")[0].split("/")[0] : "",
            ActivateDtMonth: this.props.role.activateDate ? this.props.role.activateDate.split(" ")[0].split("/")[1] < "10" ? this.props.role.activateDate.split(" ")[0].split("/")[1][1] : this.props.role.activateDate.split(" ")[0].split("/")[1] : "",
            ActivateDtYear: this.props.role.activateDate ? this.props.role.activateDate.split(" ")[0].split("/")[2] : "",


            ExpiryDtDay: this.props.role.expiryDate ? this.props.role.expiryDate.split(" ")[0].split("/")[0] < "10" ? this.props.role.expiryDate.split(" ")[0].split("/")[0][1] : this.props.role.expiryDate.split(" ")[0].split("/")[0] : "",
            ExpiryDtMonth: this.props.role.expiryDate ? this.props.role.expiryDate.split(" ")[0].split("/")[1]
              < "10" ? this.props.role.expiryDate.split(" ")[0].split("/")[1][1] : this.props.role.expiryDate.split(" ")[0].split("/")[1] : "",
            ExpiryDtYear: this.props.role.expiryDate ? this.props.role.expiryDate.split(" ")[0].split("/")[2] : "",

            SuspendStartDay: this.props.role.suspendStartDate ? this.props.role.suspendStartDate.split(" ")[0].split("/")[0] < "10" ? this.props.role.suspendStartDate.split(" ")[0].split("/")[0][1] : this.props.role.suspendStartDate.split(" ")[0].split("/")[0] : "",
            SuspendStartMonth: this.props.role.suspendStartDate ? this.props.role.suspendStartDate.split(" ")[0].split("/")[1] < "10" ? this.props.role.suspendStartDate.split(" ")[0].split("/")[1][1] : this.props.role.suspendStartDate.split(" ")[0].split("/")[1] : "",
            SuspendStartYear: this.props.role.suspendStartDate ? this.props.role.suspendStartDate.split(" ")[0].split("/")[2] : "",

            SuspendEndDay: this.props.role.suspendEndDate ? this.props.role.suspendEndDate.split(" ")[0].split("/")[0] < "10" ? this.props.role.suspendEndDate.split(" ")[0].split("/")[0][1] : this.props.role.suspendEndDate.split(" ")[0].split("/")[0] : "",
            SuspendEndMonth: this.props.role.suspendEndDate ? this.props.role.suspendEndDate.split(" ")[0].split("/")[1] < "10" ? this.props.role.suspendEndDate.split(" ")[0].split("/")[1][1] : this.props.role.suspendEndDate.split(" ")[0].split("/")[1] : "",
            SuspendEndYear: this.props.role.suspendEndDate ? this.props.role.suspendEndDate.split(" ")[0].split("/")[2] : "",


          }}
          validationSchema={Yup.object().shape({
            roleName: Yup.string()
              .min(3, "Too Short!")
              .max(50, "Too Long!")
              .required("You must specify a Role Name"),
            description: Yup.string()
              .min(10, "Description must be minimum of 10 Characters!")
              .max(100, "Description must be maximum of 100 Characters!"),
            isActive: Yup.string().required("You must specify a Status"),
          })}
          onReset={(values, { resetForm }) => {
            resetForm();
          }}
          onSubmit={(fields, values) => {
            let ActivateDtPass = false;
            let ExpiryDtPass = false;
            let SuspendStartDtPass = false;
            let SuspendEndDtPass = false;
            let formattedActivateDate = "";
            let formattedExpiryDate = "";
            let formattedSuspendStartDate = "";
            let formattedSuspendEndDate = "";

            if ((fields["ActivateDtYear"] !== undefined && fields["ActivateDtYear"] !== "") || (fields["ActivateDtMonth"] !== undefined && fields["ActivateDtMonth"] !== "") || (fields["ActivateDtDay"] !== undefined && fields["ActivateDtDay"] !== "")) {
              ActivateDtPass = false;
              let fromdy = fields["ActivateDtDay"].length === 1 ? "0" + fields["ActivateDtDay"] : fields["ActivateDtDay"]
              let frommont = fields["ActivateDtMonth"].length === 1 ? "0" + fields["ActivateDtMonth"] : fields["ActivateDtMonth"];
              formattedActivateDate = fromdy + "/" + frommont + "/" + fields["ActivateDtYear"];
            } else {
              ActivateDtPass = true;
              error("Activate Date is required", errorNotification);
            }
            if ((fields["ExpiryDtYear"] !== undefined && fields["ExpiryDtYear"] !== "") || (fields["ExpiryDtMonth"] !== undefined && fields["ExpiryDtMonth"] !== "") || (fields["ExpiryDtDay"] !== undefined && fields["ExpiryDtDay"] !== "")) {
              ExpiryDtPass = false;
              let fromdy = fields["ExpiryDtDay"].length === 1 ? "0" + fields["ExpiryDtDay"] : fields["ExpiryDtDay"]
              let frommont = fields["ExpiryDtMonth"].length === 1 ? "0" + fields["ExpiryDtMonth"] : fields["ExpiryDtMonth"];
              formattedExpiryDate = fromdy + "/" + frommont + "/" + fields["ExpiryDtYear"];
            } else {
              ExpiryDtPass = true;
              error("Expiry Date is required", errorNotification);
            }

            if ((fields["SuspendStartYear"] !== undefined && fields["SuspendStartYear"] !== "") || (fields["SuspendStartMonth"] !== undefined && fields["SuspendStartMonth"] !== "") || (fields["SuspendStartDay"] !== undefined && fields["SuspendStartDay"] !== "")) {
              SuspendStartDtPass = false;
              let fromdy = fields["SuspendStartDay"].length === 1 ? "0" + fields["SuspendStartDay"] : fields["SuspendStartDay"]
              let frommont = fields["SuspendStartMonth"].length === 1 ? "0" + fields["SuspendStartMonth"] : fields["SuspendStartMonth"];
              formattedSuspendStartDate = fromdy + "/" + frommont + "/" + fields["SuspendStartYear"];

            } else {
              SuspendStartDtPass = true;
              error("Suspended Start Date is required", errorNotification);
            }

            if ((fields["SuspendEndYear"] !== undefined && fields["SuspendEndYear"] !== "") || (fields["SuspendEndMonth"] !== undefined && fields["SuspendEndMonth"] !== "") || (fields["SuspendEndDay"] !== undefined && fields["SuspendEndDay"] !== "")) {
              SuspendEndDtPass = false;
              let fromdy = fields["SuspendEndDay"].length === 1 ? "0" + fields["SuspendEndDay"] : fields["SuspendEndDay"]
              let frommont = fields["SuspendEndMonth"].length === 1 ? "0" + fields["SuspendEndMonth"] : fields["SuspendEndMonth"];
              formattedSuspendEndDate = fromdy + "/" + frommont + "/" + fields["SuspendEndYear"];

            } else {
              SuspendEndDtPass = true;
              error("Suspended End Date is required", errorNotification);
            }

            if (!ActivateDtPass && !ExpiryDtPass && !SuspendStartDtPass && !SuspendEndDtPass) {

              this.setState({
                roleId: this.props.role.roleId,
                roleName: fields["roleName"].trim(),
                description: fields["description"],
                activateDate: formattedActivateDate ? formattedActivateDate + " 00:00:00" : "",
                expiryDate: formattedExpiryDate ? formattedExpiryDate + " 00:00:00" : "",
                suspendStartDate: formattedSuspendStartDate ? formattedSuspendStartDate + " 00:00:00" : "",
                suspendEndDate: formattedSuspendEndDate ? formattedSuspendEndDate + " 00:00:00" : "",
                isActive: fields["isActive"] === "1" ? 1 : 0,
                statusList: fields["statusList"],
              });

              LoginService.TokenUser(res => {
                if (res.status === "success") {
                  localStorage.setItem("jwt-token", res.responseObject);

                  this.props.editRole(this.state, cb => {
                    if (cb.status === "success") {
                      success(
                        fields["roleName"] +
                        " " +
                        "has been created on FinX successfully",
                        successNotification
                      );
                      this.props.fetchRoles();
                      $(".modal-backdrop").remove();
                    } else {
                      error(cb.reasonText, {
                        ...warningNotification,
                        container: "top-center"
                      });
                      localStorage.setItem("reload", "true");
                      window.location.reload();
                      this.props.fetchRoles();
                    }
                  });
                }
              });
            }
          }}
          render={({
            handleReset,
            isValid,
            errors,
            touched,
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            ...formikProps
          }) => (
            <React.Fragment>
              <Form
                className="modal fade"
                onKeyDown={onKeyDown => {
                  if (
                    (onKeyDown.charCode || onKeyDown.keyCode) === 13 &&
                    onKeyDown.target.name !== "isActive" &&
                    onKeyDown.target.name !== "submitForm" &&
                    onKeyDown.target.name !== "cancelForm"
                  ) {
                    onKeyDown.preventDefault();
                  }
                }}
                id="edit-role"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="false"
              >
                <div class="modal-dialog modal-md" role="document">
                  <div class="modal-content">
                    <button
                      type="reset"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                      <div class="row">
                        <section class="edit-role">
                          <div class="form-group">
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Role Name
                                <span class="validation"> *</span>
                              </label>
                              <Field
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.roleName && touched.roleName
                                    ? " is-invalid"
                                    : "")
                                }
                                name="roleName"
                                id="roleName"
                                placeholder="Role Name"
                              />
                              <ErrorMessage
                                name="roleName"
                                component="div"
                                className="validation"
                              />
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Activate Date:
                                <span class="validation"> *</span>
                              </label>
                              {/* <Field
                                type="date"
                                name="activateDate"
                                id="activateDate"
                                className={
                                  "form-control" +
                                  (errors.activateDate && touched.activateDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="activateDate"
                                component="div"
                                className="validation"
                              /> */}
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                  <Field
                                    as="select"
                                    name="ActivateDtDay"
                                    id="ActivateDtDay"
                                    onChange={(e) => {
                                      var listOfMonth = [];
                                      setFieldValue((values.ActivateDtDay = e.target.value));
                                      if ([31, '31'].includes(e.target.value)) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 3, value: "Mar" },
                                          { key: 5, value: "May" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 10, value: "Oct" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if ([30, '30'].includes(e.target.value)) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if (
                                        [28, '28', 29, '29'].includes(e.target.value)
                                      ) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      }
                                      setFieldValue((values.listofActivateDtMonth = listOfMonth));
                                    }}
                                    className={"form-control" + (errors.ActivateDtDay && touched.ActivateDtDay ? "is-invalid" : "")}
                                  >
                                    <option value="">DD</option>
                                    {
                                      (values.listofActivateDtDay || []).map(
                                        (d) => (<option value={d}> {d} </option>
                                        )
                                      )}
                                  </Field>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                  <Field
                                    as="select"
                                    name="ActivateDtMonth"
                                    id="ActivateDtMonth"
                                    onChange={(e) => {
                                      setFieldValue(
                                        (values.ActivateDtMonth = e.target.value)
                                      );
                                      if ([2, '2'].includes(e.target.value)) {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 29; i++) {
                                          listOfDay.push(i);
                                        }
                                      } else if (
                                        // e.target.value == 1 ||
                                        // e.target.value == 3 ||
                                        // e.target.value == 5 ||
                                        // e.target.value == 7 ||
                                        // e.target.value == 8 ||
                                        // e.target.value == 10 ||
                                        // e.target.value == 12
                                        [1, '1', 3, '3', 5, '5', 7, '7', 8, '8', 10, '10', 12, '12',].includes(e.target.value)
                                      ) {
                                        listOfDay = [];
                                        for (var ldd = 1; ldd <= 31; ldd++) {
                                          listOfDay.push(ldd);
                                        }
                                      } else {
                                        listOfDay = [];
                                        for (var lod = 1; lod <= 30; lod++) {
                                          listOfDay.push(lod);
                                        }
                                      }
                                      setFieldValue((values.listofActivateDtDay = listOfDay));
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.ActivateDtMonth && touched.ActivateDtMonth
                                        ? "is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="">MM</option>
                                    {(values.listofActivateDtMonth || []).map(
                                      (m) => (
                                        <option value={m.key}>
                                          {m.value}
                                        </option>
                                      )
                                    )}
                                  </Field>
                                </div>
                                <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                  <Field
                                    as="select"
                                    name="ActivateDtYear"
                                    id="ActivateDtYear"
                                    onChange={(e) => {
                                      setFieldValue((values.ActivateDtYear = e.target.value));
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.ActivateDtYear && touched.ActivateDtYear ? "is-invalid" : "")
                                    }
                                  >
                                    <option value="">YYYY</option>
                                    {(
                                      this.state.listofActivateDtYear || []
                                    ).map((year) => (
                                      <option value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </div>

                            </div>

                            <div class="col-md-12 col-sm-12 col-xs-12"></div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Expiry Date:
                                <span class="validation"> *</span>
                              </label>
                              {/* <Field
                                type="date"
                                name="expiryDate"
                                id="expiryDate"
                                className={
                                  "form-control" +
                                  (errors.expiryDate && touched.expiryDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="expiryDate"
                                component="div"
                                className="validation"
                              /> */}
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                  <Field
                                    as="select"
                                    name="ExpiryDtDay"
                                    id="ExpiryDtDay"
                                    onChange={(e) => {
                                      var listOfMonth = [];
                                      setFieldValue((values.ExpiryDtDay = e.target.value));
                                      if ([31, '31'].includes(e.target.value)) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 3, value: "Mar" },
                                          { key: 5, value: "May" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 10, value: "Oct" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if ([30, '30'].includes(e.target.value)) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if (
                                        [28, '28', 29, '29'].includes(e.target.value)
                                      ) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      }
                                      setFieldValue((values.listofExpiryDtYear = listOfMonth));
                                    }}
                                    className={"form-control" + (errors.ExpiryDtDay && touched.ExpiryDtDay ? "is-invalid" : "")}
                                  >
                                    <option value="">DD</option>
                                    {
                                      (values.listofExpiryDtDay || []).map(
                                        (d) => (<option value={d}> {d} </option>
                                        )
                                      )}
                                  </Field>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                  <Field
                                    as="select"
                                    name="ExpiryDtMonth"
                                    id="ExpiryDtMonth"
                                    onChange={(e) => {
                                      setFieldValue(
                                        (values.ExpiryDtMonth = e.target.value)
                                      );
                                      if ([2, '2'].includes(e.target.value)) {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 29; i++) {
                                          listOfDay.push(i);
                                        }
                                      } else if (
                                        // e.target.value == 1 ||
                                        // e.target.value == 3 ||
                                        // e.target.value == 5 ||
                                        // e.target.value == 7 ||
                                        // e.target.value == 8 ||
                                        // e.target.value == 10 ||
                                        // e.target.value == 12
                                        [1, '1', 3, '3', 5, '5', 7, '7', 8, '8', 10, '10', 12, '12',].includes(e.target.value)
                                      ) {
                                        listOfDay = [];
                                        for (var ldd = 1; ldd <= 31; ldd++) {
                                          listOfDay.push(ldd);
                                        }
                                      } else {
                                        listOfDay = [];
                                        for (var lod = 1; lod <= 30; lod++) {
                                          listOfDay.push(lod);
                                        }
                                      }
                                      setFieldValue((values.listofExpiryDtDay = listOfDay));
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.ExpiryDtMonth && touched.ExpiryDtMonth
                                        ? "is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="">MM</option>
                                    {(values.listofExpiryDtMonth || []).map(
                                      (m) => (
                                        <option value={m.key}>
                                          {m.value}
                                        </option>
                                      )
                                    )}
                                  </Field>
                                </div>
                                <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                  <Field
                                    as="select"
                                    name="ExpiryDtYear"
                                    id="ExpiryDtYear"
                                    onChange={(e) => {
                                      setFieldValue((values.ExpiryDtYear = e.target.value));
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.ExpiryDtYear && touched.ExpiryDtYear ? "is-invalid" : "")
                                    }
                                  >
                                    <option value="">YYYY</option>
                                    {(
                                      this.state.listofExpiryDtYear || []
                                    ).map((year) => (
                                      <option value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Suspend Start Date:
                                <span class="validation"> *</span>
                              </label>
                              {/* <Field
                                type="date"
                                name="suspendStartDate"
                                id="suspendStartDate"
                                className={
                                  "form-control" +
                                  (errors.suspendStartDate &&
                                    touched.suspendStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="suspendStartDate"
                                component="div"
                                className="validation"
                              /> */}
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                  <Field
                                    as="select"
                                    name="SuspendStartDay"
                                    id="SuspendStartDay"
                                    onChange={(e) => {
                                      var listOfMonth = [];
                                      setFieldValue((values.SuspendStartDay = e.target.value));
                                      if ([31, '31'].includes(e.target.value)) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 3, value: "Mar" },
                                          { key: 5, value: "May" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 10, value: "Oct" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if ([30, '30'].includes(e.target.value)) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if (
                                        [28, '28', 29, '29'].includes(e.target.value)
                                      ) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      }
                                      setFieldValue((values.listOfSuspendStartMonth = listOfMonth));
                                    }}
                                    className={"form-control" + (errors.SuspendStartDay && touched.SuspendStartDay ? "is-invalid" : "")}
                                    className="form-control"
                                  >
                                    <option value="">DD</option>
                                    {
                                      (values.
                                        listOfSuspendStartDay || []).map(
                                          (d) => (<option value={d}> {d} </option>
                                          )
                                        )}
                                  </Field>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                  <Field
                                    as="select"
                                    name="SuspendStartMonth"
                                    id="SuspendStartMonth"
                                    onChange={(e) => {
                                      setFieldValue(
                                        (values.SuspendStartMonth = e.target.value)
                                      );
                                      if ([2, '2'].includes(e.target.value)) {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 29; i++) {
                                          listOfDay.push(i);
                                        }
                                      } else if (
                                        // e.target.value == 1 ||
                                        // e.target.value == 3 ||
                                        // e.target.value == 5 ||
                                        // e.target.value == 7 ||
                                        // e.target.value == 8 ||
                                        // e.target.value == 10 ||
                                        // e.target.value == 12
                                        [1, '1', 3, '3', 5, '5', 7, '7', 8, '8', 10, '10', 12, '12',].includes(e.target.value)
                                      ) {
                                        listOfDay = [];
                                        for (var ldd = 1; ldd <= 31; ldd++) {
                                          listOfDay.push(ldd);
                                        }
                                      } else {
                                        listOfDay = [];
                                        for (var lod = 1; lod <= 30; lod++) {
                                          listOfDay.push(lod);
                                        }
                                      }
                                      setFieldValue((values.listOfSuspendStartDay = listOfDay));
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.SuspendStartMonth && touched.SuspendStartMonth
                                        ? "is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="">MM</option>
                                    {(values.listOfSuspendStartMonth || []).map(
                                      (m) => (
                                        <option value={m.key}>
                                          {m.value}
                                        </option>
                                      )
                                    )}
                                  </Field>
                                </div>
                                <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                  <Field
                                    as="select"
                                    name="SuspendStartYear"
                                    id="SuspendStartYear"
                                    onChange={(e) => {
                                      setFieldValue((values.SuspendStartYear = e.target.value));
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.SuspendStartYear && touched.SuspendStartYear ? "is-invalid" : "")
                                    }
                                  >
                                    <option value="">YYYY</option>
                                    {(
                                      this.state.listOfSuspendStartYear || []
                                    ).map((year) => (
                                      <option value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12"></div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Suspend End Date:
                                <span class="validation"> *</span>
                              </label>
                              {/* <Field
                                type="date"
                                name="suspendEndDate"
                                id="suspendEndDate"
                                className={
                                  "form-control" +
                                  (errors.suspendEndDate &&
                                    touched.suspendEndDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="suspendEndDate"
                                component="div"
                                className="validation"
                              /> */}
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                  <Field
                                    as="select"
                                    name="SuspendEndDay"
                                    id="SuspendEndDay"
                                    onChange={(e) => {
                                      var listOfMonth = [];
                                      setFieldValue((values.SuspendEndDay = e.target.value));
                                      if ([31, '31'].includes(e.target.value)) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 3, value: "Mar" },
                                          { key: 5, value: "May" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 10, value: "Oct" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if ([30, '30'].includes(e.target.value)) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if (
                                        [28, '28', 29, '29'].includes(e.target.value)
                                      ) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      }
                                      setFieldValue((values.listOfSuspendEndMonth = listOfMonth));
                                    }}
                                    className={"form-control" + (errors.SuspendEndDay && touched.SuspendEndDay ? "is-invalid" : "")}
                                  >
                                    <option value="">DD</option>
                                    {
                                      (values.listOfSuspendEndDay || []).map(
                                        (d) => (<option value={d}> {d} </option>
                                        )
                                      )}
                                  </Field>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                  <Field
                                    as="select"
                                    name="SuspendEndMonth"
                                    id="SuspendEndMonth"
                                    onChange={(e) => {
                                      setFieldValue(
                                        (values.SuspendEndMonth = e.target.value)
                                      );
                                      if ([2, '2'].includes(e.target.value)) {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 29; i++) {
                                          listOfDay.push(i);
                                        }
                                      } else if (
                                        // e.target.value == 1 ||
                                        // e.target.value == 3 ||
                                        // e.target.value == 5 ||
                                        // e.target.value == 7 ||
                                        // e.target.value == 8 ||
                                        // e.target.value == 10 ||
                                        // e.target.value == 12
                                        [1, '1', 3, '3', 5, '5', 7, '7', 8, '8', 10, '10', 12, '12',].includes(e.target.value)
                                      ) {
                                        listOfDay = [];
                                        for (var ldd = 1; ldd <= 31; ldd++) {
                                          listOfDay.push(ldd);
                                        }
                                      } else {
                                        listOfDay = [];
                                        for (var lod = 1; lod <= 30; lod++) {
                                          listOfDay.push(lod);
                                        }
                                      }
                                      setFieldValue((values.listOfSuspendEndDay = listOfDay));
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.SuspendEndMonth && touched.SuspendEndMonth
                                        ? "is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="">MM</option>
                                    {(values.listOfSuspendEndMonth || []).map(
                                      (m) => (
                                        <option value={m.key}>
                                          {m.value}
                                        </option>
                                      )
                                    )}
                                  </Field>
                                </div>
                                <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                  <Field
                                    as="select"
                                    name="SuspendEndYear"
                                    id="SuspendEndYear"
                                    onChange={(e) => {
                                      setFieldValue((values.SuspendEndYear = e.target.value));
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.SuspendEndYear && touched.SuspendEndYear ? "is-invalid" : "")
                                    }
                                  >
                                    <option value="">YYYY</option>
                                    {(
                                      this.state.listOfSuspendEndYear || []
                                    ).map((year) => (
                                      <option value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Description
                                {/* <span class="validation"> *</span> */}
                              </label>
                              <Field
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.description && touched.description
                                    ? " is-invalid"
                                    : "")
                                }
                                name="description"
                                id="description"
                                placeholder="Description"
                              />
                              <ErrorMessage
                                name="description"
                                component="div"
                                className="validation"
                              />
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12"></div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>
                                Status{" "}
                                <span className="validation">
                                  {" "}
                                  *
                                </span>
                              </label>
                              <Field
                                as="select"
                                className={
                                  "form-control" +
                                  (errors.isActive && touched.isActive
                                    ? " is-invalid"
                                    : "")
                                }
                                name="isActive"
                                id="isActive"

                              >{this.props.role.isActive === 1} ? <option value="1">Active</option>
                                :  {this.props.role.isActive === 0} ? <option value="0">Inactive</option> : ''

                              </Field>
                              <ErrorMessage
                                name="isActive"
                                component="div"
                                className="validation"
                              />
                            </div>
                          </div>
                        </section>

                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <button
                            type="submit"
                            name="submitForm"
                            class="btn-5 mt-30 pull-right"
                            onClick={async () => {
                              const curErrors = await formikProps.validateForm();
                              const curErrorsKeys = Object.keys(curErrors);

                              if (curErrorsKeys.length) {
                                // Assuming curErrorsKeys[0] identifies the first failing field, use that to
                                // find the correct DOM element to call .focus() on.
                                //
                                // Since I set the id attribute on all my form's <input>s to match the error
                                // keys, I just use document.getElementById(), but of course you could use refs
                                const el = document.getElementById(
                                  curErrorsKeys[0]
                                );

                                if (el) el.focus();
                              }
                            }}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            name="cancelForm"
                            id="cancel"
                            class="btn-5 mr-20 mt-30 pull-right"
                            data-dismiss="modal"
                            onClick={handleReset}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default EditRole;
