import React, { Component } from "react";
import { DiscountService } from "../../services/discount.service";

const Discount = (OriginalComponent) => {
  class discountGet extends Component {
    constructor(props) {
      super(props);
      this.state = {
        getDiscountTypeList: [],
        getAllActiveVideos: [],
        getAllActiveCourses: [],
        // getAllVideosAndCourses: [],
      };
    }
    componentDidMount() {
      DiscountService.getDiscountTypeList((res) => {
        // console.log("res-getDiscountTypeList", res.data.responseListObject);
        this.setState({
          getDiscountTypeList: res.data.responseListObject,
        });
      });
      DiscountService.getAllActiveVideos((res) => {
        // console.log("res-getAllActiveVideos", res.data.responseListObject);
        this.setState({
          getAllActiveVideos: res.data.responseListObject,
        });
      });
      DiscountService.getAllActiveCourses((res) => {
        // console.log("res-getAllActiveCourses", res.data.responseListObject);
        this.setState({
          getAllActiveCourses: res.data.responseListObject,
        });
      });
      // DiscountService.getAllVideosAndCourses((res) => {
      //   // console.log("res-getAllVideosAndCourses", res.data.responseListObject);
      //   this.setState({
      //     getAllVideosAndCourses: res.data.responseListObject,
      //   });
      // });
    }

    render() {
      const {
        getDiscountTypeList,
        getAllActiveCourses,
        getAllActiveVideos,
        // getAllVideosAndCourses,
      } = this.state;
      return (
        <OriginalComponent
          getDiscountTypeList={getDiscountTypeList}
          getAllActiveCourses={getAllActiveCourses}
          getAllActiveVideos={getAllActiveVideos}
          // getAllVideosAndCourses={getAllVideosAndCourses}
          {...this.props}
        />
      );
    }
  }
  return discountGet;
};

export default Discount;
