import React, { Component } from "react";
import AdminHeader from "../../components/dashboardheader/adminheader";
import Finxfooter from "../finxfooter/finxfooter";
import DiscountListLandingPage from "../../components/discount/discountList";
// import { connect } from "formik";
import { DiscountService } from "../../services/discount.service";

class discountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getAllDiscounts: [],
      fromDate: "",
      toDate: "",
    };
  }

  // ! Start and End Date Prior 1

  // getDefaultDates = () => {
  //   const date = new Date();
  //   const year = date.getFullYear();
  //   let month = date.getMonth() + 1;
  //   let firstDateOfMonth = new Date(year, month, 1).getDate();

  //   firstDateOfMonth =
  //     firstDateOfMonth < 10 ? "0" + firstDateOfMonth : firstDateOfMonth;
  //   month = month < 10 ? "0" + month : month;

  //   const startDate = `${year}/${month}/${firstDateOfMonth}`;
  //   const endDate = date.toString().substr(0, 10);
  //   console.log("startDate", startDate);
  //   console.log("endDate", endDate);

  // return {
  //   startDate,
  //   endDate,
  // };
  // };

  componentDidMount() {
    // ! Start and End Date Prior 1
    // this.getDefaultDates();

    // ! Start and End Date Prior 2
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - 31);
    // console.log(
    //   "ssss",
    //   endDate.toLocaleDateString(),
    //   startDate.toLocaleDateString()
    // );

    // ! Start and End Date Prior 3

    const monthMilliSeconds = 2629800000;

    // const toDate = new Date().toLocaleDateString();
    const prevMonthMilliseconds =
      parseFloat(new Date().getTime()) - parseFloat(monthMilliSeconds);
    // const fromDate = new Date(prevMonthMilliseconds).toLocaleDateString();
    // console.log("::::::::::::", fromDate, toDate);

    // let params = "fromDate=" + fromDate + "&toDate=" + toDate;
    // let params = "fromDate=" + startDate.toLocaleDateString() + "&toDate=" + endDate.toLocaleDateString();
    // console.log('params', params);

    // this.setState({
    //   fromDate,
    //   toDate,
    // });
    // this.setState({
    //   fromDate: startDate.toLocaleDateString(),
    //   toDate: endDate.toLocaleDateString(),
    // });
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    let dateFormatter = new Intl.DateTimeFormat('en-IN', options);
    let fromDate = dateFormatter.format(startDate);
    let toDate = dateFormatter.format(endDate);
    let params = `fromDate=${fromDate}&toDate=${toDate}`;
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });

    DiscountService.fetchDiscount(params, (res) => {
      if (res.status === "success") {
        this.setState({
          loading: false,
          getAllDiscounts: res.responseListObject,
        });
      } else {
        this.setState({
          loading: false,
          getAllDiscounts: [],
        });
      }
    });

    // ! Fetch All Discounts Without Start And End Date

    // DiscountService.getAllDiscounts((res) => {
    //  // console.log("res-res-getAllDiscounts", res.data.responseListObject);
    //   this.setState({
    //     getAllDiscounts: res.data.responseListObject,
    //   });
    // });
  }
  render() {
    // console.log("state", this.state);
    const { getAllDiscounts, fromDate, toDate } = this.state;

    return (
      <>
        <AdminHeader {...this.props} />
        <DiscountListLandingPage
          getAllDiscounts={getAllDiscounts}
          fromDate={fromDate}
          toDate={toDate}
          {...this.props}
        />
        <Finxfooter />
      </>
    );
  }
}

// export default connect(discountList);
export default discountList;
