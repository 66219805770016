export const FETCH_LEARNING_PATH_PENDING = 'FETCH_LEARNING_PATH_PENDING';
export const FETCH_LEARNING_PATH_SUCCESS = 'FETCH_LEARNING_PATH_SUCCESS';
export const FETCH_LEARNING_PATH_ERROR = 'FETCH_LEARNING_PATH_ERROR';

export function fetchLearningPathPending() {
    return {
        type: FETCH_LEARNING_PATH_PENDING,
    }
}

export function fetchLearningPathSuccess(learningPath) {

    return {
        type: FETCH_LEARNING_PATH_SUCCESS,
        payload: learningPath
    }
}

export function fetchLearningPathError(error) {
    return {
        type: FETCH_LEARNING_PATH_ERROR,
        error: error
    }
}