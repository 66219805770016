import React from 'react';
import { useState } from 'react';
import { Data } from './Data';
import plusIco from '../../../assets/images/plus-ico.png';
import minusIco from '../../../assets/images/minus-ico.png';
import './Accordion.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const AccordionSect = () => {


  return (
    <div className='ap-accordionMain col-100 floatLft'>
        <Accordion allowZeroExpanded="true">
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the Finx Placement Linked Program?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The Finx Placement Linked Program is a job-readiness certification program with an assured job*
with Kotak Mahindra Bank Ltd. (KMBL)*. The program includes <b>Chartered Financial Expert (CFX)</b>,
an industry recognized training and certification course conducted by <b>FinX</b> (AV Financial Experts
Network Pvt Ltd.), and a mandatory NISM V-A Mutual Fund Distributors Certification. The
selected candidates will get the opportunity to join as Corporate Account Manager- Corporate
Salary (Sales) role or other similar roles with our program partner, Kotak Mahindra Bank.
<br />
<h6>*Terms and conditions of program apply.</h6> </li>
                       
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is assured job*?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The assured job from Kotak Mahindra Bank is offered upon clearing Kotak Mahindra Bank’s
interview process, successfully completing the CFX course, and subject to meeting the
employment checks by Kotak Mahindra Bank.</li>                      
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are the benefits of the FinX Placement Linked Program?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                    <li className='col-100 floatLft'>Get an <b>opportunity to be offered* a role</b> as a Corporate Account Manager - Corporate
Salary (Sales) or similar profile, with Kotak Mahindra Bank. Kotak Mahindra Bank, the
fourth largest private sector bank in India, has been certified as the “Best Workplaces’ in
BFSI, India 2022 and also been awarded the “Economic Times Future- Ready 2022-
2023”.</li>
                        <li className='col-100 floatLft'>Receive a <b>Provisional Offer Letter*</b> for placement at Kotak Mahindra Bank <b>before</b> you
start training for the CFX course.</li>
                        <li className='col-100 floatLft'>Get <b>certified</b> with the mandatory NISM-Series-V-A: Mutual Fund Distributors
Certification** as part of this course. This is a mandatory regulatory certification for
many job roles across Kotak Mahindra Bank.</li>
                        <li className='col-100 floatLft'>Start with a <b>CTC of Rs. 3.75 Lacs P.A</b> and monthly/ quarterly performance-linked
incentives.</li>
                        <li className='col-100 floatLft'><b>Applicable conveyance and mobile reimbursements</b> as per Kotak Mahindra Bank
policies.</li>
                        <li className='col-100 floatLft'>Enjoy other <b>benefits and perks</b> in accordance to the role eligibility as per Kotak
Mahindra Bank policies.
<br/><h6>*Terms and conditions of program apply.</h6></li>
                        
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is Chartered Financial Expert (CFX)?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                    <li className='col-100 floatLft'>The Chartered Financial Expert (CFX) course is an industry recognized, short-term
professional certification course to kick-start your career in BFSI sector. It is supported by
Indian Banks Association (IBA), Association of Mutual Funds of India (AMFI), General
Insurance Council (GIC), & Centre for Investment Education and Learning (CIEL). This course
includes training and operational support to conduct mandatory NISM-Series-V-A: Mutual
Fund Distributors Certification Examination.
</li>
                        {/* <li className='col-100 floatLft'>Please click here to register and pay: <a target="_blank" href="https://core.finxpert.org/"><u>https://core.finxpert.org/</u></a><br />
<h6>*Terms and conditions of program apply.</h6></li> */}
                        
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the credibility of the CFX course?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>We have a three-tier governing structure comprising of the Governing Council, Academic
Council and content advisory council. The members include representatives of industry
bodies, heads of co-certification partners, industry leaders, noted academicians and senior
industry professionals.</li>
<li className='col-100 floatLft'>For further details please visit: <a href="https://finxpert.org/about-us/our-credentials/" target="_blank"><u>Our Credentials</u></a></li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What will be the medium of delivery for the training program?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Online instructor led training for 4 days a week and once a week on-the-job training at Morningstar office.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Where will the course be conducted?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The training of the program will be conducted after your regular college timings in your college or a central location. The online classes will be conducted on a virtual delivery platform.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What are the CFX training timings?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The timings of the program will be decided in collaboration with the college based on the regular study schedule of the students. It is approx. 4-5 hours daily.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Who are the trainers?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The training will be imparted by seasoned trainers & practitioners of the BFSI industry, who possess several years of experience.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Who is eligible for the program?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Students with 50% marks in graduation across any stream.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What kind of job role will I be offered? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Students will be inducted as Morningstar Development Program Associates and get an opportunity to work on data research and analysis at Morningstar.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Is there any age limit to do the course? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The recommended age profile is 20 to 25 years. </li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What is the selection process and eligibility criteria?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The students registering for the program will undertake an aptitude test. On successfully clearing the aptitude test, students will be invited for an interview with Morningstar, India. After clearing the interview process, selected candidates will be provided a conditional offer letter from Morningstar to start the program.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Will I get a certificate on completing the program?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Yes, you will receive a certificate from CFX on completion of the training program. And a conditional offer letter from Morningstar.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How can I pay the refundable deposit amount of Rs. 5,000/-?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The payment can be made after registration on the FinX website. Please <a href="#">click here</a> to register. core.finexpert.org</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What topic do I need to prepare for the aptitude test?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>For the aptitude test, prepare yourself in 3 main areas - Logical Reasoning, English Communication and Analytical Skills.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What is the duration of the interview?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Morningstar will inform you via email about interview timings, duration and login credentials.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What will be the timings of on-the-job training at Morningstar India?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The on-the-job training is a full working day at Morningstar, India office.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Is there a dress code for on-the-job training?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>For on-the-job training, formal office wear is the dress code.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What will be the cost to Company?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>The CTC will be between INR 3.99 – 5.10 Lac per annum*and a joining bonus of Rs. 10, 000/-.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Is this a work- from- home or remote location program?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>No. Candidates are expected to complete their on-the-job training at the Morningstar office at Navi Mumbai.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Is there any travel allowance or facility? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Travelling facility will be provided depending on the shift travelling. </li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        If I am working, can I apply for the program ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Since this is an extensive training program that includes on-the-job training, you may have to quit from your current role to apply and pursue this program.</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem> */}
        </Accordion>

        
    </div>
  )
}

export default AccordionSect