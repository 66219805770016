import RestDataSource from './restdatasource';
import { Global_var } from '../global/global_var';
import { LoginService } from "./login.service";

export const FetchAlertService = {
  fetchAlert,
  fetchAlertsByDates,
  fetchPartyAlertCount,
  updateReadUnreadAlerts,
  fetchLatestThreeAlerts
};

function fetchAlert(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_ALERTS_NOTIFICATION_EVENTS;

  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

 function fetchAlertsByDates(param, fn, fnError) {
   var url = Global_var.BASEURL + Global_var.URL_ALERT_LIST_BY_DATE;
   return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
 }


 function fetchPartyAlertCount(param, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_ALERT_COUNTS}`;
  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function fetchLatestThreeAlerts(param, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_LATEST_THREE_ALERTS}`;
  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function updateReadUnreadAlerts(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATE_ALERT_COUNT;
    return new RestDataSource(url).UpdateOneByParam(data, '',res => fn(res.data));
  });
}