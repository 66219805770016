import React, { Component } from "react";
import OpsHeader from "../../components/dashboardheader/opsheader";
import Finxfooter from "../finxfooter/finxfooter";
import EventListLandingPage from "../../components/ops-event/eventList";
import { EventService } from "../../services/event.service";
import { PopUpPortal } from "../popups/PopUpPortal";

class eventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getEventList: [],
      fromDate:"",
      toDate:"",
      loading:false
    };
  }
  componentDidMount() {
    //! Old From Date & To Date Setting Logic Starts
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - 31);
    // let params = "fromDate=" + startDate.toLocaleDateString() + "&toDate=" + endDate.toLocaleDateString();
    // this.setState({
    //     fromDate:startDate.toLocaleDateString(),
    //     toDate:endDate.toLocaleDateString(),
    //   });
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    let dateFormatter = new Intl.DateTimeFormat('en-IN', options);
    let fromDate = dateFormatter.format(startDate);
    let toDate = dateFormatter.format(endDate);
    let params = `fromDate=${fromDate}&toDate=${toDate}`;
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
    //! Old From Date & To Date Setting Logic Ends

    //! New From Date & To Date Setting Logic Starts
    // const startDate1 = new Date();
    // const fromday = String(startDate1.getDate()).padStart(2, '0');
    // const frommonth = String(startDate1.getMonth()).padStart(2, '0');
    // const fromyear = startDate1.getFullYear();
    // const endDate1 = new Date();
    // const todate = String(endDate1.getDate()).padStart(2, '0');
    // const tomonth = String(endDate1.getMonth() + 1).padStart(2, '0');
    // const toyear = endDate1.getFullYear();
    // const startDate2 = `${fromday}/${frommonth}/${fromyear}`;
    // const endDate2 = `${todate}/${tomonth}/${toyear}`;
    // let params = "fromDate=" + startDate2 + "&toDate=" + endDate2;
    // this.setState({
    //   fromDate:startDate2,
    //   toDate:endDate2,
    // });
    //! New From Date & To Date Setting Logic Ends
    this.setState({loading:true})
  EventService.eventReportList(params, (res) => {
      if (res.status === "success") {
        this.setState({
          loading: false,
          getEventList: res.responseListObject,
        });
      } else {
        this.setState({
          loading: false,
          getEventList: [],
          fromDate: "",
          toDate: "",
        });
      }
    });
  }
  render() {
    
    const { getEventList,fromDate,toDate } = this.state;
    return (
      <>
      {this.state.loading && (
        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
      )}
        <OpsHeader {...this.props} />
        <EventListLandingPage 
        getEventList={getEventList}
        fromDate={fromDate}
        toDate={toDate}
        {...this.props} />
        <Finxfooter />
      </>
    );
  }
}

export default eventList;
