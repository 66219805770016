import {
  FETCH_UNASSIGNED_USERS_REPORT_PENDING,
  FETCH_UNASSIGNED_USERS_REPORT,
  FETCH_UNASSIGNED_USERS_REPORT_ERROR
} from "../action/unassignedUsersAction";

const initialState = {
  pending: false,
  unassignedUsersReport: [],
  error: null
};

const unassignedUsersReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNASSIGNED_USERS_REPORT_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_UNASSIGNED_USERS_REPORT:
      return {
        ...state,
        pending: false,
        err: false,
        unassignedUsersReport: action.payload
      };
    case FETCH_UNASSIGNED_USERS_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default unassignedUsersReportReducer;

export const unassignedUsersReport = state => state.unassignedUsersReport;
export const unassignedUsersReportPending = state => state.pending;
export const unassignedUsersReportError = state => state.error;
