import { Global_var } from "../global/global_var";
import RestDataSource from "../services/restdatasource";

export const RegionManagementService = {
  getCountry,
  getState,
  getCity,
  getCities,
  getSuburb,
  getSuburbs,
  validateEmail,
  getUserEmailFlag,
  getMultiCity,
  getMultiSuburb,
  getStateWithJobCount,
  getRecommendedCourseList,
};

function getCountry(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETCOUNTRY;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getStateWithJobCount(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETSTATE_WITH_JOBCOUNT;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getState(country, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETSTATE;
  return new RestDataSource(url, fnError).GetOneByParam(country, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getCity(stateId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETCITY;
  return new RestDataSource(url, fnError).GetOneByParam(stateId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getSuburb(cityId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETSUBURB;

  return new RestDataSource(url, fnError).GetOneByParam(cityId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getCities(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETCITIES;
  return new RestDataSource(url, fnError).Store(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getSuburbs(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETSUBURBS;

  return new RestDataSource(url, fnError).Store(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getMultiCity(stateId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_MULTICITY;
  return new RestDataSource(url, fnError).Store(stateId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getMultiSuburb(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_MULTISUBURB;
  return new RestDataSource(url, fnError).Store(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Email Validate
function validateEmail(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_VALIDATE_EMAIL;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getUserEmailFlag(stateId, fn) {
  var url = Global_var.BASEURL + Global_var.URL_USER_EMAIL_FLAG;
  // console.log(url);
  return new RestDataSource(url).GetOneByParam(stateId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getRecommendedCourseList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETLEARNINGAllPRODUCT;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
