import React, { Component } from "react";
import OpsHeader from "../../components/dashboardheader/opsheader";
import Finxfooter from "../finxfooter/finxfooter";
import AddProductValidity from "../../components/ops-product-validity/addProductValidity";
import { DiscountService } from "../../services/discount.service";

class ProductValidityAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getAllActiveVideos: [],
      getAllActiveCourses: [],
    };
  }  
  componentDidMount() {
    DiscountService.getAllActiveVideos((res) => {
      this.setState({
        getAllActiveVideos: res.data.responseListObject,
      });
    });
    DiscountService.getAllActiveCourses((res) => {
      this.setState({
        getAllActiveCourses: res.data.responseListObject,
      });
    });
  }
  render() {
    const {
      getAllActiveCourses,
      getAllActiveVideos,
      // getAllVideosAndCourses,
    } = this.state;
    return (
      <>
        <OpsHeader {...this.props} />
        <AddProductValidity 
        {...this.props} 
        getAllActiveCourses={getAllActiveCourses}
        getAllActiveVideos={getAllActiveVideos}
        />
        <Finxfooter />
      </>
    );
  }
}

export default ProductValidityAdd;
