import {
  fetchStudentPending,
  fetchStudentSuccess,
  fetchStudentError
} from "../action/studentAction";
import {
  fetchGdpiStatusPending,
  fetchGdpiStatusSuccess,
  fetchGdpiStatusError
} from "../action/gdpiStatusAction";
import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { LoginService } from "./login.service";

export function fetchStudents() {
  let url = Global_var.BASEURL + Global_var.URL_GDPI_STUDENT;
  return dispatch => {
    dispatch(fetchStudentPending());

    return new RestDataSource(url, err =>
      dispatch(fetchStudentError(err.message))
    ).GetData(res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchStudentSuccess(res.data.responseListObject));
      }
    });
  };
}

export function initateGDPI(data, fn, fnError) {
  
  let url = Global_var.BASEURL + Global_var.URL_GDPI_STUDENT;
  return new RestDataSource(url, fnError).Store(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

export function updateGdpiScore(data, cb) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_GDPI_STUDENT;
      return new RestDataSource(url).Update(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          cb(res.data);
        }
      });
    }
  });
}

export function fetchGdpiStatus() {
  let parameter= "codeTypeName=gdpi_status";
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_CD_TYPE_NAME;
  return dispatch => {
    dispatch(fetchGdpiStatusPending());
    return new RestDataSource(url, err =>
      dispatch(fetchGdpiStatusError(err.message))
    ).GetOneByParam(parameter,res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchGdpiStatusSuccess(res.data.responseListObject));
      }
    });
  };
}

export function updateStudentAssessment(data, fn, fnError) {
  
  let url = Global_var.BASEURL + Global_var.URL_ASSESSMENT_UPDATION;
  return new RestDataSource(url, fnError).Update(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// let url = Global_var.BASEURL + Global_var.URL_GDPI_Student;
// return dispatch => {
//     return new RestDataSource(url, (err) => dispatch(fetchStudentError(err.message)))
//         .Store(data, res => {
//             if (res != null) {
//                 if (res.headers["jtitoken"] != null) {
//                     localStorage.setItem('jti-token', res.headers.jtitoken);
//                 }
//                 fn(res.data);
//                 dispatch(fetchStudentSuccess(res.data));

//             }
//         });
// };
