import React, { Component } from "react";
import OpsHeader from "../../components/dashboardheader/opsheader";
import Finxfooter from "../finxfooter/finxfooter";
import EditProductValidity from "../../components/ops-product-validity/editProductValidity";

class ProductValidityEdit extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    // console.log("props", this.props);

    return (
      <>
        <OpsHeader {...this.props} />
        <EditProductValidity {...this.props} />
        <Finxfooter />
      </>
    );
  }
}

export default ProductValidityEdit;
