import { FETCH_STATUS_PENDING, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR } from '../action/productStatusAction';

const initialState = {
    
    pending: false,
    status:[],
    error: null
}

const productStatusReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_STATUS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_STATUS_SUCCESS:
            return {
                ...state,
                pending: false,
                status:action.payload,
            }
        case FETCH_STATUS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default productStatusReducer;
export const fetchStatusPending = state => state.pending;
export const fetchStatusError = state => state.error;
export const getStatus = state => state.status;