import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { LoginService } from "./login.service";
import { fetchAdminUserPending, fetchAdminUserSuccess, fetchAdminUserError } from "../action/adminUserAction";
import { fetchAdminProfileUserPending, fetchAdminProfileUserError, fetchAdminProfileUserSuccess } from "../action/adminProfileUserAction";

export function addAdminUser(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADMIN_CREATE_USER;
      return new RestDataSource(url, fnError).Store(data, res => {
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

export function fetchAdminUser(param) {
  let url = Global_var.BASEURL + Global_var.URL_FETCH_ADMIN_USER;
  return dispatch => {
    dispatch(fetchAdminProfileUserPending());
    return new RestDataSource(url, err =>
      dispatch(fetchAdminProfileUserError(err.message))
    ).GetOneByParam(param, res => {
      if (res !== null) {
        if (res.headers["jtitoken"] !== null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchAdminProfileUserSuccess(res.data.responseListObject));
      }
    });
  };
}

export function fetchAdminUserByPartyAndRole(partyID, roleId, callback) {
  let url =
    Global_var.BASEURL + Global_var.URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID + "?partyID=" + partyID + "&roleID=" + roleId;
  return dispatch => {
    dispatch(fetchAdminUserPending());
    return new RestDataSource(url, err =>
      dispatch(fetchAdminUserError(err.message))
    ).GetData(res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchAdminUserSuccess(res.data.responseObject));
        callback(res.data.responseObject);
      }
    });
  };
}


export function editAdminUser(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADMIN_EDIT_USER;
      return new RestDataSource(url, fnError).Update(data, res => {
        fn(res.data);
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
        }
      });
    }
  });
}

export function inActiveAdminUser(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_INACTIVE_ADMIN_USER;
      return new RestDataSource(url, fnError).Update(data, res => {
        //console.log("RESPONSE", res.data);
        fn(res.data);
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
        }
      });
    }
  });
}
export function unblockUser(data, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_UNBLOCK_USER;
      return new RestDataSource(url, fnError).Update(data, res => {
        //console.log("RESPONSE", res.data);
        fn(res.data);
        if (res !== null) {
          if (res.headers["jtitoken"] !== null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
        }
      });
    }
  });
}
