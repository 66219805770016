import React, { Component } from "react";
import OpsHeader from "../../components/dashboardheader/opsheader";
import Finxfooter from "../finxfooter/finxfooter";
import CloneDiscount from "../../components/ops-discount/cloneDiscount";
import Discount from "./discountGetContainers";

class discountClone extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    // console.log("props", this.props);

    return (
      <>
        <OpsHeader {...this.props} />
        <CloneDiscount {...this.props} />
        <Finxfooter />
      </>
    );
  }
}

export default Discount(discountClone);