import React, { Component } from "react";
import { connect } from "react-redux";
import MyPayments from "../../components/student-report/myPayments";
import {
  getStudentsPaymentHistoryError,
  getStudentsPaymentHistory,
  getStudentsPaymentHistoryPending
} from "../../reducer/studentPaymentHistoryReducer";
import { bindActionCreators } from "redux";
import { getPaymentHistory } from "../../services/fetchStudentDashboard.service";
import { LoginService } from "../../services/login.service";
import AdminHeader from "../../components/dashboardheader/adminheader";
import FinxFooter from "../finxfooter/finxfooter";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import { getAdminUserProfile, getAdminUserProfilePending,/* getAdminUserProfileError */ } from "../../reducer/adminProfileUserReducer";
import { fetchProductListData, } from "../../services/fetchStudentDashboard.service";
import { fetchAdminUser } from "../../services/admin.service";
import {
  getproductDetailsAll, getproductDetailsAllPending,
} from "../../reducer/productDetailsAllReducer";
import { PopUpPortal } from "../popups/PopUpPortal";


const mapStateToProps = state => ({
  productDetailsAll: getproductDetailsAll(state.productDetailsAllReducer),

  productDetailsAllPending: getproductDetailsAllPending(state.productDetailsAllReducer),
  adminProfileUser: getAdminUserProfile(state.adminUserProfileReducer),
  pending: getAdminUserProfilePending(state.adminUserProfileReducer),
  //adminUserPending: getAdminUserProfilePending(state.adminUserProfileReducer),
  studentPaymentHistory: getStudentsPaymentHistory(state.studentsPaymentHistoryReducer),
  pendingstudentPaymentHistory: getStudentsPaymentHistoryPending(state.studentsPaymentHistoryReducer),
  errorstudentPaymentHistory: getStudentsPaymentHistoryError(state.studentsPaymentHistoryReducer)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStudentPaymentHistoryData: getPaymentHistory,
      fetchAdminUser: fetchAdminUser,
      fetchProductListData: fetchProductListData,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const orderReportLanding = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      //console.log("order", this.props.location.state.alerts);
      this.state = {
        roleId: 0,
        loading: true
      };
    }

    componentDidMount() {
      let partyID = "";

      if (localStorage.getItem("login") !== null) {
        let RoleId = JSON.parse(localStorage.getItem("login"))["role"][0][
          "roleId"
        ];
        this.setState({ roleId: RoleId });
        if (RoleId === 5) {
          partyID = localStorage.getItem("userid-Token");
        } else {
          partyID = 0;
        }

        LoginService.TokenUser(res => {
          localStorage.setItem("jwt-token", res.responseObject);
        });
        const { fetchStudentPaymentHistoryData, fetchProductListData, fetchAdminUser } = this.props;

        LoginService.TokenUser(res => {
          if (res.status === "success") {
            sessionStorage.setItem("jwt-token", res.responseObject);
            fetchProductListData();
          }
        });
        // LoginService.TokenUser(res => {
        //   if (res.status === "success") {
        //     sessionStorage.setItem("jwt-token", res.responseObject);
        //     fetchAdminUser("limit=-1");
        //   }
        // });
        LoginService.TokenUser(res => {
          if (res.status === "success") {
            localStorage.setItem("jwt-token", res.responseObject);
            fetchAdminUser("limit=-1");

          }
        });


        // fetchStudentPaymentData();
        let params =
          "studentPartyID=" +
          partyID +
          "&fromDate=" +
          "&toDate=" +
          "&offset=" +
          "&limit=-1" +
          "&orderDir=asc" +
          "&orderColumn=" +
          "&searchText=";

        fetchStudentPaymentHistoryData(params);
      } else {
        window.location.href = "/";
      }
    }

    render() {
      const {
        studentPaymentHistory,
        pendingstudentPaymentHistory,
        productDetailsAllPending,
        pending,
        adminProfileUser,
        productDetailsAll
      } = this.props;


      if (!pendingstudentPaymentHistory && !productDetailsAllPending && !pending) {
        return (
          <React.Fragment>
            {this.state.roleId === 5 ? (
              <DashboardHeader
                //alertNotificationEvents={this.props.location.state.alerts}
                //notificationEvents={this.props.location.state.notification}
                {...this.props}
              />
            ) : (
              <AdminHeader
                //alertNotificationEvents={this.props.location.state !== undefined ? this.props.location.state.alerts || [] : []}
                //notificationEvents={this.props.location.state !== undefined ? this.props.location.state.notification || [] : []}
                {...this.props}
              />
            )}
            <MyPayments
              studentPaymentHistoryData={studentPaymentHistory}
              adminUser={adminProfileUser}
              productDetailsAll={productDetailsAll}
            />
            <FinxFooter />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />
          </React.Fragment>
        )
      }
    }
  }
);

export default orderReportLanding;
