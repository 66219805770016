export const FETCH_STUDENT_PROGRESS_OVERVIEW =
  "FETCH_STUDENT_PROGRESS_OVERVIEW";
export const FETCH_STUDENT_PROGRESS_OVERVIEW_PENDING =
  "FETCH_STUDENT_PROGRESS_OVERVIEW_PENDING";
export const FETCH_STUDENT_PROGRESS_OVERVIEW_ERROR =
  "FETCH_STUDENT_PROGRESS_OVERVIEW_ERROR";

export function fetchStudentProgressOverviewPending() {
  return {
    type: FETCH_STUDENT_PROGRESS_OVERVIEW_PENDING
  };
}

export function fetchStudentProgressOverviewSuccess(studerProgressOverview) {
  return {
    type: FETCH_STUDENT_PROGRESS_OVERVIEW,
    payload: studerProgressOverview
  };
}

export function fetchStudentProgressOverviewError(error) {
  return {
    type: FETCH_STUDENT_PROGRESS_OVERVIEW_ERROR,
    error: error
  };
}
