import { FETCH_LEARNING_PATH_PRODUCT_PENDING, FETCH_LEARNING_PATH_PRODUCT_SUCCESS, FETCH_LEARNING_PATH_PRODUCT_ERROR } from '../action/learningPathProductDataAction';

const initialState = {
    
    pending: false,
    learningPathProductData: [],
    error: null
}

const learningPathProductDataReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_LEARNING_PATH_PRODUCT_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_LEARNING_PATH_PRODUCT_SUCCESS:
            return {
                ...state,
                pending: false,
                learningPathProductData: action.payload,
              
            }
        case FETCH_LEARNING_PATH_PRODUCT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        default:
            return state;
    }
}
export default learningPathProductDataReducer;
export const getlearningPathProductData = state => state.learningPathProductData;
export const getlearningPathProductDataPending = state => state.pending;
export const getlearningPathProductDataError = state => state.error;
