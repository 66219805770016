import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";

export const AssessmentReport = {
  fethAssessmentReport,
  fetchCfxAssessmentReport
};

function fethAssessmentReport(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_ASSESSMENT_REPORT;

  return new RestDataSource(url, fnError).GetOneByParam(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}


function fetchCfxAssessmentReport(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CFX_ASSESSMENT_REPORT;

  return new RestDataSource(url, fnError).GetOneByParam(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
