import React, { Component } from "react";
// import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";

import { connect } from "react-redux";
import {
  getStudents,
  getStudentsPending,
  getStudentsError
} from "../../reducer/studentReducer";
import { bindActionCreators } from "redux";
import { fetchStudents, fetchGdpiStatus } from "../../services/fetchStudents";

import GdpiStudentList from "../../components/gdpi/gdpiStudentList";
import { LoginService } from "../../services/login.service";
import {
  error,
  // errorNotification
} from "../../components/notification/notifications";
import AdminHeader from "../../components/dashboardheader/adminheader";
// import $ from "jquery";
import { getGdpiStatus } from "../../reducer/gdpiStatusReducer";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchStudentDashboard.service";

const mapStateToProps = state => ({
  students: getStudents(state.studentReducer),
  pending: getStudentsPending(state.studentReducer),
  gdpiStatus: getGdpiStatus(state.gdpiStatusReducer),
  err: getStudentsError(state.studentReducer),
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),
  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStudents: fetchStudents,
      fetchGdpiStatus: fetchGdpiStatus,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const gdpiScore = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {

      const { fetchStudents, fetchGdpiStatus,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,
      } = this.props;
      let partyID = localStorage.getItem("userid-Token");

      LoginService.TokenUser(res => {

        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          fetchStudents();
          fetchGdpiStatus();
        }
      });
      // fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID);
    }

    render() {
      const { students, pending, fetchStudents, gdpiStatus,
        // alertNotificationEvents,
        // pendingAlertNotificationEvents,
        // errorAlertNotigicationEvents,
        // notificationEvents,
        // pendingNotificationEvents,
        // errorNotificationEvents,
      } = this.props;

      //console.log("alertNotificationEvents",this.props.alertNotificationEvents)
      //console.log("notificationEvents",this.props.notificationEvents)

      if (pending) {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />

            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <GdpiStudentList
              students={students}
              error={error}
              fetchStudents={fetchStudents}
              gdpiStatus={gdpiStatus}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      } else {

        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              {...this.props}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <GdpiStudentList
              students={students}
              error={error}
              fetchStudents={fetchStudents}
              gdpiStatus={gdpiStatus}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
    }
  }
);

export default gdpiScore;
