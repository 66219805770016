import { FETCH_ADMIN_PROFILE_USER_ACTION_PENDING, FETCH_ADMIN_PROFILE_USER_ACTION_SUCCESS, FETCH_ADMIN_PROFILE_USER_ACTION_ERROR } from '../action/adminProfileUserAction';

const initialState = {
    
    pending: false,
    adminProfileUser: [],
    error: null
}

const adminUserProfileReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_ADMIN_PROFILE_USER_ACTION_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_ADMIN_PROFILE_USER_ACTION_SUCCESS:
            return {
                ...state,
                pending: false,
                adminProfileUser: action.payload,
              
            }
        case FETCH_ADMIN_PROFILE_USER_ACTION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        default:
            return state;
    }
}
export default adminUserProfileReducer;
export const getAdminUserProfile = state => state.adminProfileUser;
export const getAdminUserProfilePending = state => state.pending;
export const getAdminUserProfileError = state => state.error;
