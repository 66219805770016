export const FETCH_VOCATIONAL_COURSES_ALL_PENDING = 'FETCH_VOCATIONAL_COURSES_ALL_PENDING';
export const FETCH_VOCATIONAL_COURSES_ALL_SUCCESS = 'FETCH_VOCATIONAL_COURSES_ALL_SUCCESS';
export const FETCH_VOCATIONAL_COURSES_ALL_ERROR = 'FETCH_VOCATIONAL_COURSES_ALL_ERROR';

export function fetchVocationalCoursesAllPending() {
    return {
        type: FETCH_VOCATIONAL_COURSES_ALL_PENDING,
    }
}

export function fetchVocationalCoursesAllSuccess(Vocational_Courses_All) {

    return {
        type: FETCH_VOCATIONAL_COURSES_ALL_SUCCESS,
        payload: Vocational_Courses_All
    }
}

export function fetchVocationalCoursesAllError(error) {
    return {
        type: FETCH_VOCATIONAL_COURSES_ALL_ERROR,
        error: error
    }
}