import React, { Component } from "react";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  warning,
  warningNotification,
  success,
  successNotification,
} from "../notification/notifications";
import { withRouter } from "react-router-dom";
import { DiscountService } from "../../services/discount.service";
import DiscountTooltip from "./DiscountTooltip";
import Logo from "../../assets/images/infoicon.png"

class CloneDiscount extends Component {
  constructor(props) {
    super(props);

    var todaydate = new Date();
    this.state = {
      loading: false,
      today: moment(todaydate).format("YYYY-MM-DD"),
      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
      fetchDiscountDetails: {},
      isSaveBtnDisabled: false,
      isSaveDraftBtnDisabled: false,
    };
  }

  handleCancel = () => {
    this.props.history.goBack("-1");
  };
  componentDidMount() {
    this.initailizedDateDetails();
    this.fetchDiscountDetailsOnId();
  }
  initailizedDateDetails() {
    var listOfYear = [];
    var listOfMonth = [];
    var listOfDay = [];
    // var year = new Date().getFullYear() + 1;

    // for (var i = 0; i < 10; i++) {
    //   var current = year - i;
    //   listOfYear.push(current);
    // }
    var year = 2018;

    for (var i = 1; year + i < 2051; i++) {
      var current = year + i;
      listOfYear.push(current);
    }
    listOfYear.reverse();

    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    for (var ld = 1; ld <= 31; ld++) {
      listOfDay.push(ld);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear: listOfYear });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });
  }
  fetchDiscountDetailsOnId() {
    // console.log("discountId", this.props.location.state.discountId);
    let discountId = this.props.location.state.discountId;
    let params = "discountId=" + discountId;
    DiscountService.fetchDiscountDetailsOnID(params, (res) => {
      // console.log('res',res);

      if (res.status === "success") {
        let fromDateString = res.responseObject.fromDate;
        let toDateString = res.responseObject.toDate;
        let fromDate = `${parseInt(fromDateString.split("/")[1])}/${
          fromDateString.split("/")[0]
        }/${fromDateString.split("/")[2]}`;
        let toDate = `${parseInt(toDateString.split("/")[1])}/${
          toDateString.split("/")[0]
        }/${toDateString.split("/")[2]}`;
        // console.log(
        //   "FORMATTED",
        //   fromDate,
        //   toDate,
        //   new Date(toDate).getMonth() + 1
        // );
        res.responseObject.fromDate = fromDate;
        res.responseObject.toDate = toDate;
        this.setState({
          loading: false,
          fetchDiscountDetails: res.responseObject,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  handleDiscountType(event, setFieldValue) {
    // console.log("event target value", event.target.value);
    // console.log('value',value);

    event.preventDefault();
    event.target.value === "3"
      ? setFieldValue("discountValue", "")
      : setFieldValue("freeGift", "");

    setFieldValue("discountType", event.target.value);
  }

  render() {
    console.log("props", this.props);
    console.log("fetchDiscountDetails", this.state.fetchDiscountDetails);
    // console.log("Type ID", this.state.fetchDiscountDetails.discountTypeID);
    const { fetchDiscountDetails } = this.state;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          isActive: fetchDiscountDetails.isActive,
          discountType: `${fetchDiscountDetails.discountTypeID}`,
          discountName: fetchDiscountDetails.discountName,
          discountDescription: fetchDiscountDetails.discountDesc
            ? fetchDiscountDetails.discountDesc
            : "",
          discountValue: fetchDiscountDetails.discountValue
            ? fetchDiscountDetails.discountValue
            : "",
          freeGift: fetchDiscountDetails.freeGift
            ? fetchDiscountDetails.freeGift
            : "",
          applicableType: fetchDiscountDetails.applicableType,
          discountCode: fetchDiscountDetails.discountCode
            ? fetchDiscountDetails.discountCode
            : "",
          applyFromDate: "",
          expiryDate: "",
          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          listOfMonth1: this.state.listOfMonth,
          listOfDay1: this.state.listOfDay,
          fromday:
            fetchDiscountDetails === undefined
              ? ""
              : new Date(fetchDiscountDetails.fromDate).getDate(),
          frommonth:
            fetchDiscountDetails === undefined
              ? ""
              : new Date(fetchDiscountDetails.fromDate).getMonth() + 1,
          fromyear:
            fetchDiscountDetails === undefined
              ? ""
              : new Date(fetchDiscountDetails.fromDate).getFullYear(),
          today:
            fetchDiscountDetails === undefined
              ? ""
              : new Date(fetchDiscountDetails.toDate).getDate(),
          tomonth:
            fetchDiscountDetails === undefined
              ? ""
              : new Date(fetchDiscountDetails.toDate).getMonth() + 1,
          toyear:
            fetchDiscountDetails === undefined
              ? ""
              : new Date(fetchDiscountDetails.toDate).getFullYear(),
          discountLevel: fetchDiscountDetails.discountLevel,
          associatedProduct: fetchDiscountDetails.discountProductIds,
          mandateType: fetchDiscountDetails.mandateType,
          getDiscountTypeList: this.props.getDiscountTypeList,
          getAllActiveVideos: this.props.getAllActiveVideos,
          getAllActiveCourses: this.props.getAllActiveCourses,
          // getAllVideosAndCourses: this.props.getAllVideosAndCourses,
        }}
        validationSchema={Yup.object().shape({
          discountName: Yup.string()
            .required("You must specify a Discount Name")
            .max(50, "Discount Name not greater than 50"),
          discountDescription: Yup.string().max(
            100,
            "Discount Description not greater than 100"
          ),
          discountValue: Yup.string().when("discountType", {
            is: (discountType) =>
              discountType === "1" || discountType === "2" ? true : false,
            then: Yup.string()
              .required("Discount Value is required")
              .max(15, "Discount Value not greater than 15")
              .matches(
                // /^([1-9]\d*|0{1})([1-9]\d*|0)(\.\d{0,2})?$/,
                /^([1-9]\d*|0)(\.\d{0,2})?$/,
                // "Please provide valid Discount Value more than one number and 2 decimals values only"
                "Please provide valid Discount Value and 2 decimals values only"
              ),
            otherwise: Yup.string(),
          }),
          freeGift: Yup.string().when("discountType", {
            is: (discountType) => (discountType === "3" ? true : false),
            then: Yup.string()
              .required("Free Gift is required")
              .max(100, "Free Gift Value not greater than 100"),
            otherwise: Yup.string(),
          }),
          applicableType: Yup.string().required(
            "You must specify a Applicable Type"
          ),
          discountCode: Yup.string().when("applicableType", {
            is: (applicableType) =>
              applicableType === "Discount_Code" ? true : false,
            then: Yup.string()
              .required("Discount Code is required")
              .max(50, "Discount Code not greater than 50"),
            otherwise: Yup.string(),
          }),
          fromday: Yup.string().required("You must specify a Apply From Date"),
          frommonth: Yup.string().required(
            "You must specify a Apply From Month"
          ),
          fromyear: Yup.string().required("You must specify a Apply From Year"),
          today: Yup.string().required("You must specify a Expiry Date"),
          tomonth: Yup.string().required("You must specify a Expiry Month"),
          toyear: Yup.string().required("You must specify a Expiry Year"),
          discountLevel: Yup.string().required(
            "You must specify a Discount Level"
          ),
          associatedProduct: Yup.string().when("discountLevel", {
            is: (discountLevel) =>
              discountLevel === "COURSE" ||
              discountLevel === "VIDEO" ||
              discountLevel === "COURSE&VIDEO"
                ? true
                : false,
            then: Yup.string().required("Associated Product is required"),
            otherwise: Yup.string(),
          }),
        })}
        onSubmit={(fields) => {
          // console.log("On Clone");

          switch (fields.discountType) {
            case "1":
              fields.freeGift = "";
              break;
            case "2":
              fields.freeGift = "";
              break;
            case "3":
              fields.discountValue = "";
              break;
            default:
              break;
          }

          let formattedFromDate = "";
          let formattedEndDate = "";

          if (
            (fields["fromyear"] !== undefined && fields["fromyear"] !== "") ||
            (fields["frommonth"] !== undefined && fields["frommonth"] !== "") ||
            (fields["fromday"] !== undefined && fields["fromday"] !== "")
          ) {
            let fromdy =
              fields["fromday"].length === 1
                ? "0" + fields["fromday"]
                : fields["fromday"];
            let frommont =
              fields["frommonth"].length === 1
                ? "0" + fields["frommonth"]
                : fields["frommonth"];
            formattedFromDate =
              fromdy + "/" + frommont + "/" + fields["fromyear"] + " 00:00:00";
          } else {
            formattedFromDate = "";
          }

          if (
            (fields["toyear"] !== undefined && fields["toyear"] !== "") ||
            (fields["tomonth"] !== undefined && fields["tomonth"] !== "") ||
            (fields["today"] !== undefined && fields["today"] !== "")
          ) {
            let tody =
              fields["today"].length === 1
                ? "0" + fields["today"]
                : fields["today"];
            let tomont =
              fields["tomonth"].length === 1
                ? "0" + fields["tomonth"]
                : fields["tomonth"];

            formattedEndDate =
              tody + "/" + tomont + "/" + fields["toyear"] + " 00:00:00";
          } else {
            formattedEndDate = "";
          }

          let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
          let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
          let todayConverted = moment(new Date().toLocaleString(), "DD.MM.YYYY");

          if (toDtConverted.diff(fromDtConverted, "days") < 0) {
            this.setState({ loading: false });
            warning(
              'The “Expiry Date” should be after the “Apply From Date”',
              warningNotification
            );
          }
          else if (fromDtConverted.diff(todayConverted, "days") < 0) {
            this.setState({ loading: false });
            warning(
              "Apply From Date should be greater than or equal to Today Date",
              warningNotification
            );
          }
          else {
            let product = [];
            if (fields.discountLevel !== "ALL") {
              for (var i = 0; i < fields.associatedProduct.length; i++) {
                let productObject = {
                  productId: fields.associatedProduct[i].split("-")[1],
                  productType: fields.associatedProduct[i].split("-")[0],
                };
                product.push(productObject);
              }
            } else {
              let productObject = {
                productId: 0,
                productType: "ALL",
              };
              product.push(productObject);
            }

            let data = {
              discountName: fields.discountName,
              discountDesc: fields.discountDescription
                ? fields.discountDescription
                : "",
              discountTypeID: fields.discountType,
              discountValue: fields.discountValue
                ? fields.discountValue.toFixed(2)
                : null,
              freeGift: fields.freeGift,
              isActive: fields.isActive,
              discountCode: fields.discountCode ? fields.discountCode : null,
              fromDate: formattedFromDate,
              toDate: formattedEndDate,
              mandateType: fields.mandateType,
              discountProducts: product,
            };
            this.setState({ loading: true})
            if([1,"1"].includes(fields.isActive)){
              this.setState({isSaveBtnDisabled: true})
            }else if([2,"2"].includes(fields.isActive)){
              this.setState({isSaveDraftBtnDisabled: true})
            }
            DiscountService.addDiscount(
              data,
              (cb) => {
                // console.log("cb", cb);
                // console.log("cb.status", cb.status);

                if (cb.status === "success") {
                  this.setState({ loading: false });
                  success("Discount added successfully!", successNotification);
                  this.props.history.push("/discount-list");
                } else {
                  // console.log("cb.status", cb.status);

                  warning(cb.reasonText, {
                    ...warningNotification,
                    container: "top-center",
                  });
                  this.setState({ loading: false,isSaveBtnDisabled: false,isSaveDraftBtnDisabled: false });
                }
              },
              (err) => console.log("Error", err)
            );

            // console.log("data", data);
            // alert(JSON.stringify(data, null, 2));
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleReset,
          handleBlur,
          handleChange,
          setFieldValue,
          ...formikProps
        }) => (
          <>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section className="dashboard-tab user-profile pt-50 mb-50">
              <Form onSubmit={handleSubmit} className="form-group">
                <div className="">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-130">
                        <div className="bg-box">
                          <section className="create-user" id="create-user">
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">Add Discount</h2>
                                <div className="form-group">
                                  <div className="row">
                                  <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div 
                                      className="col-md-10 col-lg-10 col-sm-10 col-xs-10 pl pr ">
                                      <label>
                                        Discount Type{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="discountType"
                                        id="discountType"
                                        // onChange={handleChange}
                                        // onChange={(event) => (
                                        //   handleReset(event),
                                        //   handleChange(event)
                                        // )}
                                        onChange={(event, value) =>
                                          this.handleDiscountType(
                                            event,
                                            setFieldValue,
                                            value
                                          )
                                        }
                                        onBlur={handleBlur}
                                        value={values.discountType}
                                        title={
                                          values.discountType === "1"
                                            ? "Percentage Based"
                                            : values.discountType === "2"
                                            ? "Fixed Amount"
                                            : "Free Gift"
                                        }
                                        className={
                                          "form-control" +
                                          (errors.discountType &&
                                          touched.discountType
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        {values.getDiscountTypeList.map(
                                          (data) => (
                                            <option
                                              key={data.discount_typeid}
                                              value={data.discount_typeid}
                                            >
                                              {data.discount_type}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                      </div>
                                      <div 
                                      className="col-md-2 col-lg-2 col-sm-2 col-xs-2 pl pr  ">
                                      <Tippy
                                        maxWidth={"100%"}
                                        delay={500}
                                        placement={"top"}
                                        content={<DiscountTooltip />}
                                      >
                                        {/* <i
                                          className="fa fa-exclamation discountTooltip"
                                          aria-hidden="true"
                                        ></i> */}
                                          <img 
                                            style={{
                                              maxWidth:"30px",maxHeight:"30px",width:"23px",height:"23px", marginLeft:"7px" ,marginTop:"45px"

                                            }}
                                              
                                              src={Logo} alt="Info Icon" />  
                                      </Tippy>
                                      </div>
                                      <div 
                                   
                                    className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr ">
                                      <ErrorMessage
                                        name="discountType"
                                        component="div"
                                        className="validation"
                                      />
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Discount Name{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="discountName"
                                        id="discountName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.discountName}
                                        title={values.discountName}
                                        placeholder="Discount Name"
                                        className={
                                          "form-control" +
                                          (errors.discountName &&
                                          touched.discountName
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="discountName"
                                        className="validation "
                                        component="div"
                                      />
                                    </div>

                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>Discount Description </label>
                                      <Field
                                        type="text"
                                        title={values.discountDescription}
                                        name="discountDescription"
                                        id="discountDescription"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.discountDescription}
                                        placeholder="Discount Description"
                                        className={
                                          "form-control" +
                                          (errors.discountDescription &&
                                          touched.discountDescription
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="discountDescription"
                                        className="validation "
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    {(values.discountType === "1" ||
                                      values.discountType === "2") && (
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                          {values.discountType === "1"
                                            ? "Discount Value (%)"
                                            : "Discount Value (₹)"}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="number"
                                          // type="text"
                                          name="discountValue"
                                          id="discountValue"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.discountValue}
                                          title={values.discountValue}
                                          placeholder={
                                            values.discountType === "1"
                                              ? "Percentage Based"
                                              : "Fixed Amount"
                                          }
                                          className={
                                            "form-control" +
                                            (errors.discountValue &&
                                            touched.discountValue
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="discountValue"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    )}

                                    {values.discountType === "3" && (
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                          Free Gift{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="freeGift"
                                          id="freeGift"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.freeGift}
                                          title={values.freeGift}
                                          placeholder="Free Gift"
                                          className={
                                            "form-control" +
                                            (errors.freeGift && touched.freeGift
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="freeGift"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                    )}
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Apply From Date{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="fromday"
                                            id="fromday"
                                            onChange={(e) => {
                                              console.log(
                                                "fromday",
                                                values.fromday
                                              );

                                              var listOfMonth = [];
                                              setFieldValue(
                                                (values.fromday =
                                                  e.target.value)
                                              );
                                              if (
                                                [31, "31"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [30, "30"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, "28", 29, "29"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue(
                                                (values.listOfMonth = listOfMonth)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.fromday && touched.fromday
                                                ? "is-invalid"
                                                : "")
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay || []).map(
                                              (d, index) => (
                                                <option key={index} value={d}>
                                                  {" "}
                                                  {d}{" "}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="fromday"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="frommonth"
                                            id="frommonth"
                                            onChange={(e) => {
                                              console.log(
                                                "frommonth",
                                                values.frommonth
                                              );
                                              setFieldValue(
                                                (values.frommonth =
                                                  e.target.value)
                                              );
                                              if (
                                                [2, "2"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                // e.target.value == 1 ||
                                                // e.target.value == 3 ||
                                                // e.target.value == 5 ||
                                                // e.target.value == 7 ||
                                                // e.target.value == 8 ||
                                                // e.target.value == 10 ||
                                                // e.target.value == 12
                                                [
                                                  1,
                                                  "1",
                                                  3,
                                                  "3",
                                                  5,
                                                  "5",
                                                  7,
                                                  "7",
                                                  8,
                                                  "8",
                                                  10,
                                                  "10",
                                                  12,
                                                  "12",
                                                ].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (
                                                  var ldd = 1;
                                                  ldd <= 31;
                                                  ldd++
                                                ) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (
                                                  var lod = 1;
                                                  lod <= 30;
                                                  lod++
                                                ) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue(
                                                (values.listOfDay = listOfDay)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.month && touched.month
                                                ? "is-invalid"
                                                : "")
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth || []).map(
                                              (m, index) => (
                                                <option
                                                  key={index}
                                                  value={m.key}
                                                >
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="frommonth"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="fromyear"
                                            id="fromyear"
                                            onChange={(e) => {
                                              console.log(
                                                "fromyear",
                                                values.fromyear
                                              );
                                              setFieldValue(
                                                (values.fromyear =
                                                  e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.fromyear &&
                                              touched.fromyear
                                                ? "is-invalid"
                                                : "")
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">YYYY</option>
                                            {(this.state.listOfYear || []).map(
                                              (year, index) => (
                                                <option
                                                  key={index}
                                                  value={year}
                                                >
                                                  {year}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="fromyear"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Expiry Date{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="today"
                                            id="today"
                                            onChange={(e) => {
                                              console.log(
                                                "today",
                                                values.today
                                              );
                                              var listOfMonth = [];
                                              setFieldValue(
                                                (values.today = e.target.value)
                                              );

                                              if (
                                                [31, "31"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [30, "30"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, "28", 29, "29"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue(
                                                (values.listOfMonth1 = listOfMonth)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.today && touched.today
                                                ? "is-invalid"
                                                : "")
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay1 || []).map(
                                              (d, index) => (
                                                <option key={index} value={d}>
                                                  {" "}
                                                  {d}{" "}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="today"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="tomonth"
                                            id="tomonth"
                                            onChange={(e) => {
                                              console.log(
                                                "tomonth",
                                                values.tomonth
                                              );
                                              setFieldValue(
                                                (values.tomonth =
                                                  e.target.value)
                                              );
                                              if (
                                                [2, "2"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                // e.target.value == 1 ||
                                                // e.target.value == 3 ||
                                                // e.target.value == 5 ||
                                                // e.target.value == 7 ||
                                                // e.target.value == 8 ||
                                                // e.target.value == 10 ||
                                                // e.target.value == 12
                                                [
                                                  1,
                                                  "1",
                                                  3,
                                                  "3",
                                                  5,
                                                  "5",
                                                  7,
                                                  "7",
                                                  8,
                                                  "8",
                                                  10,
                                                  "10",
                                                  12,
                                                  "12",
                                                ].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (
                                                  var ldd = 1;
                                                  ldd <= 31;
                                                  ldd++
                                                ) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (
                                                  var lod = 1;
                                                  lod <= 30;
                                                  lod++
                                                ) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue(
                                                (values.listOfDay1 = listOfDay)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.tomonth && touched.tomonth
                                                ? "is-invalid"
                                                : "")
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth1 || []).map(
                                              (m, index) => (
                                                <option
                                                  key={index}
                                                  value={m.key}
                                                >
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="tomonth"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="toyear"
                                            id="toyear"
                                            onChange={(e) => {
                                              console.log(
                                                "toyear",
                                                values.toyear
                                              );
                                              setFieldValue(
                                                (values.toyear = e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.toyear && touched.toyear
                                                ? "is-invalid"
                                                : "")
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">YYYY</option>
                                            {(this.state.listOfYear1 || []).map(
                                              (year, index) => (
                                                <option
                                                  key={index}
                                                  value={year}
                                                >
                                                  {year}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="toyear"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Mandate Type{" "}
                                        <span className="validation"> *</span>
                                      </label>

                                      <Field
                                        as="select"
                                        name="mandateType"
                                        id="mandateType"
                                        onChange={handleChange}
                                        title={
                                          values.mandateType === "public"
                                            ? "Public"
                                            : "Private"
                                        }
                                        value={values.mandateType}
                                        className={
                                          "form-control" +
                                          (errors.mandateType &&
                                          touched.mandateType
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                      </Field>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Applicable Type{" "}
                                        <span className="validation"> *</span>
                                      </label>

                                      <Field
                                        as="select"
                                        name="applicableType"
                                        id="applicableType"
                                        // onChange={handleChange}
                                        onChange={(event) => {
                                          handleChange(event);
                                          setFieldValue("discountCode", "");
                                        }}
                                        title={
                                          values.applicableType ===
                                          "Direct_applicable"
                                            ? "Direct Applicable"
                                            : "Discount Code"
                                        }
                                        value={values.applicableType}
                                        className={
                                          "form-control" +
                                          (errors.applicableType &&
                                          touched.applicableType
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">-- Select --</option>
                                        <option value="Direct_applicable">
                                          Direct Applicable
                                        </option>
                                        <option value="Discount_Code">
                                          Discount Code
                                        </option>
                                      </Field>

                                      <ErrorMessage
                                        name="applicableType"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    {values.applicableType ===
                                      "Discount_Code" && (
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                          Discount Code{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="discountCode"
                                          name="discountCode"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.discountCode}
                                          title={values.discountCode}
                                          placeholder="Discount Code"
                                          className={
                                            "form-control" +
                                            (errors.discountCode &&
                                            touched.discountCode
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="discountCode"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Discount Level
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="discountLevel"
                                        id="discountLevel"
                                        onChange={handleChange}
                                        // onChange={(event) => (
                                        //   handleChange(event),
                                        //   setFieldValue("associatedProduct", [])
                                        // )}
                                        onBlur={handleBlur}
                                        value={values.discountLevel}
                                        title={
                                          values.discountLevel === "ALL"
                                            ? "For All"
                                            : values.discountLevel === "COURSE"
                                            ? "Course"
                                            : values.discountLevel === "VIDEO"
                                            ? "Video"
                                            : "Course & Video"
                                        }
                                        className={
                                          "form-control" +
                                          (errors.discountLevel &&
                                          touched.discountLevel
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">-- Select --</option>
                                        <option value="ALL">For All</option>
                                        <option value="COURSE"> Course </option>
                                        <option value="VIDEO"> Video </option>
                                        <option value="COURSE&VIDEO">
                                          Course &amp; Video{" "}
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="discountLevel"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    {(values.discountLevel === "COURSE" ||
                                      values.discountLevel === "VIDEO" ||
                                      values.discountLevel ===
                                        "COURSE&VIDEO") && (
                                      <div className="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                          Associated Product{" "}
                                          <span className="validation"> *</span>
                                        </label>

                                        <Field
                                          multiple
                                          as="select"
                                          name="associatedProduct"
                                          id="associatedProduct"
                                          onChange={handleChange}
                                          // onChange={(event) => (
                                          //   handleChange(event),
                                          //   setFieldValue("associatedProduct", [])
                                          // )}
                                          onBlur={handleBlur}
                                          value={values.associatedProduct}
                                          title={values.associatedProduct}
                                          className={
                                            "form-control" +
                                            (errors.associatedProduct &&
                                            touched.associatedProduct
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          {
                                            values.discountLevel ===
                                            "COURSE" ? (
                                              values.getAllActiveCourses.map(
                                                (data) => (
                                                  <option
                                                    title={data.productName}
                                                    key={data.productId}
                                                    value={`COURSE-${data.productId}`}
                                                  >
                                                    {data.productName}
                                                  </option>
                                                )
                                              )
                                            ) : values.discountLevel ===
                                              "VIDEO" ? (
                                              values.getAllActiveVideos.map(
                                                (data) => (
                                                  <option
                                                    title={data.productName}
                                                    key={data.productId}
                                                    value={`VIDEO-${data.productId}`}
                                                  >
                                                    {data.productName}
                                                  </option>
                                                )
                                              )
                                            ) : (
                                              <>
                                                <optgroup label="Course">
                                                  {values.getAllActiveCourses.map(
                                                    (data) => (
                                                      <option
                                                        title={data.productName}
                                                        key={data.productId}
                                                        value={`COURSE-${data.productId}`}
                                                      >
                                                        {data.productName}
                                                      </option>
                                                    )
                                                  )}
                                                </optgroup>
                                                <optgroup label="Video">
                                                  {values.getAllActiveVideos.map(
                                                    (data) => (
                                                      <option
                                                        title={data.productName}
                                                        key={data.productId}
                                                        value={`VIDEO-${data.productId}`}
                                                      >
                                                        {data.productName}
                                                      </option>
                                                    )
                                                  )}
                                                </optgroup>
                                              </>
                                            )
                                            // values.getAllVideosAndCourses.map(
                                            //     (data) => (
                                            //       <option
                                            //         key={data.productId}
                                            //         value={`${data.productName
                                            //           .split("-")[0]
                                            //           .trim()}-${
                                            //           data.productId
                                            //         }`}
                                            //       >
                                            //         {data.productName}
                                            //       </option>
                                            //     )
                                            //   )
                                          }
                                        </Field>

                                        <ErrorMessage
                                          name="associatedProduct"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                            <button
                              name="cancelForm"
                              type="button"
                              className="btn-4 pull-right"
                              onClick={this.handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              name="Save"
                              type="submit"
                              className="btn-4 mr-20 pull-right"
                              onClick={() => {
                                setFieldValue("isActive", 1);
                              }}
                              disabled={this.state.isSaveBtnDisabled}
                              style={{
                                opacity: this.state.isSaveBtnDisabled ? 0.5 : 1 ,
                                cursor: this.state.isSaveBtnDisabled ? "not-allowed" : "pointer" 
                              }}
                            >
                              Submit
                            </button>
                            <button
                              name="SaveAsDraft"
                              type="submit"
                              value="Draft"
                              className="btn-4 mr-20 pull-right draft-btn"
                              onClick={() => {
                                setFieldValue("isActive", 2);
                              }}
                              disabled={this.state.isSaveDraftBtnDisabled}
                              style={{
                                opacity: this.state.isSaveDraftBtnDisabled ? 0.5 : 1 ,
                                cursor: this.state.isSaveDraftBtnDisabled ? "not-allowed" : "pointer" 
                              }}

                            >
                              Save as Draft
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </section>
          </>
        )}
      />
    );
  }
}
export default withRouter(CloneDiscount);
