import { FETCH_PRODUCT_PRE_REQUISITE_PENDING, FETCH_PRODUCT_PRE_REQUISITE_SUCCESS, FETCH_PRODUCT_PRE_REQUISITE_ERROR } from '../action/productPreRequisiteAction';

const initialState = {
    pending: false,
    productPreRequisite: [],
    error: null
}

const productPreRequisiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRODUCT_PRE_REQUISITE_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_PRODUCT_PRE_REQUISITE_SUCCESS:
            return {
                ...state,
                pending: false,
                productPreRequisite: action.payload
            }
        case FETCH_PRODUCT_PRE_REQUISITE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default productPreRequisiteReducer;
export const getProductPreRequisite = state => state.productPreRequisite;
export const getProductPreRequisitePending = state => state.pending;
export const getProductPreRequisiteError = state => state.error;