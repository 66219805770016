// Library Imports
import * as React from "react";
import * as ReactDOM from "react-dom";

export class Modal2 extends React.Component {
    element = document.createElement("div");
    modalRoot = document.body;

    componentDidMount() {
        this.element.className = "my-modal";
        this.modalRoot.appendChild(this.element);
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.element);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.element);
    }
}
