export const FETCH_ASSESSMENT_REPORT = "FETCH_ASSESSMENT_REPORT";
export const FETCH_ASSESSMENT_REPORT_PENDING =
  "FETCH_ASSESSMENT_REPORT_PENDING";
export const FETCH_ASSESSMENT_REPORT_ERROR = "FETCH_ASSESSMENT_REPORT_ERROR";

export function fetchAssessmentReportPending() {
  return {
    type: FETCH_ASSESSMENT_REPORT_PENDING
  };
}

export function fetchAssessmentReportSuccess(assessmentReport) {
  return {
    type: FETCH_ASSESSMENT_REPORT,
    payload: assessmentReport
  };
}

export function fetchAssessmentReportError(error) {
  return {
    type: FETCH_ASSESSMENT_REPORT_ERROR,
    error: error
  };
}
