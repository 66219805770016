import {
  FETCH_STUDENT_PAYMENT_HISTORY_PENDING,
  FETCH_STUDENT_PAYMENT_HISTORY,
  FETCH_STUDENT_PAYMENT_HISTORY_ERROR
} from "../action/fetchStudentPaymentHistory";

const initialState = {
  pending: false,
  studentsPaymentHistory: [],
  error: null
};

const studentsPaymentHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDENT_PAYMENT_HISTORY_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_STUDENT_PAYMENT_HISTORY:
      return {
        ...state,
        pending: false,
        err: false,
        studentsPaymentHistory: action.payload
      };
    case FETCH_STUDENT_PAYMENT_HISTORY_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default studentsPaymentHistoryReducer;

export const getStudentsPaymentHistory = state => state.studentsPaymentHistory;
export const getStudentsPaymentHistoryPending = state => state.pending;
export const getStudentsPaymentHistoryError = state => state.error;
