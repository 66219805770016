import {FETCH_NOTIFICATION_EVENTS_SUCCESS, FETCH_NOTIFICATION_EVENTS_PENDING,  FETCH_NOTIFICATION_EVENTS_ERROR } from '../action/notificationAction';

const initialState = {
    pending: false,
    notificationEvents: [],   
    error: null
}

const notificationEventsReducer = (state = initialState, action) => {
    ;
    switch (action.type) {
        case FETCH_NOTIFICATION_EVENTS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_NOTIFICATION_EVENTS_SUCCESS:
            return {
                ...state,
                pending: false,
                notificationEvents: action.payload
            }
        case FETCH_NOTIFICATION_EVENTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }      
        default:
            return state;
    }
}
export default notificationEventsReducer;
export const getNotificationEvents = state => state.notificationEvents;
export const getNotificationEventsPending = state => state.pending;
export const getNotificationEventsError = state => state.error;