export const FETCH_PRODUCT_DETAILS_ALL_PENDING = 'FETCH_PRODUCT_DETAILS_ALL_PENDING';
export const FETCH_PRODUCT_DETAILS_ALL_SUCCESS = 'FETCH_PRODUCT_DETAILS_ALL_SUCCESS';
export const FETCH_PRODUCT_DETAILS_ALL_ERROR = 'FETCH_PRODUCT_DETAILS_ALL_ERROR';

export function fetchProductDetailsAllPending() {
    return {
        type: FETCH_PRODUCT_DETAILS_ALL_PENDING,
    }
}

export function fetchProductDetailsAllSuccess(Product_Details_ALL) {

    return {
        type: FETCH_PRODUCT_DETAILS_ALL_SUCCESS,
        payload: Product_Details_ALL
    }
}

export function fetchProductDetailsAllError(error) {
    return {
        type: FETCH_PRODUCT_DETAILS_ALL_ERROR,
        error: error
    }
}