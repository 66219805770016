export const FETCH_ALERT_NOTIFICATION_EVENTS_PENDING = 'FETCH_ALERT_NOTIFICATION_EVENTS_PENDING';
export const FETCH_ALERT_NOTIFICATION_EVENTS_SUCCESS = 'FETCH_ALERT_NOTIFICATION_EVENTS_SUCCESS';
export const FETCH_ALERT_NOTIFICATION_EVENTS_ERROR = 'FETCH_ALERT_NOTIFICATION_EVENTS_ERROR';

export function fetchAlertNotificationEventsPending() {
    ;
    return {
        type: FETCH_ALERT_NOTIFICATION_EVENTS_PENDING
    }
}

export function fetchAlertNotificationEventsSuccess(alert_notification_events) {
;
    return {
        type: FETCH_ALERT_NOTIFICATION_EVENTS_SUCCESS,
        payload: alert_notification_events
    }
}

export function fetchAlertNotificationEventsError(error) {
    return {
        type: FETCH_ALERT_NOTIFICATION_EVENTS_ERROR,
        error: error
    }
}