/*Ref https://codesandbox.io/s/react-example-fndli */
// Library Imports
import React, { Component } from "react";
// Component Imports
import { PopUp } from "./Popup";
import { Modal2 } from "./Modal2";
//! Unused Imports
// import ReactDOM from "react-dom";

export class PopUpPortal extends Component {
    render() {

        if (!this.props.IsVisible) {
            return null;
        }

        return (
            <Modal2>
                <PopUp
                    HidePopup={this.props.HidePopup}
                    IsVisible={this.props.IsVisible}
                >
                    {this.props.children}
                </PopUp>
            </Modal2>
        );
    }
}
