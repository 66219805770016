import { Global_var } from "../global/global_var";
import { LoginService } from "./login.service";
import RestDataSource from "./restdatasource";

export const DiscountService = {
  // getAllDiscounts,
  fetchDiscount,
  getDiscountTypeList,
  getAllActiveVideos,
  getAllActiveCourses,
  // getAllVideosAndCourses,
  getAssociatedProducts,
  fetchDiscountDetailsOnID,
  addDiscount,
  updateDiscount,
  getProductValidityList,
};

// ! Get All Discounts Without Start And End Date

// function getAllDiscounts(getAllDiscounts, fn, fnError) {
//   LoginService.TokenUser((res) => {
//     if (res.status === "success") {
//       localStorage.setItem("jwt-token", res.responseObject);
//       var url = Global_var.BASEURL + Global_var.URL_GET_ALL_DISCOUNTS;
//       return new RestDataSource(url, fnError).GetData(
//         getAllDiscounts,
//         (res) => {
//           if (res != null) {
//             if (res.headers["jtitoken"] != null) {
//               localStorage.setItem("jti-token", res.headers.jtitoken);
//             }
//             fn(res.data);
//           }
//         }
//       );
//     }
//   }, fnError);
// }

function fetchDiscount(fetchDiscount, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_ALL_DISCOUNTS;
      return new RestDataSource(url, fnError).GetOneByParam(
        fetchDiscount,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getDiscountTypeList(getDiscountTypeList, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_DISCOUNT_TYPE_LIST;
      return new RestDataSource(url, fnError).GetData(
        getDiscountTypeList,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getAllActiveVideos(getAllActiveVideos, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_ALL_ACTIVE_VIDEOS;
      return new RestDataSource(url, fnError).GetData(
        getAllActiveVideos,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getAllActiveCourses(getAllActiveCourses, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_ALL_ACTIVE_COURSES;
      return new RestDataSource(url, fnError).GetData(
        getAllActiveCourses,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

// function getAllVideosAndCourses(getAllVideosAndCourses, fn, fnError) {
//   LoginService.TokenUser((res) => {
//     if (res.status === "success") {
//       localStorage.setItem("jwt-token", res.responseObject);
//       var url =
//         Global_var.BASEURL + Global_var.URL_GET_ALL_ACTIVE_VIDEOS_AND_COURSES;
//       return new RestDataSource(url, fnError).GetData(
//         getAllVideosAndCourses,
//         (res) => {
//           if (res != null) {
//             if (res.headers["jtitoken"] != null) {
//               localStorage.setItem("jti-token", res.headers.jtitoken);
//             }
//             fn(res.data);
//           }
//         }
//       );
//     }
//   }, fnError);
// }
function getAssociatedProducts(getAssociatedProducts, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_ASSOCIATED_PRODUCTS;
      return new RestDataSource(url, fnError).GetOneByParam(
        getAssociatedProducts,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}
function fetchDiscountDetailsOnID(fetchDiscountDetailsOnID, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url =
        Global_var.BASEURL + Global_var.URL_FETCH_DISCOUNT_DETAILS_ON_ID;
      return new RestDataSource(url, fnError).GetOneByParam(
        fetchDiscountDetailsOnID,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function addDiscount(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_SAVE_DISCOUNT_DETAILS;
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function updateDiscount(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_UPDATE_DISCOUNT_DETAILS;
      return new RestDataSource(url, fnError).Update(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function getProductValidityList(getProductValidityList, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_VALIDITY_ALL_DETAILS;
      return new RestDataSource(url, fnError).GetData(
        getProductValidityList,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}
