import {
  fetchRolesPending,
  fetchRolesSuccess,
  fetchRolesError
} from "../action/roleAction";
import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";

export function fetchRoles(limit) {
  let url = Global_var.BASEURL + Global_var.URL_ROLES;

  return dispatch => {
    dispatch(fetchRolesPending());
    return new RestDataSource(url, err =>
      dispatch(fetchRolesError(err))
    ).GetOneByParam(limit, (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        dispatch(fetchRolesSuccess(res.data.responseListObject));
      }
    });
  };
}

export function addRoles(data, fn) {
  return dispatch => {
    dispatch(fetchRolesPending());
    return new RestDataSource(Global_var.BASEURL + Global_var.URL_ROLES).Store(
      data,
      res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      }
    );
  };
}

export function editRoles(data, fn) {
  return dispatch => {
    dispatch(fetchRolesPending());
    return new RestDataSource(Global_var.BASEURL + Global_var.URL_ROLES).Update(
      data,
      res => {
        //console.log("active",res)
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
          dispatch(fetchRoles());
        }
      }
    );
  };
}

// export function inActiveRoles(data, fn) {
//   return dispatch => {
//     dispatch(fetchRolesPending());
//     return new RestDataSource(Global_var.BASEURL + Global_var.URL_ROLES).Update(
//       data,
//       res => {
//         //console.log("active")
//         if (res != null) {
//           if (res.headers["jtitoken"] != null) {
//             localStorage.setItem("jti-token", res.headers.jtitoken);
//           }
//           fn(res.data);
//           dispatch(fetchRoles());
//         }
//       }
//     );
//   };
// }
export function inActiveRoles(data, fn, fnError) {
  var url = Global_var.BASEURL +Global_var.URL_ROLES;

  return new RestDataSource(url, fnError).Update(data,(res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
