// Library Imports
import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export class PopUp extends Component {
    componentWillUnmount() {
        //console.log("unmounted");
    }
    renderPopupArea = () => {
        return (
            <div
                style={{
                    // width: "100%",
                    // height: "100%",
                    // position: "absolute",
                    // top: "45px",
                    // backgroundColor: "#000",
                    // opacity: 0.5,
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    top: "80px",
                    background: "#000",
                    opacity: 0.5,
                    margin: "0 auto",
                    zIndex:1051
                   
                }}
            >
                {/* <button onClick={this.props.HidePopup}>X</button> */}
                {/* <div style={{ margin: "10px" }}>{this.props.children}</div> */}
                <Loader className="center-block"
                    type="TailSpin"
                    height={200}
                    width={200}
                    visible="true"
                    style={{ margin: "0 auto",
                    // marginLeft: "45%", 
                    // marginLeft:"40vw",
                    // marginTop: '10%'                 
                }}
                />
            </div>
        );
    };

    render() {
        return this.props.IsVisible ? this.renderPopupArea() : null;
    }
}
