import React from "react";
// import updated 28-09-2022

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

// import Amfifinxheader from "../../containers/finxheader/amfifinxheader";

import downloadbenefit1 from "../../assets/images/downloadbenefit1.png";
// import { MobileValidationService } from "../../../services/mobile.validation.service";
// import { LookUpService } from "../../services/lookupService";
import downloadbenefit2 from "../../assets/images/downloadbenefit2.png";
import downloadbenefit3 from "../../assets/images/downloadbenefit3.png";
import downloadbenefit4 from "../../assets/images/downloadbenefit4.png";
import finxadvantage1 from "../../assets/images/finxadvantage1.png";
import finxadvantage2 from "../../assets/images/finxadvantage2.png";
import finxadvantage3 from "../../assets/images/finxadvantage3.png";
import finxadvantage4 from "../../assets/images/finxadvantage4.png";
import jobDoc1 from '../../assets/pdf/JD_AM_Corp Sal.pdf';
import jobDoc from '../../assets/pdf/Corporate Account Manager  Corporate Salary - KMB.pdf';
import benefitsImg5 from "../../assets/images/benefits-img5.png";
import benefitsImg6 from "../../assets/images/benefits-img6.png";
import startedImg1 from "../../assets/images/started-ico1.png";
import startedImg2 from "../../assets/images/started-ico2.png";
import startedImg3 from "../../assets/images/started-ico3.png";
import startedImg4 from "../../assets/images/started-ico4.png";
import arrowLeft from "../../assets/images/arro-r.png";
import arrowRight from "../../assets/images/arro-l-png.png";
import curveArrow from "../../assets/images/arro-ro.png";
import supportIco1 from "../../assets/images/support-ico-1.png";
import supportIco2 from "../../assets/images/support-ico-2.png";
import supportIco3 from "../../assets/images/support-ico-3.png";
import whatsappIco from "../../assets/images/whatsapp-icon.png";
import OrientationPDF from "../../assets/pdf/OrientationPDF.pdf";
import TC from "../../assets/pdf/T-C_ExpertMFD.pdf";
// import whyMFD from "../../../src/assets/pdf/MFD shuru.pdf";
import $ from "jquery";
import * as Yup from "yup";
import { LoginService } from "../../services/login.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  success,
  successNotification,
  error,
  errorNotification,
  warning,
  warningNotification,
} from "../../../src/components/notification/notifications";

import "./Homepage.css";
import Footer from "../../../src/components/kotak/footer/Footer";
import Header from "../../../src/components/kotak/header/Header";
import BannerSlider from "../../../src/components/kotak/banner-slider/BannerSlider";
import AccordionSect from "../../../src/components/kotak/accordion/Accordion";
import ScrollButton from "../../../src/components/kotak/scrolltop/ScrollButton";
// import cfxDoc from "../../assets/pdf/FinX Kotak Brochure.pdf";
// import cfxDoc from "../../assets/pdf/FinX Kotak Brochure-5_Final_new.pdf";
import cfxDoc from "../../assets/pdf/FinX Kotak Brochure_ 21 April_compressed.pdf";
// import getStartedImg from "../../../src/assets/images/getstarted-img1.png";
// import getStartedImg from "../../../src/assets/images/finx_kotak_howtogetstarted.png";
import getStartedImg from "../../../src/assets/images/finx_kotak_howtogetstarted_new.png";
// import programBenefits from "../../../src/assets/images/program benefits.png";
import programBenefits from "../../../src/assets/images/CFX Advantage_Kotak.png";
// import kotakEmpBenefitImg from "../../../src/assets/images/kotak employee benefits.png";
import kotakEmpBenefitImg from "../../../src/assets/images/Scroll 2-min.png";
import testimonImg from "../../../src/assets/images/testimon-img.jpg";
import starsImg from "../../../src/assets/images/stars-img.png";
// import faqDoc from "../../../src/assets/pdf/FAQ CFX_KotakBank.pdf";
// import faqDoc from "../../../src/assets/pdf/FAQ CFX_KotakBank_final_kotak_V3_10.04_new.pdf";
import faqDoc from "../../../src/assets/pdf/FAQ CFX_KotakBank_final_kotak_V3 (1)_19.04.pdf";
import cfxLogo1 from "../../../src/assets/images/cfx-logo.png";
import cfxLogo2 from "../../../src/assets/images/cfxlogo-small.png";
import finxLogo from "../../../src/assets/images/finxlogo1.png";
import ReactPlayer from "react-player/youtube";
import "../../assets/css/init.css";
import { GeneralEnquiryService } from "../../services/generalenquiryService";

// import "./Homepage.css";

class kotak extends React.Component {
  constructor(props) {
    var currentDate = new Date();
    super(props);
    this.state = {
      open: false,
      showModal: false,
      display: "none",
      mobileNumber: "",
      fullName: "",
      message: "",
      countryCode: "91",
      OTP: "",
      firstName: "",
      lastName: "",
      emailId: "",

      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  // handleClose = () => {
    
  //   if (this.state.display === "none") {
  //     this.setState({
  //       display: "block",
  //     });
  //   } else {
  //     this.setState({
  //       display: "none",
  //     });
  //     // window.location.reload();
  //   }
  // };
  
   styleforplayer = {
 
    // Adding media query..
    '@media (max-width: 550px)': {
      width: '10%',
    },
  };
  isNumber(e) {
    const re = /^[0-9\b]+$/;
    if (re.test(e)) {
      return true;
    }
  }
  startTimer() {
    this.handleGenerateOtp();
    this.setState({ time: {}, seconds: 46, disableopt: true });
    if (this.timer == 0 && this.state.seconds == 46) {
      this.timer = setInterval(this.countDown, 1000);
    } else {
      clearInterval(this.timer);
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  // handleGenerateOtp = () => {
  //   let mobile = this.state.mobileNumber;

  //   if (mobile.toString().length === 10) {
  //     mobile = "mobileNo=" + mobile + "&productID=0";
  //     MobileValidationService.MobileValidation(
  //       mobile,
  //       (res) => {
  //         if (res.status === "success") {
  //           this.GenerateOTP();
  //           if (res.reasonCode === "FC-01") {
  //           }
  //         }
  //       },
  //       (error) => {}
  //     );
  //   }
  // };

  // countDown() {
  //   // Remove one second, set state so a re-render happens.
  //   let seconds = this.state.seconds - 1 <= 0 ? 0 : this.state.seconds - 1;
  //   this.setState({ time: this.secondsToTime(seconds), seconds: seconds });

  //   // Check if we're at zero.
  //   if (seconds == 0) {
  //     clearInterval(this.timer);
  //   }
  //   if (seconds === 0) {
  //     this.setState({
  //       showResendOtp: true,
  //     });
  //   } else {
  //     this.setState({
  //       showResendOtp: false,
  //     });
  //   }
  // }

  // GenerateOTP = () => {
  //   LookUpService.getLookingFor("codeTypeId=100", (res) => {
  //     this.setState({
  //       lookingForList: res.responseListObject,
  //     });
  //   });
  //   LookUpService.getLookingFor("codeTypeId=107", (res) => {
  //     this.setState({
  //       currentProfession: res.responseListObject,
  //       // countryListForPer: res.responseListObject
  //     });
  //   });

  //   this.setState({ isMobileUnique: true, isOTPGenerated: true });

  //   if (this.state.isMobileUnique) {
  //     MobileValidationService.MobileOTP(
  //       this.state.mobileNumber,
  //       this.state.countryCode,
  //       (res) => {
  //         if (res.status === "success") {
  //           success("You will receive otp in 45 secs.", successNotification);
  //         }
  //         return true;
  //       }
  //     );
  //     this.setState({
  //       showGenerateOtp: false,
  //       showResendOtp: false,
  //       showOTPBox: true,
  //       showTimer: true,
  //     });
  //   }
  // };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-HBDWR4PNHC";
    script.async = true;

    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-HBDWR4PNHC');
    
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=GTM-WX26Q65";
    script1.async = true;

    document.head.appendChild(script1);

    window.dataLayer = window.dataLayer || [];
    function gtag1(){
      window.dataLayer.push(arguments);
    }
    gtag1('js', new Date());
    gtag1('config', 'GTM-WX26Q65');

    // Add Linkedin Code
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.innerHTML = `
      var _linkedin_partner_id = "5529801";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;

    const script3 = document.createElement('script');
    script3.type = 'text/javascript';
    script3.innerHTML = `
      (function(l) {
        if (!l) {
          window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[];
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `;

    const noscriptImg = document.createElement('img');
    noscriptImg.height = '1';
    noscriptImg.width = '1';
    noscriptImg.style.display = 'none';
    noscriptImg.alt = '';
    noscriptImg.src = 'https://px.ads.linkedin.com/collect/?pid=5529801&fmt=gif';

    document.head.appendChild(script2);
    document.head.appendChild(script3);
    document.querySelector('noscript').appendChild(noscriptImg);
  }

  regClick = () => {
    console.log('pr',this.props.history);
    this.props.history.push("/register",{searchParams:this.props.history.location.search,pathname:this.props.history.location.pathname}); 
  }
  render() {
    return (
      <React.Fragment>
         <Formik
               initialValues={{
                mobileNumber: this.state.mobileNumber,
                fullName: this.state.fullName,
                emailId: this.state.emailID,
                message: this.state.message,
                OTP: "",
                termsandconditions: false,
              }}
                validationSchema={Yup.object().shape({
                  mobileNumber: Yup.string()
                    .matches(/^[2-9]\d{9}$/, "Please enter the valid mobile number")
                    .required("You must specify a Mobile Number")
                    .when((value) => {
                      if (value !== undefined) {
                        if (value.length === 10 && this.isNumber(value)) {
                          this.setState({
                            showGenerateOtp: true,
                            mobileNumber: value,
                          });
                        }
                        if (value.length < 10 || value.length > 10) {
                          this.setState({ showGenerateOtp: false });
                        }
                      } else {
                        this.setState({ showGenerateOtp: false });
                      }
                    }),
                  fullName: Yup.string()
                    .min(2, "Too Short!")
                    .max(50, "Too Long!")
                    .required("You must specify a  Name"),
    
                  emailId: Yup.string()
                    .email("Invalid E-Mail")
                    .required("You must specify a E-Mail Id"),
                  message: Yup.string().required("You must specify a Message"),
                })}
                onSubmit={(fields, { resetForm }) => {
                  let data = {
                      mobileNumber: fields.mobileNumber,
                      fullName: fields.fullName,
                      emailID: fields.emailId,
                      enquiryMessage: fields.message,
                      enquiryType: 1
                  }
                  // 
                  GeneralEnquiryService.PostEnquiry(data, res => {
                      if (res.status === "success") {
                          success(
                              fields.fullName +
                              " , Thank you for the Enquiry, we would contact you.",
                              successNotification
                          );
                          resetForm({});
                          window.location.reload();
                      }
                  });

              }}

                // onReset={(values, { resetForm }) => {
                //     this.setState({
                //         mobileNumber: '',
                //         fullName: '',
                //         emailId: '',
                //         message: '',
                //         //   showGenerateOtp: false,
                //         //   showResendOtp: false,
                //         //   MobileVerified: false,
                //         //   isMobileUnique: false,
                //         //   showOTPBox: false,
                //         //   isOTPGenerated: false
                //     });

                //     resetForm();
                // }}
                render={({ errors, touched, handleSubmit }) => (
                    <React.Fragment>
                        <Form
                     onSubmit={handleSubmit}
                     className="form_name"
                     id="getintouch"
                     style={{ display: this.state.display }}
                >
                  <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                      <button
                        type="reset"
                        className="close md-hidden lg-hidden sm-hidden"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                     
                    </div>
                  </div>
                </Form>
                    </React.Fragment>
                )}
            />
         
        <div>
          <Header />
          <div className="jacket-btn">
  <a className="ap-gotham-bold ap-primary-white first-jacket-btn" href={jobDoc} download>
    Job Description</a>
  <a className="ap-gotham-bold ap-primary-white second-jacket-btn" href={cfxDoc} download>
    CFX Brochure</a>
  <a className="ap-gotham-bold ap-primary-white third-jacket-btn" href={faqDoc} download>
    FAQs</a>
</div>
              
           {/* <a
            href={jobDoc}
             className="ap-commonFloatbtn ap-firstFloat11 ap-gotham-bold ap-primary-white"
             download
           >
             Job description
           </a>
          
           <a
             href={cfxDoc}
             className="ap-commonFloatbtn ap-secondFloat11 ap-gotham-bold ap-primary-white"
             download
           >
             CFX Brochure
           </a>          
           <a
             href={faqDoc}
             className="ap-commonFloatbtn ap-thirdFloat11 ap-gotham-bold ap-primary-white"
             download
             style={{width:"8%"}}
           >
             FAQs
          </a> */}
          <ScrollButton />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.open}>
              <Box sx={this.state.style}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Please call our student support team at 8767845088 or write to us at support@finxpert.org.
                </Typography>
              </Box>
            </Fade>
          </Modal>

          <div className="ap-topStrip textCenter col-100 floatLft">
            <div className="wrapper">
              <h2 className="ap-stripHead ap-primary-blue col-100 floatLft ap-gotham-bold">
                {/* Chartered Financial Expert Program{" "} */}
                FinX Placement Linked Program{" "}
              </h2>
              <h3 className="col-100 floatLft ap-primary-blue  ap-gotham-medium">
                {/* Customised program{" "}
                <span className="ap-primary-red ">with Kotak India</span> */}
                <span className="ap-primary-red ">Aspire | Upskill | Accelerate</span>
              </h3>
            </div>
          </div>
          <section className="ap-banner-main col-100 floatLft">
            <div className="ap-banner-sliderWrp col-100 floatLft">
              <BannerSlider />
            </div>
          </section>
          <div className="ap-topStrip ap-mt-none textCenter col-100 floatLft">
            <div className="wrapper">
              <h2 className="ap-stripHead ap-primary-blue col-100 floatLft  ap-gotham-bold">
                {/* Take a step ahead and accelerate your career{" "} */}
                Fast-track your career <br/> with Finx Placement Linked Program{" "}
              </h2>
            </div>
          </div>
          <section
            className="ap-benefits col-100 floatLft flexDisplay justifyCenter"
            // id="ap-benefitsDiv"
          >
            {/* <div className="ap-getStarted-img col-100 floatLft">
                  <img src={programBenefits} alt="Program Benefits" />
                </div> */}
            <div className="wrapper">
              <br /><br /><br /><br /><br />
              <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black">
                {/* <span>
                  <img src={cfxLogo1} alt="" />
                </span> */}
                {/* Program Benefits */}
                <img className="ap-finx-logo" src={finxLogo} alt="FinX" width="100px" /> Advantage
              </h2>
              {/* <div className="ap-getStarted-img col-100 floatLft">
                  <img src={programBenefits} alt="Program Benefits" />
                </div> */}
              <div className="ap-benefits-wrp col-100 floatLft relative">
                <ul className="col-100 floatLft flexDisplay justifyCenter alignStart flexWrap">
                  <li className="inlineBlk">
                    <div className="ap-innerBenefit col-100 floatLft textCenter">
                      <p className="col-100 floatLft textCenter" style={{fontSize:"24px",fontFamily:"Roboto",color:"#000000"}}>Empowered Education</p>
                      <p className="col-100 floatLft textCenter" style={{fontSize:"18px",fontFamily:"Roboto",color:"#000000"}}>
                    CFX course- <span className="fwBlack">5 weeks industry recognized, professional<br/> certification course</span>
                      </p>
                      <div className="ap-benefit-ico">
                        {/* <img src={downloadbenefit1} alt="" /> */}
                        <img src={finxadvantage1} alt="" />
                      </div>
                      {/* <p className="col-100 floatLft textCenter ap-primary-bodyColor ap-roboto-font fwMedium">
                        Opportunity to work on{" "}
                        <span className="fwBlack">data research</span> <br />{" "}
                        and <span className="fwBlack">analysis</span> at
                        Kotak India
                      </p> */}
                    </div>
                  </li>
                  <li className="inlineBlk">
                    <div className="ap-innerBenefit col-100 floatLft textCenter">
                    <p className="col-100 floatLft textCenter" style={{fontSize:"24px",fontFamily:"Roboto",color:"#000000"}}>Regulatory Certification</p>
                    <p className="col-100 floatLft textCenter" style={{fontSize:"18px",fontFamily:"Roboto",color:"#000000"}}>
                        <span className="fwBlack">
                        Regulatory NISM Series V-A Mutual Fund Certification 
                        </span>&nbsp;as part of<br/>
                        &nbsp;this curriculum 
                        
                      </p>
                      <div className="ap-benefit-ico">
                        {/* <img src={downloadbenefit2} alt="" /> */}
                        <img src={finxadvantage2} alt="" />
                      </div>
                      {/* <p className="col-100 floatLft textCenter ap-primary-bodyColor ap-roboto-font fwMedium">
                        <span className="fwBlack">
                          5 weeks, fully sponsored, work <br /> integrated
                          program
                        </span>{" "}
                        for graduates
                      </p> */}
                    </div>
                  </li>
                  <li className="inlineBlk no-mar">
                    <div className="ap-innerBenefit col-100 floatLft textCenter">
                    <p className="col-100 floatLft textCenter" style={{fontSize:"24px",fontFamily:"Roboto",color:"#000000"}}>Asssured Job</p>
                    <p className="col-100 floatLft textCenter" style={{fontSize:"18px",fontFamily:"Roboto",color:"#000000"}}>
                        
                        <span className="fwBlack">
                        Provisional job offer letter * before you start<br/> CFX course
                        </span>
                        {/* <br/> */}
                        {/* <span className="fwBlack">
                        before you start CFX course
                        </span>{" "} */}
                         
                      </p>
                      <div className="ap-benefit-ico">
                        {/* <img src={downloadbenefit3} alt="" /> */}
                        <img src={finxadvantage3} alt="" />
                      </div>
                      {/* <p className="col-100 floatLft textCenter ap-primary-bodyColor ap-roboto-font fwMedium">
                        Receive a{" "}
                        <span className="fwBlack">
                          conditional offer letter
                        </span>{" "}
                        to work <br /> at Kotak{" "}
                        <span className="fwBlack">before</span> you start
                        working
                      </p> */}
                    </div>
                  </li>
                  <li className="inlineBlk no-mar">
                    <div className="ap-innerBenefit col-100 floatLft textCenter">
                    <p className="col-100 floatLft textCenter" style={{fontSize:"24px",fontFamily:"Roboto",color:"#000000"}}>Starting Salary</p>
                    <p className="col-100 floatLft textCenter" style={{fontSize:"18px",fontFamily:"Roboto",color:"#000000"}}>
                    <span className="fwBlack">Assured job* with KMBL at a  CTC of Rs. 3.75 Lacs P.A</span> & monthly/ 
                    <br/>quarterly performance-linked incentives. <br /> 
                      </p>
                      <div className="ap-benefit-ico">
                        {/* <img src={downloadbenefit4} alt="" /> */}
                        <img src={finxadvantage4} alt="" />
                      </div>
                      {/* <p className="col-100 floatLft textCenter ap-primary-bodyColor ap-roboto-font fwMedium">
                        <span className="fwBlack">
                          A CTC of INR 3.99 - 5.10 Lac
                        </span>{" "}
                        <br /> per annum*
                      </p> */}
                    </div>
                  </li>
                </ul>
                <p className="absolute ap-roboto-font ap-primary-bodyColor fwBold ap-note" style={{
                  fontSize:"12px",
                  fontFamily:"Roboto",
                  color:"#000000"
                }}>
                *Terms & Conditions Apply
                </p>
              </div>
            </div>
           
          </section>

          <section className="ap-employee-bene col-100 floatLft flexDisplay justifyCenter" id="ap-benefitsDiv">
            <div className="wrapper">
              <br/><br/><br/><br/><br/>
            <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black">
                  {/* <u><b>Kotak Mahindra Bank</b></u> Employee Benefits */}
                  Employee Benefits
                </h2>
                <h2 className="col-100 floatLft textCenter ap-common-head ap-gotham-bold ap-primary-black">
                <span className="ap-primary-red">Kotak Mahindra Bank</span>
              </h2>
              {/* <h2 className="col-100 floatLft textCenter ap-common-head ap-gotham-bold ap-primary-red">
                Kotak India
              </h2>
              <h2 className="col-100 floatLft textCenter ap-common-head ap-gotham-bold ap-primary-black">
                Employee Benefits Offered
              </h2> */}
              <div className="ap-benePoints-wrp1 col-100 floatLft flexDisplay justifyCenter">
                <ul>
                  <li className="col-100 floatLft ap-roboto-font fwMedium ap-primary-bodyColor">
                    <span className="fwBold">Assured job* </span> 
                      on successful completion of program
                  </li>
                  <li className="col-100 floatLft ap-roboto-font fwMedium ap-primary-bodyColor">
                    Exciting role as Corporate Account Manager<br/> (Sales)<br/>
                     <a href={jobDoc1} download style={{fontSize:"12px"}}>Click here for job description</a>
                  </li>  
                  <li className="col-100 floatLft ap-roboto-font fwMedium ap-primary-bodyColor">
                  CTC of Rs. 3.75 Lacs P.A & monthly/ quarterly performance-linked incentives
                  </li>
                  <li className="col-100 floatLft ap-roboto-font fwMedium ap-primary-bodyColor">
                  Applicable conveyance and mobile reimbursements as per Kotak Mahindra Bank policies
                  <div>
                  <h6>*Terms & Conditions Apply</h6>
                  {/* <h6>*Subject to applicable employment checks by Kotak Mahindra Bank</h6>
                  <h6>** Subject to successfully completing the CFX Course</h6> */}
                  </div>
                  </li>
                  {/* <li className="col-100 floatLft ap-roboto-font fwMedium ap-primary-bodyColor"> */}
                  {/* <div style={{paddingLeft:"30px"}}>
                  <h6>*Subject to applicable employment checks by Kotak</h6>
                  <h6>** Subject to successfully completing the CFX Course</h6>
                  </div> */}
                  {/* </li> */}
                </ul>
              </div>
            </div>
            {/* <div className="wrapper">
            <br />
            <div
                className="ap-getStarted col-100 floatLft"
                // id="ap-getStarted"
              >
                <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black">
                  <u>Kotak Mahindra Bank</u> Employee Benefits
                </h2>
                <div className="ap-getStarted-img col-100 floatLft">
                  <img src={kotakEmpBenefitImg} alt="Kotak Mahindra Bank Employee Benefits" />
                </div>
              </div>
            </div> */}
          </section>

          <section className="ap-chartered col-100 floatLft flexDisplay justifyCenter">
            <div className="wrapper">
              <div className="ap-top-div col-100 floatLft" >
                <h2  className="ap-common-head-small ap-gotham-bold col-100 floatLft textCenter ap-primary-black" id="ap-programs">
                  <br/><br/>
                  <span >
                    <img src={cfxLogo2} alt="" />
                  </span>
                  {/* Chartered Financial Expert Course */}
                  Course
                </h2>
                {/* <h3 className="ap-gotham-medium col-100 floatLft textCenter ap-primary-black">
                  Customised program with{" "}
                  <span className="ap-primary-red">Kotak India</span>
                </h3> */}
                <div className="ap-contentBx col-100 floatLft">
                  <div className="ap-innerCnt floatLft ">
                    {/* <p className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font textJustify">
                      FinX is the{" "}
                      <span className="fwBlack">
                        training and recruitment partner
                      </span>{" "}
                      for <span className="fwBlack">Kotak India</span>. We
                      are offering{" "}
                      <span className="fwBlack">
                        5 weeks work integrated training
                      </span>{" "}
                      to graduates with 50% marks and above in collaboration
                      with Kotak. The program has{" "}
                      <span className="fwBlack">
                        4 days a week of online training & 1 day a week business
                        overview
                      </span>{" "}
                      session at the Kotak office. <br />
                      <span className="ap-height col-100 floatLft"></span>
                      On successful completion of the training, and clearing
                      prerequisites of Kotak, candidates will be offered a
                      role as{" "}
                      <span className="fwBlack">
                        Kotak Development Program Associate
                      </span>
                      .
                    </p> */}
                    <p className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font textJustify">
                      The{" "}
                      <span className="fwBlack">
                      Chartered Financial Expert (CFX)
                      </span>{" "}
                      course is an industry recognized, short-term professional certification
                      program to kick-start your career in 
                      <span className="fwBlack"> BFSI </span>sector. 
                      It is supported by{" "}
                      <span className="fwBlack">Indian Banks Association (IBA)</span>,{" "}
                      <span className="fwBlack">Association of Mutual Funds of India (AMFI)</span>,{" "}
                      <span className="fwBlack">General Insurance Council (GIC)</span>, and{" "}
                      <span className="fwBlack">CIEL</span>.
                      Training and operational support for<span className="fwBlack"> NISM-Series-V-A Mutual Fund</span>
                      <span className="fwBlack"> Distributors Certification Examination
                      </span>{" "} is also
                      included in this program.
                      <br />
                      <span className="ap-height col-100 floatLft"></span>
                      On successful completion of the <span className="fwBlack"> CFX </span> course, and clearing
                      prerequisites of <span className="fwBlack">Kotak Mahindra Bank</span>, candidates will be offered a
                      role as{" "}
                      <span className="fwBlack">
                      Corporate Account Manager- Corporate Salary (Sales) 
                      </span>{" "}or other suitable role.
                    </p>
                    <div>
                    <h6 className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font textJustify">*Terms & Conditions Apply</h6>
                    {/* <h6 className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font textJustify">*Subject to applicable employment checks by Kotak Mahindra Bank</h6>
                    <h6 className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font textJustify">** Subject to successfully completing the CFX Course</h6> */}
                    </div>
                    <a
                      // href="https://bit.ly/3yezxWu"
                      // href="https://core.finxpert.org/register"
                      // href="http://localhost:3000/register"
                      // target="_blank"
                      onClick={this.regClick}
                      className="ap-expertJoin-click textUpper ap-primary-white ap-gotham-bold inlineBlk"
                    >
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
              {/* <div
                className="ap-getStarted col-100 floatLft"
                id="ap-getStarted"
              >
                <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black">
                  How to get started
                </h2>
                <div className="ap-getStarted-img col-100 floatLft">
                  <img src={getStartedImg} alt="Getting Started" />
                </div>
              </div> */}
            </div>
          </section>

          <section className="ap-about col-100 floatLft flexDisplay justifyCenter"  id='aboutKotak'>
            <div className="wrapper">
              <br /><br /><br /><br /><br />
              <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black ap-lineAfter relative">
                About
              </h2>
              <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-red">
              Kotak Mahindra Bank
              </h2>
              <div className="ap-yellowDiv col-100 floatLft">
                {/* <ul className="col-100 floatLft">
                  <li className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font">
                    Kotak is a{" "}
                    <span className="fwBlack">global leader</span> in
                    independent investment research and data
                  </li>
                  <li className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font">
                    Kotak <span className="fwBlack">educates</span> and{" "}
                    <span className="fwBlack">equips investors</span> and other
                    financial professionals to make informed decisions on
                    investments and in business.
                  </li>
                  <li className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font">
                    Kotak’s <span className="fwBlack">mission</span> is to
                    provide <span className="fwBlack">insights</span> required
                    for <span className="fwBlack">investors</span> and{" "}
                    <span className="fwBlack">companies</span> to make more
                    informed decisions that lead to a more just and{" "}
                    <span className="fwBlack">sustainable global economy</span>.
                  </li>
                  <li className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font">
                    Over <span className="fwBlack">38 years</span> of experience
                    in research and ratings
                  </li>
                  <li className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font">
                    <span className="fwBlack">9000+ professional staff</span>{" "}
                    with more than half engaged in research
                  </li>
                </ul> */}
                {/* <ul className="col-100 floatLft">
                  <li className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font">
                  <span className="fwBlack">Kotak Mahindra Group</span>{" "} is one of India's leading financial services
                   conglomerates with four <span className="fwBlack">Strategic
Business Units - </span>
                  </li>
                  <li className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font">
                  <span className="fwBlack">Consumer Banking</span>,
                  <span className="fwBlack"> Corporate Banking</span>,
                  <span className="fwBlack"> Commercial Banking</span> and 
                  <span className="fwBlack"> Treasury</span>, which cater to
                  retail and corporate customers across urban and rural India
                  </li>
                  <li className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font">
                  As on 31 March 2022, <span className="fwBlack"> Kotak Mahindra Bank Ltd </span>
                  has a national footprint of<span className="fwBlack"> 1,700 </span> branches and
                  <span className="fwBlack"> 2,705 </span>ATMs, 
                  and branches in GIFT City and DIFC (Dubai).
                  </li>
                </ul> */}
                <p className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font textJustify">
                  <span className="fwBlack">Kotak Mahindra Group</span>{" "} is one of India's leading financial services
                   conglomerates with four <span className="fwBlack">Strategic Business Units - </span>
                  <span className="fwBlack">Consumer Banking</span>,
                  <span className="fwBlack"> Corporate Banking</span>,
                  <span className="fwBlack"> Commercial Banking</span> and 
                  <span className="fwBlack"> Treasury</span>, which cater to
                  retail and corporate customers across urban and rural India.
                  As on 31 March 2022, <span className="fwBlack"> Kotak Mahindra Bank Ltd </span>
                  has a national footprint of<span className="fwBlack"> 1,700+ </span> branches and
                  <span className="fwBlack"> 2,800+ </span>ATMs, 
                  and branches in GIFT City and DIFC (Dubai).
                </p>
              </div>
              {/* <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black  relative"> */}
                {/* FinX CIEL */}
                {/* About FinX
              </h2> */}
              <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black">
              {/* // ap-lineAfter relative"> */}
                {/* About */}
              </h2>
              <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-red">
              FinX
              {/* <img src={finxLogo} alt="FinX" width="100px" /> */}
              </h2>
              <div className="ap-pinkDiv col-100 floatLft">
                <p className="col-100 floatLft ap-primary-white fwMedium ap-roboto-font textJustify">
                  We are a{" "}
                  <span className="fwBlack">skilling & training company</span>{" "}
                  driven by the{" "}
                  <span className="fwBlack">promise of a brighter India</span>,
                  vision of connecting{" "}
                  <span className="fwBlack">formal education</span> with{" "}
                  <span className="fwBlack">practical skilling</span> to create{" "}
                  <span className="fwBlack">job-ready students</span> for BFSI.
                  Our associate, <span className="fwBlack">CIEL</span>, is one
                  of the{" "}
                  <span className="fwBlack">
                    largest corporate training companies
                  </span>{" "}
                  in BFSI Industry and has been providing{" "}
                  <span className="fwBlack">corporate learning solutions</span>{" "}
                  for <span className="fwBlack">15 years</span> to employees and
                  professionals in Banking, Asset Management, Wealth Advisory,
                  Insurance, NBFCs, Financial Distribution and Broking
                  companies. Our{" "}
                  <span className="fwBlack">senior management team</span>{" "}
                  comprises of accomplished and{" "}
                  <span className="fwBlack">seasoned professionals</span> from
                  BFSI Industry with a cumulative experience of over{" "}
                  <span className="fwBlack">200 years.</span>
                  We are partners of <span className="fwBlack">SEBI, PFRDA, NISM</span> and over 100 corporates.
                  Industry bodies like <span className="fwBlack">AMFI, Indian Bank Association &amp; 
                  General Insurance Council </span> are on the governing body.
                </p>
              </div>
              <div
                className="ap-getStarted col-100 floatLft"
                id="ap-getStarted"
              >
                <br/><br/><br/><br/><br/>
                <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black">
                  How to get started
                </h2>
                <div className="ap-getStarted-img1 col-100 floatLft">
                <img src={getStartedImg} alt="Getting Started" />
                </div>
              </div>
              {/* <div
                className="ap-getStarted col-100 floatLft"
                id="ap-getStarted"
              >
                <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black">
                  Kotak Employee Benefits
                </h2>
                <div className="ap-getStarted-img col-100 floatLft">
                  <img src={kotakEmpBenefitImg} alt="Getting Started" />
                </div>
              </div> */}
            </div>
          </section>

          <section className="ap-lineSep col-100 floatLft flexDisplay justifyCenter">
            <div className="wrapper-1600"></div>
          </section>

          <section className="ap-testimon-new col-100 floatLft flexDisplay justifyCenter">
            <div className="wrapper-1340">
              <h2 className="ap-common-head ap-gotham-bold col-100 floatLft textCenter ap-primary-black">
                {/* Testimonial */}
                What our learners say
              </h2>
              {/* <div className="ap-testimon-inner col-100 floatLft flexDisplay justifySpace alignStart flexWrap">
                <div className="ap-testimon-text floatLft">
                  <h2 className="ap-gotham-bold col-100 floatLft">
                    Nidhi Sawant
                  </h2>
                  <div className="ap-starts-wrp">
                    <img src={starsImg} alt="" />
                  </div>
                  <p className="col-100 floatLft fwMedium ap-roboto-font">
                    I learned and developed skills beyond what I had expected.
                    The trainers and the entire CFX team ensured a smooth
                    journey and provided the guidance as per the student's
                    requirements. Here I am today ready to start my career in
                    the BFSI sector.
                  </p>
                </div>
                <div className="ap-testimon-img floatRgt">
                  <img src={testimonImg} alt="" />
                </div>
              </div> */}
              <div className="ap-testimon-inner col-100 floatLft ">
                    <ReactPlayer  
             className='ap-react-player' url='https://youtu.be/COnoT6U2tCk' />
             </div>
            </div>
          </section>

          {/* <section className="ap-lineSep col-100 floatLft flexDisplay justifyCenter newlinee">
            <div className="wrapper-1600"></div>
          </section> */}

          <section className="ap-applyFor col-100 floatLft flexDisplay justifyCenter">
            <div className="wrapper">
              <div className="ap-innerApply col-100 floatLft flexDisplay alignCenter">
                <div className="ap-applyCnt col-100 floatLft">
                  {/* <h3 className="col-100 floatLft ap-primary-white ap-gotham-bold">
                    Apply for
                  </h3>
                  <h2 className="col-100 floatLft ap-primary-white ap-gotham-bold">
                    Chartered Financial <br /> Expert Program
                  </h2>
                  <p className="col-100 floatLft ap-primary-white ap-gotham-medium">
                    Customised program with <br />{" "}
                    <span className="ap-primary-red">Kotak India</span>
                  </p> */}

                  {/* <p className="col-100 floatLft ap-primary-white ap-gotham-medium">
                  Industry leaders hire from FinX{" "}
                  </p>
                  <h3 className="col-100 floatLft ap-primary-white ap-gotham-bold">
                    Apply for
                  </h3>
                  <h2 className="col-100 floatLft ap-primary-white ap-gotham-bold" style={{marginBottom:"20px"}}>
                    The CFX Course
                  </h2>
                  <a
                    onClick={this.regClick}
                    className="ap-joinProg-click inlineBlk ap-primary-white ap-gotham-bold textUpper"
                  >
                    Join Now
                  </a> */}
                  <p className="col-100 floatLft ap-primary-white ap-gotham-medium" 
                  // style={{fontFamily:"Roboto",color:"#000"}}
                  >
                  Industry leaders hire from <br/>
                  <h2 className="col-100 floatLft ap-primary-white ap-gotham-bold">
                    
                  FinX{" "}
                  </h2>
                  </p>
                  <h3 className="col-100 floatLft ap-primary-white ap-gotham-bold"
                  // style={{fontFamily:"Roboto",color:"#000"}}
                  >
                    Apply for
                  </h3>
                  <h2 className="col-100 floatLft ap-primary-white ap-gotham-bold"
                  // style={{fontFamily:"Roboto",color:"#000"}}
                  >
                    The CFX Course
                  </h2>
                  {/* <p className="col-100 floatLft ap-primary-white ap-gotham-medium">
                    Customised program with <br />{" "}
                    <span className="ap-primary-red">Morningstar India</span>
                  </p> */}
                  <a className="ap-joinProg-click inlineBlk ap-primary-white ap-gotham-bold textUpper" style={{marginTop:"20px"}} onClick={this.regClick}>
                    Join Now
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section
            className="ap-FAQ col-100 floatLft flexDisplay justifyCenter"
            id="ap-Faq"
          >
            <div className="wrapper">
              <br /><br /><br /><br /><br />
              <h2 className="ap-common-head col-100 floatLft textCenter ap-gotham-bold">
                Frequently Asked Questions (FAQ)
              </h2>
              <div className="ap-accordWrp col-100 floatLft" id="accord">
                <AccordionSect />
                <div className="ap-knowMore-click col-100 floatLft textCenter">
                  <a href={faqDoc} className="primary-dark" download>
                    Know more
                  </a>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-77MR2CST4V"></script>
        <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-77MR2CST4V');
        `}
        {window.alert("kotak")}
        </script> */}
      </React.Fragment>
    );
  }
}

export default kotak;
