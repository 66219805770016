import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik, Form, Field } from "formik";
import { PaymentHistorySearchService } from "../../services/paymentHistorySearchService";
import { LoginService } from "../../services/login.service";
import * as moment from "moment";
import * as Yup from "yup";
import { ExcelService } from "../Excel/excel";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { warning, warningNotification } from "../notification/notifications";

export class myPayments extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("YYYY-MM-DD"),
      data: [],
      roleId: 0,
      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
      loading: true,
      paymentStatus: ""
    };
    this.showAll = this.showAll.bind(this);

  }
  componentDidMount() {
    this.componentDetails(this.props.studentPaymentHistoryData);
    this.initailizedDateDetails();
    this.setState({ loading: false });
  }

  initailizedDateDetails() {
    var listOfYear = [];
    var listOfMonth = [];
    var listOfDay = [];
    var year = new Date().getFullYear();

    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);
    }

    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    for (var ld = 1; ld <= 31; ld++) {
      listOfDay.push(ld);
    }

    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear: listOfYear });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });
  }

  componentDetails(studentPaymentHistoryData) {
    let modifiedData = [];
    let modifiedData1 = [];
    // let studname = '';

    let isrecordpresent = false;
    for (let index = 0; index < studentPaymentHistoryData.length; index++) {
      const element = studentPaymentHistoryData[index];
      isrecordpresent = false;
      for (let index = 0; index < this.props.productDetailsAll.length; index++) {
        const ele = this.props.productDetailsAll[index];
        if (element.orderItemList.length > 0) {
          if (element.orderItemList[0].productID === ele.productID) {
            isrecordpresent = true;
            let datamodified = {
              orderID: element.orderID,
              studentPartyID: element.studentPartyID,
              orderPlacedOn: element.orderPlacedOn,
              orderItemList: element.orderItemList,
              totalItemPrice: element.totalItemPrice,
              totalOrderPrice: element.totalOrderPrice,
              paymentStatus: element.paymentStatus,
              transactionID: element.transactionID,
              productName: ele.productName
            }
            modifiedData1.push(datamodified);
          }
        }
      }
      if (!isrecordpresent) {
        let datamodified = {
          orderID: element.orderID,
          studentPartyID: element.studentPartyID,
          orderPlacedOn: element.orderPlacedOn,
          orderItemList: element.orderItemList,
          totalItemPrice: element.totalItemPrice,
          totalOrderPrice: element.totalOrderPrice,
          paymentStatus: element.paymentStatus,
          transactionID: element.transactionID,
          productName: ''
        }
        modifiedData1.push(datamodified);
      }
    }
    let isuserrecordpresent = false;

    for (let index = 0; index < modifiedData1.length; index++) {
      const element = modifiedData1[index];
      isuserrecordpresent = false;
      for (let index = 0; index < this.props.adminUser.length; index++) {
        const eleuser = this.props.adminUser[index];
        if (eleuser.partyID === element.studentPartyID) {
          isuserrecordpresent = true;
          let datamodified = {
            orderID: element.orderID,
            studentPartyID: element.studentPartyID,
            orderPlacedOn: element.orderPlacedOn,
            orderItemList: element.orderItemList,
            totalItemPrice: element.totalItemPrice,
            totalOrderPrice: element.totalOrderPrice,
            paymentStatus: element.paymentStatus,
            productName: element.productName,
            transactionID: element.transactionID,
            studentName: eleuser.name
          }
          modifiedData.push(datamodified);
        }
      }
      if (!isuserrecordpresent) {
        let datamodified = {
          orderID: element.orderID,
          studentPartyID: element.studentPartyID,
          orderPlacedOn: element.orderPlacedOn,
          orderItemList: element.orderItemList,
          totalItemPrice: element.totalItemPrice,
          totalOrderPrice: element.totalOrderPrice,
          paymentStatus: element.paymentStatus,
          productName: element.productName,
          transactionID: element.transactionID,
          studentName: ''
        }
        modifiedData.push(datamodified);
      }
    }

    this.setState({ data: modifiedData });


  }

  exportToCSV = () => {
    this.setState({ loading: true });
    let modifiedData = [];
    let data = this.state.data;

    data.forEach(element => {
      var data1 = {
        Order_ID: element.orderID,
        Date: element.orderPlacedOn ? element.orderPlacedOn.split(" ")[0] : " ",


        Price: element.totalOrderPrice,
        Transaction_ID: element.transactionID,
        Payment_Status: element.paymentStatus === "Txn Success"
          ? "Success"
          : element.paymentStatus === "TXN_FAILURE"
            ? "FAIL"
            : element.paymentStatus === "Initiated" ||
              element.paymentStatus === "initiated"
              ? "Initiated"
              : element.paymentStatus,


      }
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'Payment_Report-');
    this.setState({ loading: false });
  };

  showAll() {

    this.setState({ loading: true });
    // let data = {
    //   startDate: "",
    //   endDate: "",
    //   paymentStatus: ""
    // };

    this.setState({
      fromyear: "",
      frommonth: "",
      fromday: "",
      toyear: "",
      tomonth: "",
      today: "",
      paymentStatus: ""

    })


    let data = {
      startDate: "",
      endDate: "",
      paymentStatus: ""
    };

    this.generateData(data);
    this.setState({ loading: false });
  }

  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(value.toLowerCase()) : true
  }

  generateData(data) {

    this.setState({ loading: true });
    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
      let partyID = "";
      let RoleId = JSON.parse(localStorage.getItem("login"))["role"][0][
        "roleId"
      ];
      this.setState({ roleId: RoleId });
      if (RoleId === 5) {
        partyID = localStorage.getItem("userid-Token");
      } else {
        partyID = 0;
      }
      let params =
        "studentPartyID=" +
        partyID +
        "&limit=-1&fromDate=" +
        data.startDate +
        "&toDate=" +
        data.endDate +
        "&searchText=" +
        data.paymentStatus + "&orderDir=asc";
      PaymentHistorySearchService.fetchPaymentSearchResult(
        params,
        res => {
          if (res.status === "success") {

            this.setState({ loading: true });
            // this.setState({ data: res.responseListObject });
            this.componentDetails(res.responseListObject);
            //resetForm({});
            this.setState({ loading: false });
          } else {
            this.setState({
              data: []
            });
          }
        }
      );
    });
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          paymentStatus: "",
          startDate: "",
          endDate: "",
          maxDate: new Date(),
          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          listOfMonth1: this.state.listOfMonth,
          listOfDay1: this.state.listOfDay,
          fromday: "",
          frommonth: "",
          fromyear: "",
          today: "",
          tomonth: "",
          toyear: "",
        }}
        validationSchema={Yup.object().shape({
          // endDate:new Date()
          //   //startDate: Yup.string().required("You must specify the start date"),
          // endDate: Yup.lazy(key => {
          //   return (
          //     Yup.string()
          //       .default(() => "")
          //       //.required("You must specify the end date")
          //       .when("startDate", (startDate, schema) => {
          //         return schema.test({
          //           test: EndIntensity =>
          //             !!startDate && EndIntensity >= startDate,
          //           message:
          //             "End date should be greater than or equal tostart date"
          //         });
          //       })
          //   );
          // })
        })}
        onSubmit={(fields) => {
          console.log(fields)
          let formattedFromDate = "";
          let formattedEndDate = "";
          // if ((fields["fromyear"] !== undefined && fields["fromyear"] !== "") || (fields["frommonth"] !== undefined && fields["frommonth"] !== "") || (fields["fromday"] !== undefined && fields["fromday"] !== "")) {
          //   let fromdy = fields["fromday"].length == 1 ? "0" + fields["fromday"] : fields["fromday"]
          //   let frommont = fields["frommonth"].length == 1 ? "0" + fields["frommonth"] : fields["frommonth"];
          //   formattedFromDate =
          //     fromdy + "/" + frommont + "/" + fields["fromyear"];
          // }
          if ((this.state.fromyear !== undefined && this.state.fromyear !== "") || (this.state.frommonth !== undefined && this.state.frommonth !== "") || (this.state.fromday !== undefined && this.state.fromday !== "")) {
            let fromdy = this.state.fromday.length === 1 ? "0" + this.state.fromday : this.state.fromday
            let frommont = this.state.frommonth.length === 1 ? "0" + this.state.frommonth : this.state.frommonth;
            formattedFromDate =
              fromdy + "/" + frommont + "/" + this.state.fromyear;
          } else {
            //warning("Please select valid from date", warningNotification);
            formattedFromDate = "";
          }

          // if ((fields["toyear"] !== undefined && this.state.toyear !== "") || (fields["tomonth"] !== undefined && fields["tomonth"] !== "") || (fields["today"] !== undefined && this.state.today !== "")) {
          if ((this.state.toyear !== undefined && this.state.toyear !== "") || (this.state.tomonth !== undefined && this.state.tomonth !== "") || (this.state.today !== undefined && this.state.today !== "")) {
            let tody = this.state.today.length === 1 ? "0" + this.state.today : this.state.today
            let tomont = this.state.tomonth.length === 1 ? "0" + this.state.tomonth : this.state.tomonth;

            formattedEndDate =
              tody + "/" + tomont + "/" + this.state.toyear;

            // formattedEndDate = this.state.today + "/" + this.state.tomonth + "/" + this.state.toyear;
          } else {
            formattedEndDate = "";
          }
          let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
          let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
          let todayConverted = moment(new Date(), "DD.MM.YYYY");

          if (toDtConverted.diff(fromDtConverted, 'days') < 0) {
            this.setState({ loading: false });
            warning('“End Date” should be after the “Start Date”', warningNotification);
          } else if (fromDtConverted.diff(todayConverted, 'days') > 0) {
            this.setState({ loading: false });
            warning('“Start Date” should be before “Today’s Date”', warningNotification);
          } else {

            this.setState({ loading: true });
            let data = {
              startDate: formattedFromDate,
              endDate: formattedEndDate,
              //paymentStatus: fields["paymentStatus"]
              paymentStatus: this.state.paymentStatus
            };
            this.generateData(data);
            this.setState({ loading: false });
          }
        }}
        render={({ handleSubmit, handleChange, errors, touched, values, setFieldValue }) => (
          <React.Fragment>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section class="dashboard-tab user-tab pt-50 mb-50">
              <div class="padding-rl-50">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-8 col-sm-6 col-xs-8">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              My Payments
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-4">
                              <span class="right-icon">
                                <a href="#">
                                  <i onClick={() => this.exportToCSV()}
                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <Form onSubmit={handleSubmit} className="">
                          <div class="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                    <h5 className="filter-heading">
                                      Search Criteria
                                    </h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Payment Status</label>
                                      <Field
                                        as="select"
                                        name="paymentStatus"
                                        id="paymentStatus"
                                        class="form-control"
                                        // onChange={handleChange}
                                        value={this.state.paymentStatus}
                                        onChange={e => {
                                          // setFieldValue(values.paymentStatus = e.target.value)
                                          this.setState({
                                            paymentStatus: e.target.value
                                          })
                                        }}
                                      >
                                        <option value="selected">
                                          Select Status
                                        </option>
                                        <option value="Txn Success">
                                          Success
                                        </option>
                                        <option value="TXN_FAILURE">
                                          Fail
                                        </option>
                                        <option value="Initiated">
                                          Initiate
                                        </option>
                                      </Field>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Start Date</label>

                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="fromday"
                                            id="fromday"
                                            value={this.state.fromday}
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              //setFieldValue((values.fromday = e.target.value));
                                              this.setState({
                                                fromday: e.target.value
                                              })
                                              if ([31, '31'].includes(e.target.value)) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if ([30, '30'].includes(e.target.value)) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, '28', 29, '29'].includes(e.target.value)
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue((values.listOfMonth = listOfMonth));
                                            }}
                                            className={"form-control" + (errors.fromday && touched.fromday ? "is-invalid" : "")}
                                          >
                                            <option value="">DD</option>
                                            {
                                              (values.listOfDay || []).map(
                                                (d) => (<option value={d}> {d} </option>
                                                )
                                              )}
                                          </Field>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="frommonth"
                                            id="frommonth"
                                            value={this.state.frommonth}
                                            onChange={(e) => {
                                              // setFieldValue(
                                              //   (values.frommonth = e.target.value)
                                              // );
                                              this.setState({
                                                frommonth: e.target.value
                                              })
                                              if ([2, '2'].includes(e.target.value)) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                // e.target.value == 1 ||
                                                // e.target.value == 3 ||
                                                // e.target.value == 5 ||
                                                // e.target.value == 7 ||
                                                // e.target.value == 8 ||
                                                // e.target.value == 10 ||
                                                // e.target.value == 12
                                                [1, '1', 3, '3', 5, '5', 7, '7', 8, '8', 10, '10', 12, '12',].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (var ldd = 1; ldd <= 31; ldd++) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (var lod = 1; lod <= 30; lod++) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue((values.listOfDay = listOfDay));
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.month && touched.month
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth || []).map(
                                              (m) => (
                                                <option value={m.key}>
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="fromyear"
                                            id="fromyear"
                                            value={this.state.fromyear}
                                            onChange={(e) => {
                                              // setFieldValue((values.fromyear = e.target.value));
                                              this.setState({
                                                fromyear: e.target.value
                                              })
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.fromyear && touched.fromyear ? "is-invalid" : "")
                                            }
                                          >
                                            <option value="">YYYY</option>
                                            {(
                                              this.state.listOfYear || []
                                            ).map((year) => (
                                              <option value={year}>
                                                {year}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>End Date</label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="today"
                                            id="today"
                                            value={this.state.today}
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              //setFieldValue((values.today = e.target.value));
                                              this.setState({
                                                today: e.target.value
                                              })
                                              if ([31, '31'].includes(e.target.value)) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [30, '30'].includes(e.target.value)
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, '28', 29, '29'].includes(e.target.value)
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue((values.listOfMonth1 = listOfMonth));
                                            }}
                                            className={"form-control" + (errors.today && touched.today ? "is-invalid" : "")}
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay1 || []).map(
                                              (d) => (<option value={d}> {d} </option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="tomonth"
                                            id="tomonth"
                                            value={this.state.tomonth}
                                            onChange={(e) => {
                                              // setFieldValue(
                                              //   (values.tomonth = e.target.value)
                                              // );
                                              this.setState({
                                                tomonth: e.target.value
                                              })
                                              if ([2, '2'].includes(e.target.value)) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                // e.target.value == 1 ||
                                                // e.target.value == 3 ||
                                                // e.target.value == 5 ||
                                                // e.target.value == 7 ||
                                                // e.target.value == 8 ||
                                                // e.target.value == 10 ||
                                                // e.target.value == 12
                                                [1, '1', 3, '3', 5, '5', 7, '7', 8, '8', 10, '10', 12, '12',].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (var ldd = 1; ldd <= 31; ldd++) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (var lod = 1; lod <= 30; lod++) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue((values.listOfDay1 = listOfDay));
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.tomonth && touched.tomonth
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth1 || []).map(
                                              (m) => (
                                                <option value={m.key}>
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="toyear"
                                            id="toyear"
                                            value={this.state.toyear}
                                            onChange={(e) => {
                                              //setFieldValue((values.toyear = e.target.value));
                                              this.setState({
                                                toyear: e.target.value
                                              })
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.toyear && touched.toyear ? "is-invalid" : "")
                                            }
                                          >
                                            <option value="">YYYY</option>
                                            {(
                                              this.state.listOfYear1 || []
                                            ).map((year) => (
                                              <option value={year}>
                                                {year}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div className="col-md-12 col-sm-12 mt-40">
                                      <button
                                        className="btn-5 mr-20 ml-20"
                                        type="submit"
                                      >
                                        Search
                                      </button>
                                      <button
                                        className="btn-5"
                                        type="reset"
                                        onClick={this.showAll}
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <div class="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filterable
                            ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            defaultSorted={[
                              {
                                id: "lastModifiedDateTime",
                                desc: true
                              }
                            ]}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "User ID",
                                    id: "studentPartyID",
                                    accessor: d => (d.studentPartyID || '').toString()
                                  },
                                  {
                                    Header: "User Name",
                                    id: "studentName",
                                    accessor: d => (d.studentName || '').toString()
                                  },
                                  {
                                    Header: "Course Name",
                                    id: "productID",
                                    accessor: d => (d.productName || '').toString(),

                                  },
                                  {
                                    Header: "Order ID",
                                    id: "orderID",
                                    accessor: d => (d.orderID || '').toString()
                                  },

                                  {
                                    Header: "Date",
                                    id: "orderPlacedOn",
                                    accessor: d =>
                                      d.orderPlacedOn ? d.orderPlacedOn : ""
                                  },
                                  {
                                    Header: "Price",
                                    id: "totalOrderPrice",
                                    Cell: row => (
                                      <div style={{ textAlign: "right" }}>{row.value}</div>
                                    ),
                                    accessor: d =>
                                      (new Intl.NumberFormat('en-IN', {
                                        style: 'currency',
                                        currency: 'INR'
                                      }).format(d.totalOrderPrice) || '').toString()

                                  },
                                  {
                                    Header: "Transaction ID",
                                    id: "transactionID",
                                    accessor: d => (d.transactionID || '').toString()
                                  },
                                  {
                                    Header: "Payment Status",
                                    id: "paymentStatus",
                                    // accessor: d => ((d[1] || '').partyId || "").toString()
                                    accessor: d =>
                                      d.paymentStatus === "Txn Success"
                                        ? "Success"
                                        : d.paymentStatus === "TXN_FAILURE"
                                          ? "FAIL"
                                          : d.paymentStatus === "Initiated" ||
                                            d.paymentStatus === "initiated"
                                            ? "Initiated"
                                            : d.paymentStatus
                                  }
                                ]
                              }
                            ]}
                            defaultPageSize={10}
                            style={{
                              width: "100%",
                              maxHeight: "500px"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default myPayments;
