import React, { Component } from "react";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import { warning, warningNotification } from "../notification/notifications";
import { DiscountService } from "../../services/discount.service";
import { LoginService } from "../../services/login.service";
import { withRouter } from "react-router-dom";

class DiscountListLandingPage extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      data: [],
      associatedProducts: [],
      discountName: "",
      filtered: [],
      loading: true,
      today: moment(todaydate).format("YYYY-MM-DD"),
      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
      fromday: "",
      frommonth: "",
      fromyear: "",
      todate: "",
      tomonth: "",
      toyear: "",
    };
    this.showAll = this.showAll.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });

    if (localStorage.getItem("reload") === "true") {
      localStorage.setItem("reload", "false");
    }

    this.setState({ loading: false });
    this.initailizedDateDetails();
  }

  componentWillReceiveProps(props) {
    this.setState({
      data: props.getAllDiscounts,
      fromday:
        parseInt(props.fromDate.split("/")[0]) < 10 &&
        props.fromDate.split("/")[0].includes("0")
          ? parseInt(`${props.fromDate.split("/")[0].replace("0", "")}`)
          : parseInt(`${props.fromDate.split("/")[0]}`),
      frommonth:
        parseInt(props.fromDate.split("/")[1]) < 10 &&
        props.fromDate.split("/")[1].includes("0")
          ? parseInt(`${props.fromDate.split("/")[1].replace("0", "")}`)
          : parseInt(`${props.fromDate.split("/")[1]}`),
      fromyear: props.fromDate.split("/")[2],
      todate:
        parseInt(props.toDate.split("/")[0]) < 10 &&
        props.toDate.split("/")[0].includes("0")
          ? parseInt(`${props.toDate.split("/")[0].replace("0", "")}`)
          : parseInt(`${props.toDate.split("/")[0]}`),
      tomonth:
        parseInt(props.toDate.split("/")[1]) < 10 &&
        props.toDate.split("/")[1].includes("0")
          ? parseInt(`${props.toDate.split("/")[1].replace("0", "")}`)
          : parseInt(`${props.toDate.split("/")[1]}`),
      toyear: props.toDate.split("/")[2],
    });
  }

  initailizedDateDetails() {
    var listOfYear = [];
    var listOfMonth = [];
    var listOfDay = [];
    // var year = new Date().getFullYear();
    var year = 2018;

    for (var i = 1; year + i < 2051; i++) {
      var current = year + i;
      listOfYear.push(current);
    }
    listOfYear.reverse();

    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    for (var ld = 1; ld <= 31; ld++) {
      listOfDay.push(ld);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear: listOfYear });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });
  }

  filterCaseInsensitive = ({ id, value }, row) => {
    // console.log("id", row[id], value);

    return row[id] !== undefined
      ? id === "noOfUsed"
        ? // ? parseInt(row[id]) === parseInt(value)
          String(row[id]).includes(String(value))
        : String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };

  showAll() {
    this.props.history.push("/ops-dashboard");
  }
  handleClone(discount) {
    this.props.history.push("/ops-clone-discount", discount);
  }
  handleEdit(discount) {
    this.props.history.push("/ops-edit-discount", discount);
  }
  handleGetAssociatedProducts(discountId, discountName) {
    // console.log("discount ID", discountId);
    // console.log("discount Name", discountName);
    let params = "discountId=" + discountId;

    DiscountService.getAssociatedProducts(params, (res) => {
      if (res.status === "success") {
        this.setState({
          loading: false,
          associatedProducts: res.responseListObject,
          discountName: discountName,
        });
      } else {
        this.setState({
          loading: false,
          associatedProducts: [],
          discountName: "",
        });
      }
    });
  }
  isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()); // If parsing the date is successful, it's a valid date
  };

  render() {
    console.log("props", this.props);
    console.log("state", this.state);

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          startDate: "",
          endDate: "",
          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          listOfMonth1: this.state.listOfMonth,
          listOfDay1: this.state.listOfDay,
          fromday: this.state.fromday,
          frommonth: this.state.frommonth,
          fromyear: this.state.fromyear,
          today: this.state.todate,
          tomonth: this.state.tomonth,
          toyear: this.state.toyear,
        }}
        validationSchema={Yup.object().shape({
          fromday: Yup.string().required("You must specify a Start Date"),
          frommonth: Yup.string().required("You must specify a Start Month"),
          fromyear: Yup.string().required("You must specify a Start Year"),
          today: Yup.string().required("You must specify a End Date"),
          tomonth: Yup.string().required("You must specify a End Month"),
          toyear: Yup.string().required("You must specify a End Year"),
        })}
        onSubmit={(fields, { resetForm }) => {
          let formattedFromDate = "";
          let formattedEndDate = "";

          if (
            (fields["fromyear"] !== undefined && fields["fromyear"] !== "") ||
            (fields["frommonth"] !== undefined && fields["frommonth"] !== "") ||
            (fields["fromday"] !== undefined && fields["fromday"] !== "")
          ) {
            let fromdy =
              fields["fromday"].length === 1
                ? "0" + fields["fromday"]
                : fields["fromday"];
            // console.log("From Day", fromdy);
            let frommont =
              fields["frommonth"].length === 1
                ? "0" + fields["frommonth"]
                : fields["frommonth"];
            // console.log("From Month", frommont);
            formattedFromDate =
              fromdy + "/" + frommont + "/" + fields["fromyear"];
          } else {
            formattedFromDate = "";
          }

          if (
            (fields["toyear"] !== undefined && fields["toyear"] !== "") ||
            (fields["tomonth"] !== undefined && fields["tomonth"] !== "") ||
            (fields["today"] !== undefined && fields["today"] !== "")
          ) {
            let tody =
              fields["today"].length === 1
                ? "0" + fields["today"]
                : fields["today"];
            // console.log("Todate", tody);

            let tomont =
              fields["tomonth"].length === 1
                ? "0" + fields["tomonth"]
                : fields["tomonth"];
            // console.log("To Month", tomont);

            formattedEndDate = tody + "/" + tomont + "/" + fields["toyear"];
          } else {
            formattedEndDate = "";
          }

          let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
          let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
          // let todayConverted = moment(new Date(), "DD.MM.YYYY");
          if (!this.isValidDate(fromDtConverted)) {
            warning("Invalid Entry From",warningNotification);
            return;
          }
          if (!this.isValidDate(toDtConverted)) {
            warning("Invalid Entry To",warningNotification);
            return;
          }
          if (toDtConverted.diff(fromDtConverted, "days") < 0) {
            this.setState({ loading: false });
            warning(
              '“Entry To” should be greater than “Entry From”',
              warningNotification
            );
          }
          // else if (fromDtConverted.diff(todayConverted, "days") > 0) {
          //   this.setState({ loading: false });
          //   warning(
          //     "Entry From should be less then today",
          //     warningNotification
          //   );
          // }
          else {
            this.setState({ loading: true });

            LoginService.TokenUser((res) => {
              // console.log(
              //   "Validation",
              //   formattedFromDate,
              //   parseInt(formattedFromDate.split("/")[1]) < 10,
              //   formattedFromDate.split("/")[1].includes("0"),
              //   parseInt(formattedFromDate.split("/")[1]) < 10 &&
              //     formattedFromDate.split("/")[1].includes("0")
              //     ? `${formattedFromDate.split("/")[1].replace("0", "")}`
              //     : ""
              // );
              let data;
              localStorage.setItem("jwt-token", res.responseObject);
              this.setState(
                {
                  fromday:
                    parseInt(formattedFromDate.split("/")[0]) < 10 &&
                    formattedFromDate.split("/")[0].includes("0")
                      ? parseInt(
                          `${formattedFromDate.split("/")[0].replace("0", "")}`
                        )
                      : parseInt(`${formattedFromDate.split("/")[0]}`),
                  frommonth:
                    parseInt(formattedFromDate.split("/")[1]) < 10 &&
                    formattedFromDate.split("/")[1].includes("0")
                      ? parseInt(
                          `${formattedFromDate.split("/")[1].replace("0", "")}`
                        )
                      : parseInt(`${formattedFromDate.split("/")[1]}`),
                  fromyear: formattedFromDate.split("/")[2],
                  todate:
                    parseInt(formattedEndDate.split("/")[0]) < 10 &&
                    formattedEndDate.split("/")[0].includes("0")
                      ? parseInt(
                          `${formattedEndDate.split("/")[0].replace("0", "")}`
                        )
                      : parseInt(`${formattedEndDate.split("/")[0]}`),
                  tomonth:
                    parseInt(formattedEndDate.split("/")[1]) < 10 &&
                    formattedEndDate.split("/")[1].includes("0")
                      ? parseInt(
                          `${formattedEndDate.split("/")[1].replace("0", "")}`
                        )
                      : parseInt(`${formattedEndDate.split("/")[1]}`),
                  toyear: formattedEndDate.split("/")[2],
                },
                () => {
                  data = {
                    startDate: `${
                      parseInt(formattedFromDate.split("/")[0]) < 10 &&
                      !formattedFromDate.split("/")[0].includes("0")
                        ? `0${formattedFromDate.split("/")[0]}`
                        : formattedFromDate.split("/")[0]
                    }/${
                      parseInt(formattedFromDate.split("/")[1]) < 10 &&
                      !formattedFromDate.split("/")[1].includes("0")
                        ? `0${formattedFromDate.split("/")[1]}`
                        : formattedFromDate.split("/")[1]
                    }/${this.state.fromyear}`,
                    endDate: `${
                      parseInt(formattedEndDate.split("/")[0]) < 10 &&
                      !formattedEndDate.split("/")[0].includes("0")
                        ? `0${formattedEndDate.split("/")[0]}`
                        : formattedEndDate.split("/")[0]
                    }/${
                      parseInt(formattedEndDate.split("/")[1]) < 10 &&
                      !formattedEndDate.split("/")[1].includes("0")
                        ? `0${formattedEndDate.split("/")[1]}`
                        : formattedEndDate.split("/")[1]
                    }/${this.state.toyear}`,
                  };
                }
              );

              // console.log("data", data);
              // alert(JSON.stringify(data, null, 2));
              resetForm();

              let params =
                "fromDate=" + data.startDate + "&toDate=" + data.endDate;
              DiscountService.fetchDiscount(params, (res) => {
                // console.log("res", res);

                if (res.status === "success") {
                  this.setState({
                    loading: false,
                    data: res.responseListObject,
                  });
                } else {
                  this.setState({
                    loading: false,
                    data: [],
                  });
                }
              });
            });
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
          handleReset,
          setFieldValue,
          resetForm,
          ...formikProps
        }) => (
          <>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section className="dashboard-tab user-tab pt-50 mb-50">
              <div className="padding-rl-50">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-header">
                        <h3>
                          <div className="row">
                            <div className="col-md-8 col-sm-6 col-xs-8">
                              <span className="left-icon">
                                <i
                                  className="fa fa-list"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              Discount List
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-4">
                              <span className="right-icon">
                                <a title="Add Discount" href="/ops-add-discount">
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>

                                {/* // Excel Icon  */}

                                {/* <a title="Download Discount Report">
                                  <i
                                    className="fa fa-file-excel-o"
                                    aria-hidden="true"
                                    onClick={() => this.exportToCSV()}
                                  ></i>
                                </a> */}
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>

                      <div className="bg-box">
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                    <h5 className="filter-heading">
                                      Search Discount
                                    </h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Entry From{" "}<span className="validation"> *</span></label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="fromday"
                                            id="fromday"
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              setFieldValue(
                                                (values.fromday =
                                                  e.target.value)
                                              );
                                              if (
                                                [31, "31"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [30, "30"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, "28", 29, "29"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue(
                                                (values.listOfMonth = listOfMonth)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.fromday && touched.fromday
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay || []).map(
                                              (d, index) => (
                                                <option key={index} value={d}>
                                                  {" "}
                                                  {d}{" "}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="fromday"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="frommonth"
                                            id="frommonth"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.frommonth =
                                                  e.target.value)
                                              );
                                              if (
                                                [2, "2"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                // e.target.value == 1 ||
                                                // e.target.value == 3 ||
                                                // e.target.value == 5 ||
                                                // e.target.value == 7 ||
                                                // e.target.value == 8 ||
                                                // e.target.value == 10 ||
                                                // e.target.value == 12
                                                [
                                                  1,
                                                  "1",
                                                  3,
                                                  "3",
                                                  5,
                                                  "5",
                                                  7,
                                                  "7",
                                                  8,
                                                  "8",
                                                  10,
                                                  "10",
                                                  12,
                                                  "12",
                                                ].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (
                                                  var ldd = 1;
                                                  ldd <= 31;
                                                  ldd++
                                                ) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (
                                                  var lod = 1;
                                                  lod <= 30;
                                                  lod++
                                                ) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue(
                                                (values.listOfDay = listOfDay)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.month && touched.month
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth || []).map(
                                              (m, index) => (
                                                <option
                                                  key={index}
                                                  value={m.key}
                                                >
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="frommonth"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="fromyear"
                                            id="fromyear"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.fromyear =
                                                  e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.fromyear &&
                                              touched.fromyear
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">YYYY</option>
                                            {(this.state.listOfYear || []).map(
                                              (year, index) => (
                                                <option
                                                  key={index}
                                                  value={year}
                                                >
                                                  {year}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="fromyear"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Entry To{" "}<span className="validation"> *</span></label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="today"
                                            id="today"
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              setFieldValue(
                                                (values.today = e.target.value)
                                              );

                                              if (
                                                [31, "31"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [30, "30"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, "28", 29, "29"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue(
                                                (values.listOfMonth1 = listOfMonth)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.today && touched.today
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay1 || []).map(
                                              (d, index) => (
                                                <option key={index} value={d}>
                                                  {" "}
                                                  {d}{" "}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="today"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="tomonth"
                                            id="tomonth"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.tomonth =
                                                  e.target.value)
                                              );
                                              if (
                                                [2, "2"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                // e.target.value == 1 ||
                                                // e.target.value == 3 ||
                                                // e.target.value == 5 ||
                                                // e.target.value == 7 ||
                                                // e.target.value == 8 ||
                                                // e.target.value == 10 ||
                                                // e.target.value == 12
                                                [
                                                  1,
                                                  "1",
                                                  3,
                                                  "3",
                                                  5,
                                                  "5",
                                                  7,
                                                  "7",
                                                  8,
                                                  "8",
                                                  10,
                                                  "10",
                                                  12,
                                                  "12",
                                                ].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (
                                                  var ldd = 1;
                                                  ldd <= 31;
                                                  ldd++
                                                ) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (
                                                  var lod = 1;
                                                  lod <= 30;
                                                  lod++
                                                ) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue(
                                                (values.listOfDay1 = listOfDay)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.tomonth && touched.tomonth
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth1 || []).map(
                                              (m, index) => (
                                                <option
                                                  key={index}
                                                  value={m.key}
                                                >
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="tomonth"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="toyear"
                                            id="toyear"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.toyear = e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.toyear && touched.toyear
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">YYYY</option>
                                            {(this.state.listOfYear1 || []).map(
                                              (year, index) => (
                                                <option
                                                  key={index}
                                                  value={year}
                                                >
                                                  {year}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="toyear"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 mt-40">
                                      <div className="row">
                                        <button
                                          className="btn-5 mr-20 ml-20"
                                          type="submit"
                                        >
                                          Apply
                                        </button>
                                        <button
                                          className="btn-5"
                                          type="reset"
                                          onClick={this.showAll}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>

                        <div className="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filtered={this.state.filtered}
                            onFilteredChange={(filtered) => {
                              this.setState({
                                filtered: filtered,
                              });
                            }}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={this.filterCaseInsensitive}
                            ref={(refReactTable) => {
                              this.refReactTable = refReactTable;
                            }}
                            columns={[
                              {
                                columns: [
                                  // {
                                  //   Header: "Discount ID",
                                  //   id: "discountId",
                                  //   accessor: (d) => d.discountId,
                                  // },
                                  {
                                    Header: "Name",
                                    id: "discountName",
                                    accessor: (d) => d.discountName,
                                    Cell: (row) => (
                                      <span title={row.value}>{row.value}</span>
                                    ),
                                  },
                                  {
                                    Header: "Discount Type",
                                    id: "discountType",
                                    accessor: (d) => d.discountType,
                                    Cell: (row) => (
                                      <span title={row.value}>{row.value}</span>
                                    ),
                                  },

                                  {
                                    Header: "Description",
                                    id: "discountDesc",
                                    accessor: (d) =>
                                      d.discountDesc ? d.discountDesc : "----",
                                    Cell: (row) => (
                                      <span title={row.value}>{row.value}</span>
                                    ),
                                  },
                                  {
                                    Header: "From Date",
                                    id: "fromDate",
                                    accessor: (d) => d.fromDate,
                                  },
                                  {
                                    Header: "Expiry Date",
                                    id: "expiryDate",
                                    accessor: (d) => d.expiryDate,
                                  },
                                  {
                                    Header: "Products",
                                    id: "associatedProducts",
                                    filterable: false,
                                    sortable: false,
                                    accessor: (d) =>
                                      d.associatedProducts === null ? (
                                        "---"
                                      ) : d.associatedProducts === "ALL" ? (
                                        "ALL"
                                      ) : (
                                        <a
                                          data-toggle="modal"
                                          data-target="#myModal"
                                          href="#"
                                          onClick={() =>
                                            this.handleGetAssociatedProducts(
                                              d.discountId,
                                              d.discountName
                                            )
                                          }
                                        >
                                          {" "}
                                          {d.associatedProducts}
                                        </a>
                                      ),
                                  },
                                  {
                                    Header: "No. of Used",
                                    id: "noOfUsed",
                                    accessor: (d) => d.noOfUsed,
                                  },
                                  {
                                    Header: "Last Used",
                                    id: "lastUsed",
                                    accessor: (d) =>
                                      d.lastUsed ? d.lastUsed : "---",
                                  },
                                  {
                                    Header: "Mandate Type",
                                    id: "mandateType",
                                    width: 120,
                                    accessor: (d) =>
                                      d.mandateType === "public"
                                        ? "Public"
                                        : "Private",
                                  },

                                  {
                                    Header: "Status",
                                    id: "status",
                                    accessor: (d) =>
                                      d.status === "Inactive"
                                        ? "InActive"
                                        : d.status,
                                  },
                                  {
                                    Header: "Created On",
                                    id: "createdDate",
                                    accessor: (d) => d.createdOn,
                                  },

                                  {
                                    Header: "Actions",
                                    id: "action",
                                    filterable: false,
                                    sortable: false,
                                    Cell: (props) => {
                                      return (
                                        <div>
                                          <a className="mar-left">
                                            <i
                                              title="Edit Discount"
                                              className="fa fa-edit fa-2x "
                                              aria-hidden="true"
                                              onClick={() =>
                                                this.handleEdit(props.original)
                                              }
                                            ></i>
                                          </a>
                                          <a className="mar-left" href="#">
                                            <i
                                              title="Copy &amp; Create New"
                                              className="fa fa-clone fa-2x clone"
                                              aria-hidden="true"
                                              onClick={() =>
                                                this.handleClone(props.original)
                                              }
                                            ></i>
                                          </a>
                                        </div>
                                      );
                                    },
                                  },
                                ],
                              },
                            ]}
                            defaultPageSize={10}
                            // style={{
                            //   width: "100%",
                            //   maxHeight: "500px",
                            // }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <!-- Associated Product Details Modal --> */}

            <div
              className="modal fade"
              id="myModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      Associated Product Details
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="mb-10">
                      <h2 className="sub-header">
                        Discount Name :{" "}
                        <span style={{ color: "black" }}>
                          {this.state.discountName}
                        </span>
                      </h2>
                      {/* <h4>Discount Name : {this.state.discountName}</h4> */}
                    </div>
                    <table className="table table-bordered table-hover table-striped">
                      <thead>
                        <tr className="discount-table-heading">
                          <th>Product ID</th>
                          <th>Product Name</th>
                          <th>Product Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.associatedProducts.map((data) => (
                          <tr key={data.productId}>
                            <td>{data.productId}</td>
                            <td>{data.productName}</td>
                            <td>{data.productType}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn discount-table-heading"
                      data-dismiss="modal"
                      style={{
                        float: "right",
                        marginTop: "-30px",
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      />
    );
  }
}
export default withRouter(DiscountListLandingPage);
