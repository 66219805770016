export const FETCH_ADMIN_PROFILE_USER_ACTION_PENDING = 'FETCH_ADMIN_PROFILE_USER_ACTION_PENDING';
export const FETCH_ADMIN_PROFILE_USER_ACTION_SUCCESS = 'FETCH_ADMIN_PROFILE_USER_ACTION_SUCCESS';
export const FETCH_ADMIN_PROFILE_USER_ACTION_ERROR = 'FETCH_ADMIN_PROFILE_USER_ACTION_ERROR';

export function fetchAdminProfileUserPending() {
    return {
        type: FETCH_ADMIN_PROFILE_USER_ACTION_PENDING,
    }
}

export function fetchAdminProfileUserSuccess(adminProfileUser) {

    return {
        type: FETCH_ADMIN_PROFILE_USER_ACTION_SUCCESS,
        payload: adminProfileUser
    }
}

export function fetchAdminProfileUserError(error) {
    return {
        type: FETCH_ADMIN_PROFILE_USER_ACTION_ERROR,
        error: error
    }
}