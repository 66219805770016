import { FETCH_PRODUCT_DETAILS_BY_PARTY_PENDING, FETCH_PRODUCT_DETAILS_BY_PARTY_SUCCESS, FETCH_PRODUCT_DETAILS_BY_PARTY_ERROR } from '../action/productDetailsByPartyIDAction';

const initialState = {
    pending: false,
    productDetailsByParty: [],
    error: null
}

const productDetailsByPartyIDReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRODUCT_DETAILS_BY_PARTY_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_PRODUCT_DETAILS_BY_PARTY_SUCCESS:
            return {
                ...state,
                pending: false,
                productDetailsByParty: action.payload
            }
        case FETCH_PRODUCT_DETAILS_BY_PARTY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default productDetailsByPartyIDReducer;
export const getproductDetailsByParty = state => state.productDetailsByParty;
export const getproductDetailsByPartyPending = state => state.pending;
export const getproductDetailsByPartyError = state => state.error;