import React, { Component } from "react";
// import progress from "../../assets/images/line-graph.png";
import * as moment from "moment";

import { PopUpPortal } from "../../containers/popups/PopUpPortal";
// import AdminRecentActivityCarousel from "../../components/carousel/adminRecentActivityCarousel";
// import { RegisterUserService } from "../../services/registration.service";
// import { LoginService } from "../../services/login.service";
import { chartService } from "../../services/chart.service";
import { Line } from "react-chartjs-2";
// import { Carousel } from "react-responsive-carousel";
// import progressoverview from "../../assets/images/progress-overview.jpg";
import {
  // success,
  // warning,
  // successNotification,
  error,
  errorNotification,
  // warningNotification
} from "../notification/notifications";

class RecentActivity extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("DD/MM/YYYY"),
      roleType: "",
      show: false,
      role: "admin",
      loading: true,
      chartData: {},
      displayTitle: "true",
      displayLegend: true,
      legendPosition: "left",
      enrollmentdata: '',
      enr_labels: '',
      enr_label: '',
      enr_data: '',
      coursedata: '',
      crs_labels: '',
      crs_label: '',
      crs_data: '',
      reportdata: '',
      rp_labels: '',
      rp_label: '',
      rp_data: '',
      chartType: '',
      startDate: '',
      endDate: '',
      reportParam: '',
    };
  }


  componentDidMount() {

    var todaydate = new Date();
    var ToDate = moment(todaydate).format("DD/MM/YYYY")
    // let Week = ''
    var FromDate = moment(todaydate).subtract(7, "d").format("DD/MM/YYYY")
    // let enrollment_labels = []
    // let enrollment_label = []
    // let enrollment_data = []
    // let course_data = []
    // let report_data = []

    let data = "ChartType=Week&FromDate=" + FromDate + "&ToDate=" + ToDate
    //console.log("data", data)

    // fetch Enrollment Course graph
    this.fetchEnrollmentChartData(data);
  }

  fetchEnrollmentChartData(data) {
    let enrollment_labels = []
    let enrollment_label = []
    let enrollment_data = []

    chartService.fetchEnrollmentChartData(
      data,
      cb => {
        // fetcch Course Chart graph
        this.fetchCourseChartData(data);
        // Session Report Graph
        this.fetchReportChartData(data);
        if (cb.reasonCode === "success") {
          this.setState({ enrollmentdata: cb.responseListObject });
          if (this.state.enrollmentdata) {

            if (this.state.enrollmentdata[0].fromdate === this.state.enrollmentdata[0].todate) {
              this.state.enrollmentdata.map(team => {

                enrollment_labels.push((team.fromdate).split(" ")[0])
                enrollment_label.push((team.todate).split(" ")[0])
                enrollment_data.push(team.studentcount)
              });
            } else {
              this.state.enrollmentdata.map(team => {

                enrollment_labels.push(team.fromdate.split(" ")[0] + " to " + (team.todate).split(" ")[0])
                enrollment_data.push(team.studentcount)
              });
            }

            this.setState({
              enr_labels: enrollment_labels,
              enr_label: enrollment_label,
              enr_data: enrollment_data
            })
            this.handleChartData();
            //removed Dataset
          }
        } else {
          error(cb.reasonText, {
            ...errorNotification,
            container: "top-center"
          });
        }
      },
      err => console.log("Recent Activity Error", err)
    );



  }

  fetchCourseChartData(data) {
    let course_labels = []
    let course_label = []
    let course_data = []

    chartService.fetchCourseChartData(
      data,
      cb => {
        if (cb.reasonCode === "success") {
          this.setState({ coursedata: cb.responseListObject });

          if (this.state.coursedata) {

            if (this.state.coursedata[0].fromdate === this.state.coursedata[0].todate) {
              this.state.coursedata.map(team => {

                course_labels.push((team.fromdate).split(" ")[0])
                course_label.push((team.todate).split(" ")[0])
                course_data.push(team.studentcount)
              });
            } else {
              this.state.coursedata.map(team => {

                course_labels.push(team.fromdate.split(" ")[0] + " to " + (team.todate).split(" ")[0])
                course_data.push(team.studentcount)
              });
            }
            this.setState({ crs_labels: course_labels, crs_label: course_label, crs_data: course_data })
            this.handleChartData();

          }
        } else {
          error(cb.reasonText, {
            ...errorNotification,
            container: "top-center"
          });
        }
      },
      err => console.log("fetchCourseChartData Error", err)
    );

  }

  fetchReportChartData(data) {
    let report_data = [];
    let report_labels = [];
    let report_label = [];
    this.setState({ reportParam: data });
    chartService.fetchReportChartData(
      data,
      cb => {
        let reportparam = this.state.reportParam;
        if (cb.reasonCode === "success") {
          this.setState({
            reportdata: cb.responseListObject
          });

          if (this.state.reportdata) {

            if (reportparam.split('&')[0].split('=')[1] === 'Week' || reportparam.split('&')[0].split('=')[1] === 'Year') {
              //if (this.state.reportdata[0].fromdate === this.state.reportdata[0].todate) {
              this.state.reportdata.map(team => {

                report_labels.push((team.fromdate).split(" ")[0])
                report_label.push((team.todate).split(" ")[0])
                report_data.push(team.studentcount)
              });
            } else {
              this.state.reportdata.map(team => {

                report_labels.push(team.fromdate.split(" ")[0] + " to " + (team.todate).split(" ")[0])
                report_data.push(team.studentcount)
              });
            }
            this.setState({ rp_labels: report_labels, rp_label: report_label, rp_data: report_data })

            this.handleChartData();
            //removed chart Data

          }
        } else {
          error(cb.reasonText, {
            ...errorNotification,
            container: "top-center"
          });
        }
      },
      err => console.log("fetchReportChartData Error", err)
    );
  }

  handleChartData() {
    //if (this.state.enr_data.length > 0 || this.state.crs_data.length > 0 || this.state.rp_data.length > 0)
    this.setState({
      chartData: {
        labels: this.state.rp_labels,

        datasets: [
          {
            label: "New Enrollments",
            data: this.state.enr_data || [],
            fill: false,
            borderColor: "red",
            borderCapStyle: "square",
            borderDash: [], // try [5, 15] for instance
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "black",
            pointBackgroundColor: "white",
            pointBorderWidth: 1,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: "yellow",
            pointHoverBorderColor: "brown",
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10
          },
          {
            label: "Course Completed",
            data: this.state.crs_data || [],
            fill: false,
            backgroundColor: "rgba(225,0,0,0.4)",
            borderColor: "green",
            borderCapStyle: "square",
            borderDash: [], // try [5, 15] for instance
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "black",
            pointBackgroundColor: "white",
            pointBorderWidth: 1,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: "yellow",
            pointHoverBorderColor: "brown",
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10
          },
          {
            label: "Number of Sessions",
            data: this.state.rp_data || [],
            fill: false,
            backgroundColor: "rgba(225,0,0,0.4)",
            borderColor: "orange",
            borderCapStyle: "square",
            borderDash: [], // try [5, 15] for instance
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "black",
            pointBackgroundColor: "white",
            pointBorderWidth: 1,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: "yellow",
            pointHoverBorderColor: "brown",
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10
          }
        ]
      }
    });
  }

  handlevalue = (e) => {

    let ChartTypeValue = e.target.value
    let Week = ''
    var ToDate = this.state.today
    var todaydate = new Date();
    var previousDate = ''
    if (ChartTypeValue === "7") {
      Week = "Week";
      previousDate = moment(todaydate).subtract(7, "d").format("DD/MM/YYYY")
    }
    else if (ChartTypeValue === "14") {
      Week = "Week";
      previousDate = moment(todaydate).subtract(14, "d").format("DD/MM/YYYY");
    }
    else if (ChartTypeValue === "30") {
      Week = "Month";
      previousDate = moment(todaydate).subtract(30, "d").format("DD/MM/YYYY");
    }
    else if (ChartTypeValue === "90") {
      Week = "Month";
      previousDate = moment(todaydate).subtract(90, "d").format("DD/MM/YYYY");
    }
    else if (ChartTypeValue === "180") {
      Week = "Year";
      previousDate = moment(todaydate).subtract(180, "d").format("DD/MM/YYYY");
    }
    else if (ChartTypeValue === "360") {
      Week = "Year";
      previousDate = moment(todaydate).subtract(360, "d").format("DD/MM/YYYY");
    }
    else {
      return null;
    }

    let data1 = {
      ChartType: Week,
      FromDate: previousDate,
      ToDate: ToDate
    }

    let data2 = "ChartType=" + data1.ChartType + "&FromDate=" + data1.FromDate + "&ToDate=" + data1.ToDate

    this.fetchEnrollmentChartData(data2);
    this.fetchCourseChartData(data2);
    this.fetchReportChartData(data2);


  }

  render() {

    if (this.props.studentProgressOverviewDataPending) {
      return (
        <React.Fragment>
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        </React.Fragment>
      );
    } else {
      return (
        <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 padding-0">
            <h3 className="student-title">Recent Activity</h3>
            <div className="recent-activity">
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-8 col-sm-6 col-xs-12 mt-10 mb-10 pull-left">
                      <p>*Dates Indicate the Start of the Week</p>
                      {/* <select
                        type="select"
                        className="form-control"
                        placeholder="Activity"
                        required
                      >
                        <option>--Select--</option>
                        <option>New Enrollments</option>
                        <option>Course completed</option>
                        <option>Number of sessions</option>
                      </select> */}
                    </div>
                    <div className="col-md-4 col-sm-8 col-xs-12 mt-10 mb-10 pull-right">
                      <select
                        type="select"
                        className="form-control"
                        placeholder="Activity"
                        onChange={this.handlevalue}
                        required
                      >
                        {/* <option>--Select--</option> */}
                        <option value="7">This Week</option>
                        <option value="14">Last Two Weeks</option>
                        <option value="30">Last Month</option>
                        <option value="90">Last Three Months</option>
                        <option value="180">Last Six Months</option>
                        <option value="360">Last Twelve Months</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div>
                  <Line
                    data={this.state.chartData}
                    options={
                      {
                        //   title: {
                        //     display: "this.state.displayTitle",
                        //     fontSize: 50
                        //   },
                        //   legend: {
                        //     display: this.state.displayLegend,
                        //     position: this.state.legendPosition
                        //   }
                      }
                    }
                  />
                </div>


              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default RecentActivity;

/* <AdminRecentActivityCarousel
// data2={ this.state.data2 }
  studentProgressOverview={
    this.props.studentProgressOverviewData
}

/>        */