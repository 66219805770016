export const FETCH_STUDENT_PENDING = 'FETCH_STUDENT_PENDING';
export const FETCH_STUDENT_SUCCESS = 'FETCH_STUDENT_SUCCESS';
export const FETCH_STUDENT_ERROR = 'FETCH_STUDENT_ERROR';

export function fetchStudentPending() {
    
    return {
        type: FETCH_STUDENT_PENDING
    }
}

export function fetchStudentSuccess(students) {
    

    return {
        type: FETCH_STUDENT_SUCCESS,
        payload: students
    }
}

export function fetchStudentError(error) {
    
    return {
        type: FETCH_STUDENT_ERROR,
        error: error
    }
}