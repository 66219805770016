import React, { Component } from "react";
import OpsHeader from "../../components/dashboardheader/opsheader";
import Finxfooter from "../finxfooter/finxfooter";
import EditEventLandingPage from "../../components/ops-event/editEvent";
import {EventService} from "../../services/event.service";

class eventEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getAllEventTypes: [],
    };
  }
  componentDidMount(){
    EventService.getAllEventTypes((res) => {
      console.log("res-getAllEventTypes", res);
      this.setState({
        getAllEventTypes: res.data.responseListObject,
      });
    });
  }
  render() {
    console.log("eventeditc");
    return (
      <>
        <OpsHeader {...this.props} />
        <EditEventLandingPage getAllEventTypes={this.state.getAllEventTypes} {...this.props} />
        <Finxfooter />
      </>
    );
  }
}

export default eventEdit;
