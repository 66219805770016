import { applyMiddleware, createStore, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

// import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import productsReducer from "../reducer/userReducer";
import roleReducer from "../reducer/roleReducer";
import userProfileReducers from "../reducer/userProfileReducer";
import studentReducer from "../reducer/studentReducer";
import calenderEventsReducer from "../reducer/calenderEventsReducer";
import alertNotificationEventsReducer from "../reducer/alertsNotificationReducer";
import productPreRequisiteReducer from "../reducer/productPreRequisiteReducer";
import productDetailsByPartyIDReducer from "../reducer/productDetailsByPartyIDReducer";
import adminUserReducer from "../reducer/adminUserReducer";
import productDetailsAllReducer from "../reducer/productDetailsAllReducer";
import gdpiStatusReducer from "../reducer/gdpiStatusReducer";
import studentsProgressOverviewReducer from "../reducer/studentProgressOverviewReducer";
import studentEnquiryReducer from "../reducer/studentEnquiryReducer";
import notificationEventsReducer from "../reducer/notificationReducer";

import productPartnerReducer from "../reducer/productPartnerReducer";
import productStatusReducer from "../reducer/productStatusReducer";
import productHasModuleReducer from "../reducer/productHasModuleReducer";
import productProductTypeReducer from "../reducer/productProductTypeReducer";
import studentsPaymentHistoryReducer from "../reducer/studentPaymentHistoryReducer";
import studentsEnrollmentReducer from "../reducer/studentEnrollmentReducer";

import modulesListReducer from "../reducer/modulesListReducer";
import adminUserProfileReducer from "../reducer/adminProfileUserReducer";

import learningPathReducer from "../reducer/learningPathReducer";
import learningPathProductDataReducer from "../reducer/learningPathProductDataReducer";
import userActivityReducer from "../reducer/userActivityReducer";
import assessmentReportReducer from "../reducer/assessmentReportReducer";
import cfxAssessmentReportReducer from "../reducer/cfxAssessmentReportReducer";
import unassignedUsersReportReducer from "../reducer/unassignedUsersReducers";
import vocationalCoursesAllReducer from "../reducer/vocationalCoursesAllReducer";

// import { initialState } from '../reducer/userReducer';

// const loggerMiddleware = createLogger();
export default createStore(
  combineReducers({
    productsReducer,
    roleReducer,
    userProfileReducers,
    studentReducer,
    calenderEventsReducer,
    alertNotificationEventsReducer,
    notificationEventsReducer,
    productPreRequisiteReducer,
    productDetailsByPartyIDReducer,
    adminUserReducer,
    productDetailsAllReducer,
    studentsProgressOverviewReducer,
    gdpiStatusReducer,
    studentEnquiryReducer,
    productPartnerReducer,
    productStatusReducer,
    productHasModuleReducer,
    productProductTypeReducer,
    studentsPaymentHistoryReducer,
    modulesListReducer,
    studentsEnrollmentReducer,
    learningPathReducer,
    learningPathProductDataReducer,
    userActivityReducer,
    assessmentReportReducer,
    adminUserProfileReducer,
    cfxAssessmentReportReducer,
    unassignedUsersReportReducer,
    vocationalCoursesAllReducer
  }),

  // composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware))
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
