import React from "react";
import { useState, useRef } from "react";

// import logoImg from "../../../assets/images/logoimg.png";
// import logoImg from "../../../assets/images/finxkotak.png";
// import logoImg from "../../../assets/images/Finx-and-Kotak_top-bar.png";
// import logoImg from "../../../assets/images/Finx-and-Kotak_top-bar_new.png";
// import logoImg from "../../../assets/images/finx_kotak_new.png";
// import logoImg from "../../../assets/images/Company Logos_kotak_menu.png";
// import logoImg from "../../../assets/images/nnn.png";
// import logoImg from "../../../assets/images/sss.png";
// import logoImg from "../../../assets/images/new_kotak_logo_cropped.png";
// import logoImg from "../../../assets/images/finx kotak landing page menu image-3.png";
import logoImg from "../../../assets/images/18 April _Header Logo";
// import logoImg from "../../../assets/images/18 April _Header Logo_trans";
// import logoImg from "../../../assets/images/finxkotaklogo.jpg";
import hamImg from "../../../assets/images/ham-menu.png";
import "./Header.css";
import { useHistory } from "react-router-dom";

function Header() {
  const [isActive, setActive] = useState(false);
  const history = useHistory();

  const toggleClass = () => {
    setActive(!isActive);
  };
  const regClick = () => {
    // history.push("/register",{searchParams:history.location.search}); 
    history.push("/register",{searchParams:history.location.search,pathname:history.location.pathname}); 
  }
  console.log('====================================');
    console.log('histroy',history);
    console.log('====================================');
  return (
    <header className="col-100 floatLft flexDisplay justifyCenter morhead">
      <div className="wrapper">
        <div className="ap-headerWrp col-100 floatLft flexDisplay justifySpace alignCenter">
          <div className="ap-logo1 floatLft">
            <a href="#">
              <img src={logoImg} alt="Site Logo" />
            </a>
          </div>
          <nav
            className={
              isActive ? "ap-navMenu1 floatRgt open" : "ap-navMenu1 floatRgt"
            }
          >
            <ul className="ap-navigation1 col-100 floatLft">
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                   href="#aboutKotak"
                  className="ap-primary-black ap-gotham-medium"
                  // target="_blank"
                >
                  About KMBL
                </a>
              </li>
              {/* <li className='inlineBlk'><a className='ap-primary-black ap-gotham-medium' >About Morningstar</a></li>  */}
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                  href="#ap-benefitsDiv"
                  className="ap-primary-black ap-gotham-medium"
                >
                  Benefits
                </a>
              </li>
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                  href="#ap-programs"
                  className="ap-primary-black ap-gotham-medium"
                >
                  Program
                </a>
              </li>
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                  href="#ap-getStarted"
                  className="ap-primary-black ap-gotham-medium"
                >
                  Process
                </a>
              </li>
              <li onClick={toggleClass} className="inlineBlk">
                <a
                   
                  href="mailto:info@finxpert.org"
                  className="ap-primary-black ap-gotham-medium"
                >
                  Contact
                </a>
              </li>
              <li className="inlineBlk ap-loginBtnWrp1">
                <a
                //   href="https://bit.ly/3yezxWu"
                  // href="https://core.finxpert.org/register"
                  // href="http://localhost:3000/register"
                  className="ap-loginClick1 ap-bg-blue ap-gotham-bold"
                  // target="_blank"
                  onClick={regClick}
                >
                  Register
                </a>
              </li>
            </ul>
          </nav>
          <div className="ap-mobBtnWrp1 floatRgt">
            <a 
            // href="https://bit.ly/3yezxWu"
            // href="https://core.finxpert.org/register"
            // href="http://localhost:3000/register"
            className="ap-mobLogin-btn1"
            onClick={regClick}>
              Register
            </a>
            <button className="ap-hamBurgerClick1" onClick={toggleClass}>
              <img src={hamImg} alt="Hamburger Menu" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
