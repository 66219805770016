import React from 'react';
import Slider from 'react-slick';
import bannerImg1 from '../../../assets/images/banner-1.jpg';
import bannerImg2 from '../../../assets/images/banner-2.jpg';
import bannerImg3 from '../../../assets/images/banner-3.jpg';
import bannerImg4 from '../../../assets/images/banner-4.jpg';
import bannerImgMob1 from '../../../assets/images/bannermob-1.jpg';
import bannerImgMob2 from '../../../assets/images/bannermob-2.jpg';
import bannerImgMob3 from '../../../assets/images/bannermob-3.jpg';
import bannerImgMob4 from '../../../assets/images/bannermob-4.jpg';
import { Carousel } from 'react-responsive-carousel';

import './BannerSlider.css';

function BannerSlider() {
    const settings = {
        // dots: true,
        arrows: false,
        // fade: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        SlidesToScroll: 1
    };
  return (
    <div className='ap-banner-slider1 col-100 floatLft relative' 
    // style={{height:'530px'}}
    >
 <Carousel infiniteLoop useKeyboardArrows autoPlay autoPlaySpeed={3000}>
        {/* <Slider {...settings}> */}
            <div className="ap-bannerItem1 col-100 floatLft relative">
                
                <div className="ap-bannerImg col-100 floatLft">
                    <img  src={bannerImg1} className="ap-deskBanner1" alt="Banner Image 1" />
                    <img src={bannerImgMob1} className="ap-mobBanner1" alt="Banner Mob Image 1" />
                </div>
                <div className="ap-banner-cnt1 floatLft textLeft absolute">
                    <h1 className='col-100 floatLft ap-gotham-medium ap-primary-black'>
                        Get an opportunity to work on <br /> data research & analysis with
                    </h1>
                    <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red'>Morningstar India</h2>
                    <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>a global leader in
                    independent research & data.</h3>
                    <a 
                    // href="https://bit.ly/3yezxWu" 
                    href="https://uatcore.finxpert.org/register" 
                    target="_blank" className="ap-joinBtn1 inlineBlk ap-gotham-bold ap-primary-black">JOIN NOW</a>
                </div>
               
            </div>
            <div className="ap-bannerItem1 col-100 floatLft relative">
                <div className="ap-bannerImg col-100 floatLft">
                    <img src={bannerImg2} className="ap-deskBanner1" alt="Banner Image 2" />
                    <img  src={bannerImgMob2} className="ap-mobBanner1" alt="Banner Mob Image 2" />
                </div>
                <div className="ap-banner-cnt1 floatLft textLeft absolute">
                    <p className='col-100 floatLft ap-gotham-medium ap-primary-black'>
                    Groom your skills and <br /> kickstart your career
                    </p>
                    <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red'>Morningstar India</h2>
                    <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>a global leader in
                    independent research & data.</h3>
                    <a 
                    // href="https://bit.ly/3yezxWu"
                    href="https://uatcore.finxpert.org/register"
                     target="_blank" className="ap-joinBtn1 inlineBlk ap-gotham-bold ap-primary-black">JOIN NOW</a>
                </div>
            </div>
            <div className="ap-bannerItem1 col-100 floatLft relative">
                <div className="ap-bannerImg col-100 floatLft">
                    <img src={bannerImg3} className="ap-deskBanner1" alt="Banner Image 3" />
                    <img  src={bannerImgMob3} className="ap-mobBanner1" alt="Banner Mob Image 3" />
                </div>
                <div className="ap-banner-cnt1 floatLft textLeft absolute">
                    <p className='col-100 floatLft ap-gotham-medium ap-primary-black'>
                        Build an upward career
                    </p>
                    <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red'>Morningstar India</h2>
                    <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>a global leader in
                    independent research & data.</h3>
                    <a 
                    // href="https://bit.ly/3yezxWu"
                    href="https://uatcore.finxpert.org/register"
                     target="_blank" className="ap-joinBtn1 inlineBlk ap-gotham-bold ap-primary-black">JOIN NOW</a>
                </div>
            </div>
            <div className="ap-bannerItem1 col-100 floatLft relative">
                <div className="ap-bannerImg col-100 floatLft">
                    <img src={bannerImg4} className="ap-deskBanner1" alt="Banner Image 4" />
                    <img  src={bannerImgMob4} className="ap-mobBanner1" alt="Banner Mob Image 4" />
                </div>
                <div className="ap-banner-cnt1 floatLft textLeft absolute">
                    <p className='col-100 floatLft ap-gotham-medium ap-primary-black'>
                        Learn, work and progress to achieve <br /> newer heights in your career
                    </p>
                    <h2 className='col-100 floatLft ap-gotham-bold ap-primary-red'>Morningstar India</h2>
                    <h3 className='col-100 floatLft ap-gotham-bold ap-primary-black'>a global leader in
                    independent research & data.</h3>
                    <a 
                    // href="https://bit.ly/3yezxWu"
                    href="https://uatcore.finxpert.org/register"
                     target="_blank" className="ap-joinBtn1 inlineBlk ap-gotham-bold ap-primary-black">JOIN NOW</a>
                </div>
            </div>

        {/* </Slider> */}
        </Carousel>
        
    </div>
  )
}

export default BannerSlider