import React from 'react';
import Finxlogo from '../../assets/images/finxlogo1.png';
import { withRouter } from 'react-router-dom';
import { sessionerror, sessionNotification, warningNotification, warning, error, errorNotification, success, successNotification } from '../../components/notification/notifications';
import Modal from 'react-responsive-modal';
import $ from "jquery"
import loadable from '@loadable/component';

const LoginService = loadable(() => import('../../services/login.service'))
const PopUpPortal = loadable(() => import('../popups/PopUpPortal'))
const MobileValidationService = loadable(() => import('../../services/mobile.validation.service'))
const RegisterUserService = loadable(() => import('../../services/registration.service'))


class finxheader extends React.Component {

    constructor(props) {
        super(props);
        //localStorage.clear();
        this.state = {
            userLoginName: '',
            userPassword: '',
            companyid: '',
            countryCode: '91',
            showResendOtp: false,
            showTimer: false,
            isMobile: false,
            isEmail: false,
            isPasswordfocused: false,
            isLogined: false,
            OTPTokenCount: 0,
            loading: false,
            time: {},
            seconds: 180,
            isMaintainenceActivated: false,
            warning: false
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.handleuserLoginName = this.handleuserLoginName.bind(this);
        this.handleUserPassword = this.handleUserPassword.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        //this.submitClick = this.submitClick.bind(this);
        //this.handlebuid = this.handlebuid.bind(this);
        //localStorage.setItem('checkedtab',false);
    }
    forgotpassword = event => {
        localStorage.setItem('reload', 'true');
        this.props.history.push("/forgotpassword", {
            isloaded: true,
        })
    };


    secondsToTime = (secs) => {

        let hours = Math.floor(secs / (60 * 60));
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {

        $(window).blur(function () {
            window.addEventListener('storage', e => {


                if (localStorage.getItem('userid-Token') === null && localStorage.getItem('login') === null) {

                    if (localStorage.getItem("windowloggedout") === false) {
                        //localStorage.removeItem("windowloggedout");
                        //window.location.href = '/';
                    } else {

                        window.location.href = '/';
                    }
                }
                e.currentTarget.removeEventListener("storage", e)
            });

        });
        if (localStorage.getItem('userid-Token') !== null && localStorage.getItem('login') !== null) {
            //alert("checktab" + localStorage.getItem('checkedtab'))
            // if (localStorage.getItem('roleId') === 5) {
                if ([5,'5'].includes(localStorage.getItem('roleId'))) {
                //alert("checktab 2" + localStorage.getItem('checkedtab'))
                //window.location.href = '/studentDashboard';
                if (localStorage.getItem('checkedtab') === 'false') {
                    //alert("checktab 3" + localStorage.getItem('checkedtab'))
                    localStorage.setItem('checkedtab', true);
                    window.location.href = '/studentDashboard';
                }
            } else if ([6,'6'].includes(localStorage.getItem('roleId'))) {
                if (localStorage.getItem('checkedtab') == 'false') {
                    //alert("checktab 3" + localStorage.getItem('checkedtab'))
                    localStorage.setItem('checkedtab', true);
                    window.location.href = '/ops-dashboard';
                }
            } else {
                //window.location.href = '/admin-dashboard';
                //alert("checktab 4" + localStorage.getItem('checkedtab'))
                if (localStorage.getItem('checkedtab') === 'false') {
                    localStorage.setItem('checkedtab', true);
                    window.location.href = '/admin-dashboard';

                }

            }

        }
        else {

            if (localStorage.getItem('checkedtab') === 'false') {
                localStorage.setItem('checkedtab', true);
                window.location.href = '/';

            }
        }

        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });

        // Check session timeout URL
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessiontimeout = urlParams.get('sessiontimeout')
        if (sessiontimeout) {
            sessionerror("Your session is expired, Please login again.", {
                ...sessionNotification, dismiss: {
                    duration: 4000, onScreen: false,
                    showIcon: true,
                }
            });
            //localStorage.clear();
        }

        $('#return-to-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 500);
        });
    }

    startTimer() {
        this.handleGenerateOtp()
        this.setState({ time: {}, seconds: 180, })
        if (this.timer === 0 && this.state.seconds === 180) {
            this.timer = setInterval(this.countDown, 1000);
        }
        else {
            //if (this.state.seconds !== 0) {
            clearInterval(this.timer);
            //}
            this.timer = setInterval(this.countDown, 1000)
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1 <= 0 ? 0 : this.state.seconds - 1;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
        }
        if (seconds === 5) {
            this.setState({ showResendOtp: true, showTimer: true });
        }
    }

    handleuserLoginName(e) {
        let isMobile = this.isNumber(e.target.value);
        if (isMobile && !this.state.showResendOtp) {
            // if (e.target.value.length === 10) {
            //     this.startTimer();
            //     this.setState({ isMobile: true, showTimer: true })
            // }
        } else if (!isMobile) {
            this.setState({ isMobile: false, showResendOtp: false, showTimer: false });
            let Emailvalid = this.isEmail(e.target.value);
            if (Emailvalid) {
                //this.ValidateEmail(e.target.value);
            }
        }
        this.setState({ userLoginName: e.target.value });
    }

    handleBlur(e) {
        if (this.state.isMobile) {
            if (e.target.value.length < 10 || e.target.value.length > 10) {
                warning("Mobile number should be of 10 digits", warningNotification);
            } else {
                if (this.state.isMobile && !this.state.showResendOtp) {
                    if (e.target.value.length === 10) {
                        this.startTimer();
                        this.setState({ isMobile: true, showTimer: true })
                    }
                }
            }
        } else if (this.state.isEmail) {
            this.ValidateEmail(e.target.value);
        }

    }

    ValidateEmail(value) {
        let emailvaidateParam = 'emailID=' + value.toLowerCase();
        LoginService.TokenUser(res => {
            if (res.status === 'success') {
                localStorage.setItem('jwt-token', res.responseObject);
                RegisterUserService.validateUserData(emailvaidateParam, res1 => {
                    if (res1.status === 'success') {
                        warning("Your Email ID is not registered with us. Please register yourself by clicking the Register button below.", warningNotification);
                    } else {
                        // this.loginHandler();
                    }
                });
            }
        });
    }


    handleGenerateOtp = () => {
        localStorage.setItem('TokenName', 'jwt');
        LoginService.TokenUser(res => {
            if (res.status === 'success') {
                localStorage.setItem('jwt-token', res.responseObject);
                let mobile = this.state.userLoginName;
                mobile = "mobileNo=" + mobile + "&productID=";
                MobileValidationService.MobileValidation(mobile, res1 => {
                    if (res1.status === "success") {
                        if (res1.reasonCode === 'FC-02') {
                            MobileValidationService.MobileOTP(this.state.userLoginName, this.state.countryCode,
                                (res2) => {
                                    if (res2.status === 'success') {
                                        success('OTP will be generated in few seconds...', {
                                            ...successNotification, dismiss: {
                                                duration: 3000, onScreen: false,
                                                showIcon: true,
                                            }
                                        });
                                    }
                                    return true;
                                }, (error) => {
                                    error(error, errorNotification);
                                });
                            this.setState({ showResendOtp: false, showTimer: true });
                        } else if (res1.reasonCode === 'FC-01') {
                            //console.log('mobilevalidaton', res1.reasonText);
                            warning("Mobile number is not registered. Please try again.", {
                                ...warningNotification, dismiss: {
                                    duration: 3000, onScreen: false,
                                    showIcon: true,
                                }
                            });
                            this.setState({ showResendOtp: false, showTimer: false });
                        } else {
                            warning(res.reasonText, { ...warningNotification, container: "top-center" });
                            this.setState({ showResendOtp: false, showTimer: false });
                        }
                    } else {
                        warning(res1.reasonText, { ...warningNotification, container: "top-center" });
                        this.setState({ showResendOtp: false, showTimer: false });
                    }

                });

            }
        })
    }

    isEmail(e) {
        const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{3,5})$/;
        if (re.test(e)) {
            this.setState({ isEmail: true });
            return true;
        }
        else {
            this.setState({ isEmail: false });
            return false;
        }
    }

    isNumber(e) {

        const re = /^[0-9\b]+$/;
        if (re.test(e)) {
            this.setState({ isMobile: true })
            return true;
        }
        else {
            this.setState({ isMobile: false })
            return false;
        }
    }

    handleUserPassword(e) {
        this.setState({ isPasswordfocused: true, userPassword: e.target.value });
    }

    _handleKeyDown(e) {
        //alert("outside"+ e.key);
        if (e.key === 'Enter') {
            //alert("Inside "+ e.key);
            if (e.target.name === 'password') {
                this.setState({ isPasswordfocused: true, userPassword: e.target.value });
                this.loginHandler(e);
            }
            // else if (e.target.name === 'userLoginName') {
            //     this.handleuserLoginName(e);

            // }
        }
        if (e.getModifierState("CapsLock")) {
            this.setState({ warning: true });
        } else {
            this.setState({ warning: false });
        }

    }

    loginHandler = (event) => {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (!this.state.isMaintainenceActivated) {
            if (this.state.userLoginName !== '' && this.state.userPassword !== '') {
                this.setState({ loading: true });
                const LoginDetails = {
                    userLoginName: this.state.userLoginName.trim(),// 'PramilaPatil',
                    emailId: this.state.userLoginName.trim(),// 'PramilaPatil',
                    userPassword: this.state.userPassword.trim(), //'pramila555',
                }
                var logincon = [];
                this.setState({ loading: true });
                LoginService.SendUser(LoginDetails, this.state.isMobile, (res) => {

                    if (res.status === 'success') {
                        logincon = res;
                        this.setState({ credentials: logincon });
                        localStorage.setItem('eMail', logincon.responseObject.emailId);
                        localStorage.setItem('userid-Token', logincon.responseObject.partyId);
                        localStorage.setItem('userid', logincon.responseObject.userId);
                        localStorage.setItem('roleId', logincon.responseObject.role[0].roleId);
                        localStorage.setItem('roleName', logincon.responseObject.role[0].roleName);
                        localStorage.setItem('checkedtab', false);
                        localStorage.setItem('TokenName', 'jti');
                        LoginService.TokenUser(res => {
                            this.setState(state => {
                                let oldstate = Object.assign({}, state);
                                oldstate.credentials.responseObject.token = res.responseObject;
                                this.state = oldstate; // setState({...})
                                localStorage.setItem('jti-token', res.responseObject);
                                localStorage.setItem('login', JSON.stringify(oldstate.credentials.responseObject));
                            });

                        }).then(()=>{
                        localStorage.setItem('TokenName', 'jwt');
                        LoginService.TokenUser(res => {
                            localStorage.setItem('jwt-token', res.responseObject);
                            this.setState({ loading: false });
                            if (this.state.credentials.responseObject["forcePasswordChangeOnNextLoginFlag"] === '1') {
                                localStorage.setItem('reload', 'true');
                                this.props.history.push('/changepassword');
                            } else {
                                if ([5,'5'].includes(localStorage.getItem('roleId'))) {
                                    $('.modal-backdrop').hide();
                                    this.setState({ loading: true });
                                    setTimeout(() => {  
                                    this.setState({ loading: false });
                                    this.props.history.push('/studentDashboard');
                                    localStorage.setItem('reload', 'true');
                                    }, 3000);
                                } else if ([6,'6'].includes(localStorage.getItem('roleId'))) {
                                    if (localStorage.getItem('checkedtab') == 'false') {
                                        //alert("checktab 3" + localStorage.getItem('checkedtab'))
                                        localStorage.setItem('checkedtab', true);
                                        window.location.href = '/ops-dashboard';
                                    }
                                } else {
                                    $('.modal-backdrop').hide();
                                    this.setState({ loading: true });
                                    setTimeout(() => {  
                                    this.setState({ loading: false });
                                    this.props.history.push('/admin-dashboard');
                                    localStorage.setItem('reload', 'true');
                                    }, 3000);
                                }
                            }
                        });
                        });
                    } else {
                        this.setState({ loading: false });

                        if (res.reasonCode === 'DM_02') {
                            let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
                            this.setState({ OTPTokenCount: otpcounter });
                            if (this.state.OTPTokenCount >= 4) {
                                //warning("Click on Resend OTP", warningNotification);
                                this.setState({ loading: false, isLogined: false, showResendOtp: true, showTimer: false });
                                warning('You have made 3 unsuccessful attempts to Login to FinX. Click on Resend OTP.', warningNotification);
                            } else {
                                warning(res.reasonText + " count " + this.state.OTPTokenCount, warningNotification);
                            }
                        } else if (res.reasonCode === 'SM_11') {
                            //warning("Token is getting refreshed", warningNotification);
                            window.location.href = '/';
                        } else if (res.reasonCode === 'SM-03') {
                            if (res.reasonText === 'InActive user') {
                                warning("Access Unauthorized. For login support, please contact support@finxpert.org.", warningNotification);
                            } else {
                                warning("Invalid Login Credentials. Please try again", warningNotification);
                            }
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                        } else if (res.reasonCode === 'SM-07') {
                            this.setState({ loading: false, isLogined: true, showResendOtp: false, showTimer: false });
                        } else if (res.reasonCode === 'SM-12') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            error('You have made 3 unsuccessful attempts to Login to FinX. Your account has been locked.  For login support, please contact support@finxpert.org.', errorNotification);
                        } else if (res.reasonCode === 'SM-13') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning('Your Account has been Suspended. For login support, please contact support@finxpert.org', warningNotification);
                        }
                        else if (res.reasonCode === 'SM-04') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning('Your Account has been Suspended. For login support, please contact support@finxpert.org', warningNotification);
                        }
                        else {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning(res.reasonText, warningNotification);
                        }
                    }
                })
            } else {
                if (this.state.userLoginName === '') {
                    error("You must specify a Username", errorNotification);
                } else if (this.state.userPassword === '' && this.state.isPasswordfocused) {
                    error("You must specify Password", errorNotification);
                } else if (this.state.userLoginName === '' && this.state.userPassword === '') {
                    error("You must specify a  Username and Password", errorNotification);
                }


            }
        } else {
            error('System is unavailable on 2nd ( Monday ) March 2020 for upgradation starting from 7.00 PM to 10.00 PM. Inconvenience is regretted.Reach out to Finx Core Support Team for any clarifications.', errorNotification);

        }
    }
    onCloseModal = () => {
        this.setState({ isLogined: false });
    }

    handleYes = () => {
        let isMobile = this.state.isMobile;
        const data = {
            userName: this.state.userLoginName,// 'PramilaPatil',
        }
        localStorage.setItem('TokenName', 'jwt');
        LoginService.TokenUser(res => {
            if (res.status === 'success') {
                localStorage.setItem('jwt-token', res.responseObject);

                LoginService.LogoutUser(data, isMobile, (res) => {
                    if (res.status === 'success') {
                        localStorage.clear();
                        localStorage.setItem("windowloggedout", true)
                        window.location.href = '/';
                    } else {
                        warning(res.reasonText, warningNotification);
                    }
                }, (error) => {
                    //console.log(error);
                    error(error, errorNotification);
                })
            }
        }, (error) => {
            //console.log(error);
            error(error, errorNotification);
        })
    }



    render() {
        // let OTPstyle = {
        //     otpTimer: {
        //         margin: '10px',
        //         color: 'blue',
        //     },
        //     resendBtn: {
        //         backgroundColor: '#5cb85c',
        //         color: 'white',
        //         border: '1 px solid #ccc'
        //     }
        // }
        return (
            <React.Fragment>

                {this.state.loading && <PopUpPortal HidePopup={this.state.loading}
                    IsVisible={this.state.loading} />}
                <section className="header">
                    <nav className="navbar navbar navbar-fixed-top">
                        <div className="container-fluid padding-pl-pr">
                            {/*  Brand/logo */}
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="modal" data-target="#flipFlop" aria-expanded="false">
                                    <span data-toggle="modal" data-backdrop="static" data-keyboard="true" data-target="#login">Login &nbsp; <i className="fa fa-sign-in"
                                        aria-hidden="true"></i></span>
                                </button>
                                <a className="navbar-brand" href="/"><img src={Finxlogo} alt="Finx Logo" /></a>
                            </div>
                            {/* <!-- login Navbar --> */}
                            <div className="login hidden-xs hidden-sm pull-right">
                                <form >
                                    <ul className="login-detail">
                                        <li>
                                            <input type="text"
                                                name="userLoginName"
                                                className="form-control"
                                                placeholder="Username/Mobile No."
                                                onChange={this.handleuserLoginName}
                                                value={this.state.userLoginName}
                                                onKeyDown={this._handleKeyDown}
                                                onBlur={this.handleBlur}
                                                autoComplete="off"
                                                disabled={this.state.isMaintainenceActivated}
                                            />
                                            {this.state.warning ? <span style={{ color: "white" }}>Caps Lock On!</span> : ""}
                                        </li>
                                        <li>
                                            <input type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder={!this.state.isMobile ? "Password" : "OTP"}
                                                onChange={this.handleUserPassword}
                                                value={this.state.userPassword}
                                                onKeyDown={this._handleKeyDown}
                                                autoComplete="off"
                                                disabled={this.state.isMaintainenceActivated}
                                            />
                                            {this.state.showResendOtp ?
                                                <React.Fragment>
                                                    <span>
                                                        {/* <Otp
                                                        style={OTPstyle}
                                                        minutes={3.0}      // Minutes ( Pass the no of minutes that you want count )
                                                        //resendEvent={this.handleGenerateOtp.bind(this)} //  Resend button event you can pass your function name here
                                                    /> */}

                                                        <a href="#"
                                                            // onClick={this.handleGenerateOtp}
                                                            onClick={this.startTimer}
                                                        > Resend OTP</a>
                                                    </span>

                                                </React.Fragment>
                                                : this.state.showTimer ? <div>
                                                    <h5 className="timer1">{this.state.time.m} : {this.state.time.s} Time Left</h5>
                                                </div> :
                                                    !this.state.isMaintainenceActivated ?
                                                        <span>
                                                            <a href="#" onClick={this.forgotpassword} className="sidebartogglexyz">Forgot Password?</a>
                                                            {/* <Link to="/forgotpassword">Forgot Password?&nbsp;&nbsp;&nbsp;</Link> */}
                                                        </span> : ''
                                            }

                                        </li>
                                        <li className="login-button">
                                            {!this.state.isMaintainenceActivated ?
                                                <a href="#" onClick={this.loginHandler}
                                                >Login
                                                    <i className="fa fa-sign-in" aria-hidden="true"></i></a> : ''}
                                            {/*
                                            <button onClick={this.loginHandler} >Login
                                    <img src={loginIcon} alt="Login" />
                                            </button> */}
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </nav>

                </section>


                <div className="modal fade" id="login" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content login-modal">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title">Login FinX</h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <input type="text"
                                            className="form-control"
                                            placeholder="Username/Mobile No."
                                            onChange={this.handleuserLoginName}
                                            value={this.state.userLoginName}
                                            onKeyDown={this._handleKeyDown}
                                            autoComplete="off" required />

                                        <input type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            placeholder={!this.state.isMobile ? "Password" : "OTP"}
                                            onChange={this.handleUserPassword}
                                            value={this.state.userPassword}
                                            onKeyDown={this._handleKeyDown}
                                            autoComplete="off" required /> */}
                                        <input type="text"
                                            name="userLoginName"
                                            className="form-control"
                                            placeholder="Username/Mobile No."
                                            onChange={this.handleuserLoginName}
                                            value={this.state.userLoginName}
                                            onKeyDown={this._handleKeyDown}
                                            onBlur={this.handleBlur}
                                            autoComplete="off"
                                            disabled={this.state.isMaintainenceActivated}
                                        />

                                        <input type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder={!this.state.isMobile ? "Password" : "OTP"}
                                            onChange={this.handleUserPassword}
                                            value={this.state.userPassword}
                                            onKeyDown={this._handleKeyDown}
                                            autoComplete="off"
                                            disabled={this.state.isMaintainenceActivated}
                                        />
                                        {this.state.showResendOtp ?
                                            <React.Fragment>
                                                <span>
                                                    {/* <Otp
                                                        style={OTPstyle}
                                                        minutes={3.0}      // Minutes ( Pass the no of minutes that you want count )
                                                        //resendEvent={this.handleGenerateOtp.bind(this)} //  Resend button event you can pass your function name here
                                                    /> */}

                                                    <a href="#"
                                                        // onClick={this.handleGenerateOtp}
                                                        onClick={this.startTimer}
                                                    > Resend OTP</a>
                                                </span>

                                            </React.Fragment>
                                            : this.state.showTimer ? <div>
                                                <h5 className="timer2">{this.state.time.m} : {this.state.time.s} Time Left</h5>
                                            </div> :
                                                !this.state.isMaintainenceActivated ?
                                                    <span>
                                                        <a href="#" onClick={this.forgotpassword} className="sidebartogglexyz">Forgot Password?</a>
                                                        {/* <Link to="/forgotpassword">Forgot Password?&nbsp;&nbsp;&nbsp;</Link> */}
                                                    </span> : ''}
                                        {!this.state.isMaintainenceActivated ?
                                            <button className="btn-1 mt-30" type='button'> <a href="#" onClick={this.loginHandler} > Login <i className="fa fa-sign-in" aria-hidden="true"></i> </a> </button> : ''
                                        }
                                        { /*   <span className="forgot_reload pull-right mt-10">
                                        <a onClick={this.forgotpassword} className="sidebartogglexyz">Forgot Password?</a>
                                            {/* <Link to="/forgotpassword" isloaded='true' >Forgot Password?</Link> * /}
                                        </span> */ }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isLogined ?
                    (

                        <Modal open={this.state.isLogined} onClose={this.onCloseModal} center>
                            <div className="" id="" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-md" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className="scr-board">
                                                <h2>FinX Core Login</h2>
                                                <div className="bottom-line"></div>
                                            </div>
                                            {/* <h4 className="modal-title">FinX Core Login</h4> */}
                                        </div>
                                        <div className="modal-body">

                                            <div className="">
                                                <div className="">
                                                    <div className="">
                                                        <div className="reset">
                                                            <h3 className="word-break">You have already been logged in other machine. Do you want to Logout?
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn-5 mt-40 mr-20" name="btnYes" onClick={this.handleYes}>Yes</button>
                                            <button type="button" className="btn-5 mt-40" name="btnNo" onClick={this.onCloseModal}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                    )
                    : null}


            </React.Fragment>
        );
    }
}

export default withRouter(finxheader);
