import {
  FETCH_STUDENT_PENDING,
  FETCH_STUDENT_SUCCESS,
  FETCH_STUDENT_ERROR
} from "../action/studentAction";

const initialState = {
  pending: false,
  students: [],
  error: null
};

const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDENT_PENDING:
      return {
        ...state,
        pending: true,
        err: false
      };
    case FETCH_STUDENT_SUCCESS:
      return {
        ...state,
        pending: false,
        err: false,
        students: action.payload
      };
    case FETCH_STUDENT_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default studentsReducer;
export const getStudents = state => state.students;
export const getStudentsPending = state => state.pending;
export const getStudentsError = state => state.error;
