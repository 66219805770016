import React from 'react';
// import { LoginService } from "../../services/login.service";
// import { error, success, warning, warningNotification, errorNotification, successNotification } from '../notification/notifications';
// import $ from "jquery";
import AdminHeader from "../dashboardheader/adminheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
// import Finxheader from "../../containers/finxheader/finxheader";
// import ReactHTMLParser from "react-html-parser";
// import { FetchAlertService } from "../../services/fetchalerts";

class AdminAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchalerts: '',
            alerts: this.props.history.location.state !== undefined ? this.props.history.location.state.alerts : JSON.parse(localStorage.getItem('AdminAlertNotification')),
            alertid: this.props.history.location.state !== undefined ? this.props.location.state.alertid : 0
        }
        this.handleCancel = this.handleCancel.bind(this);

    }
    handleCancel = () => {
        this.props.history.goBack('-1');
        //window.$("#gdpi-score").modal('hide');
        // window.location.reload();

    };
    componentDidMount() {
        //yyyyyalert(this.state.alertid);
    }
    render() {
        return (
            <React.Fragment>
                <AdminHeader
                    {...this.props}
                />

                <section id="notification-page" class="pt-50 mb-50">
                    <div class="container">
                        <div class="panel-group" id="accordion">
                            {this.state.alerts ? this.state.alerts.map((item, key) => (
                                <div class="panel panel-default">
                                    <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href={'#collapse' + item.alertID} aria-expanded="false">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                {item.alertName}
                                            </h4>
                                        </div>
                                    </a>

                                    <div id={'collapse' + item.alertID}
                                        class={"panel-collapse collapse" + (this.props.location.state !== undefined ? this.props.location.state.alertid ? (this.props.location.state.alertid === item.alertID ? "in" : '') : "" : "")} aria-expanded="false">
                                        <div class="panel-body">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div className="notification-content">
                                                    <div className="notification-body">
                                                        <p className="alert-body">{item.alertText} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : ""

                            }
                        </div>
                        <div className="row">
                            <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.handleCancel}><span aria-hidden="true">Back</span></button>
                        </div>

                    </div>
                </section>

                <Finxfooter />
            </React.Fragment>

        )
    }


}

export default AdminAlert;

