import React, { Component } from "react";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { warning, warningNotification } from "../notification/notifications";
import { DiscountService } from "../../services/discount.service";
import { LoginService } from "../../services/login.service";
import { withRouter } from "react-router-dom";

class ProductValidityListLandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filtered: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });

    if (localStorage.getItem("reload") === "true") {
      localStorage.setItem("reload", "false");
    }
    DiscountService.getProductValidityList((res) => {
      console.log("res", res);

      if (res.data.status === "success") {
        this.setState({
          loading: false,
          data: res.data.responseListObject,
        });
      } else {
        this.setState({
          loading: false,
          data: [],
        });
      }
    });

    this.setState({ loading: false });
  }

  filterCaseInsensitive = ({ id, value }, row) => {


    return row[id] !== undefined
      ? id === "noOfUsed"
        ? 
          String(row[id]).includes(String(value))
        : String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };

  render() {
    return (
      <>
      {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
        <section className="dashboard-tab user-tab pt-50 mb-50">
          <div className="padding-rl-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="table-header">
                    <h3>
                      <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-8">
                          <span className="left-icon">
                            <i className="fa fa-list" aria-hidden="true"></i>
                          </span>{" "}
                          Product Validity List
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-4">
                          <span className="right-icon">
                            <a
                              title="Add Product Validity"
                              href="/add-product-validity"
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </h3>
                  </div>

                  <div className="bg-box">
                    <div className="table-responsive">
                      <ReactTable
                        minRows={2}
                        data={this.state.data || []}
                        filtered={this.state.filtered}
                        onFilteredChange={(filtered) => {
                          this.setState({
                            filtered: filtered,
                          });
                        }}
                        className="-striped -highlight"
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        ref={(refReactTable) => {
                          this.refReactTable = refReactTable;
                        }}
                        columns={[
                          {
                            columns: [
                              {
                                Header: "Product Type",
                                id: "productType",
                                accessor: (d) => d.productType,
                              },
                              {
                                Header: "Product Name",
                                id: "productName",
                                width: 120,
                                accessor: (d) => d.productName,
                                Cell: (row) => (
                                  <span title={row.value}>{row.value}</span>
                                ),
                              },
                              {
                                Header: "January",
                                id: "januaryValidity",
                                accessor: (d) =>
                                  d.januaryValidity
                                    ? d.januaryValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "February",
                                id: "februaryValidity",
                                accessor: (d) =>
                                  d.februaryValidity
                                    ? d.februaryValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "March",
                                id: "marchValidity",
                                accessor: (d) =>
                                  d.marchValidity
                                    ? d.marchValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "April",
                                id: "aprilValidity",
                                accessor: (d) =>
                                  d.aprilValidity
                                    ? d.aprilValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "May",
                                id: "mayValidity",
                                accessor: (d) =>
                                  d.mayValidity
                                    ? d.mayValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "June",
                                id: "juneValidity",
                                accessor: (d) =>
                                  d.juneValidity
                                    ? d.juneValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "July",
                                id: "julyValidity",
                                accessor: (d) =>
                                  d.julyValidity
                                    ? d.julyValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "August",
                                id: "augustValidity",
                                accessor: (d) =>
                                  d.augustValidity
                                    ? d.augustValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "September",
                                id: "septemberValidity",
                                accessor: (d) =>
                                  d.septemberValidity
                                    ? d.septemberValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "October",
                                id: "octoberValidity",
                                accessor: (d) =>
                                  d.octoberValidity
                                    ? d.octoberValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "November",
                                id: "novemberValidity",
                                accessor: (d) =>
                                  d.novemberValidity
                                    ? d.novemberValidity.toString()
                                    : "----",
                              },
                              {
                                Header: "December",
                                id: "decemberValidity",
                                accessor: (d) =>
                                  d.decemberValidity
                                    ? d.decemberValidity.toString()
                                    : "----",
                              },

                              // {
                              //   Header: "Created On",
                              //   id: "createdDate",
                              //   accessor: (d) => d.createdOn,
                              // },
                            ],
                          },
                        ]}
                        defaultPageSize={20}
                        // style={{
                        //   width: "100%",
                        //   maxHeight: "500px",
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withRouter(ProductValidityListLandingPage);
