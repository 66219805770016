export const FETCH_STUDENT_ENQUIRY_PENDING = "FETCH_STUDENT_ENQUIRY_PENDING";
export const FETCH_STUDENT_ENQUIRY_SUCCESS = "FETCH_STUDENT_ENQUIRY_SUCCESS";
export const FETCH_STUDENT_ENQUIRY_ERROR = "FETCH_STUDENT_ENQUIRY_ERROR";

export function fetchStudentEnquiryPending() {
  return {
    type: FETCH_STUDENT_ENQUIRY_PENDING
  };
}

export function fetchStudentEnquirySuccess(student_enquiry) {
  return {
    type: FETCH_STUDENT_ENQUIRY_SUCCESS,
    payload: student_enquiry
  };
}

export function fetchStudentEnquiryError(error) {
  return {
    type: FETCH_STUDENT_ENQUIRY_ERROR,
    error: error
  };
}
