export const FETCH_ADMIN_USER_PENDING = 'FETCH_ADMIN_USER_PENDING';
export const FETCH_ADMIN_USER_SUCCESS = 'FETCH_ADMIN_USER_SUCCESS';
export const FETCH_ADMIN_USER_ERROR = 'FETCH_ADMIN_USER_ERROR';

export function fetchAdminUserPending() {
    return {
        type: FETCH_ADMIN_USER_PENDING
    }
}

export function fetchAdminUserSuccess(admin_user) {

    return {
        type: FETCH_ADMIN_USER_SUCCESS,
        payload: admin_user
    }
}

export function fetchAdminUserError(error) {
    return {
        type: FETCH_ADMIN_USER_ERROR,
        error: error
    }
}