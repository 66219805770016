import { FETCH_PARTNER_PENDING, FETCH_PARTNER_SUCCESS, FETCH_PARTNER_ERROR } from '../action/productPartnerAction';

const initialState = {
    
    pending: false,
    partner:[],
    error: null
}

const productDetailsAllReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_PARTNER_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_PARTNER_SUCCESS:
            return {
                ...state,
                pending: false,
                partner:action.payload,
            }
        case FETCH_PARTNER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default productDetailsAllReducer;
export const fetchPartnerPending = state => state.pending;
export const fetchPartnerError = state => state.error;
export const getPartner = state => state.partner;