import React, { Component } from "react";
import AdminHeader from "../../components/dashboardheader/adminheader";
import Finxfooter from "../finxfooter/finxfooter";
import EditProductValidity from "../../components/product-validity/editProductValidity";

class ProductValidityEdit extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    // console.log("props", this.props);

    return (
      <>
        <AdminHeader {...this.props} />
        <EditProductValidity {...this.props} />
        <Finxfooter />
      </>
    );
  }
}

export default ProductValidityEdit;
