import React, { Component } from "react";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
// import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  // warning,
  // warningNotification,
  success,
  successNotification,
  error,
  errorNotification
} from "../notification/notifications";
import { withRouter } from "react-router-dom";

import { ProductService } from "../../services/product.service";

class AddProductValidity extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      loading: false,
      today: moment(todaydate).format("YYYY-MM-DD"),
    };
  }
  handleCancel = () => {
    this.props.history.goBack("-1");
  };
  componentDidMount() {
    console.clear()
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          getAllActiveVideos: this.props.getAllActiveVideos,
          getAllActiveCourses: this.props.getAllActiveCourses,
          productValidityDetails: [],
          productType: "",
          product: "",
          1: "",
          2: "",
          3: "",
          4: "",
          5: "",
          6: "",
          7: "",
          8: "",
          9: "",
          10: "",
          11: "",
          12: "",
          productID: null,
        }}
        validationSchema={Yup.object().shape({
          productType: Yup.string().required("You must specify a Product Type"),
          product: Yup.string().required("You must specify a Product"),
          1: Yup.string()
            .required("You must specify a January")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          2: Yup.string()
            .required("You must specify a February")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          3: Yup.string()
            .required("You must specify a March")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          4: Yup.string()
            .required("You must specify a April")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          5: Yup.string()
            .required("You must specify a May")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          6: Yup.string()
            .required("You must specify a June")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          7: Yup.string()
            .required("You must specify a July")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          8: Yup.string()
            .required("You must specify a August")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          9: Yup.string()
            .required("You must specify a September")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          10: Yup.string()
            .required("You must specify a October")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          11: Yup.string()
            .required("You must specify a November")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
          12: Yup.string()
            .required("You must specify a December")
            .matches(
              /^([1-9]\d*|0{1})([0-9])?$/,
              "Please provide valid Number"
            ),
        })}
        onSubmit={(fields) => {
          this.setState({ loading: true });
          let data = {
            productValidityDetails : fields.productValidityDetails
          };
          // ! TODO : Update Qualification Details
          ProductService.saveProductValidityDetails(data, (res) => {
            if (res.status === "success") {
              this.setState({ loading: false });
              success(
                "Product validity details saved/updated successfully",
                successNotification
              );
              setTimeout(() => {
                window.location.href="/product-validity-list"
              }, 1500);
            } else {
              this.setState({ loading: false });
              error(res.reasonText, {
                ...errorNotification,
                container: "top-center",
              });
            }
          });
        



        }}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleReset,
          handleBlur,
          handleChange,
          setFieldValue,
          ...formikProps
        }) => (
          <>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section className="dashboard-tab user-profile pt-50 mb-50">
              <Form onSubmit={handleSubmit} className="form-group">
                <div className="">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-130">
                        <div className="bg-box">
                          <section className="create-user" id="create-user">
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">
                                  Add/Edit Product Validity
                                </h2>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Product Type{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="productType"
                                        id="productType"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          const type = e.target.value;
                                          setFieldValue("productType", type);
                                          setFieldValue("productID", "");
                                        }}
                                        value={values.productType}
                                        className={
                                          "form-control" +
                                          (errors.productType &&
                                          touched.productType
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option selected disabled value={""}>
                                          Select Product Type
                                        </option>
                                        <option value={"COURSE"}>Course</option>
                                        <option value={"VIDEO"}>Video</option>
                                      </Field>
                                      <ErrorMessage
                                        name="productType"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Product{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="product"
                                        id="product"
                                        onChange={(e) => {
                                          const productID = e.target.value;
                                          setFieldValue("productID", productID);
                                          setFieldValue("product", productID);

                                          if (productID !== "") {
                                            if (
                                              values.productType &&
                                              productID
                                            ) {
                                              const data = {
                                                productID: productID,
                                                productType: values.productType,
                                              };
                                              ProductService.getProductValidityDetails(
                                                data,
                                                (res) => {
                                                  const response = res.responseListObject;
                                                  setFieldValue("productValidityDetails",response);
                                                  if(response.length >= 12 ){
                                                    response.forEach(entity => {
                                                      setFieldValue(entity.forMonth,entity.validityDays)
                                                    });
                                                  }else{
                                                    const productValidityDetails = [];
                                                    for(let month = 1;month <= 12 ; month++){
                                                      setFieldValue(month,60)
                                                      productValidityDetails.push({
                                                        "productID": productID,
                                                        "validityDays": 60,
                                                        "forMonth": month,
                                                        "productType": values.productType,
                                                      })
                                                    }
                                                    setFieldValue("productValidityDetails",productValidityDetails)
                                                  }
                                                }
                                              );
                                            }
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        value={values.product}
                                        className={
                                          "form-control" +
                                          (errors.product && touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">
                                          Select Product
                                        </option>
                                        {values.productType === "COURSE" ? (
                                          values.getAllActiveCourses.map(
                                            (data) => (
                                              <option
                                                title={data.productName}
                                                key={data.productId}
                                                value={`${data.productId}`}
                                              >
                                                {data.productName} (
                                                {data.productId})
                                              </option>
                                            )
                                          )
                                        ) : values.productType === "VIDEO" ? (
                                          values.getAllActiveVideos.map(
                                            (data) => (
                                              <option
                                                title={data.productName}
                                                key={data.productId}
                                                value={`${data.productId}`}
                                              >
                                                {data.productName} (
                                                {data.productId})
                                              </option>
                                            )
                                          )
                                        ) : (
                                          <option disabled>
                                            No Record Found
                                          </option>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="product"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    {/* <div className="row"> */}
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        January{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="1"
                                        id="1"
                                        value={values[1]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(1,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                              if(String(details.forMonth) === e.target.id){
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 1 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 1 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="1"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        February{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="2"
                                        id="2"
                                        value={values[2]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(2,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 2 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 2 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="2"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        March{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="3"
                                        id="3"
                                        value={values[3]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(3,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 3 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 3 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="3"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        April{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="4"
                                        id="4"
                                        value={values[4]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(4,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 4 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 4 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="4"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        May{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="5"
                                        id="5"
                                        value={values[5]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(5,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 5 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 5 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="5"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        June{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="6"
                                        id="6"
                                        value={values[6]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(6,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 6 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 6 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="6"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        July{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="7"
                                        id="7"
                                        value={values[7]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(7,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 7 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 7 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="7"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        August{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="8"
                                        id="8"
                                        value={values[8]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(8,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 8 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 8 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="8"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        September{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="9"
                                        id="9"
                                        value={values[9]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(9,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 9 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 9 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="9"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        October{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="10"
                                        id="10"
                                        value={values[10]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(10,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 10 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 10 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="10"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        November{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="11"
                                        id="11"
                                        value={values[11]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(11,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 11 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 11 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="11"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-2 col-sm-4 col-xs-12 pl ">
                                      <label>
                                        December{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        name="12"
                                        id="12"
                                        value={values[12]}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(12,e.target.value)
                                          // const validityID = e.target.getAttribute('data-validityID');
                                          const productValidityDetails = values.productValidityDetails;
                                          // ! TODO
                                          // const index = productValidityDetails.findIndex(details => (details.forMonth == e.target.id || details.forMonth === e.target.id ))
                                          const index = productValidityDetails.findIndex(details => (String(details.forMonth) === e.target.id))
                                          
                                          if(index !== -1){
                                            productValidityDetails.forEach(details => {
                                                // if(details.forMonth === e.target.id || details.forMonth == e.target.id){
                                                  if(String(details.forMonth) === e.target.id){
                                                    details.validityDays = e.target.value;
                                                }
                                            });
                                          }else{
                                            productValidityDetails.push({
                                                "productID": values.productID,
                                                "validityDays": e.target.value,
                                                "forMonth": e.target.id,
                                                "productType": values.productType,
                                            })
                                          }
                                          setFieldValue("productValidityDetails",productValidityDetails)
                                        }}
                                        data-validityID={(()=>{
                                            let id = null;
                                            values.productValidityDetails.filter(data => data.forMonth === 12 ? id = data.validityID : null)
                                            return id;
                                        })()}
                                        //value={(() => {
                                        //  if(values.productValidityDetails.length > 0){
                                        //   let days = 60;
                                        //  values.productValidityDetails.filter(data => data.forMonth === 12 ? days  = data.validityDays : days)
                                        //  return days;
                                        //  }
                                        //})()}
                                        disabled={!values.productType || !values.productID}
                                        placeholder={"Enter Days"}
                                        className={
                                          "form-control" +
                                          (errors.product &&
                                          touched.product
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="12"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>

                                  {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                            <button
                              name="cancelForm"
                              type="button"
                              className="btn-4 pull-right"
                              onClick={this.handleCancel}
                            >
                              Cancel
                            </button>

                            <button
                              name="Save"
                              type="submit"
                              className="btn-4 mr-20 pull-right"
                              onClick={() => {
                                setFieldValue("isActive", 1);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                          {/* <br/>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                      <pre>
                                        {JSON.stringify(values.productValidityDetails,null,1)}
                                      </pre>
                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </section>
          </>
        )}
      />
    );
  }
}
export default withRouter(AddProductValidity);
