import { FETCH_MODULES_LIST_PENDING, FETCH_MODULES_LIST_SUCCESS, FETCH_MODULES_LIST_ERROR } from '../action/modulesListAction';

const initialState = {
    
    pending: false,
    modules: [],
    error: null
}

const modulesListReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_MODULES_LIST_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_MODULES_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                modules: action.payload,
              
            }
        case FETCH_MODULES_LIST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        default:
            return state;
    }
}
export default modulesListReducer;
//export const getModulesList = state => state.modules;
export const getModuleList = state => state.modules;
export const getModulesListPending = state => state.pending;
export const getModulesListError = state => state.error;
