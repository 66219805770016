import {
  FETCH_CFX_ASSESSMENT_REPORT_PENDING,
  FETCH_CFX_ASSESSMENT_REPORT,
  FETCH_CFX_ASSESSMENT_REPORT_ERROR
} from "../action/cfxAssessmentAction";

const initialState = {
  pending: false,
  cfxAssessmentReport: [],
  error: null
};

const cfxAssessmentReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CFX_ASSESSMENT_REPORT_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_CFX_ASSESSMENT_REPORT:
      return {
        ...state,
        pending: false,
        err: false,
        assessmentReport: action.payload
      };
    case FETCH_CFX_ASSESSMENT_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default cfxAssessmentReportReducer;

export const cfxAssessmentReport = state => state.cfxAssessmentReport;
export const cfxAssessmentReportPending = state => state.pending;
export const cfxAssessmentReportError = state => state.error;
