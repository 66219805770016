import { FETCH_HASMODULE_PENDING, FETCH_HASMODULE_SUCCESS, FETCH_HASMODULE_ERROR } from '../action/productHasModuleAction';

const initialState = {
    
    pending: false,
    hasModule:[],
    error: null
}

const productHasModuleReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_HASMODULE_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_HASMODULE_SUCCESS:
            return {
                ...state,
                pending: false,
                hasModule:action.payload,
            }
        case FETCH_HASMODULE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default productHasModuleReducer;
export const fetchHasModulePending = state => state.pending;
export const fetchHasModuleError = state => state.error;
export const getHasModule = state => state.getHasModule;