export const FETCH_STATUS_PENDING = 'FETCH_STATUS_PENDING';
export const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS';
export const FETCH_STATUS_ERROR = 'FETCH_STATUS_ERROR';

export function fetchStatusPending() {
    return {
        type: FETCH_STATUS_PENDING,
    }
}

export function fetchStatusSuccess(Status) {

    return {
        type: FETCH_STATUS_SUCCESS,
        payload: Status
    }
}

export function fetchStatusError(error) {
    return {
        type: FETCH_STATUS_ERROR,
        error: error
    }
}