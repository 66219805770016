import { Global_var } from '../global/global_var';
import RestDataSource from '../services/restdatasource';

export const LoginService = {
  SendUser,
  sendUserSocial,
  isUserExist,
  updateUserSocialDetails,
  LogoutUser,
  SessionUserDetails,
  TokenUser,
  ForgotPassword,
  ChangePassword,
  ChangeAdminPassword,
  updateForgotPassword,
  ChangeGPassword,
  GetUserRegistratonType
};

function SendUser(user, isMobile, fn, fnError) {
  let url = '';
  let userMob = user;
  if (!isMobile) {
    url = Global_var.BASEURL + Global_var.URL_LOGIN;
  } else {
    url = Global_var.BASEURL + Global_var.URL_LOGIN_MOBILE;
    userMob = {
      "mobileNumber": user.userLoginName,
      "otp": user.userPassword
    }
  }

  return new RestDataSource(url, fnError).Store(userMob, (res) => fn(res.data));
}

/**
 * ! author : Akshay Bharadva
 * ! Function For User Social Login
 */
function sendUserSocial(user, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URI_LOGIN_SOCIAL;
  console.log(url)
  return new RestDataSource(url, fnError).Store(user, (res) => fn(res.data));
}
/**
* ! author : Akshay Bharadva
* ! Function For User Existance
*/
function isUserExist(email, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_USER_EXIST;
  console.log(url)
  return new RestDataSource(url, fnError).GetOneByParam(email, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
/**
* ! author : Akshay Bharadva
* ! Function For User Social ID Update
*/
function updateUserSocialDetails(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_USER_SOCIAL_DETAILS_UPDATE;
  console.log(url)
  return new RestDataSource(url, fnError).Update(user, res => {
    fn(res.data);
    if (res !== null) {
      if (res.headers["jtitoken"] !== null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
    }
  });
}

function LogoutUser(user, isMobile, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_LOGOUT;
  let userMob = user;
  if (isMobile) {
    userMob = {
      "mobileNumber": user.userName
    }
  }
  return new RestDataSource(url, fnError).Store(userMob, (res) => {
    localStorage.setItem('jti-token', res.headers.jtitoken);
    fn(res.data)
  });
}

function TokenUser(fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_JWT_TRUST;
  return new RestDataSource(url, fnError).GetData(res => fn(res.data));
}

function ForgotPassword(user, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_FORGOTPASSWORD;
  return new RestDataSource(url, fnError).Store(user, (res) => {
    localStorage.setItem('jti-token', res.headers.jtitoken);
    fn(res.data)
  });
}

function updateForgotPassword(user, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_UPDATEFORGOTPASSWORD;
  return new RestDataSource(url, fnError).Store(user, (res) => {
    localStorage.setItem('jti-token', res.headers.jtitoken);
    fn(res.data);
  });
}

function ChangePassword(user, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_CHANGEPASSWORD;
  return new RestDataSource(url, fnError).Store(user, (res) => {
    localStorage.setItem('jti-token', res.headers.jtitoken);
    fn(res.data)
  });
}
function ChangeGPassword(user, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_CHANGE_G_PASSWORD;
  return new RestDataSource(url, fnError).Store(user, (res) => {
    localStorage.setItem('jti-token', res.headers.jtitoken);
    fn(res.data)
  });
}
function GetUserRegistratonType(userId, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_GET_USER_REGISTER_TYPE;
  return new RestDataSource(url, fnError).GetOneByParam(userId, (res) => {
    localStorage.setItem('jti-token', res.headers.jtitoken);
    fn(res.data)
  });
}

function ChangeAdminPassword(user, fn, fnError) {

  let url = Global_var.BASEURL + Global_var.URL_CHANGE_ADMIN_PASSWORD;
  return new RestDataSource(url, fnError).Store(user, (res) => {
    localStorage.setItem('jti-token', res.headers.jtitoken);
    fn(res.data)
  });
}

function SessionUserDetails(sessionEmailId, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_Session_DETAILS;
  return new RestDataSource(url, fnError).GetOneByParam(sessionEmailId, (res) => {
    localStorage.setItem('jti-token', res.headers.jtitoken);
    fn(res.data)
  });
}
