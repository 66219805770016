export const FETCH_LEARNING_PATH_PRODUCT_PENDING = 'FETCH_LEARNING_PATH_PRODUCT_PENDING';
export const FETCH_LEARNING_PATH_PRODUCT_SUCCESS = 'FETCH_LEARNING_PATH_PRODUCT_SUCCESS';
export const FETCH_LEARNING_PATH_PRODUCT_ERROR = 'FETCH_LEARNING_PATH_PRODUCT_ERROR';

export function fetchLearningPathProductPending() {
    return {
        type: FETCH_LEARNING_PATH_PRODUCT_PENDING,
    }
}

export function fetchLearningPathProductSuccess(learningPathProductData) {

    return {
        type: FETCH_LEARNING_PATH_PRODUCT_SUCCESS,
        payload: learningPathProductData
    }
}

export function fetchLearningPathProductError(error) {
    return {
        type: FETCH_LEARNING_PATH_PRODUCT_ERROR,
        error: error
    }
}